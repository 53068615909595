import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function caliberCategories(state = initialState.caliberCategories, action) {
    switch (action.type) {
        case types.LOAD_CALIBERCATEGORIES_SUCCESS:
            return action.caliberCategories;

        case types.CREATE_CALIBERCATEGORY_SUCCESS:
            return [
                ...state,
                Object.assign({}, action.caliberCategory)
            ];

        case types.UPDATE_CALIBERCATEGORY_SUCCESS:
            return [
                ...state.filter(caliberCategory => caliberCategory.id !== action.caliberCategory.id),
                Object.assign({}, action.caliberCategory)
            ];

        default:
            return state;
    }
}

