export const ANSWER_YES = "yes";
export const ANSWER_NO = "no";
export const ANSWER_NOT_APPLICABLE = "not_applicable";
export const ANSWER_INVALID = "invalid";
export const ANSWER_EMPTY = "empty";
export const ANSWER_NOT_COMPLIANT = "Not Compliant";
export const Not_Active = "Not Active";
export const ANSWER_COMPLIANT = "Compliant";
export const ANSWER_ACTIVE = "Active";

export const ANSWERS = {
  [ANSWER_NO]: "لا",
  [ANSWER_YES]: "نعم",
  [ANSWER_INVALID]: "لا ينطبق",
  [ANSWER_NOT_APPLICABLE]: "لا ينطبق",
  [ANSWER_EMPTY]: "لم يتم الاجابة",
  [ANSWER_NOT_COMPLIANT]: "لا",
  [ANSWER_ACTIVE]: "نعم",
};
