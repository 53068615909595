import React from "react";
export function MinusIcon(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" {...props}>
      <g
        id="Icon_feather-plus-circle"
        data-name="Icon feather-plus-circle"
        transform="translate(-2 -2)"
      >
        <path
          id="Path_48739"
          data-name="Path 48739"
          d="M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_48741"
          data-name="Path 48741"
          d="M12,18h6"
          transform="translate(9.421 1)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_48744"
          data-name="Path 48744"
          d="M6.442-10.046H6.407L4.588-4.86H8.262ZM5.054-12.568H7.9L12.832,0H10.046L8.965-2.883h-5.1L2.769,0H.158Z"
          transform="translate(8.421 24.284)"
        ></path>
      </g>
    </svg>
  );
}
