import { ajaxCallError, beginAjaxCall, endAjaxCall } from "./ajaxStatusActions";
import toastr from "toastr";
import request from "../common/request";
import {
  FETCH_DURATION_FAIL,
  FETCH_DURATION_SUCCESS,
  SA_FORM_PERIOD_LOADING,
  SA_FORM_PERIOD_STOP_LOADING,
  SA_FORM_SET_PERIOD,
  SA_FORM_UPDATE,
  SA_FORM_UPDATE_LEGAL_AGREEMENT,
  SA_FORM_RESET_PERIOD,
} from "./actionTypes";
import { replaceArabicNumbersToEnglish } from "../../utilities/helpers";
import { ANSWER_YES } from "../common/constants/caliberAnswers";

export const fetchDuration =
  (durationId, assessment_version, periodAssessmentID, isReturned) =>
  (dispatch, getState) => {
    const establishmentId = getState().establishment.establishmentProfile.id;

    dispatch(beginAjaxCall());
    dispatch(startLoadingSAFormPeriod());

    return request(
      `/v1/establishments/${establishmentId}/${
        isReturned ? "returned_assessment" : "durations"
      }/${durationId}/${assessment_version}/${periodAssessmentID}`
    )
      .then((res) => res.json())
      .then((res) => {
        dispatch(beginAjaxCall());
        dispatch({
          type: FETCH_DURATION_SUCCESS,
        });
        dispatch(endAjaxCall());
        dispatch(stopLoadingSAFormPeriod());
        if (isReturned) {
          dispatch({
            type: SA_FORM_SET_PERIOD,
            payload: { ...res, isReturned },
          });
        } else {
          setSAFormPeriod(res.duration)(dispatch);
        }
        return res.duration;
      })
      .catch((err) => {
        dispatch({
          type: FETCH_DURATION_FAIL,
        });
        dispatch(endAjaxCall());
        dispatch(stopLoadingSAFormPeriod());
        throw new Error(err);
      });
    // .finally(() => {
    //   dispatch(endAjaxCall());
    // })
  };

export const updateCaliber = (caliber) => (dispatch) => {
  dispatch({
    type: SA_FORM_UPDATE,
    payload: {
      key: "calibers",
      val: caliber,
    },
  });
};

export const updateCaliberAttachment = (attachment) => (dispatch) => {
  dispatch({
    type: SA_FORM_UPDATE,
    payload: {
      key: "attachments",
      val: attachment,
    },
  });
};

export const updateStandardCategory = (standardCategory) => (dispatch) => {
  dispatch({
    type: SA_FORM_UPDATE,
    payload: {
      key: "standardCategories",
      val: standardCategory,
    },
  });
};

export const updateCaliberCategory = (caliberCategory) => (dispatch) => {
  dispatch({
    type: SA_FORM_UPDATE,
    payload: {
      key: "caliberCategories",
      val: caliberCategory,
    },
  });
};

export const updateLegalAgreement = (legalAgreement) => (dispatch) => {
  dispatch({
    type: SA_FORM_UPDATE_LEGAL_AGREEMENT,
    payload: legalAgreement,
  });
};

export const startLoadingSAFormPeriod = () => (dispatch) => {
  dispatch({
    type: SA_FORM_PERIOD_LOADING,
  });
};
export const stopLoadingSAFormPeriod = () => ({
  type: SA_FORM_PERIOD_STOP_LOADING,
});
export const setSAFormPeriod = (period) => (dispatch) => {
  dispatch({
    type: SA_FORM_SET_PERIOD,
    payload: period,
  });
};
export const resetSAFormPeriod = () => (dispatch) => {
  dispatch({
    type: SA_FORM_RESET_PERIOD,
  });
};
// ajax actions
export const saveCaliber =
  (caliber, assessmentId, periodId, assessmentVersion, isReturned) =>
  (dispatch, getState) => {
    const { establishment } = getState();
    const establishmentId = establishment.establishmentProfile.id;

    const violationNumber =
      caliber.violation_number && typeof caliber.violation_number === "string"
        ? parseInt(replaceArabicNumbersToEnglish(caliber.violation_number))
        : caliber.violation_number
        ? parseInt(caliber.violation_number)
        : caliber.violation_number;

    const submission = {
      period_id: periodId,
      assessment_id: assessmentId,
      assessment_caliber: caliber.secondary_id,
      period_assessment_id: caliber.period_assessment_id,
      answer: caliber.answer,
      assessment_version: assessmentVersion,
      note: caliber.answer === ANSWER_YES ? "" : caliber.note,
      violation_number:
        caliber.answer === ANSWER_YES || violationNumber === ""
          ? null
          : violationNumber,
    };
    dispatch(beginAjaxCall());
    request(
      `/v1/establishments/${establishmentId}/${
        isReturned ? "returned_assessment" : "assessments"
      }`,
      "PATCH",
      {
        submission,
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status == 412) {
          dispatch(ajaxCallError(res));
          toastr.error(res.details);
        }
        dispatch(endAjaxCall());
        return res;
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };

export const saveCaliberAttachment =
  (file, attachmentId, isReturned, secondary_id) => (dispatch, getState) => {
    dispatch(beginAjaxCall());

    const {
      establishment,
      SAform: {
        attachments,
        periodId,
        assessmentId,
        PeriodAssessmentId,
        assessmentVersion,
      },
    } = getState();

    const establishmentId = establishment.establishmentProfile.id;

    const attachment = attachments.find((att) => att.id === attachmentId);
    const data = new FormData();
    data.append("attachment", file);
    data.append("id", attachmentId);
    data.append("mandatory", attachment.mandatory);
    data.append("period_id", periodId);
    data.append("assessment_version", assessmentVersion);
    data.append("attachment_name", attachment.attachment_name);
    data.append("assessment_id", assessmentId);
    data.append("type", file.type);
    data.append("name", file.name);
    data.append("assessment_caliber_id", secondary_id);

    return request(
      `/v1/establishments/${establishmentId}/${
        isReturned ? "returned_assessment" : "assessments"
      }/attachment`,
      "POST",
      data,
      { upload: true }
    )
      .then((res) => res.json())
      .then(({ attachment: submissionAttachment }) => {
        updateCaliberAttachment({
          ...attachment,
          ...submissionAttachment,
          attachment_name: attachment.attachment_name,
        })(dispatch);
        dispatch(endAjaxCall());
        return submissionAttachment;
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };

export const deleteCaliberAttachment =
  (attachmentId, isReturned) => (dispatch, getState) => {
    dispatch(beginAjaxCall());

    const { establishment, SAform } = getState();
    const establishmentId = establishment.establishmentProfile.id;

    const attachment = SAform.attachments.find(
      (att) => att.id === attachmentId
    );

    return request(
      `/v1/establishments/${establishmentId}/${
        isReturned ? "returned_assessment" : "assessments"
      }/attachment`,
      "DELETE",
      { submission_attachment_id: attachment.submission_attachment_id.UUID }
    )
      .then(() => {
        updateCaliberAttachment({
          ...attachment,
          type: "",
          name: "",
          submission_attachment_id: {
            Valid: false,
          },
          url_attachment: "",
        })(dispatch);
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };

export const downloadCaliberAttachment =
  (attachment) => (dispatch, getState) => {
    dispatch(beginAjaxCall());

    const { establishment } = getState();
    const establishmentId = establishment.establishmentProfile.id;
    const submissionAttachmentId = attachment.submission_attachment_id.UUID;

    return request(
      `/v1/establishments/${establishmentId}/assessments/attachment/${submissionAttachmentId}`,
      "GET"
    )
      .then((res) => res.json())
      .then((blob) => {
        updateCaliberAttachment({
          ...attachment,
          file: blob,
        })(dispatch);
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };

export const downloadURI = (url, filename) => {
  fetch(url).then(function (t) {
    return t.blob().then((b) => {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
    });
  });
};

export function getSurvay(establishment_id) {
  return request(`/v1/establishments/${establishment_id}/survey`)
    .then((res) => res.json())
    .then((result) => {
      // console.log("result",result);
      return result;
    })
    .catch((error) => {
      throw error;
    });
}
