import initialState from "./initialState";
import {
  CERTIFICATE_START_LOADING,
  CERTIFICATE_STOP_LOADING,
  UNIFIED_FETCH_FAST_TRACK_RESULT_SUCCESS,
  CERTIFICATE_SET_ERROR,
  CERTIFICATE_SHOW_CERTIFICATE,
  CERTIFICATE_HIDE_CERTIFICATE,
} from "../actions/actionTypes";

export default function certificate(state = initialState.certificate, action) {
  switch (action.type) {
    case CERTIFICATE_START_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
      });
    case CERTIFICATE_STOP_LOADING:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case UNIFIED_FETCH_FAST_TRACK_RESULT_SUCCESS:
      return Object.assign({}, state, {
        auditReport: action.auditReport,
        reports: action.reports,
      });
    case CERTIFICATE_SET_ERROR:
      return Object.assign({}, state, {
        error: true,
        isLoading: false,
      });

    case CERTIFICATE_SHOW_CERTIFICATE:
      return Object.assign({}, state, {
        isVisible: true,
      });

    case CERTIFICATE_HIDE_CERTIFICATE:
      return Object.assign({}, state, {
        isVisible: false,
      });
    default:
      return state;
  }
}
