import delay from './delay';
import moment from 'moment';

const options = {year: 'numeric', month: 'numeric', day: 'numeric'};

const periodSelfAssessments = [
  {
    id: '1',
    name: 'فترة التقييم 1',
    duration: '124',
    start_date: {
      String: moment().subtract(3, 'days').format('YYYY-MM-DD'),
      Valid: true,
    },
    end_date: {
      String: moment().add(3, 'days').format('YYYY-MM-DD'),
      Valid: true,
    },
    stop_date: {
      String: null,
      Valid: true,
    },
    stop_reason: {
      String: null,
      Valid: true,
    },
    status: '1',
    state: 'Enabled',
    explanation: '',
    assessment: {
      id: '2',
      name: 'Form 2',
      status: true,
      id: '1',
      secondary_id: '1',
      name: 'Assessment 1',
      is_active: false,
      is_deleted: false,
      standard_categories: [
        {
          id: '1',
          secondary_id: '1',
          name: 'Standard category 1',
          description: 'Standard category description 1',
          weight: 100,
          caliber_categories: [
            {
              id: '1',
              secondary_id: '1',
              name: 'Caliber category 1',
              description: 'Caliber category description 1',
              weight: 1,
              calibers: [
                {
                  id: '1',
                  secondary_id: '1',
                  name: {
                    String: 'Caliber 1',
                    Valid: true,
                  },
                  violation_category: {
                    id: '1',
                    name: 'بسيطة',
                    rank: 0
                  },
                  description: 'Caliber text',
                  corrections: 'Caliber text corrections',
                  min_penalty_amount: 0,
                  max_penalty_amount: 1,
                  caliber_type: {
                    id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                    name: 'معيار كمي'
                  },
                  question: {
                    id: '1',
                    secondary_id: '1',
                    name: '',
                    answer: '',
                    is_updated: false,
                    is_deleted: false,
                  },
                  weight: 2,
                  answer: {
                    String: '',
                    Valid: true,
                  },
                  caliber_label: {
                    id: '1',
                    name: 'اشتراطات عامة للمستشفيات ',
                    is_updated: false,
                    is_deleted: false
                  },
                  caliber_category: {
                    name: '',
                    specialization_name: ''
                  },
                  is_updated: false,
                  is_deleted: false,
                  attachment_categories: [
                    {
                      id: '1',
                      name: 'Attachment categories 1',
                      assessment_caliber_id: '1',
                      attachment_id: '1',
                      is_mandatory: true
                    },
                    {
                      id: '2',
                      name: 'Attachment categories 2',
                      assessment_caliber_id: '1',
                      attachment_id: '2',
                      is_mandatory: true
                    }
                  ]
                }
              ],
              specialization_id: '',
              specialization_name: '',
              completion_percentage: 0,
              is_deleted: false,
              is_updated: false
            }
          ],
          is_deleted: false,
          is_updated: false
        },
        {
          id: '2',
          secondary_id: '2',
          name: 'Standard category 2',
          description: 'Standard category description 2',
          weight: 100,
          caliber_categories: [
            {
              id: '2',
              secondary_id: '2',
              name: 'Caliber category 2',
              description: 'Caliber category description 2',
              weight: 1,
              calibers: [
                {
                  id: '1',
                  secondary_id: '1',
                  name: {
                    String: 'Caliber 1',
                    Valid: true,
                  },
                  violation_category: {
                    id: '1',
                    name: 'بسيطة',
                    rank: 0
                  },
                  description: 'Caliber text',
                  corrections: 'Caliber text corrections',
                  min_penalty_amount: 0,
                  max_penalty_amount: 1,
                  caliber_type: {
                    id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                    name: 'معيار كمي'
                  },
                  question: {
                    id: '1',
                    secondary_id: '1',
                    name: '',
                    answer: '',
                    is_updated: false,
                    is_deleted: false,
                  },
                  weight: 2,
                  answer: {
                    String: '',
                    Valid: true,
                  },
                  caliber_label: {
                    id: '1',
                    name: 'اشتراطات عامة للمستشفيات ',
                    is_updated: false,
                    is_deleted: false
                  },
                  caliber_category: {
                    name: '',
                    specialization_name: ''
                  },
                  is_updated: false,
                  is_deleted: false,
                  attachment_categories: [
                    {
                      id: '1',
                      name: 'Attachment categories 1',
                      assessment_caliber_id: '1',
                      attachment_id: '1',
                      is_mandatory: true
                    }
                  ]
                },
                {
                  id: '2',
                  secondary_id: '2',
                  name: {
                    String: 'Caliber 2',
                    Valid: true,
                  },
                  description: 'Caliber text',
                  corrections: 'Caliber text corrections',
                  caliber_type: {
                    id: '119cc392-2d69-4a3e-a153-fd3573ac3f2c',
                    name: 'معيار كمي'
                  },
                  answer: {
                    String: '',
                    Valid: true,
                  },
                  question: {
                    id: '1',
                    secondary_id: '1',
                    name: '',
                    answer: '',
                  },
                },
                {
                  id: '3',
                  secondary_id: '3',
                  name: {
                    String: 'Caliber 3',
                    Valid: true,
                  },
                  description: 'Caliber text',
                  corrections: 'Caliber text corrections',
                  caliber_type: {
                    id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                    name: 'معيار كمي'
                  },
                  answer: {
                    String: '',
                    Valid: true,
                  },
                  question: {
                    id: '1',
                    secondary_id: '1',
                    name: {
                      String: 'Caliber question',
                      Valid: true,
                    },
                    answer: {
                      String: '',
                      Valid: true,
                    },
                  },
                },
              ],
              specialization_id: '',
              specialization_name: '',
              completion_percentage: 0,
              is_deleted: false,
              is_updated: false
            },
            {
              id: '3',
              secondary_id: '3',
              name: 'Caliber category 3',
              description: 'Caliber category description 3',
              weight: 1,
              calibers: [
                {
                  id: '1',
                  secondary_id: '1',
                  name: {
                    String: 'Caliber 1',
                    Valid: true,
                  },
                  violation_category: {
                    id: '1',
                    name: 'بسيطة',
                    rank: 0
                  },
                  description: 'Caliber text',
                  corrections: 'Caliber text corrections',
                  min_penalty_amount: 0,
                  max_penalty_amount: 1,
                  caliber_type: {
                    id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                    name: 'معيار كمي'
                  },
                  question: {
                    id: '1',
                    secondary_id: '1',
                    name: '',
                    answer: '',
                    is_updated: false,
                    is_deleted: false,
                  },
                  weight: 2,
                  answer: {
                    String: '',
                    Valid: true,
                  },
                  caliber_label: {
                    id: '1',
                    name: 'اشتراطات عامة للمستشفيات ',
                    is_updated: false,
                    is_deleted: false
                  },
                  caliber_category: {
                    name: '',
                    specialization_name: ''
                  },
                  is_updated: false,
                  is_deleted: false,
                  attachments: [
                    {
                      id: '1',
                      name: 'Attachment categories 1',
                      assessment_caliber_id: '1',
                      attachment_id: '1',
                      is_mandatory: true
                    }
                  ]
                },
                {
                  id: '2',
                  secondary_id: '2',
                  name: {
                    String: 'Caliber 2',
                    Valid: true,
                  },
                  description: 'Caliber text',
                  corrections: 'Caliber text corrections',
                  caliber_type: {
                    id: '119cc392-2d69-4a3e-a153-fd3573ac3f2c',
                    name: 'معيار كمي'
                  },
                  answer: {
                    String: '',
                    Valid: true,
                  },
                  question: {
                    id: '1',
                    secondary_id: '1',
                    name: '',
                    answer: '',
                  },
                },
              ],
              specialization_id: '',
              specialization_name: '',
              completion_percentage: 0,
              is_deleted: false,
              is_updated: false
            },
          ],
          is_deleted: false,
          is_updated: false
        }
      ],
    },
  },
  {
    id: '2',
    name: 'فترة التقييم 2',
    duration: '124',
    start_date: {
      String: '2017-09-19',
      Valid: true,
    },
    end_date: {
      String: '2018-01-30',
      Valid: true,
    },
    stop_date: {
      String: null,
      Valid: true,
    },
    stop_reason: {
      String: null,
      Valid: true,
    },
    status: '2',
    state: 'Submitted',
    explanation: '',
    completionPercentage: 20,
    assessment: {
      id: '2',
      name: 'Form 2',
      establishmentCategory: '3',
      status: true,
      standard_categories: [
        {
          id: '1',
          name: 'عام',
          secondary_id: '1',
          completionPercentage: 20,
          weight: '100',
          status: false,
          caliber_categories: [
            {
              id: '1',
              secondary: '1',
              name: 'الانظمة واللوائح',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل يوجد في المستشفى قسم مستقل للإدارة',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '2',
                      name: 'هل مدير المستشفى مؤهل تأهيل جامعي',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '3',
                      name: 'هل المدير الطبي سعودي',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '4',
                      name: 'هل التهوية داخل المبنى جيدة',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '5',
                      name: 'هل يوجد أماكن مناسبة لإنتظار الرجال وأخرى منفصلة لانتظار النساء؟ ',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '6',
                      name: 'هل صالات الانتظار مجهزة بالاثاث المناسب والماء البارد الصالح للشرب؟  ',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '7',
                      name: 'هل يوجد دورات مياه كافية ونظيفة؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '8',
                      name: 'هل المنشأة مكيفه صيفاً و شتاءً؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '9',
                      name: 'هل المنشأة نظيفة ولائقة المظهر ؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '10',
                      name: 'هل توجد مداخل ومخارج مخصصة لذوي الاحتياجات الخاصة؟ ',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '12',
                      name: 'هل توجد لوحة عند مدخل المجمع توضح اسمه وتخصصه حسب الترخيص الممنوح له ومواعيد العمل؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '13',
                      name: 'هل اللائحة الداخلية للمنشأة شاملة لكل ما يخص العاملين بها وفقا لما حدده النظام؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '14',
                      name: 'هل يوجد نظام حديث لتسجيل المواعيد والاستعلام؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '15',
                      name: 'هل يوجد مكان مناسب للتنظيم وحفظ ملفات المرضى؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    }

                  ],
            },
            {
              id: '2',
              caliberCategoryId: '2',
              caliberCategoryName: 'الحد الادنى من الكوادر',
              caliberSubjects: [
                {
                  id: '1',
                  name: '',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل عدد الإستشاريين يتناسب مع عددالتخصصات المرخص بها',
                      description: 'وجود عدد كافي من الإستشاريين واحد لكل ثلاثين سرير كحد أقصى',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '2',
                      name: 'هل عدد الإخصائيين يتناسب مع عددالتخصصات المرخص بها',
                      description: 'وجود عدد كافي من الاخصائيين واحد لكل عشرين سرير كحد أقصى',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                  ],
                },
              ],

            },
            {
              id: '3',
              caliberCategoryId: '3',
              caliberCategoryName: 'العيادات الخارجية',
              caliberSubjects: [
                {
                  id: '1',
                  name: '',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل يوجد في العيادات الخارجية مكان خاص لضماد الرجال',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                  ],
                },
              ],

            },
          ],
        },
        {
          id: '2',
          name: 'أشعة',
          secondary_id: '2',
          completionPercentage: 20,
          weight: '100',
          status: false,
          caliber_categories: [
            {
              id: '3',
              cecondary_id: '3',
              name: 'متطلبات وإشتراطات قسم الاشعة',
              description: 'متطلبات وإشتراطات قسم الاشعة',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل توجد أجهزة أشعة عامة',
                      description: 'هل توجد أجهزة أشعة عامة',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '2',
                      name: 'هل توجد أجهزة أشعة مقطعية',
                      description: 'هل توجد أجهزة أشعة مقطعية',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
              ],
            },
          ],
        },
        {
          id: '3',
          name: 'خدمات مساندة',
          secondary_id: '3',
          completionPercentage: 20,
          weight: '100',
          status: false,
          caliber_categories: [
            {
              id: '5',
              secondary_id: '5',
              name: 'متطلبات وإشتراطات الخدمات المساندة',
              description: 'متطلبات وإشتراطات الخدمات المساندة',

                  calibers: [
                    {
                      id: '1',
                      name: 'هل تم التعاقد مع شركة تعقيم معتمدة',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: '119cc392-2d69-4a3e-a153-fd3573ac3f2c',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'رخصة فتح منشأة ',
                          answer: '',

                        },
                        {
                          id: '2',
                          name: 'صورة من ترخيص الكادر',
                          answer: '',

                        },
                      ],
                    },

              ],
            },
          ],
        },
      ],
      questionList: [
        {
          id: '1',
          question: 'عدد الاسرة الموجودة',
          answer: '',
        },
        {
          id: '2',
          question: 'عدد الكادر الطبي',
          answer: '',

        },
        {
          id: '3',
          question: 'هل يوجد لديك صيدلية',
          answer: '',

        },
      ],
    },

  },
  {
    id: '3',
    name: 'فترة التقييم 3',
    duration: '7',
    start_date: {
      String: '2017-01-01',
      Valid: true,
    },
    end_date: {
      String: '2017-01-08',
      Valid: true,
    },
    stop_date: {
      String: null,
      Valid: true,
    },
    stop_reason: {
      String: null,
      Valid: true,
    },
    status: '5',
    state: 'Terminated',
    explanation: '',
    completionPercentage: 57,
    assessment: {
      id: '2',
      name: 'Form 2',
      establishmentCategory: '3',
      status: false,
      caliberCategoryList: [],
      standard_categories: [],
      questionList: [
        {
          id: '1',
          question: '1',
          answer: '',

        },
        {
          id: '2',
          question: '2',
          answer: '',

        },
        {
          id: '3',
          question: '3',
          answer: '',
        },
      ],
    },

  },
  {
    id: '4',
    name: 'فترة التقييم 4',
    duration: '30',
    start_date: {
      String: '2017-11-28',
      Valid: true,
    },
    end_date: {
      String: '2017-11-09',
      Valid: true,
    },
    stop_date: {
      String: '2017-10-25',
      Valid: true,
    },
    stop_reason: {
      String: null,
      Valid: true,
    },
    status: '3',
    state: 'Terminated',
    explanation: 'لا يوجد',
    completionPercentage: 90,
    assessment: {
      id: '2',
      name: 'Form 2',
      establishmentCategory: '3',
      status: true,
      standard_categories: [
        {
          id: '1',
          name: 'عام',
          secondary_id: '1',
          completionPercentage: 20,
          weight: '100',
          status: false,
          caliber_categories: [
            {
              id: '1',
              secondary_id: '1',
              name: 'الانظمة واللوائح',
              description: 'الانظمة واللوائح',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل يوجد في المستشفى قسم مستقل للإدارة',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '2',
                      name: 'هل مدير المستشفى مؤهل تأهيل جامعي',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '3',
                      name: 'هل المدير الطبي سعودي',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '4',
                      name: 'هل التهوية داخل المبنى جيدة',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },

                  ],
            },
            {
              id: '2',
              secondary_id: '2',
              name: 'الحد الادنى من الكوادر',
              description: 'الحد الادنى من الكوادر',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل عدد الإستشاريين يتناسب مع عددالتخصصات المرخص بها',
                      description: 'وجود عدد كافي من الإستشاريين واحد لكل ثلاثين سرير كحد أقصى',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '2',
                      name: 'هل عدد الإخصائيين يتناسب مع عددالتخصصات المرخص بها',
                      description: 'وجود عدد كافي من الاخصائيين واحد لكل عشرين سرير كحد أقصى',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                  ],
            },
            {
              id: '3',
              secondary_id: '3',
              name: 'العيادات الخارجية',
              description: 'العيادات الخارجية',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل يوجد في العيادات الخارجية مكان خاص لضماد الرجال',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                  ],

            },
          ],
        },
        {
          id: '2',
          name: 'أشعة',
          secondary_id: '2',
          completionPercentage: 20,
          weight: '100',
          status: false,
          caliber_categories: [
            {
              id: '3',
              secondary_id: '3',
              name: 'متطلبات وإشتراطات قسم الاشعة',
              description: 'متطلبات وإشتراطات قسم الاشعة',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل توجد أجهزة أشعة عامة',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '2',
                      name: 'هل توجد أجهزة أشعة مقطعية',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                  ],
            },
          ],
        },
        {
          id: '3',
          name: 'خدمات مساندة',
          secondary_id: '3',
          completionPercentage: 20,
          weight: '100',
          status: false,
          caliber_categories: [
            {
              id: '5',
              secondary_id: '5',
              name: 'متطلبات وإشتراطات الخدمات المساندة',
              description: 'متطلبات وإشتراطات الخدمات المساندة',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل تم التعاقد مع شركة تعقيم معتمدة',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: '119cc392-2d69-4a3e-a153-fd3573ac3f2c',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                    },
              ],
            },
          ],
        },
      ],
      attachmentCategoryList: [],
      questionList: [],
    },

  },

  {
    id: '5',
    name: 'فترة التقييم 5',
    duration: '124',
    start_date: {
      String: '2018-09-19',
      Valid: true,
    },
    end_date: {
      String: '2019-01-30',
      Valid: true,
    },
    stop_date: {
      String: null,
      Valid: true,
    },
    stop_reason: {
      String: null,
      Valid: true,
    },
    status: '1',
    state: 'Enabled',
    explanation: '',
    completionPercentage: 20,
    assessment: {
      id: '2',
      name: 'Form 2',
      establishmentCategory: '3',
      status: true,
      standard_categories: [
        {
          id: '1',
          name: 'عام',
          secondary_id: '1',
          completionPercentage: 20,
          weight: '100',
          status: false,
          caliber_categories: [
            {
              id: '1',
              secondary_id: '1',
              name: 'الانظمة واللوائح',
              description: 'الانظمة واللوائح',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل يوجد في المستشفى قسم مستقل للإدارة',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '2',
                      name: 'هل مدير المستشفى مؤهل تأهيل جامعي',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '3',
                      name: 'هل المدير الطبي سعودي',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '4',
                      name: 'هل التهوية داخل المبنى جيدة',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '5',
                      name: 'هل يوجد أماكن مناسبة لإنتظار الرجال وأخرى منفصلة لانتظار النساء؟ ',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '6',
                      name: 'هل صالات الانتظار مجهزة بالاثاث المناسب والماء البارد الصالح للشرب؟  ',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '7',
                      name: 'هل يوجد دورات مياه كافية ونظيفة؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '8',
                      name: 'هل المنشأة مكيفه صيفاً و شتاءً؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '9',
                      name: 'هل المنشأة نظيفة ولائقة المظهر ؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '10',
                      name: 'هل توجد مداخل ومخارج مخصصة لذوي الاحتياجات الخاصة؟ ',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '12',
                      name: 'هل توجد لوحة عند مدخل المجمع توضح اسمه وتخصصه حسب الترخيص الممنوح له ومواعيد العمل؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '13',
                      name: 'هل اللائحة الداخلية للمنشأة شاملة لكل ما يخص العاملين بها وفقا لما حدده النظام؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '14',
                      name: 'هل يوجد نظام حديث لتسجيل المواعيد والاستعلام؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '15',
                      name: 'هل يوجد مكان مناسب للتنظيم وحفظ ملفات المرضى؟',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    }

                  ],
            },
            {
              id: '2',
              secondary_id: '2',
              name: 'الحد الادنى من الكوادر',
              description: 'الحد الادنى من الكوادر',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل عدد الإستشاريين يتناسب مع عددالتخصصات المرخص بها',
                      description: 'وجود عدد كافي من الإستشاريين واحد لكل ثلاثين سرير كحد أقصى',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                    {
                      id: '2',
                      name: 'هل عدد الإخصائيين يتناسب مع عددالتخصصات المرخص بها',
                      description: 'وجود عدد كافي من الاخصائيين واحد لكل عشرين سرير كحد أقصى',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ]
                    },
                  ],

            },
            {
              id: '3',
              secondary_id: '3',
              name: 'العيادات الخارجية',
              description: 'العيادات الخارجية',
                  calibers: [
                    {
                      id: '1',
                      name: 'هل يوجد في العيادات الخارجية مكان خاص لضماد الرجال',
                      description: '',
                      question: '',
                      answer: '',
                      caliber_type: {
                        id: 'c0e82824-7800-4cc6-a072-530e1f004b61',
                        name: 'معيار كمي'
                      },
                      answer: {
                        String: '',
                        Valid: true,
                      },
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'Attachment categories 1',
                          assessment_caliber_id: '1',
                          attachment_id: '1',
                          is_mandatory: true
                        },
                        {
                          id: '2',
                          name: 'Attachment categories 2',
                          assessment_caliber_id: '1',
                          attachment_id: '2',
                          is_mandatory: true
                        }
                      ],
                      attachment_categories: [
                        {
                          id: '1',
                          name: 'رخصة فتح منشأة',
                          answer: '',

                        },
                        {
                          id: '2',
                          name: 'صورة من ترخيص الكادر',
                          answer: '',

                        },
                      ],
                    },
                  ],

            },
          ],
        },
      ],
      questionList: [
        {
          id: '1',
          question: 'عدد الاسرة الموجودة',
          answer: '',
        },
        {
          id: '2',
          question: 'عدد الكادر الطبي',
          answer: '',

        },
        {
          id: '3',
          question: 'هل يوجد لديك صيدلية',
          answer: '',

        },
      ],
    },

  },
];

const periodSelfAssessmentStatus = [
  {
    id: '1',
    name: 'فارغ',
  },
  {
    id: '2',
    name: 'مكتمل جزئي',
  },
  {
    id: '3',
    name: 'مكتمل',
  },
  {
    id: '4',
    name: 'إنتهى الوقت',
  },
  {
    id: '5',
    name: 'ملغى',
  },

];

const generateId = (periodSelfAssessment) => {
  return periodSelfAssessment.name.toLowerCase();
};

class EstablishmentAssessmentsApi {
  static getAllPeriodSelfAssessments() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(Object.assign([], periodSelfAssessments.map(periodSelfAssessment => {
          const periodSelfAssessmentStatusItem = this.getPeriodSelfAssessmentStatus(periodSelfAssessment.status);
          periodSelfAssessment.status_name = periodSelfAssessmentStatusItem.name;
          const today = this.convertToDate(new Date(Date.now()).toLocaleString('en-GB', options));
          const stoppedDate = this.convertToDate(periodSelfAssessment.stop_date);
          const startDate = this.convertToDate(periodSelfAssessment.start_date);
          if (periodSelfAssessment.status === '3') {
            periodSelfAssessment.elapsedDays = periodSelfAssessment.periodDays;
          } else {
            if (periodSelfAssessment.status === '4' || periodSelfAssessment.status === '3') {
              periodSelfAssessment.elapsedDays = periodSelfAssessment.periodDays;
            } else {
              if (periodSelfAssessment.status === '5') {
                periodSelfAssessment.elapsedDays = Math.abs((stoppedDate - startDate) / (24 * 60 * 60 * 1000));
              } else {
                // periodSelfAssessment.elapsedDays =12;
                if (periodSelfAssessment.status === '2' || periodSelfAssessment.status === '1') {
                  periodSelfAssessment.elapsedDays = Math.abs((today - startDate) / (24 * 60 * 60 * 1000));
                }
              }
            }
          }

          return periodSelfAssessment;
        })));
      }, delay);
    });
  }

  static getActivePeriodSelfAssessments() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(Object.assign([], periodSelfAssessments.map(periodSelfAssessment => {
          const periodSelfAssessmentStatusItem = this.getPeriodSelfAssessmentStatus(periodSelfAssessment.status);
          periodSelfAssessment.status_name = periodSelfAssessmentStatusItem.name;

          return periodSelfAssessment;
        })));
      }, delay);
    });
  }

  static getPeriodSelfAssessments() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(Object.assign([], periodSelfAssessments.map(periodSelfAssessment => {
          const periodSelfAssessmentStatusItem = this.getPeriodSelfAssessmentStatus(periodSelfAssessment.status);
          periodSelfAssessment.status_name = periodSelfAssessmentStatusItem.name;
          const today = this.convertToDate(new Date(Date.now()).toLocaleString('en-GB', options));
          const stoppedDate = this.convertToDate(periodSelfAssessment.stop_date);
          const startDate = this.convertToDate(periodSelfAssessment.start_date);

          if (periodSelfAssessment.status === '4' || periodSelfAssessment.status === '4') {
            periodSelfAssessment.elapsedDays = periodSelfAssessment.periodDays;
          } else {
            if (periodSelfAssessment.status === '5') {
              periodSelfAssessment.elapsedDays = Math.abs((stoppedDate - startDate) / (24 * 60 * 60 * 1000));
            } else {
              // periodSelfAssessment.elapsedDays =12;
              if (periodSelfAssessment.status === '2' || periodSelfAssessment.status === '1') {
                periodSelfAssessment.elapsedDays = Math.abs((today - startDate) / (24 * 60 * 60 * 1000));
              }
            }
          }

          return periodSelfAssessment;
        })));
      }, delay);
    });
  }

  static getAllPeriodSelfAssessmentStatuses() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(Object.assign([], periodSelfAssessmentStatus));
      }, delay);
    });
  }

  static convertToDate(date) {
    var split = date.split('-');
    return new Date(split[0], split[1], split[2]);
  }

  static getSearchPeriodSelfAssessments(searchPeriodSelfAssessment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(Object.assign([], periodSelfAssessments.map(periodSelfAssessment => {
          const periodSelfAssessmentStatusItem = this.getPeriodSelfAssessmentStatus(periodSelfAssessment.status);
          periodSelfAssessment.status_name = periodSelfAssessmentStatusItem.name;
          return periodSelfAssessment;
        }).filter(periodSelfAssessment => periodSelfAssessment.status.includes(searchPeriodSelfAssessment.status_id) && (
          periodSelfAssessment.name.includes(searchPeriodSelfAssessment.searchText)),
        )));
      }, delay);
    });
  }

  static getPeriodSelfAssessmentStatus(status_Id) {
    const existingPeriodSelfAssessmentStatusIndex = periodSelfAssessmentStatus.findIndex(a => a.id === status_Id);
    return periodSelfAssessmentStatus[existingPeriodSelfAssessmentStatusIndex];
  }

  static savePeriodSelfAssessment(periodSelfAssessment) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Simulate server-side validation
        const minPeriodSelfAssessmentNameLength = 3;
        if (!periodSelfAssessment || !periodSelfAssessment.name || periodSelfAssessment.name.length < minPeriodSelfAssessmentNameLength) {
          reject(`Name must be at least ${minPeriodSelfAssessmentNameLength} characters.`);
        }
        if (periodSelfAssessment.id) {
          const existingPeriodSelfAssessmentIndex = periodSelfAssessments.findIndex(a => a.id === periodSelfAssessment.id);
          periodSelfAssessments.splice(existingPeriodSelfAssessmentIndex, 1, periodSelfAssessment);
        } else {
          periodSelfAssessment.id = generateId(periodSelfAssessment);
          periodSelfAssessments.push(periodSelfAssessment);
        }

        resolve(Object.assign({}, periodSelfAssessment));
      }, delay);
    });
  }

  static activatePeriodSelfAssessment(periodSelfAssessment) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const today = new Date(Date.now());
        const periodSelfAssessmentTemp = periodSelfAssessment;

        if (periodSelfAssessmentTemp.id) {
          const existingPeriodSelfAssessmentIndex = periodSelfAssessments.findIndex(a => a.id === periodSelfAssessmentTemp.id);
          periodSelfAssessments.splice(existingPeriodSelfAssessmentIndex, 1, periodSelfAssessmentTemp);
          // periodSelfAssessments[this.getIndexOf(periodSelfAssessmentTemp.id)] = periodSelfAssessmentTemp;

        } else {
          periodSelfAssessment.id = generateId(periodSelfAssessment);
          periodSelfAssessments.push(periodSelfAssessment);
        }

        resolve(Object.assign({}, periodSelfAssessment));
      }, delay);
    });
  }

  static getIndexOf(id) {
    return periodSelfAssessments.findIndex(a => a.id === id);
  }

  static stoppedPeriodSelfAssessment(periodSelfAssessment) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const today = new Date(Date.now());
        const periodSelfAssessmentTemp = {
          id: periodSelfAssessment.id,
          name: periodSelfAssessment.name,
          duration: periodSelfAssessment.periodDays,
          start_date: periodSelfAssessment.startDate,
          end_date: periodSelfAssessment.endDate,
          stop_date: new Date(today.getTime()).toLocaleString('en-GB', options),
          status: '3',
          explanation: periodSelfAssessment.explanation,
          assessments: periodSelfAssessment.assessments,
        };

        if (periodSelfAssessmentTemp.id) {
          const existingPeriodSelfAssessmentIndex = periodSelfAssessments.findIndex(a => a.id === periodSelfAssessmentTemp.id);
          periodSelfAssessments.splice(existingPeriodSelfAssessmentIndex, 1, periodSelfAssessmentTemp);
        } else {
          periodSelfAssessment.id = generateId(periodSelfAssessment);
          periodSelfAssessments.push(periodSelfAssessment);
        }

        resolve(Object.assign({}, periodSelfAssessment));
      }, delay);
    });
  }

  static deletePeriodSelfAssessment(periodSelfAssessmentId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const indexOfPeriodSelfAssessmentToDelete = periodSelfAssessments.findIndex(periodSelfAssessment =>
          periodSelfAssessment.id === periodSelfAssessmentId,
        );
        periodSelfAssessments.splice(indexOfPeriodSelfAssessmentToDelete, 1);
        resolve();
      }, delay);
    });
  }

  static getPeriodSelfAssessment(periodSelfAssessmentId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const indexOfPeriodSelfAssessment = periodSelfAssessments.findIndex(periodSelfAssessment =>
          periodSelfAssessment.id === periodSelfAssessmentId,
        );
        resolve(periodSelfAssessments[indexOfPeriodSelfAssessment]);
      }, delay);
    });
  }

  static savePeriodSelfAssessmentAnswer(periodSelfAssessment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(periodSelfAssessment);
      }, delay);
    });
  }

  static savePeriodSelfAssessmentAttachment(periodSelfAssessment) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(periodSelfAssessment);
      }, delay);
    });
  }
}

export default EstablishmentAssessmentsApi;
