import React, { useState, useEffect } from "react";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import "./CookieBanner.scss";
import Button from "../Button/Button";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookiesAccepted");
    if (!isCookieAccepted) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="frame">
        <Text
          fontSize={"font-14"}
          textType="roman"
          mobileFontSize="font-15-mobile"
          className={"cookies"}
        >
          نحن نستخدم ملفات تعريف الارتباط (cookies) لتقديم أفضل تجربة ممكنة على موقعنا. يرجى العلم أنه من خلال استمرارك
          في استخدام هذا الموقع، أو إغلاق هذه الرسالة، فإنك توافق على استخدامنا لملفات تعريف الارتباط. لمعرفة المزيد،
          يرجى الاطلاع على
          {" "}
          <a href={`${process.env.REACT_APP_ESTABLISHMENT_BASE_URI}/#/privacyPolicy`}
             target="_blank">
            <Text
              Tag="span"
              fontSize={"font-14"}
              textType="bold"
              mobileFontSize={"font-16-mobile"}
              mobileTextType="bold"
              className={"text-wrapper-link"}>
              سياسة الخصوصية.
            </Text>
          </a>
        </Text>

          <Button
            onClick={acceptCookies}
            text="قبول الكل"
            isBold={false}
            fontSize={"font-14"}
            customTextSize="bold"
            customStyle = "buttons"
          />

      </div>
    )
  );
};

export default CookieBanner;
