import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Col, Collapse, Row } from "reactstrap";

import CaliberAnswer from "./CaliberAnswer";
import CaliberParagraph from "./CaliberParagraph";
import CaliberAttachment from "./CaliberAttachment";
import CaliberDescription from "./CaliberDescription";

import "./caliber.scss";
import { Text } from "../../../../../../shared-components/my-scope/atoms/text/index";
import { ASSESSMENT_TYPE_BASIC } from "../../../../common/constants/assessmentStatuses";
import CaliberAuditComments from "./CaliberAuditComments";
import { PrevAnswerInDetailedReport } from "../../../../components/establishmentprofile/PrevAnswerInDetailedReport";
export const Caliber = ({
  caliberCategoryIndex,
  caliberIndex,
  standardCategoryIndex,
  caliber,
  assessmentType,
  isShowAuditResult,
}) => {
  const isFirstItem =
    !caliberCategoryIndex && !caliberIndex && !standardCategoryIndex;
  const [isOpen, setIsOpen] = useState(isFirstItem);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  const isShowAuditSection =
    isShowAuditResult && assessmentType === ASSESSMENT_TYPE_BASIC;
  const isShowAuditAttachments =
    isShowAuditSection &&
    caliber.audit_answer &&
    caliber.audit_answer.attachments &&
    caliber.audit_answer.attachments.length > 0;
  const isShowAuditComments =
    isShowAuditSection &&
    caliber.audit_answer &&
    caliber.audit_answer.all_audit_comments &&
    caliber.audit_answer.all_audit_comments.length > 0;
  return (
    <div className="caliber">
      <header
        onClick={toggle}
        className="caliber-header"
        id={`caliber-${caliberIndex + 1}-header-button`}
      >
        <Text
          textType="roman"
          fontSize="font-20"
          mobileFontSize="font-16-mobile"
          className="caliber-header__text"
        >{`البند ${caliberCategoryIndex + 1}.${caliberIndex + 1}`}</Text>
        <i
          className={`caliber-header__icon fa fa-chevron-${
            isOpen ? "up" : "down"
          }`}
        />
      </header>
      <Collapse isOpen={isOpen}>
        <main className="caliber-main">
          <div className="caliber-mainــpadding">
            {assessmentType === ASSESSMENT_TYPE_BASIC &&
            isShowAuditResult &&
            caliber.name ? (
              <CaliberParagraph title="شرح البند" desc={caliber.name.String} />
            ) : (
              <CaliberParagraph title="شرح البند" desc={caliber.name} />
            )}

            {caliber.corrections && (
              <CaliberParagraph
                title="المرجعية النظامية"
                desc={caliber.corrections}
              />
            )}
            {caliber.description && (
              <CaliberDescription description={caliber.description} />
            )}
            {/* {caliber.reference && (
            <CaliberParagraph
              title="رقم المرجعية النظامية"
              desc={caliber.reference}
            />
          )} */}
            {/* <div className="caliber-description">
            <header className="caliber-description-header">
        <Text
          textType="p4"
          isBold
          className="caliber-description-header__title"
        >
          تم ارجاع هذا البند وتعديل إجابته من قبل المنشأة
        </Text>
      </header>
      </div> */}
            {caliber.is_answered !== null && (
              <PrevAnswerInDetailedReport
                attachments={caliber.attachments}
                answer={caliber.answer}
                reason={caliber?.caliber_return_reason_name}
                details={caliber?.return_caliber_details}
                caliber={caliber}
              />
            )}

            <Row>
              <Col>
                <div
                  className="horizontal-line"
                  style={{
                    marginTop: 38,
                    marginBottom: 25,
                    marginRight: 6,
                    marginLeft: 6,
                    borderTop: "1px solid #74727233",
                  }}
                ></div>
              </Col>
            </Row>
            {caliber.answer && (
              <CaliberAnswer
                answer={
                  caliber.is_answered !== null
                    ? caliber.new_answer
                    : caliber.answer
                }
                isAnswerAfterUpdate={
                  caliber.is_answered !== null &&
                  caliber.is_answered !== "not_answered"
                }
              />
            )}

            {caliber.is_answered !== null && (
              <CaliberAttachment
                attachments={
                  caliber.new_attachments?.length
                    ? caliber.new_attachments
                    : null
                }
                caliberIndex={caliberIndex + 1}
              />
            )}
          </div>
          {isShowAuditResult && assessmentType === ASSESSMENT_TYPE_BASIC && (
            <div className="caliber-main__audit">
              {caliber.audit_answer && caliber.audit_answer.auditor_answer && (
                <CaliberAnswer
                  answer={caliber.audit_answer.auditor_answer}
                  isAuditor
                />
              )}
              {isShowAuditAttachments && (
                <CaliberAttachment
                  attachments={caliber.audit_answer.attachments}
                  caliberIndex={caliberIndex + 1}
                />
              )}

              {isShowAuditComments && (
                <CaliberAuditComments
                  comments={caliber.audit_answer.all_audit_comments}
                />
              )}
            </div>
          )}
        </main>
      </Collapse>
    </div>
  );
};
Caliber.propTypes = {
  caliberCategoryIndex: PropTypes.number.isRequired,
  caliberIndex: PropTypes.number.isRequired,
  standardCategoryIndex: PropTypes.number.isRequired,
  caliber: PropTypes.object.isRequired,
  assessmentType: PropTypes.string.isRequired,
  isShowAuditResult: PropTypes.bool.isRequired,
};

Caliber.defaultProps = {};

export default Caliber;
