import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function calibers(state = initialState.calibers, action) {
    switch (action.type) {
        case types.LOAD_CALIBERS_SUCCESS:
            return action.calibers;

        case types.SEARCH_CALIBERS_SUCCESS:
            return action.calibers;

        case types.CREATE_CALIBER_SUCCESS:
            return [
                ...state,
                Object.assign({}, action.caliber)
            ];

        case types.UPDATE_CALIBER_SUCCESS:
            return [
                ...state.filter(caliber => caliber.id !== action.caliber.id),
                Object.assign({}, action.caliber)
            ];

        default:
            return state;
    }
}

