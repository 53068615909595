import React from "react";
import PropTypes from "prop-types";
import "../home/homepage.css";
import "./descriptionWithBackground.css";

import ButtonWithIcon from "../reusableComponents/ButtonWithIcon";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import AnimationRightToLeft from "../AnimationRightToLeft/AnimationRightToLeft";
import AnimationLeftToRight from "../AnimationLeftToRight/AnimationLeftToRight";
import AnimationBottomToTop from "../AnimationBottomToTop/AnimationBottomToTop";

const DescriptionWithBackground = ({ data, linkText, onClick }) => (
  <div className="flex-1 fast-track-section description-with-background-wrapper ">
    <div style={{ flex: 3 }}>
      <div className="fast-track-text">
        <AnimationRightToLeft>
          <Text
            Tag="div"
            textType="bold"
            fontSize={"font-32"}
            mobileFontSize={"font-18-mobile"}
            className="custom-h3-white"
          >
            {data.title}
          </Text>
        </AnimationRightToLeft>
        <AnimationBottomToTop>
          <Text
            Tag="div"
            textType="roman"
            fontSize={"font-26"}
            mobileFontSize={"font-16-mobile"}
            className="thread-text-white"
          >
            {data.description}
          </Text>

          {linkText && (
            <ButtonWithIcon
              text={linkText}
              color="#ffffff"
              goTo={onClick}
              textType="bold"
              fontSize={"font-24"}
              mobileFontSize={"font-16-mobile"}
              smallArrow
            />
          )}
        </AnimationBottomToTop>
      </div>
    </div>
    <div className="flex-1 description-with-background-space-holder "></div>

    <div className="flex-1 man-icon">
      <AnimationLeftToRight>
        <div>
          <img src={"img/fastTrackManIcon.svg"} className="man-icon-size" />
        </div>
      </AnimationLeftToRight>
    </div>
  </div>
);

DescriptionWithBackground.propTypes = {
  data: PropTypes.object,
  linkText: PropTypes.string,
  onClick: PropTypes.func,
};

export default DescriptionWithBackground;
