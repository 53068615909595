import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer/WhiteFooter"
import TrainingCertificateDetails from"./TrainingCertificateDetails"
class PublicTrainingCertificateDetailsPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <Header />
       <TrainingCertificateDetails/>
       <Footer />
      </>
    );
  }
}

export default PublicTrainingCertificateDetailsPage;
