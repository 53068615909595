export const ANSWER_YES = 'yes';
export const ANSWER_NO = 'no';
export const ANSWER_NOT_APPLICABLE = 'not_applicable';
export const ANSWER_INVALID = 'invalid';
export const ANSWER_EMPTY = 'empty';

export const ANSWERS = {
  [ANSWER_NO]: 'غير ملتزم',
  [ANSWER_YES]: 'ملتزم',
  [ANSWER_INVALID]: 'لا ينطبق',
  [ANSWER_NOT_APPLICABLE]: 'لاينطبق',
  [ANSWER_EMPTY]: 'لم يتم الاجابة',

};

export const ESTABLISHMENTANSWERS = {
  [ANSWER_NO]: 'لا',
  [ANSWER_YES]: 'نعم',
  [ANSWER_INVALID]: 'لا ينطبق',
  [ANSWER_NOT_APPLICABLE]: 'لاينطبق',
  [ANSWER_EMPTY]: 'لم يتم الاجابة',

};
