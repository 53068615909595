import { FETCH_DOWNLOAD_FILE } from "../actions/actionTypes";
import cloneDeep from "lodash/cloneDeep";
import initialState from "./initialState";

export default function (state = initialState.SAreport, action) {
  switch (action.type) {
    case FETCH_DOWNLOAD_FILE: {
      return Object.assign({}, state, {
        attachment: cloneDeep(action.attachment),
      });
    }
    default:
      return state;
  }
}
