import React from "react";
import PropTypes from "prop-types";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";
import "./notificationCard.scss";
import { format } from "date-fns";

const NotificationCard = (props) => {
  const { time, description, establishmentInfo, isRead } = props;
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;
  const notificationDay = time.getDate();
  const notificationMonth = time.getMonth() + 1;
  const formatedDate = format(time, "dd-MM-yyyy");

  const formatedTime = () => {
    if (notificationMonth === currentMonth) {
      const daysDifference = currentDay - notificationDay;

      switch (daysDifference) {
        case 0:
          return "اليوم";
        case 1:
          return " قبل يوم";
        case 2:
          return " قبل يومين";
        case 3:
          return " قبل 3 أيام";
        case 4:
          return " قبل 4 أيام";
        case 5:
          return " قبل 5 أيام";
        default:
          return formatedDate;
      }
    } else return formatedDate;
  };
  return (
    <div className="notification-card-wrapper">
      <div className="notification-card-text__top">
        <Text
          Tag="div"
          isBold={!isRead}
          textType="p4"
          className="notification-card-text__username"
        >
          {description}
        </Text>
        <Text
          Tag="div"
          isBold={!isRead}
          textType="p6"
          className="notification-card-text__time "
        >
          {formatedTime()}
        </Text>
      </div>

      <Text
        Tag="div"
        isBold={!isRead}
        textType="p6"
        className="notification-card-text__establishment-info "
      >
        {establishmentInfo}
      </Text>
    </div>
  );
};

NotificationCard.propTypes = {
  establishmentInfo: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.instanceOf(Date),
};
NotificationCard.defaultProps = {
  establishmentInfo: "اسم المستخدم",
  description: "اسم المنشأة",
  time: new Date(),
};

export default NotificationCard;
