export function listFormattedForDropdown(list) {
    return list.map(item => {
        return {
            value: item.id,
            text: item.name,
            value2: false,
            item: item
        };
    });
}


export function listFormattedForEstablishmentCategoriesDropdown(list) {
  return list.map(item => {
    return {
      value: item.id,
      text: item.composite_name,
      value2: false,
      item: item
    };
  });
}
