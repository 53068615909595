import React from "react";
import { SelfAssessmentContext } from "../self-assessment-form-context/index";
import { StepperSide } from "../components/stepper-side/index";
import "./self-assessment-steps.css";
import { StepperTop } from "../components/stepper-top/stepper-top";

export const SelfAssesmentStepsLayout = ({ children, params }) => {
  return (
    <SelfAssessmentContext params={params}>
      <div className=" w-full mobile-stepper">
        <StepperTop />
      </div>
      <div
        className="flex bg-white overflow-y-hidden assessment-mobile-padding show-full-no-scroll form-height-wrapper-1"
        id="assessment-inner-context-wrapper"
      >
        <div className="flex max-h-[85vh] flex-col  h-full w-full overflow-y-auto assessment-card-style form-height-wrapper-2">
          <div className="flex  flex-col  h-full w-full no-scrollbar overflow form-height-wrapper-3">
            <div className="flex-1 w-full h-full form-height-wrapper-4">
              {children}
            </div>
          </div>
        </div>
        <div
          className=" overflow-y-auto  min-w-fit no-scrollbar Desktop-stepper"
          style={{ minHeight: "865px" }}
        >
          <StepperSide />
        </div>
      </div>
    </SelfAssessmentContext>
  );
};
