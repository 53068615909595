import { refreshToken } from "../../common/request";

export const setup = () => {
  const url = new URL(window.location.href); //(location.href);
  const env_url = `${url.origin}${url.pathname}env.js`;

  let file_content = "";

  let rawFile = new XMLHttpRequest();
  rawFile.open("GET", env_url, false);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4) {
      if (rawFile.status === 200 || rawFile.status == 0) {
        file_content = rawFile.responseText;
      }
    }
  };

  rawFile.send(null);

  let script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = file_content;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export async function keepTokenAlive() {
  while (true) {
    if (window.kc) {
      refreshToken();
    }
    await sleep(60000);
    // await sleep( 100);
  }
}
