import { useSpring, animated } from "react-spring";
import React, { useState, useEffect, useRef } from "react";
function useIntersectionObserver(
  elementRef,
  { threshold = 0, root = null, rootMargin = "0%", freezeOnceVisible = false }
) {
  const [entry, setEntry] = useState();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]) => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
}
function useAnimatedPath({ isIntersecting, delay }) {
  const [length, setLength] = useState(null);
  const animatedStyle = useSpring({
    delay: isIntersecting ? delay : 0,
    config: { duration: 600 },
    from: { strokeDashoffset: 0, strokeDasharray: 0 },
    to: {
      strokeDashoffset: isIntersecting ? 0 : length,
      strokeDasharray: length,
    },
  });

  return {
    style: animatedStyle,
    ref: (ref) => {
      // The ref is `null` on component unmount
      if (ref) {
        setLength(ref.getTotalLength());
      }
    },
  };
}

function Checkmark({ isIntersecting }) {
  const animationProps = useAnimatedPath({
    isIntersecting,
    delay: 800,
  });

  return (
    <animated.path
      {...animationProps}
      stroke="#9a8e8e"
      strokeWidth="8"
      transform="translate(14630.659 17933.443)"
      d="M-14440.642-17933.443s5.2,66.8-71.3,68.563c-17.766.408-5.72,34.852-14.012,46.775-7.579,10.9-70.1,11.09-92.638,11.09-4.9,0,5.727-10.145,5.727,9.736l-17.8-9.736,6.847-3.949,10.949-6.314v20"
    />
  );
}

export default function AnimatedLeftArrow() {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const triggerRef = useRef();
  const dataRef = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: false,
  });

  useEffect(() => {
    setImmediate(() => {
      setIsIntersecting(true);
    });
  }, []);

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="202.284"
        height="147.211"
        viewBox="0 0 202.284 147.211"
      >
        <g
          id="Component_246_2"
          data-name="Component 246 – 2"
          transform="translate(8.164 4.298)"
        >
          <Checkmark isIntersecting={dataRef?.isIntersecting} />
        </g>
      </svg>
      <div ref={triggerRef} />
    </div>
  );
}
