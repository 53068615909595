export default [
  // معايير عامة للعاملين
  {
    id: 1,
    name: "المادة 51 في نظام العمل",
    description: `يجب أن يُكتب عقد العمل من نسختين ويحتفظ كل من طرفيه بنسخة. ويُعدّ العقد قائمًا ولو كان غير مكتوب، وفي هذه الحالة يجوز للعامل وحده إثبات العقد وحقوقه التي نشأت عنه بجميع طرق الإثبات. ويكون لكلّ من الطرفين أن يطلب كتابة العقد في أيّ وقت، أمّا عمّال الحكومة والمؤسسات العامّة فيقوم قرار أو أمر التعيين الصادر من الجهة المختصّة مقام العقد.`,
    recommendations: `تأكد مما يلي:
        1- أن يكتب عقد العمل من نسختين يحتفظ كل من طرفيه بنسخة منه
        2- يجب أن يكون عقد عمل غير السعودي مكتوب ومحدّد المدة.
        3- يجب أن يحتوي عقد العمل بصورة أساسية على:
        – اسم صاحب العمل ومكانه.
        – اسم العامل وجنسيّته.
        – ما يلزم لإثبات شخصيّته.
        – الأجر المتّفق عليه.
        – نوع العمل ومكانه.
        – تاريخ الالتحاق به.
        – مدّته إن كان محدّد المدّة.
        
        كما لا يجوز نقل العامل ذي الأجر الشهري إلى فئة عمال اليومية أو العمال المعينين بالأجر الأسبوعي أو بالقطعة أو بالساعة، إلا إذا وافق العامل على ذلك كتابة، ومع عدم الإخلال بالحقوق التي اكتسبها العامل في المدة التي قضاها بالأجر الشهري.`,
    parentId: 1,
  },
  {
    id: 2,
    name: "المادة 53 في نظام العمل",
    description: `إذا كان العامل خاضعًا لفترة تجربة، وجب النصّ على ذلك صراحةً في عقد العمل وتحديدها بوضوح، بحيث لا تزيد على 90 يومًا، ويجوز باتفاق مكتوب بين العامل وصاحب العمل تمديد فترة التجربة على ألّا تزيد على 180 يومًا. ولا تدخل في فترة حساب التجربة إجازتي عيد الفطر وعيد الأضحى، والإجازة المرضية. ولكلّ من الطرفين الحق في إنهاء العقد خلال هذه الفترة مالم يتضمّن العقد نصًّا يعطي الحق في الإنهاء لأحدهما.        `,
    recommendations: `تأكد مما يلي:
        إذا كان العامل خاضعا لفترة تجربة، وجب النص على ذلك صراحة في عقد العمل وتحديد مدته بوضوح، ويجوز تمديد فترة التجربة بعد تنفيذ عقد العمل على أن يكون باتفاق كتابي مستقل، بشرط أن لا تزيد إجمالي الفترة التجريبية، مع تمديد أو بدونه، على 180 يوما
        
        ويراعى في تطبيق أحكام الفترة التجريبية ما نصت عليه المادة 54 من نظام العمل:
        لا يجوز وضع العامل تحت التجربة أكثر من مرّة واحدة لدى صاحب عمل واحد، واستثناء من ذلك يجوز باتفاق طرفي العقد (كتابةً) إخضاع العامل لفترة تجربة أخرى بشرط أن تكون في مهنة أخرى أو عمل آخر، أو أن يكون قد مضى على انتهاء علاقة العامل بصاحب العمل مدة لا تقلّ عن ستة أشهر، وإذا أنهى العقد خلال فترة التجربة فإن أيًّا من الطرفين لا يستحق تعويضًا، كما لا يستحق العامل مكافأة نهاية الخدمة عن ذلك.`,
    parentId: 1,
  },
  {
    id: 3,
    name: "المادة 58 في نظام العمل",
    description: `1 - لا يجوز لصاحب العمل أن ينقل العامل بغير موافقته -كتابةً- من مكان عمله الأصلي إلى مكان آخر يقتضي تغيير محل إقامته.
        2 - لصاحب العمل -في حالات الضرورة التي قد تقتضيها ظروف عارضة ولمدة لا تتجاوز ثلاثين يوماً في السنة- تكليف العامل بعمل في مكان يختلف عن المكان المتفق عليه دون اشتراط موافقته، على أن يتحمل صاحب العمل تكاليف انتقال العامل وإقامته خلال تلك المدة.`,
    recommendations: `تحقق مما يلي:
        1- لم يتم نقل أي عامل من مكان عمله الأصلي إلى مكان آخر يقتضي تغيير محل اقامته دون موافقة العامل الكتابية
        2- في حالات الضرورة التي قد تقتضيها ضروف عارضة، لا تزيد مدة تكليف العامل بعمل في مكان يختلف عن المكان المتفق عليه دون اشتراط موافقته عن 30 يوما في السنة، وتتحمل المنشأة تكاليف انتقال العامل واقامته خلال تلك المدة`,
    parentId: 1,
  },
  {
    id: 4,
    name: "المادة 42 في نظام العمل",
    description: `على كلّ صاحب عمل إعداد عمّاله السعوديين، وتحسين مستواهم في الأعمال الفنيّة، والإداريّة، والمهنيّة، وغيرها، بهدف إحلالهم تدريجيًّا في الأعمال التي يقوم بها غير السعوديين، وعليه أن يُعدّ سجلًا يقيّد فيه أسماء العمّال السعوديين الذين أحلّهم محلّ غير السعوديين بحسب الشروط والقواعد التي تحدّدها اللائحة.`,
    recommendations: `تأكّد من عدم وجود غير السعوديين في أيّ من الأنشطة، والمهن، والوظائف التي يقتصر العمل فيها على السعوديين
        قم بالتأكّد من وجود ما يثبت من أنّ المنشأة تقوم بالإجراءات اللازمة لإعداد العمّال السعوديين، وتحسين مستواهم في الأعمال الفنيّة، والإداريّة، والمهنيّة، وغيرها، بهدف إحلالهم تدريجيًّا في الأعمال التي يقوم بها غير السعوديين.
        التأكّد من إعداد سجلّ قيد العمّال السعوديين الذين تمّ إحلالهم محلّ غير السعوديين بحسب الشروط والقواعد التي وردت في المادة السادسة عشرة في اللائحة التنفيذيّة كما يلي:
        توفير برامج التدريب والتأهيل للوظائف التي تتطلّب مهامها ذلك وفقًا للوصف المهني لمهام وواجبات الوظائف، وأن يسعى إلى معرفة ما هو جديد في التقنية مما يدخل في تأدية المهام والواجبات الوظيفية، والعمل على تمكين العاملين السعوديين لديه من التدريب والتأهيل وفقًا لذلك، ويشمل هذا برامج التدريب في ضوء المادة (44) من نظام العمل والتي تنص على: (يجب أن يشتمل برنامج التدريب على القواعد والشروط التي تتّبع في التدريب، وعلى مدّته وعدد ساعاته، والبرامج التدريبيّة النظريّة والعمليّة، وطريقة الاختبار والشهادات التي تُمنح في هذا الشأن) وتحدد اللائحة التنفيذية لنظام العمل المعايير والقواعد العامة التي ينبغي اتباعها
        إتاحة الفرص للعاملين السعوديين لديه لشغل الوظائف المشغولة بوافدين بما في ذلك تمكينهم من اكتساب الخبرة والتدريب على رأس العمل.
        إعداد سجلّ بمن يتم إحلالهم من العمّال السعوديين محلّ غير السعوديين
        
        وضع أطرٍ زمنيّة حسب الفئات والمستويات الوظيفية لجميع الوظائف لديه التي يشغلها غير سعوديين يتمكّن من خلالها من توفير الوسائل المناسبة من تأهيل وتدريب للعاملين السعوديين للحلول محلّهم.`,
    parentId: 2,
  },
  {
    id: 5,
    name: "المادة 43 في نظام العمل",
    description: `على كل صاحب عمل يشغّل خمسين عاملًا فأكثر أن يؤهل أو يدرب على أعماله من عماله السعوديين ما لا يقل عن (12%) من مجموع عماله سنويّاً، ويدخل ضمن هذه النسبة العمال السعوديون الذين يكملون دراساتهم إذا كان صاحب العمل يتحمل تكاليف الدراسة.`,
    recommendations: `تأكد من التالي:
        أن يشتمل برنامج التدريب على القواعد والشروط التي تُتّبع في التدريب والتي تُحددها لائحة المعايير والقواعد العامة التي ينبغي اتّباعها بهذا الخصوص لرفع مستوى أداء العامل من حيث المهارة والإنتاجية.
        أن يشتمل برنامج التدريب على المدّة وعدد الساعات، والبرامج التدريبيّة النظريّة والعمليّة، وطريقة الاختبار والشهادات التي تُمنح في هذا الشأن. وتُحدّد اللائحة التنفيذية المعايير والقواعد العامة التي ينبغي اتّباعها بهذا الخصوص لرفع مستوى أداء العامل من حيث المهارة والإنتاجية.`,
    parentId: 2,
  },
  {
    id: 6,
    name: "المادة 46 في نظام العمل",
    description: `يجب أن يكون عقد التأهيل أو التدريب مكتوبًا، وأن يُحدّد فيه نوع المهنة المتعاقد للتدريب عليها، ومدّة التدريب ومراحله المتتابعة، ومقدار المكافأة التي تُعطى للمتدرب في كلّ مرحلة، على ألّا يكون تحديدها بحال من الأحوال على أساس القطعة أو الإنتاج.`,
    recommendations: `تأكد من التالي:
        أن يكون عقد التأهيل أو التدريب مكتوبًا، وأن يُحدّد فيه نوع المهنة المتعاقد للتدريب عليها، ومدّة التدريب ومراحله المتتابعة، ومقدار المكافأة التي تُعطى للمتدرب في كلّ مرحلة، على ألّا يكون تحديدها بحال من الأحوال على أساس القطعة أو الإنتاج.
        
        ويراعى في عقد التدريب التالي:
        لصاحب العمل أن ينهي عقد التأهيل أو التدريب إذا ثبت له عدم قابلية أو قدرة المتدرب أو الخاضع للتأهيل على إكمال برامج التدريب أو التأهيل بصورة مفيدة، وللمتدرب أو الخاضع للتأهيل أو وليه أو وصيه مثل هذا الحق. وعلى الطرف الذي يرغب في إنهاء العقد إبلاغ الطرف الآخر بذلك قبل أسبوع على الأقل من تاريخ التوقف عن التدريب أو التأهيل.
        لصاحب العمل -بعد إكمال مدة التدريب أو التأهيل- أن يلزم المتدرب أو الخاضع للتأهيل بأن يعمل لديه مدة مماثلة لمدة التدريب أو التأهيل، فإن رفض أو امتنع المتدرب أو الخاضع للتأهيل عن العمل المدة المماثلة أو بعضها؛ وجب عليه أن يدفع لصاحب العمل تكاليف التدريب أو التأهيل التي تحملها صاحب العمل أو بنسبة المدة الباقية منها.`,
    parentId: 2,
  },
  {
    id: 7,
    name: "المادة 98 في نظام العمل",
    description: `لا يجوز تشغيل العامل تشغيلًا فعليًّا أكثر من ثماني ساعات في اليوم الواحد إذا اعتمد صاحب العمل المعيار اليومي، أو أكثر من ثمانٍ وأربعين ساعة في الأسبوع إذا اعتمد المعيار الأسبوعي. وتُخفَض ساعات العمل الفعليّة خلال شهر رمضان للمسلمين بحيث لا تزيد على ستّ ساعات في اليوم أو ست وثلاثين ساعة في الأسبوع.`,
    recommendations: `تأكد أن المنشأة تقوم بما يلي:
        1- لم يتم تشغيل العامل أكثر من خمس ساعات متتالية دون فترة للراحة والصلاة والطعام لا تقل عن نصف ساعة في المرة الواحدة خلال مجموع ساعات العمل، بحيث لا يبقى العامل في مكان العمل أكثر من إحدى عشرة ساعة في اليوم الواحد. ولا تدخل الفترات المخصصة للراحة والصلاة والطعام ضمن ساعات العمل الفعلية، ولا يكون العامل خلال هذه الفترات تحت سلطة صاحب العمل، ولا يجوز لصاحب العمل أن يلزم العامل بالبقاء خلالها في مكان العمل.
        2- عدم تشغيل العامل فعليا اكثر من 8 ساعات في اليوم الواحد او 48 ساعة في الاسبوع
        3- يجوز زيادة ساعات العمل إلى تسع ساعات في اليوم الواحد لبعض فئات العمال، أو في بعض الصناعات والأعمال التي لا يشتغل فيها العامل بصفة مستمرة. كما يجوز تخفيضها إلى سبع ساعات في اليوم الواحد لبعض فئات العمال أو في بعض الصناعات والأعمال الخطرة أو الضارة.
        4- عدم زيادة متوسّط ساعات العمل عند احتسابها لمدة ثلاثة أسابيع أو أقل على ثماني ساعات يوميًّا أو ثمانٍ وأربعين ساعة أسبوعيًّا في المنشآت التي تقتضي طبيعة العمل فيها أداء العمل بالتناوب.
        
        وفي حال تم تكليف العامل بأي ساعات اضافية، تأكد من التالي:
        1 - يجب على صاحب العمل أن يدفع للعامل أجراً إضافيّاً عن ساعات العمل الإضافية يوازي أجر الساعة مضافاً إليه 50 % من أجره الأساسي.
        2 - إذا كان التشغيل في المنشأة على أساس المعيار الأسبوعي لساعات العمل تُعد الساعات التي تزيد على الساعات المتخذة لهذا المعيار ساعات عمل إضافية.
        3 - تُعد جميع ساعات العمل التي تؤدَّى في أيام العطل والأعياد ساعات إضافية.`,
    parentId: 2,
  },
  {
    id: 8,
    name: "المادة 104 في نظام العمل",
    description: `1. يوم الجمعة هو يوم الرّاحة الأسبوعية لجميع العمّال، ويجوز لصاحب العمل بعد إبلاغ مكتب العمل المختصّ أن يستبدل هذا اليوم لبعض عمّاله بأيّ يوم من أيام الأسبوع، وعليه أن يمكّنهم من القيام بواجباتهم الدينيّة، ولا يجوز تعويض يوم الرّاحة الأسبوعية بأجرٍ كامل ولا يقل عن 24 ساعة متتالية.
        2. يكون يوم الراحة الأسبوعية بأجر كامل ولا يقل عن 24 ساعة متتالية.`,
    recommendations: `يجب مراعاة التالي:
        1. عدم استبدال يوم الجمعة لبعض العمّال بيوم من أيام الأسبوع إلّا بعد الحصول على موافقة من مكتب العمل المختص، وعلى أن يمكّنهم من القيام بواجباتهم الدينيّة، وعدم تعويض يوم الرّاحة الأسبوعية بمقابل نقدي، بحيث يكون يوم الرّاحة الأسبوعي بأجرٍ كامل ولا يقل عن 24 ساعة متتالية.
        2. يجوز في الأماكن البعيدة عن العمران وفي الأعمال التي تتطلب طبيعة العمل وظروف التشغيل فيها استمرار العمل تجميع الراحات الأسبوعية المستحقة للعامل عن مدة لا تتجاوز ثمانية أسابيع إذا اتفق صاحب العمل والعمال ووافقت على ذلك الوزارة، ويراعى في حساب مدة الراحة الأسبوعية المُجَمعّة أن تبدأ من ساعة وصول العمال إلى أقرب مدينة يتوفر بها وسائل نقل، وتنتهي ساعة العودة إليها.`,
    parentId: 3,
  },
  {
    id: 9,
    name: "المادة 109 من نظام العمل",
    description: `1. يستحق العامل عن كلّ عام إجازة سنوية لا تقلّ مدّتها عن واحد وعشرين يومًا، تُزاد إلى مدّة لا تقل عن ثلاثين يومًا إذا أمضى العامل في خدمة صاحب العمل خمس سنوات متّصلة، وتكون الإجازة بأجر يدفع مقدمًا.
        2. يجب أن يتمتع العامل بإجازته في سنة استحقاقها، ولا يجوز النزول عنها، أو أن يتقاضى بدلًا نقديًّا عوضًا عن الحصول عليها أثناء خدمته، ولصاحب العمل أن يحدّد مواعيد هذه الإجازات وفقًا لمقتضيات العمل، أو يمنحها بالتناوب لكي يؤمّن سير عمله، وعليه إشعار العامل بالميعاد المحدّد لتمتّعه بالإجازة بوقت كافٍ لا يقل عن ثلاثين يومًا.`,
    recommendations: `تأكد مما يلي:
        1. استحقاق العامل عن كلّ عام إجازة سنوية لا تقلّ مدّتها عن واحد وعشرين يومًا، وتزيد هذه الإجازة إلى مدّة لا تقلّ عن ثلاثين يومًا إذا أمضى العامل خمس سنوات.
        2. عدم تقاضي بدل نقدي عوضًا عن الحصول على الإجازة أثناء خدمة العامل.
        3. عدم تأخير فترة الإجازة لمدّة لا تزيد على تسعين يومًا والحصول على موافقة العامل كتابةً في حال استمرار التأجيل على ألّا يتعدّى التأجيل نهاية السنة التالية لسنة استحقاق الإجازة متّصلة في الخدمة.`,
    parentId: 4,
  },
  {
      id: 10,
      name: "المادة 112 من نظام العمل",
      description: `لكلّ عامل الحق – في الحالات المشار إليها - في إجازة بأجر كامل في الأعياد والمناسبات التي تحددها اللائحة.`,
      recommendations: `تأكد من التالي:
          1. تحديد اجازات الأعياد والمناسبات وفق ما يلي:
          - إجازة عيد الفطر لمدة أربعة أيام تبدأ من اليوم التالي لليوم التاسع والعشرين من شهر رمضان حسب تقويم أم القرى
          - إجازة عيد الأضحى لمدة أربعة أيام تبدأ من يوم الوقوف بعرفة
          - إجازة اليوم الوطني للمملكة لمدة يوم واحد، في اول يوم من برج الميزان حسب تقويم أم القرى
          2. في حال تداخل أيام اجازات الاعياد والمناسبات مع الآتي:
          - الراحة الاسبوعية: يعوض العامل عنها بما يعادلها قبل ايام تلك الاجازة او بعدها
          - الاجازة السنوية: تمدد الاجازة السنوية بقدر أيام هذه الاجازات
          - الاجازة المرضية: يستحق العامل الأجر الكامل عن أيام هذه الإجازات دون النظر للأجر المستحق عن أيام الإجازة المرضية
          - اليوم الوطني مع ايام إجازة أحد العيدين: لا يعوض العامل عن هذا اليوم`,
      parentId: 4,
    },
    {
      id: 11,
      name: "المادة 113 من نظام العمل",
      description: `مع مراعاة إجازات المرأة العاملة المحدّدة بموجب نظام العمل، للعامل الحق في إجازة بأجر كامل لمدّة خمسة أيام في حالة وفاة زوجه أو أحد أصوله أو فروعه، أو عند زواجه، وثلاثة أيام في حالة ولادة مولود له، ويحق لصاحب العمل أن يطلب الوثائق المؤيّدة.`,
      recommendations: `تأكد من حصول العاملين في المنشأة على إجازة بأجر كامل في الحالات التالية:
          - إجازة مدة 5 أيام في حالة وفاة أحد أصول أو فروع العامل
          - إجازة مدة 5 أيام عند زواج العامل
          - إجازة 3 أيام حال ولادة مولود للعامل`,
      parentId: 4,
    },
    {
      id: 12,
      name: "المادة 114 من نظام العمل",
      description: `للعامل الحق في الحصول على إجازة بأجر لا تقلّ مدّتها عن عشرة أيام ولا تزيد على خمسة عشر يومًا بما فيها إجازة عيد الأضحى، وذلك لأداء فريضة الحج لمرة واحدة طوال مدّة خدمته إذا لم يكن قد أدّاها من قبل، ويشترط لاستحقاق هذه الإجازة أن يكون العامل قد أمضى في العمل لدى صاحب العمل سنتين متّصلتين على الأقل، ولصاحب العمل أن يحدّد عدد العمّال الذين يُمنحون هذه الإجازة سنويًّا وفقًا لمقتضيات العمل.`,
      recommendations: `تأكد من التالي:
          تضمين وتطبيق إجازة الحج بأجر كامل المقدرة بما لا يقل عن 10 أيام ولا تزيد عن 15 يوم ضمن لائحة تنظيم العمل للمنشأة واستحقاق جميع العاملين الذين لم يؤدوا فريضة الحج من قبل لها على أن يكون العامل قد أمضى سنتين متصلتين على الأقل مع صاحب العمل`,
      parentId: 4,
    },
    {
      id: 13,
      name: "المادة 115 من نظام العمل",
      description: `1. للعامل - إذا وافق صاحب العمل على انتسابه إلى مؤسسة تعليمية أو قبل استمراره فيها - الحق في إجازة بأجر كامل لتأدية الامتحان عن سنة غير معادة تحدّد مدّتها بعدد أيام الامتحان الفعليّة. أمّا إذا كان الامتحان عن سنة معادة فيكون للعامل حق في إجازة دون أجر بعدد أيام الامتحان الفعليّة. ويُحرم العامل من أجر الإجازة إذا ثبت أنه لم يؤدّي الامتحان مع عدم إخلال بحقّ صاحب العمل في مساءلته تأديبًّا.
          2. إذا لم يحصل العامل على موافقة صاحب العمل على انتسابه إلى مؤسسة تعليمية، فللعامل أن يحصل على إجازة لتأدية الامتحان بعدد أيام الامتحان الفعليّة تُحتسب من إجازته السنويّة في حال توفّرها، وعند تعذّر ذلك فللعامل أن يحصل على إجازة دون أجر بعدد أيام الامتحان الفعليّة.
          3. على العامل أن يتقدم بطلب الإجازة قبل موعدها بخمسة عشر يومًا على الأقل.
          4. لصاحب العمل أن يطلب من العامل تقديم الوثائق المؤيّدة لطلب الإجازة، وكذلك على ما يدل على أدائه الامتحان.
          `,
      recommendations: `تأكد من التالي:
          تضمين وتطبيق إجازة  لأداء الامتحانات ضمن لائحة تنظيم العمل في المنشأة بحسب ما توضحه المادة 115 من نظام العمل`,
      parentId: 4,
    },
    {
      id: 14,
      name: "المادة 117 من نظام العمل",
      description: `للعامل الذي يثبت مرضه الحق في إجازة مَرضيّة بأجر عن الثلاثين يومًا الأولى، وبثلاثة أرباع الأجر عن الستين يومًا التالية، ودون أجر للثلاثين يومًا التي تلي ذلك خلال السنة الواحدة، سواء أكانت هذه الإجازات متّصلة أم متقطّعة. ويُقصد بالسنة الواحدة: السنة التي تبدأ من تاريخ أول إجازة مَرضيّة.`,
      recommendations: `تأكد من التالي:
          استحقاق العامل الذي يثبت مرضه على إجازة مَرضيّة بأجر كامل عن الثلاثين يومًا الأولى، و 75% من الأجر عن الستين يومًا التالية، ودون أجر للثلاثين يومًا التي تلي ذلك خلال السنة الواحدة، سواء أكانت هذه الإجازات متّصلة أم متقطّعة. ويُقصد بالسنة الواحدة: السنة التي تبدأ من تاريخ أول إجازة مَرضيّة.`,
      parentId: 4,
    },
    {
      id: 15,
      name: "المادة 90 في نظام العمل (2/1)",
      description: `1. يجب دفع أجر العامل وكلّ مبلغ مستحق له بالعملة الرسميّة للبلاد، كما يجب دفع الأجر في ساعات العمل ومكانه طبقًا للأحكام الآتية:
          ‌- العمّال باليوميّة تُصرف أجورهم مرة كل أسبوع على الأقل.
          ‌- العمّال ذوو الأجور الشهريّة تُصرف أجورهم مرّة في الشهر.
          ‌- إذا كان العمل يؤدّى بالقطعة ويحتاج لمدة تزيد على أسبوعين، فيجب أن يحصل العامل على دفعة كلّ أسبوع تتناسب مع ما أتمّه من العمل، ويُصرف باقي الأجر كاملًا خلال الأسبوع التالي لتسليم العمل.
          ‌- في غير ما ذكر تؤدّى إلى العمّال أجورهم مرة كل أسبوع على الأقل.
          `,
      recommendations: `تأكّد من أنّ المنشأة تقوم بما يلي:
          1. دفع أجر العامل وكلّ مبلغ مستحق له بالعملة الرسميّة للبلاد.
          2. دفع أجر العامل في ساعات العمل ومكانه طبقًا للأحكام المنصوصة في نظام العمل الآتية:
          - يتم صرف أجور العمّال باليوميّة مرّة كلّ أسبوع على الأقل.
          - يتم صرف أجور العمّال ذوي الأجور الشهرية مرّة في الشهر.
          - إذا كان العمل يؤدّى بالقطعة ويحتاج لمدة تزيد على أسبوعين، فيجب أن يحصل العامل على دفعة كلّ أسبوع تتناسب مع ما أتمّه من العمل، ويُصرف باقي الأجر كاملًا خلال الأسبوع التالي لتسليم العمل.
          - في غير ما ذكر تؤدى إلى العمال أجورهم مرة كل أسبوع على الأقل
          `,
      parentId: 5,
    },
    {
      id: 16,
      name: "المادة 90 في نظام العمل (2/2)",
      description: `2. تلتزم المنشآت بدفع الأجور في حسابات العمّال عن طريق البنوك المعتمدة بشرط ألّا يتجاوز استحقاقها الموعد المحدّد في الفقرة الأولى من المادة 90 في نظام العمل.
          `,
      recommendations: `تأكد من أن المنشأة تقوم بما يلي:
          1. دفع الأجور في حسابات العمال عن طريق البنوك المعتمدة بشرط ألا يتجاوز استحقاقها الموعد المحدّد في الفقرة الأولى من المادة 90 في نظام العمل.
          2. رفع ملف حماية الأجور بشكل شهري`,
      parentId: 5,
    },
    {
      id: 17,
      name: "المادة 84 من نظام العمل",
      description: `إذا انتهت علاقة العمل، وجب على صاحب العمل أن يدفع إلى العامل مكافأة عن مدّة خدمته تُحتَسب على أساس أجر نصف شهر عن كلّ سنة من السنوات الخمس الأولى، وأجر شهر عن كلّ سنة من السنوات التالية، ويُتّخذ الأجر الأخير أساسًا لحساب المكافأة، ويستحق العامل مكافأة عن أجزاء السنة بنسبة ما قضاه منها في العمل.`,
      recommendations: `تأكّد مما يلي:
      تقوم المنشأة باحتساب مكافأة نهاية الخدمة على أساس أجر نصف شهر عن كلّ سنة من السنوات الخمس الأولى، وأجر شهر عن كلّ سنة من السنوات التالية، بحيث يكون الأجر الأخير أساسًا لحساب المكافأة، ويستحقّ العامل مكافأة عن أجزاء السنة بنسبة ما قضاه منها في العمل.
      
      و إذا انتهت خدمة العامل وجب على صاحب العمل دفع أجره وتصفية حقوقه خلال أسبوع -على الأكثر- من تاريخ انتهاء العلاقة العقدية. أما إذا كان العامل هو الذي أنهى العقد، وجب على صاحب العمل تصفية حقوقه كاملة خلال مدة لا تزيد على أسبوعين. ولصاحب العمل أن يحسم أي دين مستحق له بسبب العمل من المبالغ المستحقة للعامل.`,
      parentId: 6,
    },
    {
      id: 18,
      name: "المادة 85 من نظام العمل",
      description: `إذا كان انتهاء علاقة العمل بسبب استقالة العامل، فيستحق في هذه الحالة ثلث المكافأة بعد خدمة لا تقلّ مدّتها عن سنتين متتاليتين ولا تزيد على خمس سنوات، ويستحق ثلثيها إذا زادت مدّة خدمته على خمس سنوات متتالية ولم تبلغ عشر سنوات، ويستحق المكافأة كاملة إذا بلغت مدّة خدمته عشر سنوات فأكثر.`,
      recommendations: `تأكّد مما يلي:
      تقوم المنشأة بدفع ثلث المكافأة للعامل بعد خدمة لا تقلّ مدّتها عن سنتين متتاليتين ولا تزيد على خمس سنوات.
      تستحق المكافأة كاملة في حالة ترك العامل العمل نتيجة لقوة قاهرة خارجة عن إرادته، كما تستحقّها العاملة إذا أنهت العقد خلال ستة أشهر من تاريخ عقد زواجها أو ثلاثة أشهر من تاريخ وضعها.`,
      parentId: 6,
    },
    // معايير عامة للمنشآت
    {
      id: 19,
      name: "المادة 9 من نظام العمل",
      description: `اللغة العربية هي الواجبة الإستعمال في البيانات والسجلات والملفات وعقود العمل وغيرها مما هو منصوص عليه في هذا النظام، أو في أي قرار صادر تطبيقاً لأحكامه، وكذلك التعليمات التي يصدرها صاحب العمل لعماله. وإذا استعمل صاحب العمل لغة أجنبية إلى جانب اللغة العربية في أي من الحالات المذكورة، فإن النص العربي هو المعتمد دون غيره`,
      recommendations: `تأكّد من أنّ النصّ العربي مستخدم كلغة رسميّة ضمن مستندات المنشأة ويجوز استعمال لغة أجنبية إلى جانب اللغة العربية بشرط أن تكون اللغة العربية هي اللغة المعتمدة.`,
      parentId: 7,
    },
    {
      id: 20,
      name: "المادة 13 من نظام العمل",
      description: `1. يلتزم كلّ صاحب عمل بإعداد لائحة لتنظيم العمل في منشأته وفق النّموذج المعدّ من الوزارة، ويجوز للوزير الاستثناء من ذلك.
          2. يجوز لصاحب العمل تضمين اللائحة شروطًا وأحكامًا إضافية بما لا يتعارض مع أحكام نظام العمل، ولائحته والقرارات الصادرة تنفيذًا له.
          3. يجب على صاحب العمل أن يعلن لائحة تنظيم العمل وأيّ تعديل يطرأ عليها في مكان ظاهر في المنشأة أو أيّ وسيلة أخرى تكفل علم الخاضعين لها بأحكامها.`,
      recommendations: `تأكّد من التالي:
          أن المنشأة قامت بإعداد لائحة تنظيم العمل وفق النموذج المعتمد من الوزارة في ملحق (1) من اللائحة التنظيمية لنظام العمل
           أنّ المنشأة قد قامت بإعلان لائحة تنظيم العمل بعد اعتمادها من وزارة الموارد البشرية في مكان ظاهر في المنشأة أو بأيّ وسيلة أخرى تكفل معرفة العاملين بها (ويجوز للمنشأة إعلان اللائحة من خلال البريد الإلكتروني أو الشبكة الداخلية).`,
      parentId: 7,
    },
    {
      id: 21,
      name: "المادة 15 من نظام العمل",
      description: `على صاحب العمل عند بدء العمل في أي منشأة أن يبلغ مكتب العمل المختص كتابة بالبيانات الآتية:
          اسم المنشأة ونوعها ومركزها، والعنوان الذي توجه إليه المراسلات، وأي معلومة يكون من شأنها سهولة الاتصال بالمنشأة.
          النشاط الاقتصادي المرخص له بمزاولته مع ذكر رقم السجل التجاري أو الترخيص وتاريخه، وجهة إصداره، وإرفاق صورة منه.
          عدد العمال المراد تشغيلهم في المنشأة.
          اسم مدير المنشأة المسؤول.
          أي بيانات أخرى تطلبها الوزارة.
          `,
      recommendations: `تأكد من قيامك بفتح ملف للمنشأة لدى مكتب العمل متضمنا المعلومات التالي وتحديثها باستمرار:
          اسم المنشأة ونوعها ومركزها، والعنوان الذي توجه إليه المراسلات، وأي معلومة يكون من شأنها سهولة الاتصال بالمنشأة.
          النشاط الاقتصادي المرخص له بمزاولته مع ذكر رقم السجل التجاري أو الترخيص وتاريخه، وجهة إصداره، وإرفاق صورة منه.
          عدد العمال المراد تشغيلهم في المنشأة.
          اسم مدير المنشأة المسؤول.
          أي بيانات أخرى تطلبها الوزارة.
          كما يجب مراعاة التالي:
          إذا لم يتمكّن صاحب العمل من ممارسة العمل بنفسه، فعليه أن يعيّن مسؤولًا يمثّله في مكان العمل. وفي حالة تعدّد الشركاء أو المديرين في المنشأة، يُسمّى أحدهم من المقيمين في مكان العمل، يمثّل صاحب العمل ويكون مسؤولًا عن أيّ مخالفة لأحكام نظام العمل.
          على صاحب العمل أن يبلّغ مكتب العمل المختصّ كتابةً باسم الشريك أو المدير، وعليه في حالة تغييره أن يُبلغ المكتب باسم الشريك أو المدير الجديد خلال سبعة أيام على الأكثر من تولّي هذا الأخير عمله.
          إذا لم يوجد شخص معيّن بصفة مدير مسؤول للمنشأة - أو إذا لم يباشر الشخص المعيّن عمله - فإنّ من يقوم فعلًا بأعمال المدير أو صاحب العمل نفسه يُعدّ مديرًا مسؤولًا عن المنشأة.
          وفي كلّ الأحوال تبقى مسؤولية صاحب العمل قائمة بصفة أصليّة.`,
      parentId: 7,
    },
    {
      id: 22,
      name: "المادة 17 في نظام العمل",
      description: `على صاحب العمل أن يحتفظ في مكان العمل بالسّجلات والكشوف والملفات التي تحدّد ماهيتها، والبيانات التي يجب أن تتضمّنها اللائحة.
          وعليه أن يضع في مكان ظاهر بموقع العمل جدولًا بمواعيد العمل، وفترات الرّاحة، ويوم الرّاحة الأسبوعي، ومواعيد بدء كل نوبة وانتهائها في حالة العمل بأسلوب المناوبة.`,
      recommendations: `تأكد من التالي:
          أن المنشأة تحتفظ بالملفات التالية في مكان العمل، سواء كانت بشكل ورقي أم الكتروني،: (كشف بأسماء العمال، كشف بأجور العمال، سجل قيد الغرامات الموقعة على العمال سجل الحضور والانصراف، سجل تدريب السعوديين، سجلات الفحص الطبي للعمال، ملف عمل لكل عامل)
          قم بالتأكّد من أنّ المنشأة تضع في مكان ظاهر بموقع العمل جدولًا بمواعيد العمل، وفترات الرّاحة، ويوم الرّاحة الأسبوعي، ومواعيد بدء كل نوبة وانتهائها في حالة العمل بأسلوب المناوبة.
          `,
      parentId: 7,
    },
     {
      id: 23,
      name: "الفقرة 26 من جدول المخالفات رقم 75913",
      description: `لا يجوز قيام صاحب المنشأة بتقديم معلومات أو بيانات غير صحيحة الى الوزارة ترتب عليها الحصول على أي خدمة من خدمات الوزارة أو تأشيرات عمل`,
      recommendations: `أن جميع المعلومات المقدمة الى الوزارة هي معلومات صحيحة تماما ويشمل ذلك المعلومات المقدمة من خلال منصة التقييم الذاتي`,
      parentId: 7,
    },
    {
      id: 24,
      name: "الفقرة 33 من جدول المخالفات رقم 75913",
      description: `لا يجوز قيام صاحب العمل بتسجيل شخص سعودي ضمن عمال المنشأة دون علمه أو موافقته`,
      recommendations: `تأكد مما يلي:
          - وجود عقد عمل بين صاحب العمل والعامل موقع من الطرفين
          - عدم تسجيل أي مواطن سعودي في نظام التأمينات الاجتماعية دون علمه
          - عدم تسجيل أي مواطن سعودي بعلمه والاتفاق على مرتب شهري يصرف للمواطن بدون أي عمل أو وبدون علمه بمقر المنشأة`,
      parentId: 7,
    },
    {
      id: 25,
      name: "المادة 61 من نظام العمل",
      description: `بالإضافة إلى الواجبات المنصوص عليها في نظام العمل واللوائح والقرارات الصادرة تطبيقًا له، يجب على صاحب العمل ما يأتي:
          1. أن يمتنع عن تشغيل العامل سخرة، وألّا يحتجز دون سند قضائي أجر العامل أو جزءٍ منه، وأن يعامل عمّاله بالاحترام اللائق، وأن يمتنع عن كلّ قول أو فعل يمسّ كرامتهم ودينهم.
          2. أن يعطي العمّال الوقت اللازم لممارسة حقوقهم المنصوص عليها في نظام العمل دون تنزيل من الأجور لقاء هذا الوقت، وله أن ينظّم ممارسة هذا الحق بصورة لا تخلّ بسير العمل.
          3. أن يسهّل لموظفي الجهات المختصّة كلّ مهمة تتعلّق بتطبيق أحكام نظام العمل.`,
      recommendations: `يقصد بعبارة "تشغيل العامل سخرة" : جميع الأعمال، أو الخدمات التي تفرض عنوة على العامل ، ولم ينص عليها في عقد العمل، وتتم تحت التهديد بأي عقاب، والتي لا يكون العامل قد أداها بمحض اختياره، ويستثنى من ذلك أي عمل أو خدمة تفرضها حالات الطوارئ أو القوة القاهرة، مثل حالات الحروب والحرائق والزلازل وغيرها
          ويجب على صاحب العمل التأكد من إعطاء العمال الوقت اللازم لممارسة حقوقهم دون تنزيل من أجورهم`,
      parentId: 8,
    },
    {
      id: 26,
      name: "المادة 64 من نظام العمل (1/2)",
      description: `يلتزم صاحب العمل عند انتهاء عقد العمل بما يأتي :
      1 - أن يعطي العامل -بناء على طلبه- شهادة خدمة دون مقابل، يوضح فيها تاريخ التحاقه بالعمل، وتاريخ انتهاء علاقته به، ومهنته، ومقدار أجره الأخير. ولا يجوز لصاحب العمل تضمين الشهادة ما قد يسيء إلى سمعة العامل أو يقلل من فرص العمل أمامه.`,
      recommendations: `تأكد مما يلي عند انتهاء عقد العمل:
      إعطاء شهادات خدمة للعاملين بناء على طلبهم دون مقابل ودون تضمين الشهادة ما قد يسيئ إلى سمعة العامل أو يقلل من فرص العمل أمامه.`,
      parentId: 8,
    },
    {
      id: 27,
      name: "المادة 64 من نظام العمل (2/2)",
      description: `يلتزم صاحب العمل عند انتهاء عقد العمل بما يأتي :
      2 - أن يعيد إلى العامل جميع ما أودعه لديه من شهادات أو وثائق.`,
      recommendations: `تأكد مما يلي عند انتهاء عقد العمل:
      أن المنشأة تعيد للعامل جميع ما أودعه لديها من شهادات أو وثائق`,
      parentId: 8,
    },
    {
      id: 28,
      name: "المادة 73 من نظام العمل",
      description: `يجب على صاحب العمل أن يكتب الغرامات التي يوقّعها على العامل في سجلّ خاص، مع بيان اسم العامل، ومقدار أجره، ومقدار الغرامة، وسبب توقيعها، وتاريخ ذلك. ولا يجوز التصرّف في الغرامات إلّا فيما يعود بالنّفع على عمّال المنشأة، على أن يكون التصرّف بهذه الغرامات من قبل اللجنة العمّالية في المنشأة، وفي حالة عدم وجود لجنة يكون التصرّف في الغرامات بموافقة الوزارة.`,
      recommendations: `تأكد من أنّ سجلّ الغرامات يحتوي على اسم العامل، ومقدار أجره، ومقدار الغرامة، وسبب توقيعها وتاريخ ذلك، وعدم التّصرف في الغرامات إلّا فيما يعود بالنفع على عمّال المنشأة وذلك بعد الرجوع الى اللجنة العمالية في المنشأة أو موافقة الوزارة على ذلك.
      لا يجوز لصاحب العمل أن يوقّع على العامل جزاءً غير وارد في النظام أو لائحة العمل.
      لا يجوز تشديد الجزاء في حالة تكرار المخالفة إذا كان قد انقضى على المخالفة السابقة مائة وثمانون يومًا من تاريخ إبلاغ العامل بتوقيع الجزاء عليه عن تلك المخالفة. 
      لا يجوز اتّهام العامل بمخالفة مضى على كشفها أكثر من ثلاثين يومًا، ولا يجوز توقيع جزاء تأديبي بعد انتهاء التحقيق وثبوتها في حقّ العامل بأكثر من ثلاثين يومًا.
      لا يجوز توقيع جزاء تأديبي على العامل لأمر ارتكبه خارج مكان العمل ما لم يكن متّصلًا بالعمل أو بصاحبه أو بمديره المسؤول، كما لا يجوز أن يوقّع العامل عن المخالفة الواحدة غرامة تزيد قيمتها على أجرة خمسة أيام في الشهر الواحد، و لا أن تزيد مدة إيقافه عن العمل دون أجر عن خمسة أيام في الشهر.
      لا يجوز توقيع جزاء تأديبي على العامل إلّا بعد إبلاغه كتابةً بما نُسب إليه، واستجوابه، وتحقيق دفاعه، وإثبات ذلك في محضر يودَع في ملفه الخاص، ويجوز الاستجواب شفهيًّا في المخالفات البسيطة التي لا يتعدّى الجزاء المفروض على مرتكبها الإنذار أو الغرامة باقتطاع ما لا يزيد عن أجر يوم واحد على أن يثبت ذلك في المحضر.`,
      parentId: 8,
    },
    {
      id: 29,
      name: "المادة 30 في نظام العمل (1/2)",
      description: `لا يجوز لأي شخص طبيعي أو اعتباري أن يمارس نشاط توظيف السعوديين مالم يكن مرخصاً له بذلك من الوزارة.`,
      recommendations: `تأكد مما يلي:
          أن المنشأة لديها ترخيص من الوزارة لمزاولة نشاط توظيف السعوديين، وتوضح اللائحة التنظيمية لنظام العمل ضوابط وقواعد ممارسة نشاط التوسط في توظيف السعوديين.`,
      parentId: 9,
    },
    {
      id: 30,
      name: "المادة 30 في نظام العمل (2/2)",
      description: `لا يجوز لأي شخص طبيعي أو اعتباري أن يمارس نشاط استقدام العمال مالم يكن مرخصاً له بذلك من الوزارة.`,
      recommendations: `تأكد مما يلي:
          أن المنشأة لديها ترخيص من الوزارة لمزاولة نشاط الاستقدام، وتوضح قواعد ممارسة نشاط الاستقدام وتقديم الخدمات العمالية الاشتراطات اللازمة للحصول على الترخيص`,
      parentId: 9,
    },
    {
      id: 31,
      name: "الفقرة 83 من جدول مخالفات رقم 75913",
      description: `لا يجوز قيام المرخص له بأنشطة الاستقدام بتأجير الترخيص للغير أو منحه حق الانتفاع به لممارسة النشاط، أو بيع تأشيرات العمل أو التوسط في بيعها سواء بطريقة مباشرة أو غير مباشرة`,
      recommendations: `تأكد من التالي:
          أنه لم يتم تأجير ترخيص ممارسة أنشطة الاستقدام للغير أو منح الغير حق الانتفاع به سواء بطريقة مباشرة أو غير مباشرة`,
      parentId: 9,
    },
    {
      id: 32,
      name: "الفقرة 56 من جدول مخالفات رقم 75913",
      description: ` لا يجوز استمرار المرخص له بمزاولة نشاط التوسط في الاستقدام أو تقديم الخدمات العمالية بعد إيقاع عقوبة الإغلاق.`,
      recommendations: `تأكد من التالي:
          التوقف عن مزاولة نشاط التوسط في الاستقدام أو تقديم الخدمات العمالية للغير بعد إيقاع عقوبة الاغلاق`,
      parentId: 9,
    },
    {
      id: 33,
      name: "قواعد ممارسة نشاط الاستقدام وتقديم الخدمات العمالية 1442هـ مادة 73 و 74",
      description: `تلتزم شركة الاستقدام باستقبال، وإيواء العمالة التي يتم التوسط في استقدامها لحين تسليمها لأصحاب العمل؛ وفقًا للإجراءات التي تضعها الوزارة بالتنسيق مع الجهات ذات العلاقة`,
      recommendations: `تأكد مما يلي:
      تأمين إسكان لإيواء العمالة المستقدمة خلال 30 يوما من تاريخ الحصول على تأشيرات استقدام العمالة، مع مراعاة تناسب الطاقة الاستيعابية للسكن مع عدد العاملين من كل جنس، على ألا تقل نسبة الطاقة الاستيعابية عن 10% من عدد التأشيرات الممنوحة للشركة`,
      parentId: 9,
    },
    {
      id: 34,
      name: "قواعد ممارسة نشاط الاستقدام وتقديم الخدمات العمالية 1442هـ مادة 69",
      description: `يجب على المرخص له بممارسة تقديم الخدمات العمالية الالتزام بما يلي:- 
      ممارسة النشاط بمكان مستقل، دون ممارسة أي نشاط آخر بهذا المكان، طوال مدة الترخيص.`,
      recommendations: `تأكد من التالي:
      أن المنشأة المرخص لها بممارسة نشاط تقديم الخدمات العمالية تقوم بممارسة النشاط في مكان مستقل دون ممارسة أي نشاط آخر في نفس المكان طوال مدة الترخيص`,
      parentId: 9,
    },
    {
      id: 35,
      name: "قواعد ممارسة نشاط الاستقدام وتقديم الخدمات العمالية 1442هـ مادة 63",
      description: `يجب على المرخص له بممارسة نشاط الاستقدام وتقديم الخدمات العمالية الالتزام بما يلي:-
      نماذج العقود الموحدة التي تضعها وتعتمدها وزارة الموارد البشرية والتنمية الاجتماعية`,
      recommendations: `تأكد من التزام المنشأة بنماذج العقود الموحدة التي تضعها الوزارة لأنشطة الاستقدام وتقديم الخدمات العمالية للغير:
      النموذج الموحد لعقد الارتباط (عقد ينظم العلاقة بين المرخص له ومكاتب الارسال)
      نموذج العقود الخاصة مع العملاء: عقود خاصة بنشاط تقديم الخدمات العمالية العامة - عقود خاصة بنشاط تقديم الخدمات العمالية المنزلية، على أن يتضمن العقد الآتي:
      عدد العمال، ووظائفهم، ومؤهلاتهم، وخبراتهم في العمل الذي يُطلَبون من أجله.
      حق العميل بأن يطلب من المرخص له استبدال العمال المقدمة خدماتهم بعمال آخرين يملكون نفس المؤهلات، والخبرات الضرورية للقيام بالعمل الذي طُلِبوا من أجله.
      مدة العقد لتقديم الخدمات.
      المقابل المالي الذي يحصل عليه المرخص له لقاء تقديم خدمات العمالة.`,
      parentId: 9,
    },
    {
      id: 36,
      name: "قواعد ممارسة نشاط الاستقدام وتقديم الخدمات العمالية والفقرة 18 من جدول مخالفات رقم 75913",
      description: `يجب على المرخص له بأنشطة الاستقدام الالتزام بالتجاوب مع الوزارة وذلك بالرد على الاستفسارات الكتابية ومعالجة الشكاوى`,
      recommendations: `تأكد مما يلي:- تخصيص أو إنشاء مركز اتصال وقنوات لاستقبال وحل الشكاوى من العمالة، أو أصحاب العمل، أو العملاء`,
      parentId: 9,
    },
    {
      id: 37,
      name: "المادة 121 في نظام العمل",
      description: `على صاحب العمل حفظ المنشأة في حالة صحيّة ونظيفة، وإنارتها، وتأمين المياه الصالحة للشّرب والاغتسال.`,
      recommendations: `تأكّد من التالي:
          1. هل بيئة العمل (المكاتب-خطوط الانتاج-المستودعات-الخ..) نظيفة؟
          2. هل مياه الشرب والغسيل متوفرة ونظيفة بالشكل المناسب؟
          3. هل دورات المياه نظيفة ومناسبة لعدد العاملين؟
          4. هل أماكن الغسيل نظيفة ومناسبة لعدد العاملين؟
          5. هل أماكن تغيير الملابس نظيفة ومناسبة لعدد العاملين؟
          6. هل أماكن الأكل نظيفة ومناسبة لعدد العاملين؟
          7. هل الروائح الكريهة يتم تصريفها من خلال التهوية بشكلٍ مناسب؟
          8. هل بيئة العمل ذات درجة حرارة مناسبة؟
          9. هل بيئة العمل منارة (مضاءة) بالشكل المناسب؟
          10. هل بيئة العمل يوجد فيها تهوية بالشكل المناسب؟
          11. هل يتم إزالة نفايات العمل بالشكل المناسب؟`,
      parentId: 10,
    },
    {
      id: 38,
      name: "المادة 122 في نظام العمل (1/2)",
      description: `على كلّ صاحب عمل أن يتّخذ الاحتياطات اللازمة لحماية العمّال من الأخطار، والأمراض الناجمة عن العمل، والآلات المستعملة، ووقاية العمل وسلامته. ولا يجوز لصاحب العمل أن يحمّل العمّال أو يقتطع من أجورهم أيّ مبلغ لقاء توفير هذه الحماية.`,
      recommendations: `تأكّد من التالي:
          أولاً) هل يقوم صاحب العمل بتحميل العمال لديه أو يقتطع من أجورهم أي مبلغ لقاء توفير أي احتياطات أو وسائل حماية ؟
          ثانيا) اتخاذ الاحتياطات اللازمة لحماية العمال من الاخطار والامراض الناجمة عن العمل من خلال التأكد من:
          1. هل يوجد هناك أي انزلاقات مخاطر تنقل أو سقوط مثل: سجادات متعرجة، أراضي مبللة، أو أي تغيرات غير محمية على مستوى الأرض؟
          2. هل الأرضيات سليمة ومحافظ عليها بشكلٍ جيد؟
          3. هل الممرات خالية من الحواجز والمعوقات؟
          4. هل تم تطبيق نظام تحديد السرعة داخل مواقع التخزين والشحن؟
          5. هل كواشف الدخان منتشرة في أماكنها؟
          6. هل كواشف الدخان تسير حسب جدولة صيانتها؟
          7. هل بيئة العمل خالية من الضوضاء والاهتزازات الزائدة؟
          8. هل مستويات الإنارة (الإضاءة) كافية؟ استبدال العطلان.
          9. في حالة تخزين المواد الكيميائية، هل يتم التخزين بالشكل المناسب وحسب معايير معينة؟
          10. هل المواد والمعدات وغيرها في المستودعات مرتبة بشكلٍ آمن؟
          11. هل تم تخزين الأسطوانات المضغوطة بعيد عن أشعة الشمس المباشرة؟
          12. هل يوجد اجراءات فعالة للتعامل مع انسكاب السوائل؟
          13. هل المخلفات (الهدر) مخزنة بالشكل المناسب ولا يسمح لها بالتراكم؟
          14. هل محطات العمل موضوعة بالشكل الصحيح؟
          15. هل محطات العمل مزودة بمسند للقدمين، مسند للمعصم، حماية للعينين،.. الخ من تجهيزات، حسب الحاجة؟
          16. هل تم تدريب سائقي الرافعات ومنها الرفعة الشوكية وتأهليهم؟
          17. هل تم حماية العمال من أخطار الآلات والمعدات والأدوات بإحاطة الأجزاء الخطرة، وتصريف مسببات الأخطار كالغبار، والأبخرة، والدخان، الخ..
          18. هل يتم اختيار المعدات والأدوات على أساس معايير سلامة وصحة مهنية مناسبة لطبيعة العمل؟
          19. هل تم إحاطة العاملين بمخاطر مهنتهم قبل مزاولة العمل؟
          20. هل تم إلزام العاملين باستخدام وسائل الوقاية المقررة للعمل المناط بهم؟
          21. هل تم توفير أدوات الوقاية الشخصية المناسبة للعمال (وملابس الحماية)؟`,
      parentId: 10,
    },
    {
      id: 39,
      name: "المادة 122 في نظام العمل (2/2)",
      description: `على كل صاحب عمل أن يعلن في مكان ظاهر في المنشأة التعليمات الخاصّة بسلامة العمل والعمّال وذلك باللغة العربية وبأيّ لغة أخرى يفهمها العمّال - عند الاقتضاء.`,
      recommendations: `تأكّد من التالي:
          1. هل يتم الإعلان في أماكن ظاهرة عن التعليمات الخاصة بسلامة العمل والعمال؟
          2. هل يوجد لوحات أو إعلانات (مهما كانت الوسيلة: لوحة- تلفزيون- رول أب- الخ) حول تعليمات السلامة؟
          3. هل الاعلانات حول تعليمات السلامة والصحة المهنية تحوي قوانين وسياسة ومسؤوليات السلامة والصحة المهنية؟ وبوضح وباختصار؟
          4. بالنسبة للأماكن الخطرة، هل يوجد إعلان عن تعليمات السلامة والصحة المهنية؟
          5. هل اعلانات السلامة والصحة المهنية الرسمية يفهمها العاملين (اللغة مفهومة للعاملين)؟`,
      parentId: 10,
    },
    {
      id: 40,
      name: "المادة 123 في نظام العمل",
      description: `على صاحب العمل إحاطة العامل قبل مزاولة العمل بمخاطر مهنته، وإلزامه باستعمال وسائل الوقاية المقرّرة لها، وعليه أن يوفّر أدوات الوقاية الشخصيّة المناسبة للعمّال وتدريبهم على استخدامها.`,
      recommendations: `تأكّد من التالي:
          1. هل تم إخبار العامل بالأخطار المحيطة بعمله المباشر؟
          2. هل تم إلزام العامل باستعمال وسائل الوقاية المقررة له؟
          3. هل تم توفير معدات الوقاية الشخصية المناسبة؟
          4. هل تم تدريب العامل أو العمال على استخدام معدات الوقاية الشخصية؟`,
      parentId: 10,
    },
    {
      id: 41,
      name: "المادة 124 في نظام العمل",
      description: `على العامل أن يستعمل الوسائل الوقائيّة المخصّصة لكل عملية وأن يحافظ عليها، وأن ينفّذ التعليمات الموضوعة للمحافظة على صحته ووقايته من الإصابات والأمراض، وعليه أن يمتنع عن ارتكاب أيّ فعل أو تقصير يتسبب عنه عدم تنفيذ التعليمات، أو إساءة استعمال الوسائل المعدّة لحماية مقرّ العمل وصحّة العمّال المشتغلين معه وسلامتهم أو تعطيلها.`,
      recommendations: `تأكّد من التالي:
      1. هل يستعمل العامل الوسائل الوقائية المخصصة لكل عملية؟
      2. هل يحافظ العامل على الوسائل الوقائية المخصصة لكل عملية؟
      3. هل ينفذ العامل التعليمات الموضوعة للمحافظة على صحته ووقايته من الاصابات والأمراض؟
      4. هل يسيء العامل استعمال الوسائل المعدة لحماية مقر العمل ولحماية سلامة وصحة العمال من تعطيل، من عدم اتباع التعليمات، الخ..بما قد يؤثر على صحة العمال والمشتغلين معه وسلامتهم`,
      parentId: 10,
    },
    {
      id: 42,
      name: "المادة 125 في نظام العمل",
      description: `على صاحب العمل أن يتّخذ الاحتياطات اللازمة للوقاية من الحريق وتهيئة الوسائل الفنيّة لمكافحته، بما في ذلك تأمين منافذ للنجاة وجعلها صالحة للاستعمال في أيّ وقت، وأن يعلّق في مكان ظاهر من أماكن العمل تعليمات مفصّلة بشأن وسائل منع الحريق.`,
      recommendations: `تأكّد من التالي:
          1. هل طفايات الحريق واضحة وسهلة الوصول إليها؟
          2. هل طفايات الحريق مناسبة لمخاطر الحريق المحتملة وكافية العدد؟
          3. هل تم التأكد من صلاحية وعمل طفايات الحريق؟ مع ملاحظة تتابع آخر عملية فحص لحالة الطفايات؟
          4. هل كواشف الدخان منتشرة في أماكنها؟
          5. هل كواشف الدخان تسير حسب جدولة صيانتها؟
          6. هل مخارج الطوارئ مغلقة في الحالات الطبيعية وتعمل فوراً عند الحاجة؟
          7. هل يوجد نقاط تجمع في حالات الطوارئ؟
          8. هل أنوار  (إضاءة) الطوارئ تشتغل؟
          9. هل خطط الطوارئ ومنها الإخلاء معلنة في الأماكن المناسبة؟
          10. هل الممرات خالية من الحواجز والمعوقات؟
          11. هل يوجد وسائل الإطفاء المناسبة (الذاتية)- حسب نوع العمل-؟
          12. هل أسماء ومواقع ممثلي أو المسؤولين عن السلامة والصحة المهنية ومسؤولي الإسعافات الأولية أثناء حوادث الحريق معلنة ومعروفة للعاملين؟
          13. هل لدى المنشأة رخصة الدفاع المدني (للمحلات أو المنشآت التي تتطلب رخصة من الدفاع المدني)؟
          14. هل لدى المنشأة تصريح أو شهادة سلامة من مدن (للمنشآت بداخل المدن الصناعية التابعة لمدن)؟
          15. هل لدى المنشأة عقد صيانة ساري المفعول لأنظمة الإطفاء والحرائق وموضح فيه جدول الصيانة وما تم من أعمال صيانة؟
          16. هل خطط الطوارئ ومنها الإخلاء معلنة في الأماكن المناسبة؟ هل الممرات خالية من الحواجز والمعوقات؟`,
      parentId: 10,
    },
    {
      id: 43,
      name: "القرار الوزاري رقم 3337 تاريخ 15/7/1435",
      description: `لا يجوز تشغيل العامل في الأعمال المكشوفة تحت أشعة الشمس من الساعة الثانية عشر ظهرا الى الساعة الثالثة مساء خلال الفترة الواقعة بين اليوم الخامس والعشرين من برج الجوزاء الموافق الخامس عشر من شهر يونية، إلى نهاية اليوم الرابع والعشرين من برج السنبلة الموافق الخامس عشر من شهر سبتمبر من كل عام ميلادي`,
      recommendations: `تأكّد من التالي:
          عدم تشغيل العاملين في الأعمال المكشوفة تحت أشعة الشمس من الساعة الثانية عشر ظهرا الى الساعة الثالثة مساء خلال الفترة الواقعة بين اليوم الخامس والعشرين من برج الجوزاء الموافق الخامس عشر من شهر يونية، إلى نهاية اليوم الرابع والعشرين من برج السنبلة الموافق الخامس عشر من شهر سبتمبر من كل عام ميلادي`,
      parentId: 10,
    },
    {
      id: 44,
      name: "المادة 142 في نظام العمل",
      description: `على كل صاحب عمل أن يعدّ خزانة أو أكثر للإسعافات الطبية، مزوّدة بالأدوية وغيرها، مما يلزم للإسعافات الطبية الأوّلية. وتحدّد اللائحة ما يجب أن تحتويه هذه الخزانة من وسائل الإسعافات الأوليّة وعددها، وكميّات الأدوية، وكذلك تنظيم وسائل حفظها وشروط من يقوم بمهمة الإسعافات.`,
      recommendations: `تأكّد من التالي:
          1. هل يوجد عدد كافي من صناديق الاسعافات الأولية؟ 
          2. هل تحوي صناديق الاسعافات الأولية المحتويات الصحيحة والصالحة للاستعمال؟ 
          3. هل حجم خزانة الإسعاف مناسباً لما تحتويه من أدوية ومعدات؟
          4. هل يتم حفظ خزانة الإسعاف في مكان تتوافر فيه الشروط الصحية ودرجة حرارة مناسبة؟
          5. هل تم تعليق إعلانات بشكل ظاهر في أمكنة العمل تدل على مكان الخزانة واسم المسئول عنها؟  
          (بالنسبة للفقرات 2،3،4،5 فإنها حسب المادة 30 من اللائحة التنفيذية لنظام العمل)`,
      parentId: 11,
    },
    {
      id: 45,
      name: "المادة 143 في نظام العمل",
      description: `على كل صاحب عمل أن يعهد إلى طبيب أو أكثر بفحص عماله المعرضين لاحتمال الإصابة بأحد الأمراض المهنية المحددة في جداول الأمراض المهنية - المنصوص عليها في نظام التأمينات الاجتماعية - فحصاً شاملًا مرة كل سنة على الأقل، وأن يثبت نتيجة ذلك الفحص في سجلاته، وكذلك في ملفات أولئك العمال.`,
      recommendations: `تأكّد من التالي:
          1. هل تم فحص العمال المعرضين لاحتمال الاصابة بأحد الامراض المهنية في جداول الامراض المهنية - المنصوص عليها في نظام التأمينات الاجتماعية- مرة في كل سنة على الأقل؟
          2. هل تم تسجيل نتيجة ذلك الفحص في ملفات وسجلات العمال؟`,
      parentId: 11,
    },
    {
      id: 46,
      name: "المادة 144 في نظام العمل",
      description: `على صاحب العمل أن يوفر لعمّاله العناية الصحيّة الوقائيّة والعلاجيّة طبقًا للمستويات التي يقرّرها الوزير، مع مراعاة ما يوفّره نظام الضمان الصحي التعاوني.`,
      recommendations: `تأكّد من التالي:
          1. من حصول العامل وأسرته (حسب نظام التأمين التعاوني الصحي) على التأمين الطبي.
          2. المنشأة التي يكون فيها عدد العاملين 50 فأكثر قد قامت بتعيين مُسعف أوّلي متفرّغ يحمل مؤهّلات مُصدّقة من الجهات المختصّة، وذلك بغض النظر عمّا إذا كان هنالك طبيب زائر أو طبيب يعمل بدوام كامل أو وجود رعاية طبيّة متكاملة، على أن يتوفّر بمكان العمل غرفة للإسعافات الاوّلية وفقًا للشروط:
          2.1– أن تكون مستوفية للمتطلّبات الصحيّة.
          2.2– أن تقع في مكان مناسب يمكن وصول المصابين أو نقلهم إليها بسرعة وبسهولة مع وضع لافتات إرشاديّة على مكان هذه الغرف في أماكن متعددة بموقع العمل للتعرّف عليها بسهولة.
          2.3– ألّا تزيد المسافة بينها وبين أقصى مكان للعمل على مائتي متر ما لم تتوافر وسيلة النّقل السريع للمصابين.
          2.4– أن يتوافر عدد مناسب من النقّالات لنقل المصابين إلى مكان الإسعاف.
          2.5– غرفة للتمريض وغرفة أو أكثر لتغيير الملابس في المناجم أو المحاجر التي يشتغل فيها خمسين عاملًا أو أكثر.
          3. المنشأة التي فيها 500 عامل فأكثر والتي تبعد مسافة لا تقلّ عن عشرة كيلومترات عن أقرب وحدة صحيّة قد قامت بتأمين سيارة إسعاف لنقل المصابين، وأنه تم تخصيص هاتف لتأمين الاتصالات اللازمة المتعلقة بالطوارئ عند الضرورة`,
      parentId: 11,
    },
    {
      id: 47,
      name: "المادة 147 من نظام العمل",
      description: `يُلزم صاحب العمل الذي يؤدّي عملًا في الأماكن البعيدة عن العمران، وفي المناجم، والمحاجر، ومراكز التنقيب عن النفط بتوفير المساكن والمخيّمات والوجبات الغذائية للعمّال.`,
      recommendations: `يقصد بالأماكن البعيدة عن العمران:
      - مواقع العمل التي تبتعد عن النطاق العمراني الذي تحدده الأمانات والبلديات بمسافة 50 كيلومتر إذا كانت الطريق معبدة، أو مسافة 25 كيلومتر إذا كانت الطريق غير معبدة
      - التجمعات السكانية التي لا تتوفر بها المرافق والخدمات
      
      تأكّد من التالي:
      1 - توفير حوانيت لبيع الطعام والملابس وغير ذلك من الحاجات الضرورية بأسعار معتدلة، وذلك في مناطق العمل التي لا تتوافر فيها عادة تلك الحوانيت.
      2 - توفير وسائل ترفيه وتثقيف مناسبة وملاعب رياضية ملحقة بأماكن العمل.
      3 - إجراء ما يلزم من الترتيبات الطبية المناسبة للمحافظة على صحة العمال وعلاج أسرهم علاجاً شاملًا. (ويقصد بالأسرة: الزوج والأولاد والأم والأب المقيمون معه).
      4 - توفير مدارس لتعليم أولاد العمال إذا لم يتوافر في المنطقة مدارس كافية.
      5 - إعداد مساجد أو مصليات في أماكن العمل.
      6 - إعداد برامج لمحو الأمية بين العمال.`,
      parentId: 11,
    },
    {
      id: 48,
      name: "المادة 148 من نظام العمل",
      description: `على كلّ صاحب عمل أن يوفّر لعمّاله وسائل الانتقال من محلّ إقامتهم أو من مركز تجمّع معيّن إلى أماكن العمل، وإعادتهم يوميًّا إذا كانت هذه الأماكن لا تصل إليها وسائل المواصلات المنتظمة في مواعيد تتفق مع مواعيد العمل.`,
      recommendations: `تأكّد من التالي:
      توفير وسائل الانتقال للعمال من محل اقامتهم إلى أماكن العمل وإعادتهم يوميا إذا كانت هذه الأماكن لا تصل اليها وسائل المواصلات`,
      parentId: 11,
    },
    // معايير خاصة للعاملين 
    {
      id: 49,
      name: "المادة 33 في نظام العمل",
      description: `لا يجوز لغير السعودي أن يمارس عملًا، ولا يجوز أن يسمح له بمزاولته، إلّا بعد الحصول على رخصة عمل من الوزارة وفق النموذج الذي تعدّه لهذا الغرض.
          ويشترط لمنح الرخصة ما يأتي:
          1. أن يكون العامل قد دخل البلاد بطريقة مشروعة ومصرحًا له بالعمل.
          2. أن يكون من ذوي الكفايات المهنيّة أو المؤهلات الدراسيّة التي تحتاج إليها البلاد، ولا يوجد من أبناء البلاد من يحملها أو كان العدد الموجود منهم لا يفي بالحاجة، أو يكون من فئة العمّال العاديين التي تحتاج إليها البلاد.
          3. أن يكون متعاقدًا مع صاحب عمل وتحت مسؤوليته.
          ويقصد بكلمة (العمل) في هذه المادة كلّ عمل صناعي، أو تجاري، أو زراعي، أو مالي، أو غيره، وأيّ خدمة بما في ذلك الخدمة المنزلية.
          `,
      recommendations: `تحقق من حصول العمّال على رخصة عمل من الوزارة.
          ويشترط لمنح الرخصة ما يأتي:
          1. أن يكون العامل قد دخل البلاد بطريقة مشروعة ومصرحًا له بالعمل.
          2. أن يكون من ذوي الكفايات المهنيّة أو المؤهلات الدراسيّة التي تحتاج إليها البلاد، ولا يوجد من أبناء البلاد من يحملها أو كان العدد الموجود منهم لا يفي بالحاجة، أو يكون من فئة العمّال العاديين التي تحتاج إليها البلاد.
          3. أن يكون متعاقدًا مع صاحب عمل وتحت مسؤوليته.
          ويقصد بكلمة (العمل) في هذه المادة كلّ عمل صناعي، أو تجاري، أو زراعي، أو مالي، أو غيره، وأيّ خدمة بما في ذلك الخدمة المنزلية.`,
      parentId: 12,
    },
    {
      id: 50,
      name: "المادة 37 في نظام العمل",
      description: `يجب أن يكون عقد عمل غير السعودي مكتوبًا ومحدّد المدة، وإذا خلا العقد من بيان مدته تعدّ مدة رخصة العمل هي مدّة العقد.`,
      recommendations: `تحقق مما يلي:
          1. يجب أن يكون عقد عمل غير السعودي مكتوب ومحدّد المدة، أو مدد تجديده، ولا يتحول إلى عقد غير محدد المدة حتى لو اتفق الطرفان على ذلك
          2. يجب أن يكتب عقد العمل من نسختين.
          3. يجب أن يحتوي عقد العمل بصورة أساسية على:
          - اسم صاحب العمل ومكانه.
          - اسم العامل وجنسيّته.
          - ما يلزم لإثبات شخصيّته.
          - الأجر المتّفق عليه.
          - نوع العمل ومكانه.
          - تاريخ الالتحاق به.
          - مدّة العقد و/أو مدد تجديده.`,
      parentId: 12,
    },
    {
      id: 51,
      name: "المادة 38 في نظام العمل",
      description: `لا يجوز لصاحب العمل توظيف العامل في مهنة غير المهنة المدوّنة في رخصة عمله، ويحظر على العامل الاشتغال في غير مهنته قبل اتّخاذ الإجراءات النظاميّة لتغيير المهنة.        `,
      recommendations: `1. تأكّد من عدم توظيف العامل في مهنة غير المهنة المدوّنة في رخصته.
          2. في حال كان العامل يشتغل في غير مهنته تأكّد من وجود ما يثبت ما يلي:
          - أن يكون لدى صاحب العمل ترخيص مزاولة نشاط ساري المفعول، أو سحل تجاري ساري المفعول مسجلا لدى مكتب العمل وأن يكون النشاط قائما
          - يقدم طلب تغيير المهنة عن طريق موقع الخدمات الالكترونية لدى وزارة الموارد البشرية والتنمية الاجتماعية
          - أن يكون اسم المهنة المطلوب التغيير لها محددا حسب دليل التصنيف والتوصيف السعودي
          - أن تتوفر لدى العامل المؤهلات العلمية والعملية المؤيدة لإجادته للمهنة
          ولا يجوز تكليف العامل بعمل يختلف اختلافا جوهرياً عن العمل المتفق عليه بغير موافقته الكتابية، إلا في حالات الضرورة التي قد تقتضيها ظروف عارضة ولمدة لا تتجاوز ثلاثين يوماً في السنة.`,
      parentId: 12,
    },
    {
      id: 52,
      name: "المادة 39 في نظام العمل",
      description: `1 ) لا يجوز - بغير اتباع القواعد والاجراءات النظامية المقررة – أن يترك صاحب العمل عامله يعمل لدى الغير، ولا يجوز للعامل أن يعمل لدى صاحب عمل آخر، كما لا يجوز لصاحب العمل توظيف عامل غيره، وتتولى وزارة العمل التفتيش على المنشآت، والتحقيق في المخالفات التي يتم ضبطها من قبل مفتشيها، ومن ثم إحالتها إلى وزارة الداخلية لتطبيق العقوبات المقررة بشأنها.
      2 ) لا يجوز لصاحب العمل أن يترك عامله يعمل لحسابه الخاص، كما لا يجوز للعامل أن يعمل لحسابه الخاص، وتتولى وزارة الداخلية ضبط وإيقاف وترحيل وإيقاع العقوبات على المخالفين من العاملين لحسابهم الخاص (العمالة السائبة) في الشوارع والميادين والمتغيبين عن العمل (الهاربين) وكذلك أصحاب العمل والمشغلين لهؤلاء والمتسترين عليهم والناقلين لهم وكل من له دور في المخالفة وتطبيق العقوبات المقررة بحقهم ".`,
      recommendations: `قم بالتأكد من التالي:
          1. أنّ المنشأة تقوم بالإجراءات اللازمة لعدم ترك عاملها يعمل لدى غيرها، والسّماح للعامل أن يعمل لدى صاحب عمل آخر، أو توظيف عامل غيره من غير الحصول على الموافقات الرسميّة اللازمة.
          2. عند وجود عمالة مستأجرة بنظام أجير، يتم التأكّد عن طريق عقد العامل أو إشعار أجير.
          3. قم بالتأكّد من أنّ المنشأة تقوم بالإجراءات اللازمة لعدم ترك عاملها أن يعمل لحسابه الخاص`,
      parentId: 12,
    },
    {
      id: 53,
      name: "المادة 40 في نظام العمل",
      description: `1. يتحمل صاحب العمل رسوم استقدام العامل غير السعودي، ورسوم الإقامة ورخصة العمل وتجديدهما وما يترتب على تأخير ذلك من غرامات، ورسوم تغيير المهنة، والخروج والعودة، وتذكرة عودة العامل إلى موطنه بعد انتهاء العلاقة بين الطرفين.
          2. يتحمل العامل تكاليف عودته إلى بلده في حالة عدم صلاحيته للعمل أو إذا رغب في العودة دون سبب مشروع.
          3. يتحمل صاحب العمل رسوم نقل خدمات العامل الذي يرغب في نقل خدماته إليه.
          4. يلزم صاحب العمل بنفقات تجهيز جثمان العامل ونقله إلى الجهة التي أبرم فيها العقد أو استقدم العامل منها، مالم يدفن بموافقة ذويه داخل المملكة. ويعفى صاحب العمل في حالة التزام المؤسسة العامة للتأمينات الاجتماعية بذلك.`,
      recommendations: `تحقق من أنّ المنشأة تقوم بتحمّل الرسوم التالية:
          1. رسوم استقدام العامل غير السعودي.
          2. رسوم الإقامة ورخصة العمل وتجديدهما وما يترتّب على تأخير ذلك من غرامات.
          3. رسوم تغيير المهنة، والخروج والعودة، وتذكرة عودة العامل إلى موطنه بعد انتهاء العلاقة بين الطرفين.
          4. رسوم نقل خدمات العامل الذي يرغب في نقل خدماته إليه.
          5. نفقات تجهيز جثمان العامل ونقله إلى الجهة التي أبرم فيها العقد أو استقدم العامل منها، ما لم يدفن بموافقة ذويه داخل المملكة (ويُعفى صاحب العمل في حالة التزام المؤسسة العامة للتأمينات الاجتماعية بذلك).
          ولا يجوز تحميل العامل أو ذويه أي من هذه التكاليف أو الرسوم الملزم بها صاحب العمل`,
      parentId: 12,
    },
    {
      id: 54,
      name: "فقرة 21 من جدول المخالفات رقم 75913",
      description: `يجب على صاحب العمل عند توظيف المرافقين الحصول على تصريح ساري المفعول وفق الشروط والإجراءات التي تضعها الوزارة`,
      recommendations: `تأكد من التالي:
      أن المنشأة لديها تصريح او اشعار أجير ساري المفعول لتوظيف المرافقين`,
      parentId: 12,
    },
    {
      id: 55,
      name: "فقرة 31 من جدول المخالفات رقم 75913",
      description: `لا يجوز لصاحب العمل الاحتفاظ بجواز السفر  أو  الاقامة للعامل أو أفراد أسرته ي دون موافقته`,
      recommendations: `تأكد من أن المنشأة لم تقم بحجز أي من ممتلكات العامل دون موافقته بغرض فرض أعمال أو خدمات على العامل عنوة أو تحت التهديد بأي عقاب، على سبيل المثال لا الحصر، احتفاظ المنشأة بجواز سفر العامل أو اقامته أو بطاقة التأمين الطبي دون موافقة العامل في سبيل فرض أعمال عليه لم ينص عليها في عقد العمل`,
      parentId: 12,
    },
    {
      id: 56,
      name: "المادة 36 من نظام العمل وفقرة 29 من جدول المخالفات رقم 75913",
      description: `توظيف عمالة غير سعودية في مهن أو أنشطة مقصورة على السعوديين`,
      recommendations: `1. تأكد أن الوظائف التالية لا يشغلها أي شخص غير سعودي:
      1- كبير اداري موارد بشرية 2- مدير شؤون موظفين 3- مدير شؤون عمل وعمال 4- مدير علاقات افراد 5-اختصاصي شؤون افراد 6-كاتب شؤون افراد 7-كاتب توظيف 8- كاتب شؤون موظفين 9- كاتب دوام 10-كاتب استقبال عام 11- كاتب استقبال فندقي 12-كاتب استقبال مرضى 13-كاتب شكاوى 14- أمين صندوق 15-حارس أمن خاص 16-معقب 17- ناسخ أو مصلح مفاتيح 18- مخلص جمركي
  
      2. يقتصر العمل في محلات بيع المستلزمات النسائية على المرأة السعودية بحسب قرارات تأنيث الأنشطة
      3. يقتصر العمل في منافذ البيع على السعوديين من الجنسين بحسب القرار الوزاري 95296 والقاضي بتوطين منافذ البيع في 12 نشاطا اقتصاديا (منافـــذ البيـــع فـــي محلات الملابس الجاهـــزة وملابس الأطفال والمســـتلزمات الرجاليـــة - منافذ البيع في محلات السيارات والدراجات النارية - منافذ البيع في محلات الأثاث المنزلي والمكتبي الجاهزة - منافذ البيع في محلات الأواني المنزلية - منافذ البيع في محلات الساعات - منافذ البيع في محلات النظارات - منافذ البيع في محلات الأجهزة الكهربائية والإلكترونية - منافذ البيع في محلات الأجهزة والمعدات الطبية - منافذ البيع في محلات الحلويات - منافذ البيع في محلات قطع غيار السيارات - منافذ البيع في محلات مواد الإعمار والبناء - منافذ البيع في محلات السجاد بكافة أنواعه)`,
      parentId: 12,
    },
    {
      id: 57,
      name: "المادة 28 من نظام العمل",
      description: `على كل صاحب عمل يستخدم خمسة وعشرين عاملًا فأكثر، وكانت طبيعة العمل لديه تمكنه من تشغيل المعوقين الذين تم تأهيلهم مهنياً أن يشغل 4 % على الأقل من مجموع عدد عماله من المعوقين المؤهلين مهنياً، سواء أكان ذلك عن طريق ترشيح وحدات التوظيف أو غيرها. وعليه أن يرسل إلى مكتب العمل المختص بياناً بعدد الوظائف والأعمال التي يشغلها المعوقون الذين تم تأهيلهم مهنياً، وأجر كل منهم.`,
      recommendations: `1. تأكد أن الوظائف التالية لا يشغلها أي شخص غير سعودي:
      1- كبير اداري موارد بشرية 2- مدير شؤون موظفين 3- مدير شؤون عمل وعمال 4- مدير علاقات افراد 5-اختصاصي شؤون افراد 6-كاتب شؤون افراد 7-كاتب توظيف 8- كاتب شؤون موظفين 9- كاتب دوام 10-كاتب استقبال عام 11- كاتب استقبال فندقي 12-كاتب استقبال مرضى 13-كاتب شكاوى 14- أمين صندوق 15-حارس أمن خاص 16-معقب 17- ناسخ أو مصلح مفاتيح 18- مخلص جمركي
  
      2. يقتصر العمل في محلات بيع المستلزمات النسائية على المرأة السعودية بحسب قرارات تأنيث الأنشطة
      3. يقتصر العمل في منافذ البيع على السعوديين من الجنسين بحسب القرار الوزاري 95296 والقاضي بتوطين منافذ البيع في 12 نشاطا اقتصاديا (منافـــذ البيـــع فـــي محلات الملابس الجاهـــزة وملابس الأطفال والمســـتلزمات الرجاليـــة - منافذ البيع في محلات السيارات والدراجات النارية - منافذ البيع في محلات الأثاث المنزلي والمكتبي الجاهزة - منافذ البيع في محلات الأواني المنزلية - منافذ البيع في محلات الساعات - منافذ البيع في محلات النظارات - منافذ البيع في محلات الأجهزة الكهربائية والإلكترونية - منافذ البيع في محلات الأجهزة والمعدات الطبية - منافذ البيع في محلات الحلويات - منافذ البيع في محلات قطع غيار السيارات - منافذ البيع في محلات مواد الإعمار والبناء - منافذ البيع في محلات السجاد بكافة أنواعه)`,
      parentId: 13,
    },
    {
      id: 58,
      name: "المادة 29 من نظام العمل",
      description: `إذا أصيب أيّ عامل إصابة عمل نتج عنها نقص في قدراته المعتادة لا يمنعه من أداء عمل آخر غير عمله السابق، فإنّ على صاحب العمل الذي وقعت إصابة العامل بسبب العمل لديه توظيفه في العمل المناسب بالأجر المحدّد لهذا العمل، ولا يخلّ هذا بما يستحقه من تعويض عن إصابته.`,
      recommendations: `تأكد من التالي:
      - تعويض العامل عن إصابته التي وقعت بسبب العمل
      - في حال عدم تمكن العامل من أداء عمله السابق نتيجة الإصابة، فعلى صاحب العمل توظيفه في عمل مناسب بالأجر المناسب المحدد لهذا العمل`,
      parentId: 13,
    },
    //////////////////////////////
    {
      id: 59,
      name: "المادة 151 من نظام العمل",
      description: `1. للمرأة العاملة الحق في إجازة وضع بأجر كامل لمدّة عشرة أسابيع توزعها كيف تشاء، تبدأ بحدّ أقصى بأربعة أسابيع قبل التاريخ المرجّح للوضع، بموجب شهادة طبيّة مُصدّقة من جهة صحية.
      2. يُحظر تشغيل المرأة بعد الوضع بأيّ حال من الأحوال خلال الستة أسابيع التالية له، ولها الحق في تمديد الإجازة مدة شهر دون أجر.
      3. للمرأة العاملة - في حالة إنجاب طفل مريض أو من ذوي الاحتياجات الخاصة وتتطلب حالته الصحيّة مرافقًا مستمرًّا له - الحق في إجازة مدّتها شهر بأجر كامل تبدأ بعد انتهاء مدة إجازة الوضع، ولها الحق في تمديد الإجازة لمدة شهر دون أجر.`,
      recommendations: `تأكّد من التالي:
      - تأكد من التزام المنشأة بالإجازات المقررة للمرأة العاملة بما تنص عليه المادة 151 من نظام العمل
      - على صاحب العمل توفير الرعاية الطبيّة للمرأة العاملة أثناء الحمل والولادة.
      - يحق للمرأة العاملة عندما تعود إلى مزاولة عملها بعد إجازة الوضع أن تأخذ بقصد إرضاع مولودها فترة أو فترات للاستراحة لا تزيد في مجموعها على الساعة في اليوم الواحد، وذلك علاوة على فترات الراحة الممنوحة لجميع العمّال، وتحسب هذه الفترة أو الفترات من ساعات العمل الفعليّة، ولا يترتّب عليها تخفيض الأجر.
      - لا يجوز لصاحب العمل فصل العاملة أو إنذارها بالفصل أثناء تمتّعها بإجازة الوضع.
      - لا يجوز لصاحب العمل فصل العاملة أثناء فترة مرضها الناتج عن الحمل أو الوضع، ويثبت المرض بشهادة طبيّة معتمدة، على ألّا تتجاوز مدة غيابها مائة وثمانين يومًا، ولا يجوز فصلها بغير سبب مشروع من الأسباب المنصوص عليها في نظام العمل خلال المائة والثمانين يومًا السابقة على التاريخ المحتمل للولادة.`,
      parentId: 14,
    },
    {
      id: 60,
      name: "المادة 158 من نظام العمل",
      description: `على صاحب العمل في جميع الأماكن التي يعمل فيها نساء وفي جميع المهن أن يوفر لهن مقاعد، تأميناً لاستراحتهن.`,
      recommendations: `تأكّد من التالي:
      على صاحب العمل توفير مكان مخصص للموظفات لأداء الصلاة والاستراحة و دورة مياه أو أكثر بحسب الحاجة.`,
      parentId: 14,
    },
    {
      id: 61,
      name: "المادة 159 من نظام العمل",
      description: `على كل صاحب عمل يشغل خمسين عاملة فأكثر أن يهيئ مكاناً مناسباً يتوافر فيه العدد الكافي من المربيات، لرعاية أطفال العاملات الذين تقل أعمارهم عن ست سنوات، وذلك إذا بلغ عدد الأطفال عشرة فأكثر.`,
      recommendations: `في حال كان يعمل لدى صاحب العمل 50 عاملة فأكثر تأكد من التالي:
      تهيئة مكان مناسباً يتوافر فيه العدد الكافي من المربيات، لرعاية أطفال العاملات الذين تقل أعمارهم عن ست سنوات`,
      parentId: 14,
    },
    {
      id: 62,
      name: "المادة 160 من نظام العمل",
      description: `1. للمرأة العاملة المسلمة التي يتوفى زوجها الحق في إجازة عدة بأجر كامل لمدة لا تقل عن أربعة أشهر وعشرة أيام من تاريخ الوفاة، ولها الحق في تمديد هذه الإجازة دون أجر إن كانت حاملًا -خلال هذه الفترة - حتى تضع حملها، ولا يجوز لها الاستفادة من باقي إجازة العدة الممنوحة لها - بموجب هذا النظام - بعد وضع حملها.
      2. للمرأة العاملة غير المسلمة التي يتوفى زوجها الحق في إجازة بأجر كامل لمدة خمسة عشر يوماً. 
      وفي جميع الأحوال لا يجوز للعاملة المتوفى عنها زوجها ممارسة أي عمل لدى الغير خلال هذه المدة. ويحق لصاحب العمل أن يطلب الوثائق المؤيدة للحالات المشار إليها.`,
      recommendations: `تأكّد من التالي:
      1. للمرأة العاملة المسلمة التي يتوفى زوجها الحق في إجازة عدة بأجر كامل لمدة لا تقل عن أربعة أشهر وعشرة أيام من تاريخ الوفاة، ولها الحق في تمديد هذه الإجازة دون أجر إن كانت حاملًا -خلال هذه الفترة - حتى تضع حملها، ولا يجوز لها الاستفادة من باقي إجازة العدة الممنوحة لها - بموجب هذا النظام - بعد وضع حملها.
      2. للمرأة العاملة غير المسلمة التي يتوفى زوجها الحق في إجازة بأجر كامل لمدة خمسة عشر يوماً. 
      وفي جميع الأحوال لا يجوز للعاملة المتوفى عنها زوجها ممارسة أي عمل لدى الغير خلال هذه المدة. ويحق لصاحب العمل أن يطلب الوثائق المؤيدة للحالات المشار إليها.`,
      parentId: 14,
    },
    {
      id: 63,
      name: "المادة 161 من نظام العمل",
      description: `لا يجوز تشغيل الأحداث في الأعمال الخطرة، أو الصناعات الضارّة، أو في المهن والأعمال التي يُحتمل أن تُعرّض صحتهم أو سلامتهم أو أخلاقهم للخطر، بسبب طبيعتها أو الظروف التي تُؤدّى فيها. ويُحدد الوزير بقرار منه الأعمال والصناعات والمهن المشار إليها.`,
      recommendations: `تأكد من التالي:
      عدم عمل أي شخص لم يبلغ 18 عاما في المهن والأعمال التي يحتمل أن تعرض صحته وسلامته وأخلاقه للخطر بسبب طبيعتها أو الظروف التي تؤدى اليها، ومنها:
      - العمل في المناجم أو المحاجر أو استخراج المواد المعدنية مت تحت الأرض
      - الصناعات ذات المخاطر الصحية
      - الأعمال الشاقة
      - الأعمال التي قد تعرض الحدث لمخاطر جسدية
      - أي عمل قد يؤدي مكان وظروف أدائه إلى تعرض الحدث للمشكلات الأخلاقية والنفسية والجسدية`,
      parentId: 15,
    },
    {
      id: 64,
      name: "المادة 162 من نظام العمل",
      description: `لا يجوز تشغيل أيّ شخص لم يتمّ الخامسة عشرة من عمره ولا يسمح له بدخول أماكن العمل`,
      recommendations: `تأكد من أنه لم يتم توظيف أي شخص لم يتم 15 عاما، ويستثنى من ذلك:
      العمل الذي يؤديه الأطفال والأحداث في المدارس لأغراض التعليم العام أو المهني أو التقني، وفي مؤسسات التدريب الأخرى، ولا تسري على العمل الذي يؤديه في المنشآت الأشخاص الذين بلغوا سن أربع عشرة سنة على الأقل إذا كان هذا العمل ينفذ وفقاً لشروط يقررها الوزير، وكان العمل يشكل جزءاً أساسياً من الآتي:
      1 - دورة تعليمية، أو تدريبية، تقع مسؤوليتها الرئيسية على مدرسة، أو مؤسسة تدريب.
      2 - برنامج تدريبي ينفذ قسمه الأكبر، أو كله في منشأة إذا كانت الجهة المختصة قد أقرته.
      3 - برنامج إرشادي، أو توجيهي، يرمي إلى تسهيل اختيار المهنة، أو نوع التدريب.`,
      parentId: 15,
    },
    {
      id: 65,
      name: "المادة 163 من نظام العمل",
      description: `يحظر تشغيل الأحداث أثناء فترة من الليل لا تقل عن اثنتي عشرة ساعةً متتالية إلّا في الحالات التي يُحددها الوزير بقرار منه.`,
      recommendations: `تأكد من أنه لم يتم تشغيل الأحداث أثناء فترة الليل المحظورة والتي لا تقل عن 12 ساعة متتالية، ويستثنى من ذلك:
      - المنشأة التي يقتصر العمل فيها على أفراد الأسرة
      - العمل في المدارس المهنية، ومراكز التدريب
      - العمل في المخابز، باستثناء الفترة من التاسعة مساء وحتى الرابعة صباحا
      - حالات القوة القاهرة والطوارئ`,
      parentId: 15,
    },
    {
      id: 66,
      name: "المادة 164 من نظام العمل",
      description: `لا يجوز تشغيل الأحداث تشغيلًا فعليًّا أكثر من ستّ ساعات في اليوم الواحد لسائر شهور السنة، عدا شهر رمضان فيجب ألّا تزيد ساعات العمل الفعليّة فيه على أربع ساعات. وتنظّم ساعات العمل بحيث لا يعمل الحدث أكثر من أربع ساعاتٍ متّصلة دون فترة أو أكثر للراحة والطعام والصلاة، لا تقلّ في المرة الواحدة عن نصف ساعة، وبحيث لا يبقى في مكان العمل أكثر من سبع ساعات. ولا يجوز تشغيل الأحداث في أيام الرّاحة الأسبوعية أو في أيام الأعياد والعطلات الرسميّة والإجازة السنويّة. ولا تسري عليهم الاستثناءات التي نصّت عليها المادة السادسة بعد المائة من نظام العمل.`,
      recommendations: `تأكد من التالي:
      - لا يعمل الاحداث في المنشأة اكثر من 6 ساعات في اليوم الواحد خلال جميع اشهر السنة ما عدى شهر رمضان
      - لا يعمل الاحداث أكثر من 4 ساعات في شهر رمضان
      - توفير فترات للراحة والطعام والصلاة للأحداث بحيث لا يعمل الحدث أكثر من أربع ساعات متصلة دون راحة`,
      parentId: 15,
    },
    {
      id: 67,
      name: "المادة 165 من نظام العمل",
      description: `على صاحب العمل قبل تشغيل الحدث أن يستوفي منه المستندات الآتية:
      1 - بطاقة الهوية الوطنية أو شهادة رسمية بميلاده.
      2 - شهادة باللياقة الصحية للعمل المطلوب، صادرة من طبيب مختص، ومصدق عليها من جهة صحية.
      3 - موافقة ولي أمر الحدث.
      ويجب حفظ هذه المستندات في الملف الخاص بالحدث.`,
      recommendations: `تأكد من استيفاء المستندات المحددة بحسب المادة 165 من نظام العمل قبل تشغيل الاحداث وهي:
      1 - بطاقة الهوية الوطنية أو شهادة رسمية بميلاده.
      2 - شهادة باللياقة الصحية للعمل المطلوب، صادرة من طبيب مختص، ومصدق عليها من جهة صحية.
      3 - موافقة ولي أمر الحدث.
      ويجب حفظ هذه المستندات في الملف الخاص بالحدث`,
      parentId: 15,
    },
    {
      id: 68,
      name: "المادة 166 من نظام العمل",
      description: `على صاحب العمل أن يبلغ مكتب العمل المختصّ عن كلّ حدث يشغله خلال الأسبوع الأوّل من تشغيله، وأن يحتفظ في مكان العمل بسجلّ خاص للعمّال الأحداث يبيّن فيه اسم الحدث وعمره، والاسم الكامل لولي أمره، ومحلّ إقامته، وتاريخ تشغيله.`,
      recommendations: `تأكد من التالي:
      أن المنشأة قامت بإبلاغ مكتب العمل المختص عن كل حدث تم تشغيله خلال الأسبوع الأول من التشغيل`,
      parentId: 15,
    },
    {
      id: 69,
      name: "المادة 170 من نظام العمل",
      description: `يجب أن تُسجّل في سجلّات السفينة أو أن تلحق بها جميع عقود عمل البحّارة العاملين عليها، وأن تكون هذه العقود محررة بصيغة واضحة. ويجب أن ينصّ في العقد على ما إذا كان معقودًا لمدة محددة أو لسفرة، فإذا كان معقودًا لمدة محددة حددت المدّة بصورة واضحة، وإذا كان لسفرة حددت المدينة أو المرفأ البحري الذي تنتهي عنده السفرة، وفي أي مرحلة من مراحل تفريغ السفينة أو تحميلها في هذا المرفأ ينتهي العقد.`,
      recommendations: `تأكّد مما يلي:
      إلحاق جميع عقود العمّال بسجلّات السفينة، وتكون هذه العقود مُحرّرة بصيغة واضحة، ويُنصّ في العقد على ما إذا كان معقودًا لمدّة محدّدة أو لسفرة، وإذا كان معقودًا لمدّة محددة حُدّدت المدّة بصورة واضحة، وإذا كان لسفرة حُدّدت المدينة أو المرفأ البحري الذي تنتهي عنده السفرة، وفي أيّ مرحلة من مراحل تفريغ السفينة أو تحميلها في هذا المرفأ ينتهي العقد.
      
      ويُشترط فيمن يعمل بحّارًا:
      1. أن يكون قد أتمّ من العمر ثماني عشرة سنة.
      2. أن يكون حاصلًا على شهادة تتيح له العمل في الخدمة البحرية.
      3. أن يكون لائقًا طبيًّا.`,
      parentId: 16,
    },
    {
      id: 70,
      name: "المادة 171 من نظام العمل",
      description: `يجب أن ينصّ في عقد العمل البحري على تاريخ إبرامه ومكانه، واسم المجهز، واسم البحار ولقبه وسنّه وجنسيّته وموطنه، ونوع العمل المكلّف به وكيفية أدائه، والشهادة التي تتيح له العمل في الملاحة البحرية، والتذكرة الشخصية البحرية، ومقدار الأجر، ومدّة العقد، أمّا إذا كان العقد لسفرة واحدة فيجب تحديد المدينة أو المرفأ البحري الذي تنتهي عنده الرحلة، وفي أي مرحلة من مراحل تفريغ السفينة أو تحميلها في هذا المرفأ ينتهي العمل، وغير ذلك من تفاصيل العقد. ويكون العقد من ثلاث نسخ نسخة لمجهز السفينة ونسخة للرّبان للاحتفاظ بها على ظهر السفينة ونسخة للبحّار.`,
      recommendations: `تأكد من النصّ في عقد العمل البحري على ما يلي:
      • تاريخ إبرامه ومكانه.
      • اسم المجهز.
      • اسم البحّار ولقبه وسنّه وجنسيّته وموطنه.
      • نوع العمل المكلّف به وكيفيّة أدائه.
      • الشهادة التي تتيح له العمل في الملاحة البحرية.
      • التذكرة الشخصية البحرية.
      • مقدار الأجر.
      • مدّة العقد، أما إذا كان العقد لسفرة واحدة فيجب تحديد المدينة أو المرفأ البحري الذي تنتهي عنده الرحلة، وغير ذلك من تفاصيل العقد.
      وتأكد أن العقد من 3 نسخ، نسخة لمجهز السفينة ونسخة للربان ونسخة للبحار`,
      parentId: 16,
    },
    {
      id: 71,
      name: "المادة 172 من نظام العمل",
      description: `يجب أن تعلن في السفينة وفي القسم المخصص للعاملين فيها قواعد العمل على ظهرها وشروطه، ويجب أن تتضمّن القواعد والشروط ما يأتي: 
      1. التزامات البحّارة وواجباتهم، وقواعد تنظيم العمل على ظهر السفينة، والجداول الزمنية للخدمة، وساعات العمل اليومية.
      2. واجبات مجهز السفينة تجاه البحّارة من حيث الأجور الثابتة والمكافآت وغير ذلك من أنواع الأجر.
      3. كيفية تعليق دفع الأجور أو الحسم منها، وكيفية دفع السّلف على الأجور.
      4. مكان تصفية الأجور وزمانها، وحسابها النهائي.
      5. قواعد تقديم الغذاء والمبيت وأصولها على ظهر السفينة.
      6. علاج أمراض البحّارة وإصاباتهم.
      7. سلوك البحّارة، وشروط ترحيلهم إلى بلدهم.
      8. إجازات البحّارة السنوية المدفوعة الأجر.
      9. مكافأة نهاية الخدمة، وغير ذلك من التعويضات التي ستُدفع بمناسبة إنهاء عقد العمل أو انتهائه.`,
      recommendations: `قم بالتأكّد من وجود ما يثبت من أنّ المنشأة قامت بالإعلان بالسفينة وفي القسم المخصص للعاملين فيها قواعد وشروط العمل على ظهرها، ويجب أن تتضمّن هذه القواعد والشروط ما يأتي:
      • التزامات البحّارة وواجباتهم، وقواعد تنظيم العمل على ظهر السفينة، والجداول الزمنية للخدمة، وساعات العمل اليومية.
      • واجبات مجهز السفينة تجاه البحّارة، من حيث الأجور الثابتة والمكافآت، وغير ذلك من أنواع الأجر.
      • كيفيّة تعليق دفع الأجور أو الحسم منها، وكيفيّة دفع السّلف على الأجور.
      • مكان تصفية الأجور وزمانها، وحسابها النهائي.
      • قواعد تقديم الغذاء والمبيت وأصولها على ظهر السفينة. ويكون غذاء البحّارة ونومهم على نفقة مجهز السفينة
      • علاج أمراض البحّارة وإصاباتهم.
      • سلوك البحّارة وشروط ترحيلهم إلى بلدهم.
      • إجازات البحّارة السنوية المدفوعة الأجر.
      • مكافأة نهاية الخدمة وغير ذلك من التعويضات التي ستُدفع بمناسبة إنهاء عقد العمل أو انتهائه`,
      parentId: 16,
    },
    {
      id: 72,
      name: "المادة 179 من نظام العمل",
      description: `لا تزيد ساعات العمل على ظهر السفينة أثناء وجودها في عرض البحر على أربع عشرة ساعة في مدّة أربعٍ وعشرين ساعة، ولا على اثنتين وسبعين ساعة في مدّة سبعة أيام.`,
      recommendations: `قم بالتأكّد من التالي:
      عدم زيادة ساعات العمل على ظهر السفينة أثناء وجودها في عرض البحر على أربع عشرة ساعة في مدّة أربع وعشرين ساعة، ولا على اثنتين وسبعين ساعة في مدّة سبعة أيام.`,
      parentId: 16,
    },
    {
      id: 73,
      name: "المادة 186 من نظام العمل",
      description: `لا يجوز تشغيل أيّ شخص في المنجم أو المحجر لم يتمّ الثامنة عشرة من العمر، ولا يجوز تشغيل المرأة أيًّا كان سنّها في أيّ منجم أو محجر.`,
      recommendations: `تأكّد من التالي:
      – عدم وجود أيّ شخص في المنجم أو المحجر لم يتمّ الثامنة عشرة من العمر.
      – عدم وجود أيّ امرأة أيًّا كان سنّها في أيّ منجم أو محجر.`,
      parentId: 17,
    },
    {
      id: 74,
      name: "المادة 187 من نظام العمل",
      description: `لا يجوز السماح لأيّ شخص بالعمل في العمليات التي يسري عليها هذا الباب (العمل في المناجم والمحاجر) إلّا بعد إجراء فحص طبي كامل عليه، وثبوت لياقته الصحيّة للعمل المطلوب، ويجب إعادة هذا الفحص دوريًّا ولا يجوز تحميل العامل أيّ نفقة مقابل الفحوص الطبيّة اللازمة.`,
      recommendations: `تأكّد من التالي:
      قامت المنشأة بالتأكّد من ثبوت لياقة العامل الصحيّة للعمل المطلوب وذلك بعد إجراء فحص طبي كامل، ويجب إعادة هذا الفحص دوريًّا ولا يجوز تحميل العامل أيّ نفقة مقابل الفحوص الطبيّة اللازمة.`,
      parentId: 17,
    },
    {
      id: 75,
      name: "المادة 188 من نظام العمل",
      description: `لا تزيد ساعات العمل الفعليّة التي يمضيها العامل تحت سطح الأرض على سبع ساعاتٍ في اليوم، ولا يجوز إبقاء العامل في مكان العمل سواءً فوق سطح الأرض أو في باطنها مدّة تزيد على عشر ساعاتٍ في اليوم. وإذا كان العمل في باطن الأرض فتشمل هذه المدّة الوقت الذي يستغرقه العامل للوصول من سطح الأرض والوقت الذي يستغرقه للعودة من باطن الأرض إلى سطحها.`,
      recommendations: `تأكّد من التالي:
      عدم زيادة ساعات العمل الفعليّة التي يُمضيها العامل تحت سطح الأرض على سبع ساعاتٍ في اليوم، ولا يجوز إبقاء العامل في مكان العمل سواءً فوق سطح الأرض أو في باطنها مدّة تزيد على عشر ساعاتٍ في اليوم. وإذا كان العمل في باطن الأرض فتشمل هذه المدّة الوقت الذي يستغرقه العامل للوصول من سطح الأرض والوقت الذي يستغرقه للعودة من باطن الأرض إلى سطحها.`,
      parentId: 17,
    },
    {
      id: 76,
      name: "المادة 190 من نظام العمل",
      description: `على صاحب العمل أن يُعدّ سجلًّا خاصًّا لقيد العمّال وحصرهم قبل دخولهم إلى أماكن العمل وعند خروجهم منها.`,
      recommendations: `تأكّد من التالي:
      - قامت المنشأة بإعداد سِجلّ خاص لقيد العمّال وحصرهم قبل دخولهم إلى أماكن العمل وعند خروجهم منها.
      - يُحظر دخول أماكن العمل وملحقاتها على غير العاملين فيها وعلى غير المكلّفين بالتفتيش على المنجم أو المحجر والأشخاص الذين يحملون إذنًا خاصًّا من الجهة المختصّة.`,
      parentId: 17,
    },
    {
      id: 77,
      name: "المادة 192 من نظام العمل",
      description: `على صاحب العمل إنشاء نقطة إنقاذ قريبة من مكان العمل مجهزة بأدوات الإنقاذ والإسعافات الضرورية، وأن يكون بهذه النقطة وسيلة اتصال مناسبة بحيث تَصلُح للاستعانة بها في الحال، وعليه تعيين عامل فنّي مدرّب للإشراف على عمليات الإنقاذ والإسعافات الأوّلية.`,
      recommendations: `تأكّد من التالي:
      - إنشاء نقطة إنقاذ قريبة من مكان العمل مجهزة بأدوات الإنقاذ والإسعافات الضروريّة، وأن يكون بهذه النقطة وسيلة اتصال مناسبة بحيث تصلح للاستعانة بها في الحال، وتعيين عامل فنّي مدرّب للإشراف على عمليات الإنقاذ والإسعافات الأوّلية.
      - مع عدم الإخلال بحكم المادّة الثانية والأربعين بعد المائة من نظام العمل، على صاحب العمل أن يعدّ في كلّ منجم أو محجر يشتغل فيه خمسون عاملًا على الأقل مكانًا مناسبًا يحتوي على غرفة مجهزة بوسائل الإنقاذ والإسعافات الأوّلية، وأخرى للتمريض، فضلًا عن غرفة أو أكثر لتغيير الملابس. أما في المناجم والمحاجر التي يقلّ عدد العمّال في كلّ منها عن خمسين عاملًا وتقع في دائرة قطرها عشرون كيلومترًا فيجوز لصاحب العمل أن يشترك في إنشاء مكان للإنقاذ والإسعاف في مكان وسط، أو ينشئ مكانًا للإنقاذ والإسعاف مستقلًّا.`,
      parentId: 17,
    },
];
