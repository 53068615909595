export const STATUSES = {
  COMPLETED: {label: "تم إكمال التقييم", icon: "img/complete-icon.svg"},
  IN_PROGRESS: {label: "بانتظار التقييم", icon: "img/waiting-icon.svg"},
};

export const getEstablishmentStatus = (status) => {
  switch (status) {
    case "Empty":
      return STATUSES.IN_PROGRESS;
    case "Partially Filled":
      return STATUSES.IN_PROGRESS;
    case "Completed":
      return STATUSES.COMPLETED;
    case "UnderGracePeriod":
      return STATUSES.COMPLETED;
    case "UnderInspection":
      return STATUSES.COMPLETED;
    default:
      return STATUSES.IN_PROGRESS;
  }
};
