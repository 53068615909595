import {
  loadEstablishmentProfile,
  loadEstablishments,
  confirmAgreement,
} from "./establishmentProfileActions";
import {
  loadEstablishmentAciveAssessment,
  loadEstablishmentCurrentBasicAssessments,
} from "./establishmentAssessmentActions";
import { loggedIn, logOut, logInSuccess, getUserInfo } from "./authActions";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "./ajaxStatusActions";
import request from "../common/request";

export function loadAll() {
  return function (dispatch) {
    dispatch(loadEstablishments());
    dispatch(getUserInfo());
  };
}
export function checkLoggedIn() {
  return function (dispatch) {
    return dispatch(loggedIn());
  };
}
export function chooseEstablishment(establishment) {
  return function (dispatch) {
    return dispatch(loadEstablishmentProfile(establishment));
  };
}
export function loadDurations(establishmentId) {
  return function (dispatch) {
    return dispatch(loadEstablishmentAciveAssessment(establishmentId));
  };
}

export function createReopenAssessment(
  establishmentId,
  PeriodId,
  assessmentId,
  assessmentVersion,
  periodAssessmentID,
  params = {}
) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request(
      `/v1/establishments/${establishmentId}/unifiedAssessmentEstablishments/create-reopen-assessment`,
      "POST",
      {
        period_id: PeriodId,
        assessment_id: assessmentId,
        assessment_version: assessmentVersion,
        period_assessment_id: periodAssessmentID,
      }
    )
      .then(() => {
        dispatch(loadEstablishmentAciveAssessment(establishmentId, params));
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}
export function createInspectionRequest(
  establishmentId,
  PeriodId,
  assessmentId,
  assessmentVersion
) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request(
      `/v1/establishments/${establishmentId}/inspectionAssessmentEstablishments/create`,
      "POST",
      {
        period_id: PeriodId,
        assessment_id: assessmentId,
        assessment_version: assessmentVersion,
      }
    )
      .then(() => {
        dispatch(loadEstablishmentCurrentBasicAssessments(establishmentId));
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}
export function cancelInspectionRequest(establishmentId, inspectionId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request(
      `/v1/establishments/${establishmentId}/inspectionAssessmentEstablishments/cancel`,
      "POST",
      {
        inspection_id: inspectionId,
      }
    )
      .then(() => {
        dispatch(loadEstablishmentCurrentBasicAssessments(establishmentId));
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}
export function confirmDisclaimer() {
  return function (dispatch) {
    dispatch(confirmAgreement());
  };
}

export function logIn(token) {
  return function (dispatch) {
    dispatch(logInSuccess(token));
  };
}

export function getUserProfile() {
  return function (dispatch) {
    return dispatch(getUserInfo()).then((res) => {
      return res;
    });
  };
}

export const checkOut = logOut();
