export const termsAndConditionsData = {
  title: "الشروط و الأحكام",
  description:[
    "تُعد منصة التقييم الذاتي من الخدمات الرئيسية المقدمة من وزارة الموارد البشرية والتنمية الاجتماعية، وتهدف منصة التقييم الذاتي إلى خلق بيئة عمل تحقق أعلى درجات الامتثال، من خلال توفيـر أداة إلكترونية لمساعدة المنشآت في التعرف على مدى التـزامها بمعاييـر وأنظمة العمل ورفع نسبة امتثالها. وتُدار المنصة بشكل مباشر من قبل شركة تكامل لخدمات الأعمال (\"مزود الخدمة\") .",
    "شروط الاستخدام هذه (مع الوثائق المشار إليها في هذه الوثيقة) تتضمن الإرشادات اللازمة لكيفية استخدام والاستفادة من هذه المنصة (والذي يشار إليه فيما بعد بـ\"المنصة\")، سواء كمتصفح أو كمستخدم. يتضمن إرشادات استخدام هذه المنصة على إجراءات الدخول والتصفح أو التسجيل لاستخدام المنصة.",
    { acknowledgement:"يرجى قراءة شروط الاستخدام هذه بعناية قبل استخدام المنصة، حيث باستخدامك للمنصة، فإنك تؤكد قبولك لشروط الاستخدام هذه وموافقتك على الامتثال لما تتضمنه من أحكام. إذا كنت لا تقبل هذه الشروط فيجب عليك عدم استخدام المنصة."}
  ],
  contactUs:
    "يمكن للمستخدمين الاتصال بخدمة العملاء بشأن أي مسألة تتعلق بالمنصة أو الخدمات، بما في ذلك على سبيل المثال لا الحصر الاستفسارات العامة، الاقتراحات، الشكاوى، أو النزاعات، من خلال التواصل مع مركز خدمة العملاء على الرقم (920033411) أو عبر نموذج التواصل في المنصة. لا يُعتبر الإشعار قد تم استلامه من قبل خدمة العملاء إلا إذا تلقى المستخدم تأكيدًا، سواء كان إلكترونيًا أو كتابيًا، يؤكد استلام الإشعار. نحن نرحب بجميع الأسئلة والتعليقات والطلبات المتعلقة بشروط وأحكام استخدام المنصة.",
  sections: [
    {
      subsections: [
        {
          title: "إقرارات المستخدم",
          paragraph: [
            "يقر المستخدم بالبنود التالية:",
            "- المحافظة على سرية المعلومات التي سيتم تزويده بها بعد إنشاء حساب المستخدم وعدم إفصاحها لطرف ثالث، وذلك كجزء من إجراءاتنا الخاصة بأمن وخصوصية الدخول على سبيل المثال لا الحصر: اسم المستخدم وكلمة المرور وأي معلومات أخرى.",
            "- تحمل مسؤولية استخدامك الخاص لهذه المنصة، وأي محتوى تقوم بتحميله، كما توافق على أن استخدامك لهذه المنصة يكون خاضعًا لشروط الاستخدام هذه وكافة القوانين والأنظمة المعمول بها.",
            "- عدم تجميع أو تنزيل أجزاء كثيرة من محتوى هذه المنصة، على سبيل المثال لا الحصر، قائمة بالمستخدمين، أو المصادر الإلكترونية.",
            "- مسؤوليته التامة عن المعلومات والمدخلات التي يقدمها عند استخدام المنصة.",
            "- مسؤوليته بالإلمام والإدراك بهذه الشروط والإقرارات بالضافة إلى الشروط والأحكام الأخرى المرعية، والامتثال لها.",
            "- مسؤوليته التامة عن جميع المعلومات التي يقدمها، أو التي يتم تحميلها في المنصة وأنها لا تتعارض مع المذكور في العقود التي يكون هو طرف فيها أو تتعلق به وذلك يتضمن بلا حصر المدخلات الرقمية أو الوثائق التي يتم تحميلها والتي يتوجب على المستخدم التحقق من دقتها وتحديثها، وبهذا فإن المستخدم يقر بأن جميع المعلومات المقدمة من طرفه هي معلومات دقيقة وحديثة. وأنه يوافق على المحافظة على هذه المعلومات من حيث دقتها وتحديثها. وعند وجود إخلال بهذه الشروط والأحكام أو صدور وثائق احتيالية أو مزورة يخضع المستخدم للوبات والإجراءات القانونية اللازمة وفق أحكام البند (الإنهاء والإيقاف) أدناه والأنظمة المرعية الأخرى.",
            "- تحمله مسؤولية ضمان امتثال جميع مستخدمين المنصة عبر اتصال الإنترنت الخاص به بشروط الاستخدام والأحكام الأخرى.",
            "- مسؤوليته التامة عن حسابه الخاص في المنصة ويقر ويضمن بأنه المستخدم الوحيد للحساب وعلى ذلك فجميع أنشطة الحساب تعتبر ملزمة قانونيا للمستخدم بصفته مالك الحساب.",

            "- أحقية مزود الخدمة باستخدام البيانات المسجلة من قبل المستخدم لعمل الاستبيانات والتحليلات والدراسات والإحصائيات والتقارير، ومشاركتها وتبادلها مع أطراف أخرى بما لا يتعارض مع الأنظمة ذات العلاقة. كما يتم الاستفادة من البيانات لتطوير المنصة وتحسينه بشكل مستمر لتوفير تجربة استخدام أكثر سهولة.",
            "- أن لمزود الخدمة الحق في القيام بالتحقق من المعلومات التي قدمت من قِبل المستخدم إلى المنصة، وأن لمزود الخدمة الحق في رفض أي طلبات غير مكتملة أو غير دقيقة أو تحتوي على بيانات احتيالية أو مزورة، كما أنه يحق لمزود الخدمة تأخير إتمام العملية المطلوبة لمزيد من تقييم سلامة الطلبات في حال وجود صعوبات تقنية، بالإضافة إلى أحقية مزود الخدمة في قبول/ رفض/تعديل/ إلغاء أي طلبات.",
            "- أنه في حالة حدوث أي أخطاء عند استخدام أي من الخدمات المقدمة عبر المنصة، فإن مزود الخدمة ووزارة الموارد البشرية والتنمية الاجتماعية لا يتحملان مسؤولية أي خسائر مالية أو قانونية مترتبة، وأنه من مسؤولية المستخدم الإبلاغ عن الأخطاء من خلال فتح تذكرة دعم إلكترونية عبر مركز التواصل المتضمن في بند (التواصل معنا) من شروط الاستخدام هذه، أو أي طريقة تواصل أخرى توفرها المنصة بما يضمن تحقق التواصل بين المستخدم والمنصة، وسوف يقوم مزود الخدمة أو من ينوبه باتخاذ الإجراء المناسب وإشعار المستخدم به.",
            "- بأنه فوّض مزود الخدمة أو من ينوب عنه بالتحقق من أي معلومات مقدمة بهدف الموافقة على طلب المستخدم أو رفضه، أو غيرها من إجراءات يتم فيها الاستفادة من البيانات المستخدمة لدى أي من الجهات العامة والخاصة.",
            "- أن جميع الوثائق ومن ذلك بلا حصر الشهادات والرخص والسجلات قانونية صحيحة وسليمة وسارية المفعول، وأنه يتحمل المسؤولية إذا تبين عدم صحتها أو سلامتها وسيكون عرضة لإيقاع ما يقضي به النظام الجزائي لجرائم التزوير والأنظمة الأخرى من جزاءات وعقوبات وأي تبعات قانونية تترتب على عدم صحتها وسلامتها، بما في ذلك التعويض عن الأضرار الناشئة جرّاء عدم صحة المستندات أو المعلومات المقدمة.",
            "- بعدم استغلال خدمات المنصة لأغراض غير تلك التي صممت لها وأصدرت من أجلها.",
            "- الامتثال لنظام العمل وأي أنظمة ولوائح وقرارات أخرى ذات صلة.",
          ],
        },
        {
          title: "التحقق",
          paragraph: [
            "تخضع المعلومات/البيانات التي يتم الحصول عليها من قبلكم لشروط استخدام هذه المنصة، وستستخدم للتحقق من هويتك من قبل الجهات العامة.",
            "<br/>",
            "لن تُقبل الوثائق المزورة مطلقًا ويتعرض المستخدم الذي قام بتقديم وثيقة مزورة للمساءلة من قبل الجهات المختصة."
             ],
         },
        {
          title: "الدخول الى هذه المنصة",
          paragraph:"صُممت هذه المنصة للاستخدام داخل المملكة العربية السعودية ولا نضمن توفر محتوى هذه المنصة في أماكن أخرى. ويحق لنا الحد من توفير هذه المنصة وتقنين خدماتها فيما يخص أي شخص في أي وقت كان. كما يتحمل المستخدم كامل مسؤولية دخوله لهذه المنصة من خارج المملكة."
        },
        {
          title: "حقوق الملكية الفكرية",
          paragraph: [
            "- تملك شركة تكامل لخدمات الأعمال كافة حقوق الملكية الفكرية لهذه المنصة، وجميع المواد المنشورة فيه تخضع لقوانين حماية حقوق المؤلف ونظام العلامات التجارية ونظام براءات الاختراع لدول مجلس التعاون لدول الخليج العربية والمعاهدات الدولية وجميع الأنظمة الأخرى ذات العلاقة، وجميع هذه الحقوق محفوظة.",
            "- يحق لك طباعة نسخة واحدة وتنزيل أجزاء/اقتباسات من محتوى هذه المنصة لاستخدامك الشخصي ومشاركة ذلك المحتوى مع اشخاص آخرون في منشأتك بعد الحصول على موافقة كتابية من شركة تكامل لخدمات الأعمال.",
            "- لا يحق لك تعديل النسخ الورقية أو الرقمية لأي مواد قمت بطباعتها أو تنزيلها بأي طريقة، ولا يجوز أن تستخدم أي رسوم توضحية أو صور فوتوغرافية أو سلسلة من المقاطع المرئية أو الصوتية أو أي رسومات بيانية أخرى.",
            "- ليس لك حق استخدام أي جزء من محتوى هذه المنصة لأغراض تجارية دون الحصول على إذن للقيام بذلك منا أو من ممثلينا المفوضين.",
            "- في حال القيام بطباعة أو نسخ أو تنزيل أي جزء من هذه المنصة إخلالًا لشروط الاستخدام فسيتم إيقاف حقك في استخدام هذه المنصة فورًا، وستُلزم بإعادة أو إتلاف المواد التي قمت بنسخها.",
          ],
        },
        {
          title: "عدم الاعتماد على المعلومات",
          paragraph:
            "بالرغم من الجهود المبذولة لتحديث معلومات هذه المنصة، إلا أننا لا نتعهد أو نضمن بشكل صريح أو ضمني دقة وحداثة وكمال محتوى هذه المنصة ونحن لسنا ملزمين بتحديثها. كما يجب عليك الحصول على الاستشارة الاحترافية أو المتخصصة قبل الإقدام على إتخاذ أي قرار أو التراجع عن قرار بناءً على محتوى هذه المنصة. نحن لا نضمن بأن محتوى هذه المنصة سيلبي احتياجاتك أو توقعاتك ولا نضمن دقة أو كمال أو جودة هذه المنصة أو محتواه أو إستمرارية توفر بعضًا من أجزاءه تحت ظروف خارجة عن إرادتنا.",
        },
        {
          title: "حدود المسؤولية",
          paragraph: [
            "إلى الحد الأقصى المسموح به نظامًا، شركة تكامل لخدمات الأعمال وموظفيها ووكلائها والشركات التابعة لها غير مسؤولين على الإطلاق عن أي خسارة أو ضرر سواءً كان مباشر أم غير مباشر أو عرضيًا أو خاصًا، بما في ذلك على سبيل المثال لا الحصر:",
            "- الخسائر أو الأضرار الناتجة عن خسارة الأرباح و ثقة الجمهور أو الأعمال والفرص.",
            "- أي خسائر أخرى غير مادية ناشئة عن أو فيما يتعلق باستخدامك للخدمة أو شروط الاستخدام هذه.",
            "- أي انتهاك أو مخالفة لأي من الأنظمة أو اللوائح المعمول بها في المملكة العربية السعودية.",
            "- في حال إساءة استخدام المستخدم للخدمة فإن لشركة تكامل لخدمات الأعمال اتخاذ كافة الاجراءات القانونية بحقه.",
            "- لن نتحمل أي خسارة أو ضرر ناجم عن الفيروسات أو هجمات الحرمان من الخدمة أو المواد الأخرى الضارة التي قد تؤثر على جهازك الحاسوب أو برامجه تقنيا أو البيانات والمواد الأخرى التي تحصل عليها أثناء استخدامك لهذه المنصة أو نشرك لأي محتوى من خلاله، أو على المواقع الإلكترونية المرتبطة به.",
            "- لا تتحمل شركة تكامل لخدمات الأعمال أي مسؤولية عن توقف الخدمة لأي سبب من الأسباب و لا نضمن دوام استمرارية وإتاحة هذه المنصة أو أي جزء من محتواها حيث يحق لنا تعليق أو حجب أو إيقاف أو تغيير كل أو جزء من هذه المنصة بدون إشعار مسبق و نُخلي مسؤوليتنا عن عدم إتاحة هذه المنصة لأي سبب وفي أي وقت.",
            "- يقر المستخدم بكامل مسؤوليته (أن شركة تكامل لخدمات الأعمال لا تتحمل أي مسؤولية تجاه المستخدم أو تجاه أي طرف ثالث) عن أي إخلال بشروط الاستخدام هذه، أو أي قانون معمول به أو تنظيم و يتحمل المستخدم أي تبعات أخرى (بما في ذلك أي ضرر أو خسارة تتعرض لها شركة تكامل لخدمات الأعمال أو أي طرف ثالث) جراء أي إخلال من هذا القبيل ويحق لشركة تكامل لخدمات الأعمال اتخاذ الاجراءات اللازمة والرجوع على المستخدم في حال تعرضت لأي ضرر سواء كان مباشر أو غير مباشر.",
          ],
        },
        {
          title: "الفيروسات",
          paragraph: "لا نضمن أن تكون هذه المنصة آمنة أو خالية من الأخطاء أو الفيروسات. تتحمل وحدك مسؤولية ضبط المعلومات التكنولوجية الخاصة بك، وبرامج وأنظمة الحاسوب من أجل دخول هذه المنصة، واستخدام برامج الحماية من الفيروسات الخاصة بك. يُمنع إساءة استخدام هذه المنصة عمدًا بإدخال جميع أنواع الفيروسات بلا استثناء، كما يُمنع محاولة الدخول غير المصرح به والخادم الذي يُخزن هذه المنصة عليه أو أي خادم آخر أوحاسوب أو قاعدة بيانات تتعلق بهذه المنصة. يُمنع مهاجمة هذه المنصة بهجمات الحرمان من الخدمة أو نشر هذه الخدمات. وسنقوم بالتبليغ عن أي محاولة لخرق هذه الشروط للسلطات المختصة والتعاون معها والكشف لها عن هويتك. في حال حدوث مثل هذه الإنتهاكات، فسيتم سلب حقك في استخدام هذه المنصة في الحال واتخاذ كافة الاجراءات القانونية بحقك."
        },
        {
          title: "الربط بهذه المنصة",
          paragraph: [
            "- لك حق الربط بصفحتنا الرئيسية، مع مراعاة القيام بذلك بطريقة عادلة وقانونية لا تضر بسمعة هذه المنصة أو تُسيء استخدامه .",
            "- يجب أن لا يُفسر هذا الربط كشكل من أشكال الشراكة أو الموافقة بطريقة توحي بأي شكل من أشكال مشاركتنا أو موافقتنا صراحةً أم ضمنًا.",
            "- يُمنع ربط الاتصال بين هذه المنصة وبين أي موقع ليس ملكًا لكم.",
            "- يجب عدم نشر أي من محتوى هذه المنصة على أي موقع آخر، كما لا يجوز لكم إنشاء رابط لأي جزء من هذه المنصة باستثناء الصفحة الرئيسية.",
            "- لنا حق سحب ترخيص الربط الخاص بكم دون إشعار مسبق.",
            "- يراعى اشتمال المنصة الذي يتم الربط مابينه وبين هذه المنصة على جميع معايير المحتوى المنصوص عليها في هذه الشروط.",
            "- عند الرغبة بإستخدام هذه المنصة بخلاف المذكور أعلاه، يرجى التواصل معنا.",
          ]
        },
        {
          title: "سياسة الدفع والمعاملات المالية",
          paragraph: [
            "- تكون المعاملات المالية المعتمدة في الموقع من خلال وسائل وطرق الدفع الرسمية التالية:",
            "- (سداد، مدى، فيزا، مستركارد، امريكان اكسبرس او اي طرق دفع اخرى يتم اعتمادها من قبل المنصة، ويتحمل المستخدم مسؤولية أي عمليات مالية تتم خارج المنصة، حيث لا تتحمل المنصة أي مسؤولية حيال عمليات الدفع الخارجية.",
            "- المبالغ المدفوعة غير مستردة بعد إتمام عملية الدفع.",
            "المقابل المالي:",
            "نحتفظ بالحق في فرض أي مقابل مالي على أي خدمة يتم عرضها على موقعنا.",
            "نحتفظ بالحق في فرض مقابل مالي على أي مستخدم مسجل في الموقع.",
            "نحتفظ بالحق بالتعديل على قيمة المقابل المالي، ولذلك لابد من التأكد من قيمة المقابل المالي قبل القيام بأي عملية سداد.",
          ]
        },
        {
          title: "روابط ومصادر الأطراف الأخرى في هذه المنصة",
          paragraph: "قد تحتوي هذه المنصة على روابط لمواقع أخرى ومصادر تقوم بتزويدها أطراف أخرى، يقصر استخدامك لهذه الروابط للاطلاع فقط، وليس لدينا صلاحية التحكم في محتوى هذه المواقع أوالمصادر."
        },
        {
          title: "التعويض",
          paragraph: "باستخدامك للمنصة، فأنت توافق على تعويض والدفاع عن شركة تكامل لخدمات الأعمال و الشركات التابعة لها، والمسؤولين وأعضاء مجلس الإدارة والوكلاء والموظفين من وضد إدعائات أو إجراءات أو مطالبات الأطراف الأخرى الناتجة عن أو المرتبطة باستخدامك لهذه المنصة بأي شكل من الأشكال، شاملًا بذلك أي مسؤوليات أو مصاريف ناتجة عن كل أو بعض الإدعائات والخسائر والأضرار (الفعلية والتبعية) والدعاوي القضائية والأحكام وتكاليف التقاضي وأتعاب المحامين. وفي هذه الحالة، سنزودك بإشعار كتابي بهذه المطالبة أو القضية أو الإجراء."
        },
        {
          title: "الإنهاء والإيقاف",
          paragraph: [
            "سيُحدد وفقاً لتقديرنا المطلق، ما إذا كان هناك إخلال لهذه الشروط أثناء استخدامكم لهذه المنصة.",
            "عند الإخلال بهذه الشروط، سيتم إتخاذ الإجراء الذي نراه مناسباً، وقد يؤدي عدم الامتثال لهذه الشروط أو القيام بأعمال احتيالية من قبل المستخدم إلى اتخاذ جميع أو أي من الإجراءات التالية، والتي تشمل ولا تنحصر على:",
            "- توجيه انذار كتابي للمستخدم.",
            "- الإيقاف الفوري أو المؤقت أو الدائم لحقك في استخدام هذه المنصة.",
            "- الحذف الفوري أو المؤقت أو الدائم لأي من المشاركات أو المواد تحملها التي تضيفها لهذه المنصة.",
            "- تنفيذ الإجراءات القانونية بحقك لتحمل جميع تكاليف التعويض (بما في ذلك، على سبيل المثال لا الحصر التكاليف الإدارية والقانونية) الناتجة من الإخلال.",
            "- إتخاذ إجراءات قانونية إضافية بحقك.",
            "- كشف المعلومات للسلطات القضائية المسؤولة عند الضرورة.",
            "نُخلي مسؤوليتنا من الإجراءات المتبعة نتيجة الإخلال بشروط الاستخدام. ولا تقتصر تلك الإجراءات على ما جاء ذكره ضمن الشروط، بل يحق لنا اتخاذ أي إجراء آخر نراه مناسبًا وفقاً للأنظمة المعمول بها في المملكة العربية السعودية.",
          ]
        },
        {
          title: "التغييرات في الشروط",
          paragraph: "هذه الشروط والأحكام قابلة للتغيير في أي وقت من خلال تعديل هذه الصفحة لذا يرجى مراجعة هذه الصفحة من وقت لآخر فالشروط الجديدة ملزمة من تاريخ نشرها على هذه المنصة."
        },
        {
          title: "الشروط الإضافية",
          paragraph: [
            "تشمل شروط الاستخدام هذه على الشروط الإضافية التالية، التي تنطبق كذلك على استخدامك لهذه المنصة:",
            "شروط الاستخدام (شروط استخدام لـ (ims.hrsd.gov.sa) التي تنص على الشروط التي نقوم بموجبها بمعالجة البيانات الشخصية التي تقدمونها لنا. وباستخدامكم هذه المنصة، فإنكم توافقون على هذه المعالجة وتتعهدون بأن جميع البيانات التي تقدمونها هي بيانات صحيحة ودقيقة."
            ]
        },
        {
          title: "القانون المطبق",
          paragraph: "تخضع شروط الاستخدام هذه  للقوانين والأنظمة المعمول بها في المملكة العربية السعودية، وفي حال اعتبر أي بند باطلاً أو غير صالح أو غير قابل للتنفيذ بموجب القوانين والأنظمة في المملكة العربية السعودية، فإن ذلك لن يؤثر على صحة وإلزامية بقية الأحكام والبنود، ولمحاكم المملكة العربية السعودية الاختصاص الحصري في هذا الخصوص."
        },
        {
          title: "اللغة المعتمدة",
          paragraph: "إن اللغة العربية هي اللغة الأساسية لاستخدام الموقع والاستفادة من كل المواد المنشورة عليه، ويهدف ترجمة أي من هذه المواد لتقديم خدمة مضافة، وعليه فلا يتم الاستناد إلى الترجمة المتوفرة في تفسير أي خلاف حول ما يتضمنه الموقع من محتوى بل يتم الاستناد إلى اللغة العربية."
        },
      ],
    },
  ],
};
