import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import PropTypes from "prop-types";
import {
  FAST_TRACK_BRANCHES,
  UNIFIED_ASSESSMENT,
  STATUSES,
} from "../../../../common/constants/paymentStatuses";
import * as unifiedActions from "../../../../actions/unifiedActions";
import * as profileActions from "../../../../actions/establishmentProfileActions";
import * as branchesActions from "../../../../actions/FastTrackForBranchesActions";

import Papper from "../../../../components/Papper/Papper";
import "./payment-service.scss";
import LabeledCheckbox from "../../../../components/LabeledCheckBox/LabeledCheckbox";
import { Text } from "../../../../../../shared-components/my-scope/atoms/text/index";
import Button from "../../../../components/Button/Button";
import { CheckBox } from "../../AssessmentFeature/lib/components/checkbox/checkbox";

export class PaymentService extends React.Component {
  constructor() {
    super();

    this.state = {
      approved: false,
    };

    this.changeConsent = this.changeConsent.bind(this);
    this.pay = this.pay.bind(this);
  }
  componentDidMount() {
    const { loadPaymentServices, establishmentId, isBranchesSpecialTrack } =
      this.props;

    if (establishmentId && isBranchesSpecialTrack) {
      loadPaymentServices(establishmentId);
    }
  }
  changeConsent(checked) {
    this.setState({ approved: checked });
  }

  pay() {
    const {
      periodId,
      assessmentId,
      establishmentId,
      assessmentVersion,
      unifiedService,
      selected,
      payForService,
      history,
      isBranchesSpecialTrack,
      payForBranchedSpecialTrackService,
      services,
      periodAssessmentId,
    } = this.props;

    const service = isBranchesSpecialTrack
      ? services.filter((service) => service.type === FAST_TRACK_BRANCHES)
      : unifiedService;

    if (this.state.approved && !isBranchesSpecialTrack) {
      payForService(establishmentId, {
        payment_service_id: unifiedService.id,
        assessment_id: assessmentId,
        period_id: periodId,
        period_assessment_id: periodAssessmentId,
        assessment_version: Number(assessmentVersion),
        establishment_ids: selected,
      })
        .then((val) => {
          // toastr.success("تم طلب الدفع بنجاح لخدمة التقييم الموحد");
          history.push(
            `/checkout/${val.hyperpay_bill.id}/${UNIFIED_ASSESSMENT}/${val.payment_bill.sadad_number}/${val.payment_bill.payment_resp_bill_number}`
          );
        })
        .catch(() => {
          toastr.error("حدث خطأ ما");
        });
    } else if (this.state.approved && isBranchesSpecialTrack) {
      payForBranchedSpecialTrackService(establishmentId, {
        payment_service_id: service[0].id,
        assessment_id: assessmentId,
        period_id: periodId,
        period_assessment_id: periodAssessmentId,
        assessment_version: Number(assessmentVersion),
        establishment_ids: selected,
      })
        .then((val) => {
          // toastr.success("تم طلب الدفع بنجاح لخدمة المسار المميز للفروع");
          history.push(
            `/checkout/${val.hyperpay_bill.id}/${FAST_TRACK_BRANCHES}/${val.payment_bill.sadad_number}/${val.payment_bill.payment_resp_bill_number}`
          );
        })
        .catch(() => {
          toastr.error("حدث خطأ ما");
        });
    }
  }

  render() {
    const { changePage, unifiedService, services, isBranchesSpecialTrack } =
      this.props;
    const { approved } = this.state;

    const service = isBranchesSpecialTrack
      ? services.filter((service) => service.type === FAST_TRACK_BRANCHES)
      : unifiedService;

    const serviceMap = isBranchesSpecialTrack
      ? service
      : service.unified_assessment_payment_services;
    const serviceMapCondition = isBranchesSpecialTrack
      ? serviceMap?.length !== 0
      : service.unified_assessment_payment_services?.length !== 0;
    const Title = isBranchesSpecialTrack
      ? " ملخص الطلب لخدمة المسار المميز للأفرع:"
      : " ملخص الطلب لخدمة التقيم الموحد:";
    return (
      <section className="unified-assessment">
        <Papper withBorder>
          <Text textType="p3" isBold className="ua-text ua-text--header">
            {Title}
          </Text>

          <div className="ua-service-table assessment-payment-table-big-screen">
            <table className="table-x-axis-scroll">
              <thead>
                <tr>
                  <Text
                    className="payment-first-column"
                    Tag="th"
                    textType="p4"
                    isBold
                  >
                    نوع الخدمة
                  </Text>

                  <Text Tag="th" textType="p4" isBold>
                    المبلغ
                  </Text>

                  <Text Tag="th" textType="p4" isBold>
                    ضريبة القيمة المضافة 15%
                  </Text>

                  <Text Tag="th" textType="p4" isBold>
                    المبلغ الإجمالي
                  </Text>
                </tr>
              </thead>

              <tbody>
                {!isBranchesSpecialTrack && (
                  <tr>
                    <Text
                      className="payment-first-column"
                      Tag="td"
                      textType="p5"
                    >
                      {STATUSES[service.type]}
                    </Text>

                    <Text
                      Tag="td"
                      textType="p5"
                    >{`${service.amount} ريال`}</Text>

                    <Text Tag="td" textType="p5">{`${
                      service.amount * service.vat
                    } ريال`}</Text>

                    <Text Tag="td" textType="p5">{`${
                      service.amount * service.vat + service.amount
                    } ريال سعودي`}</Text>
                  </tr>
                )}
                {serviceMapCondition &&
                  serviceMap.map((item) => (
                    <tr key={isBranchesSpecialTrack ? item.id : item.name}>
                      <Text
                        Tag="td"
                        textType="p5"
                        className="payment-first-column"
                      >
                        {`${STATUSES[item.type]}  ${
                          isBranchesSpecialTrack ? "" : item.name
                        }`}
                      </Text>

                      <Text Tag="td" textType="p5">
                        {`${item.amount} ريال`}
                      </Text>

                      <Text Tag="td" textType="p5">
                        {`${item.amount * item.vat}ريال `}
                      </Text>

                      <Text Tag="td" textType="p5">
                        {`${item.amount * item.vat + item.amount} ريال سعودي`}
                      </Text>
                    </tr>
                  ))}

                {!isBranchesSpecialTrack &&
                  service.unified_assessment_payment_services !== 0 && (
                    <tr>
                      <Text
                        className="payment-first-column"
                        Tag="td"
                        textType="p5"
                        isBold
                      >
                        {`المجموع`}
                      </Text>

                      <td>{` `}</td>
                      <td>{``}</td>

                      <Text
                        Tag="td"
                        textType="p5"
                      >{`${service.total_amount} ريال سعودي`}</Text>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
          <div className="ua-service-table assessment-payment-table-small-screen">
            <table>
              <tbody>
                <Fragment>
                  <tr>
                    <Text Tag="th" textType="p4" isBold>
                      نوع الخدمة
                    </Text>

                    <Text Tag="td" textType="p5">
                      {STATUSES[service.type]}
                    </Text>
                  </tr>
                  <tr>
                    <Text Tag="th" textType="p4" isBold>
                      المبلغ
                    </Text>

                    <Text
                      Tag="td"
                      textType="p5"
                    >{`${service.amount} ريال`}</Text>
                  </tr>
                  <tr>
                    <Text Tag="th" textType="p4" isBold>
                      ضريبة القيمة المضافة 15%
                    </Text>

                    <Text Tag="td" textType="p5">
                      {`${service.amount * service.vat} ريال`}
                    </Text>
                  </tr>
                  <tr className="assessment-payment-table-row">
                    <Text Tag="th" textType="p4" isBold>
                      المبلغ الإجمالي
                    </Text>

                    <Text Tag="td" textType="p5">
                      {`${
                        service.amount * service.vat + service.amount
                      } ريال سعودي`}
                    </Text>
                  </tr>
                </Fragment>
                {serviceMapCondition &&
                  serviceMap.map((item) => (
                    <Fragment key={item.id}>
                      <tr>
                        <Text Tag="th" textType="p4" isBold>
                          نوع الخدمة
                        </Text>

                        <Text Tag="td" textType="p5">
                          {STATUSES[item.type]}
                        </Text>
                      </tr>
                      <tr>
                        <Text Tag="th" textType="p4" isBold>
                          المبلغ
                        </Text>

                        <Text Tag="td" textType="p5">
                          {`${item.amount} ريال`}
                        </Text>
                      </tr>
                      <tr>
                        <Text Tag="th" textType="p4" isBold>
                          ضريبة القيمة المضافة 15%
                        </Text>

                        <Text Tag="td" textType="p5">
                          {`${item.amount * item.vat} ريال`}
                        </Text>
                      </tr>
                      <tr className="assessment-payment-table-row">
                        <Text Tag="th" textType="p4" isBold>
                          المبلغ الإجمالي
                        </Text>

                        <Text Tag="td" textType="p5">
                          {`${item.amount * item.vat + item.amount} ريال سعودي`}
                        </Text>
                      </tr>
                    </Fragment>
                  ))}
                {!isBranchesSpecialTrack &&
                  service.unified_assessment_payment_services !== 0 && (
                    <tr>
                      <Text Tag="th" textType="p4" isBold>
                        المجموع
                      </Text>

                      <Text Tag="td" textType="p5">
                        {`${service.total_amount} ريال سعودي`}
                      </Text>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>

          <div className="ua-consent">
            {/* <div className="sadad-payment-warning">
              <div className="sadad-payment-warning__icon">
                <img src={"img/alert_yellow_Icon.svg"} alt="alert icon" />
              </div>
              <Text Tag="span" textType="p3">
                <Text Tag="div" textType="p3">
                  يرجى سداد فواتيرك من خلال نظام سداد للمدفوعات باستخدام
                  المعلومات التالية:
                </Text>
                <Text Tag="div" textType="p3">
                  اسم الجهة: شركة تكامل لخدمات الأعمال
                </Text>
                رقم المفوتر: 199
              </Text>
            </div> */}
            {/* <LabeledCheckbox
              type="checkbox"
              checked={approved}
              onChange={this.changeConsent}
              label="أقر وأوافق على أن المبالغ المدفوعة للخدمة غير خاضعة لسياسة
              الاسترداد"
              normalFont
              id="payment-agreement-2-checkbox"
            /> */}
            <CheckBox
              label="أقر وأوافق على أن المبالغ المدفوعة للخدمة غير خاضعة لسياسة
 الاسترداد"
              checked={approved}
              onChange={this.changeConsent}
              id="payment-agreement-2-checkbox"
            />
          </div>
        </Papper>
        <footer className="ua-footer">
          <Button
            disabled={!approved}
            onClick={this.pay}
            text="ادفع"
            style={{ marginRight: "1em" }}
            id="payment-2-button"
          />
          <Button
            onClick={() => changePage(1)}
            text="السابق"
            id="payment-previous-button"
          />
        </footer>
      </section>
    );
  }
}

PaymentService.propTypes = {
  changePage: PropTypes.func.isRequired,
  periodId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  establishmentId: PropTypes.string.isRequired,
  assessmentVersion: PropTypes.string.isRequired,
  withoutResult: PropTypes.bool.isRequired,

  unifiedService: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired,
  payForService: PropTypes.func.isRequired,

  history: PropTypes.object.isRequired,
  loadPaymentServices: PropTypes.func.isRequired,
  isBranchesSpecialTrack: PropTypes.bool.isRequired,
  services: PropTypes.array.isRequired,
  payForBranchedSpecialTrackService: PropTypes.func.isRequired,
};

PaymentService.defaultProps = {};

export const mapStateToProps = (state) => ({
  unifiedService: state.unified.service,
  services: state.payment.reopen_assessment_services,
  selected: state.unified.selected,
});

export const mapDispatchToProps = (dispatch) => ({
  payForService: (establishmentId, paymentBill) =>
    dispatch(unifiedActions.payForService(establishmentId, paymentBill)),

  loadPaymentServices: (establishmentId) =>
    dispatch(profileActions.loadPaymentServices(establishmentId)),
  payForBranchedSpecialTrackService: (establishmentId, paymentBill) =>
    dispatch(
      branchesActions.payForBranchedSpecialTrackService(
        establishmentId,
        paymentBill
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentService));
