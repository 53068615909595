import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchEstablishmentSelfAssessmentResult } from "../../actions/establishmentSelfAssessmentResultActions";
import {
  loadPaymentBills,
  submitPayment,
  submitTrainingPayment,
} from "../../actions/establishmentProfileActions";
import EstablishmentScoreList from "./EstablishmentScoreList";
import EstablishmentServiceCard from "./EstablishmentServiceCard";
import EstablishmentBillCard from "./EstablishmentBillCard";

import isEqual from "lodash/isEqual";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
} from "reactstrap";

import "./styles.css";
import {
  FASTTRACK,
  RE_ASSESSMENT,
  ADDITIONAL_ASSESSMENT,
  TRAINING,
  DETAILEDREPORT,
  UNIFIED_ASSESSMENT,
} from "../../common/constants/paymentStatuses";
import toastr from "toastr";

let emptyEstablishmentProfile = {
  establishment_id: "",
  establishment_name: "",
  english_name: "",
  labor_office_id: "",
  sequence_id: "",
  establishment_status_id: "",
  establishment_status: "",
  main_economic_activity_id: "",
  sub_economic_activity_id: "",
  economic_activity_id: "",
  economic_activity_name: "",
  color_id: "",
  nitaqat_color: "",
  size_id: "",
  size_name: "",
  color_code: "",
  saudis: "",
  foreigners: "",
  total_laborer_count: "",
};

const emptyEstablishmentSelfAssessmentResult = {
  standard_categories: [],
  assessment: {
    standard_categories: [],
  },
  standard_categories_scores: [],
  final_result: 0,
  max_penalty: 0,
  min_penalty: 0,
  period_details: {
    name: "",
    start_date: null,
    end_date: null,
  },
  user: {
    id: "",
    name_ar: { String: "" },
    name_en: { String: "" },
    phone_number: { String: "" },
    email: { String: "" },
    id_number: "",
  },
  questions: [],
  violations_count: 0,
  timestamps: {
    assigned_at: {
      String: "",
      Valid: false,
    },
    first_opened_at: {
      String: "",
      Valid: false,
    },
    submitted_at: {
      String: "",
      Valid: false,
    },
  },
};

class EstablishmentAssessmentPaymentPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      establishmentProfile: { ...this.props.establishmentProfile },
      establishmentSelfAssessmentResult: {
        ...emptyEstablishmentSelfAssessmentResult,
      },
      period_id: "",
      assessment_id: "",
      collapsed: true,
      serviceId: "",
      DoYouWantToPay: false,
    };
    this.redirectToSelfAssessmentResult =
      this.redirectToSelfAssessmentResult.bind(this);
    this.payForService = this.payForService.bind(this);
    this.checkOut = this.checkOut.bind(this);
    this.handlePaymentClick = this.handlePaymentClick.bind(this);
  }

  componentDidMount() {
    if (
      this.props.match.params.periodId &&
      this.props.match.params.assessmentId &&
      this.props.match.params.establishmentId &&
      this.props.match.params.assessment_version &&
      this.props.match.params.withoutResult
    ) {
      if (this.props.match.params.withoutResult === "false") {
        this.props.fetchEstablishmentSelfAssessmentResult(
          this.props.match.params.periodId,
          this.props.match.params.assessmentId,
          this.props.match.params.establishmentId,
          this.props.match.params.assessment_version
        );
      }

      this.props.fetchAssessmentBills(
        this.props.match.params.establishmentId,
        this.props.match.params.periodId,
        this.props.match.params.assessmentId,
        this.props.match.params.assessment_version,
        this.props.match.params.service_type
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.establishmentProfile, this.props.establishmentProfile)
    ) {
      this.setState({
        establishmentProfile:
          this.props.establishmentProfile || emptyEstablishmentProfile,
      });
    }
    if (
      !isEqual(
        prevProps.establishmentSelfAssessmentResult,
        this.props.establishmentSelfAssessmentResult
      )
    ) {
      this.setState({
        establishmentSelfAssessmentResult:
          this.props.establishmentSelfAssessmentResult ||
          emptyEstablishmentSelfAssessmentResult,
      });
    }
  }

  redirectToSelfAssessmentResult() {
    const { periodId, assessmentId, establishmentId, assessment_version } =
      this.props.match.params;
    this.props.history.push(
      `/establishmentAssessmentResult/${periodId}/${assessmentId}/${establishmentId}/${assessment_version}`
    );
  }

  payForService(serviceId) {
    const {
      periodId,
      assessmentId,
      establishmentId,
      assessment_version,
      service_type,
    } = this.props.match.params;
    const { DoYouWantToPay } = this.state;

    if (!DoYouWantToPay) {
      toastr.error("يجب الموافقة على الشروط والاحكام");
      return;
    }

    if (service_type === RE_ASSESSMENT) {
      this.props
        .submitPayment(
          establishmentId,
          serviceId,
          periodId,
          assessmentId,
          assessment_version
        )
        .then(() => {})
        .catch((error) => {
          toastr.error("تعذر الدفع نسبة لوجود طلب مسبق");
        });
    }
    if (service_type === ADDITIONAL_ASSESSMENT) {
      this.props
        .submitPayment(
          establishmentId,
          serviceId,
          periodId,
          assessmentId,
          assessment_version
        )
        .then(() => {})
        .catch((error) => {
          toastr.error("تعذر الدفع نسبة لوجود طلب مسبق");
        });
    }
    if (service_type === TRAINING) {
      this.props
        .submitTrainingPayment(establishmentId, serviceId, periodId)
        .then(() => {})
        .catch((error) => {
          toastr.error("تعذر الدفع نسبة لوجود طلب مسبق");
        });
    }
    if (service_type === FASTTRACK) {
      this.props
        .submitPayment(
          establishmentId,
          serviceId,
          periodId,
          assessmentId,
          assessment_version
        )
        .then(() => {})
        .catch((error) => {
          toastr.error("تعذر الدفع نسبة لوجود طلب مسبق");
        });
    }
    if (service_type === DETAILEDREPORT) {
      this.props
        .submitPayment(
          establishmentId,
          serviceId,
          periodId,
          assessmentId,
          assessment_version
        )
        .then(() => {})
        .catch((error) => {
          toastr.error("تعذر الدفع نسبة لوجود طلب مسبق");
        });
    }
    this.handlePaymentClick("");
  }
  handlePaymentClick() {
    this.setState({ DoYouWantToPay: !this.state.DoYouWantToPay });
  }

  checkOut(url) {
    window.location.href = url;
  }

  render() {
    const {
      establishmentSelfAssessmentResult,
      establishmentProfile,
      collapsed,
    } = this.state;
    const { services, bills } = this.props;
    const { service_type } = this.props.match.params;
    const serviceList = services
      ? services.filter((service) => service.type === service_type)
      : null;
    const billList = bills
      ? bills.filter((service) => service.service_type === service_type)
      : null;

    return (
      <div>
        {(serviceList === null || serviceList.length !== 0) && (
          <div
            className="container-fluid-size result-report-block website-container-margins"
            id="divToPrint"
          >
            {this.props.match.params.withoutResult === "false" && (
              <Row
                className="establishment-assessment-card"
                style={{ paddingTop: "30px" }}
              >
                <Col>
                  <Row>
                    <Col className="card-header-style">
                      <strong
                        className="underlined-green-wide"
                        style={{ fontSize: "36px" }}
                      >
                        نتيجة التقييم
                      </strong>
                      <Button
                        className="span-float"
                        color="link"
                        size="sm"
                        onClick={() => this.redirectToSelfAssessmentResult()}
                      >
                        <i className="fa fa-file-pdf-o" /> عرض النتيجة
                      </Button>
                    </Col>
                  </Row>

                  <EstablishmentScoreList
                    report={establishmentSelfAssessmentResult}
                    establishmentProfile={establishmentProfile}
                  />
                </Col>
              </Row>
            )}

            {service_type !== UNIFIED_ASSESSMENT && (
              <Row className="establishment-assessment-card">
                <Col>
                  <Row>
                    <Col className="card-header-style">
                      <strong
                        className="underlined-green-wide"
                        style={{ fontSize: "36px" }}
                      >
                        تفاصيل الخدمة
                      </strong>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "80px" }}>
                    <Col
                      style={{
                        minHeight: "60vh",
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "1200px",
                        margin: "0 auto",
                      }}
                    >
                      <Row className="establishment-assessment-card assessment-result-details">
                        <Col className="result-details-wrap">
                          {serviceList.map((service, i) => (
                            <Row key={service.id}>
                              <EstablishmentServiceCard
                                key={i}
                                service={service}
                                payForService={this.payForService}
                                collapsed={collapsed}
                                isOpenByDefault={i === 0}
                              />
                            </Row>
                          ))}
                          <Row style={{ padding: "0 30px" }}>
                            <Col>
                              <Row className="payment-text-card">
                                <Col>
                                  <Row>
                                    <Label className="payment-title">
                                      تنبيه قبل الدفع
                                    </Label>
                                  </Row>
                                  <Row>
                                    <Label className="payment-text">
                                      <p>
                                        تنبيه: المبالغ المدفوعة للخدمة غير خاضعة
                                        لسياسة الاسترداد.
                                      </p>
                                    </Label>
                                    <div className="payment-agreement-wrap">
                                      <Label className="float-right">
                                        <input
                                          type="checkbox"
                                          name="legal-agreement"
                                          checked={this.state.DoYouWantToPay}
                                          onChange={this.handlePaymentClick}
                                        />{" "}
                                        &emsp; موافق على الشروط والاحكام
                                      </Label>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            {billList && billList.length !== 0 && (
              <Row className="establishment-assessment-card">
                <Col>
                  <Row>
                    <Col className="card-header-style">
                      <strong
                        className="underlined-green-wide"
                        style={{ fontSize: "36px" }}
                      >
                        الفواتير
                      </strong>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "80px" }}>
                    <Col
                      style={{
                        minHeight: "60vh",
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "1200px",
                        margin: "0 auto",
                      }}
                    >
                      <Row className="establishment-assessment-card assessment-result-details">
                        <Col className="result-details-wrap">
                          {billList.map((bill, i) => (
                            <Row key={bill.id} style={{ marginBottom: "30px" }}>
                              <EstablishmentBillCard
                                key={i}
                                bill={bill}
                                checkOut={this.checkOut}
                                collapsed={collapsed}
                                isOpenByDefault={i === 0}
                              />
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    );
  }
}

EstablishmentAssessmentPaymentPage.propTypes = {
  establishmentProfile: PropTypes.object,
  establishmentSelfAssessmentResult: PropTypes.object,
  establishmentSelfAssessmentResultFetchingError: PropTypes.object,
  services: PropTypes.array,
  bills: PropTypes.array,
  submitPayment: PropTypes.func,
  fetchAssessmentBills: PropTypes.func,
  ajaxStatus: PropTypes.object,
  history: PropTypes.object.isRequired,
};

EstablishmentAssessmentPaymentPage.defaultProps = {
  establishmentProfile: null,
  establishmentSelfAssessmentResult: null,
  establishmentSelfAssessmentResultFetchingError: null,
};

const mapStateToProps = (state) => ({
  establishmentProfile: state.establishmentSelfAssessmentResult
    .establishmentSelfAssessmentResult
    ? state.establishmentSelfAssessmentResult.establishmentSelfAssessmentResult
        .establishment
    : emptyEstablishmentProfile,
  establishmentSelfAssessmentResult:
    state.establishmentSelfAssessmentResult.establishmentSelfAssessmentResult,
  establishmentSelfAssessmentResultFetchingError:
    state.establishmentSelfAssessmentResult
      .establishmentSelfAssessmentResultFetchingError,
  services: state.payment.reopen_assessment_services,
  bills: state.payment.bills,
  ajaxStatus: state.ajaxStatus,
});

const mapDispatchToProps = {
  // fetchEstablishmentProfile: fetchEstablishmentProfile,
  fetchEstablishmentSelfAssessmentResult:
    fetchEstablishmentSelfAssessmentResult,
  submitPayment: submitPayment,
  submitTrainingPayment: submitTrainingPayment,
  fetchAssessmentBills: loadPaymentBills,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstablishmentAssessmentPaymentPage);
