import React from "react";
import PropTypes from "prop-types";

export const IconHome = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g
      stroke="none"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(2.500000, 3.750000)"
        stroke="#FFFFFF"
        style={{ strokeWidth: 1.8 }}
      >
        <polyline
          id="Combined-Shape"
          points="12.5 17.5 12.5 22.5 21.25 22.5 21.25 11.25 25 11.25 12.5 0 0 11.25 3.75 11.25 3.75 22.5 7.5 22.5"
        ></polyline>
        <circle id="Oval" cx="12.5" cy="13.75" r="3.75"></circle>
      </g>
    </g>
  </svg>
);

export default IconHome;
