import initialState from "./initialState";
import {
  UNIFIED_START_LOADING,
  UNIFIED_STOP_LOADING,
  UNIFIED_FETCH_ESTABLISHMENT_SUCCESS,
  UNIFIED_SAVE_SELECTED_ESTABLISHMENTS_SUCCESS,
  UNIFIED_SET_ERROR,
  UNIFIED_SELECT_ESTABLISHMENT,
  UNIFIED_DESELECT_ESTABLISHMENT,
  UNIFIED_CLEAR_SELECTED_ESTABLISHMENT,
  UNIFIED_RESET,
  UNIFIED_SET_GRACE_PERIOD_STATUS,
  UNIFIED_GET_GRACE_PERIOD_STATUS,
} from "../actions/actionTypes";

export default function unifiedReducer(state = initialState.unified, action) {
  switch (action.type) {
    case UNIFIED_START_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
      });
    case UNIFIED_STOP_LOADING:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case UNIFIED_FETCH_ESTABLISHMENT_SUCCESS:
      return Object.assign({}, state, {
        establishments: action.establishments,
        total: action.total,
      });
    case UNIFIED_SAVE_SELECTED_ESTABLISHMENTS_SUCCESS:
      return Object.assign({}, state, {
        service: action.service,
      });
    case UNIFIED_SET_ERROR:
      return Object.assign({}, state, {
        error: true,
        isLoading: false,
      });
    case UNIFIED_SELECT_ESTABLISHMENT:
      return Object.assign({}, state, {
        selected: state.selected.concat(action.id),
      });
    case UNIFIED_DESELECT_ESTABLISHMENT:
      return Object.assign({}, state, {
        selected: state.selected.filter((id) => id !== action.id),
      });
    case UNIFIED_CLEAR_SELECTED_ESTABLISHMENT:
      return Object.assign({}, state, {
        selected: [],
      });
    case UNIFIED_RESET:
      return Object.assign({}, initialState.unified);
    case UNIFIED_SET_GRACE_PERIOD_STATUS:
      return Object.assign({}, state, {
        gracePeriodStatus: action.status,
        isLoadingStatus: false,
      });
    case UNIFIED_GET_GRACE_PERIOD_STATUS:
      return Object.assign({}, state, {
        gracePeriodStatus: action.status,
        isLoadingStatus: true,
      });
    default:
      return state;
  }
}
