import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function caliberSpecializations(state = initialState.caliberSpecializations, action) {
    switch (action.type) {
        case types.LOAD_CALIBERSPECIALIZATIONS_SUCCESS:
            return action.caliberSpecializations;
        default:
            return state;
    }
}