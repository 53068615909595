import request from "../common/request";

export const fetchFastTrackServiceAuthorization =
  (establishmentId, durationId, serviceType) => (dispatch) => {
    return request(
      `/v1/establishments/${establishmentId}/can_apply_for_service/${durationId}/${serviceType}`
    )
      .then((response) => response.json())
      .then((data) => {
        return data.result;
      })
      .catch((error) => {
        throw error;
      });
  };
