import * as types from "./actionTypes";
import {
  FETCH_TRAINING_SUBJECTS_FAILURE,
  FETCH_TRAINING_SUBJECTS_SUCCESS,
  FETCH_TRAINING_SUBJECTS,
  FETCH_TRAINING_SUBJECT_FAILURE,
  FETCH_TRAINING_SUBJECT_SUCCESS,
  FETCH_TRAINING_SUBJECT,
} from "./actionTypes";
import { beginAjaxCall, ajaxCallError, endAjaxCall } from "./ajaxStatusActions";
import request from "../common/request";
import qs from "query-string";
import { parseError } from "../common/request";

/**
 * Fetch training subjects failure action
 * @param {Object} error
 * @returns {{ type: String, payload: { error } }}
 */
export const fetchTrainingSubjectsFailure = (error) => ({
  type: FETCH_TRAINING_SUBJECTS_FAILURE,
  payload: { error },
});

/**
 * Fetch training subjects  success action
 * @param {Array} training subjects list of trainings
 * @returns {{ type: String, payload: { training_subjects } }}
 */
export const fetchTrainingSubjectsSuccess = (training_subjects, total) => ({
  type: FETCH_TRAINING_SUBJECTS_SUCCESS,
  payload: { training_subjects, total },
});

/**
 * Fetch trainings action
 */
export const fetchTrainingSubjects = (establishmentId) => (dispatch) => {
  dispatch({
    type: FETCH_TRAINING_SUBJECTS,
    payload: { establishmentId },
  });

  const url = `/v1/establishments/${establishmentId}/training_subjects`;

  request(url)
    .then((response) => {
      if (!response.ok) {
        return parseError(response);
      }
      return response;
    })
    .then((res) => res.json())
    .then(
      (json) => {
        dispatch(
          fetchTrainingSubjectsSuccess(json.training_subjects || [], json.count)
        );
      },
      (error) => {
        dispatch(fetchTrainingSubjectsFailure(error));
      }
    );
};

/**

getting training subject by ID
 */

/**
 * Fetch training subject failure action
 * @param {Object} error
 * @returns {{ type: String, payload: { error } }}
 */
export const fetchTrainingSubjectFailure = (error) => ({
  type: FETCH_TRAINING_SUBJECT_FAILURE,
  payload: { error },
});

/**
 * Fetch training subject  success action
 * @param {Object} training subject
 * @returns {{ type: String, payload: { training_subject } }}
 */
export const fetchTrainingSubjectSuccess = (training_subject) => ({
  type: FETCH_TRAINING_SUBJECT_SUCCESS,
  payload: { training_subject },
});

/**
 * Fetch trainings action
 */
export const fetchTrainingSubject = (establishmentId, trainingSubjectId) => (
  dispatch
) => {
  dispatch({
    type: FETCH_TRAINING_SUBJECT,
    payload: { establishmentId, trainingSubjectId },
  });

  const url = `/v1/establishments/${establishmentId}/training_subject/${trainingSubjectId}`;

  request(url)
    .then((response) => {
      if (!response.ok) {
        return parseError(response);
      }
      return response;
    })
    .then((res) => res.json())
    .then(
      (json) => {
        dispatch(fetchTrainingSubjectSuccess(json));
      },
      (error) => {
        dispatch(fetchTrainingSubjectFailure(error));
      }
    );
};
