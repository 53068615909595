import React from "react";
export const PdfIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={41} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.555}
        x2={0.56}
        y1={0.192}
        y2={1.006}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f31111" />
        <stop offset={1} stopColor="#cb0707" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={-1.184}
        x2={-1.194}
        y1={0.767}
        y2={1.428}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0.45}
        x2={0.448}
        y1={0.987}
        y2={0.539}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={1} stopColor="#fff" stopOpacity={0.2} />
      </linearGradient>
    </defs>
    <g data-name="Group 164376" transform="translate(-12070.5 -12345.5)">
      <rect
        width={40}
        height={40}
        fill="#fff"
        stroke="#dfdfdf"
        data-name="Rectangle 142193"
        rx={8}
        transform="translate(12071 12346)"
      />
      <path
        fill="url(#a)"
        d="M24.9 20a4.885 4.885 0 0 0-4.9 4.9v13.7a4.885 4.885 0 0 0 4.9 4.9h13.7a4.885 4.885 0 0 0 4.9-4.9V24.9a4.885 4.885 0 0 0-4.9-4.9Z"
        transform="translate(12059.252 12334.252)"
      />
      <path
        fill="url(#b)"
        d="M1557.081-310.416a4.882 4.882 0 0 0-4.894 4.894v13.707a4.884 4.884 0 0 0 4.894 4.9h.146a4.781 4.781 0 0 1-4.791-4.791v-13.416a4.781 4.781 0 0 1 4.791-4.791h13.416a4.78 4.78 0 0 1 4.791 4.791v13.416a4.781 4.781 0 0 1-4.791 4.791h.144a4.885 4.885 0 0 0 4.9-4.9v-13.707a4.884 4.884 0 0 0-4.9-4.894Z"
        opacity={0.5}
        transform="translate(10527.065 12664.668)"
      />
      <path
        fill="url(#c)"
        d="M1557.081-286.92a4.882 4.882 0 0 1-4.894-4.894v-13.707a4.884 4.884 0 0 1 4.894-4.9h.146a4.781 4.781 0 0 0-4.791 4.791v13.416a4.781 4.781 0 0 0 4.791 4.791h13.416a4.78 4.78 0 0 0 4.791-4.791v-13.416a4.78 4.78 0 0 0-4.791-4.791h.144a4.885 4.885 0 0 1 4.9 4.9v13.707a4.884 4.884 0 0 1-4.9 4.894Z"
        opacity={0.2}
        transform="translate(10527.065 12664.668)"
      />
      <path
        fill="rgba(0,0,0,0.15)"
        d="M12088.891 12359.287h-.073a1.185 1.185 0 0 0-1.155 1.38 6.854 6.854 0 0 0 1.32 2.8 4.472 4.472 0 0 1-.124.969 22.757 22.757 0 0 1-1.7 5.545 5.647 5.647 0 0 0-2.75 1.931 1.18 1.18 0 0 0 .209 1.335l4.5 4.5h8.929a4.694 4.694 0 0 0 4.7-4.7v-.338l-13.116-13.122a1.084 1.084 0 0 0-.74-.3Zm-.04 1.019c.2.038.211.33.266.487a5.628 5.628 0 0 1 .042.969 2.548 2.548 0 0 1-.484-1.415c.061-.003.115-.034.175-.041Zm1.018 4.278.067.081a26.871 26.871 0 0 0 3.462 3.569 25.952 25.952 0 0 0-4.9 1.254 27.519 27.519 0 0 0 1.37-4.901Zm6.565 4.435a2.05 2.05 0 0 1 1.194.294c.03.251-.277.288-.441.2a3.357 3.357 0 0 1-.969-.477c.072-.008.142-.017.215-.018Zm-10.011 2.492-.081.151a3.481 3.481 0 0 1-.764.948c-.1.157-.363-.134-.241-.257a3.023 3.023 0 0 1 1.085-.843Z"
      />
      <path
        fill="#fff"
        d="M12088.188 12358.365a1.26 1.26 0 0 0-1.23 1.467 7.294 7.294 0 0 0 1.4 2.981 4.762 4.762 0 0 1-.132 1.031 24.212 24.212 0 0 1-1.807 5.9 6.008 6.008 0 0 0-2.927 2.054 1.288 1.288 0 0 0 .989 1.82c.767.1 1.336-.552 1.755-1.1a19.16 19.16 0 0 0 1.09-1.934 25.164 25.164 0 0 1 6.888-1.743 6.215 6.215 0 0 0 3.2 1.541 1.289 1.289 0 0 0 1.212-1.406 1.432 1.432 0 0 0-1.2-1.209 9.849 9.849 0 0 0-2.935-.123 24.625 24.625 0 0 1-4.93-5.153 6.087 6.087 0 0 0-.317-3.588 1.189 1.189 0 0 0-1.056-.538Zm.035 1.08c.209.04.223.352.282.519a5.992 5.992 0 0 1 .045 1.03 2.71 2.71 0 0 1-.516-1.506c.066.002.125-.031.189-.039Zm1.154 4.639a28.6 28.6 0 0 0 3.684 3.8 27.613 27.613 0 0 0-5.218 1.333 29.276 29.276 0 0 0 1.463-5.218Zm6.915 4.634a2.178 2.178 0 0 1 1.27.313c.032.267-.295.306-.469.21a3.575 3.575 0 0 1-1.031-.506c.077-.002.153-.012.23-.013Zm-10.741 2.812a3.7 3.7 0 0 1-.812 1.008c-.1.167-.387-.142-.256-.272a3.222 3.222 0 0 1 1.155-.9Z"
      />
    </g>
  </svg>
);
