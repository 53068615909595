import React from "react";
import PropTypes from "prop-types";
import "./buttonWithIcon.css";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

const ButtonWithIcon = ({
  text,
  color,
  align,
  goTo,
  onClick,
  textSize,
  smallArrow,
  textType,
  fontSize,
  mobileFontSize,
}) => {
  const handleClick = () => {
    goTo();
    onClick(true);
  };

  return (
    <div
      className="button-with-icon-wrapper"
      style={{
        textAlign: align && align,
        color: color && color,
      }}
    >
      <a
        onClick={handleClick}
        style={{
          fontSize: textSize ? textSize : "",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Text
          Tag="span"
          textType={textType}
          fontSize={fontSize}
          mobileFontSize={mobileFontSize}
        >
          {text}
        </Text>
        {smallArrow && (
          <span className={"button-with-icon-image-wrapper"}>
            <img
              className={
                smallArrow ? "button-with-icon-image-wrapper-size " : ""
              }
              src={color ? "img/arrowWhite.svg" : "img/arrow.svg"}
            />
          </span>
        )}
      </a>
    </div>
  );
};

ButtonWithIcon.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  align: PropTypes.string,
  goTo: PropTypes.func,
  onClick: PropTypes.func,
  textSize: PropTypes.string,
  smallArrow: PropTypes.bool,
  textType: PropTypes.string,
};
ButtonWithIcon.defaultProps = {
  color: null,
  align: null,
  smallArrow: false,
  goTo: () => {},
  onClick: () => {},
  textSize: null,
  textType: "h7",
  fontSize: "",
  mobileFontSize: "",
};

export default ButtonWithIcon;
