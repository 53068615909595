import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function ajaxStatus(state = initialState.ajaxStatus, action) {
  switch (action.type) {
    case types.BEGIN_AJAX_CALL:
      return Object.assign({}, state, {
        isLoading: true
      });

    case types.AJAX_CALL_ERROR:
      return Object.assign({}, state, {
        error: action.error
      });

    case types.END_AJAX_CALL:
      return Object.assign({}, state, {
        isLoading: false
      });

    default:
      return state;
  }
}

