import React from "react";

export const IconFastTrackAssessment = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width="23.714"
    height="23.714"
    viewBox="0 0 23.714 23.714"
  >
    <g id="Group_165417"
      data-name="Group 165417"
      stroke="none"
      strokeWidth="0.2"
      fill="none"
      fillRule="evenodd"
    >
      <path id="path2526" d="M-303.659-606.468a4.355,4.355,0,0,1-2.907-1.111,3.069,3.069,0,0,0-2.309-.771c-.126.011-.254.016-.38.016a4.4,4.4,0,0,1-4.395-4.395c0-.126.005-.254.016-.38a3.08,3.08,0,0,0-.771-2.309,4.355,4.355,0,0,1-1.111-2.907,4.355,4.355,0,0,1,1.111-2.907,3.08,3.08,0,0,0,.771-2.309c-.011-.126-.016-.254-.016-.381a4.4,4.4,0,0,1,4.395-4.395c.126,0,.254.005.38.016a3.069,3.069,0,0,0,2.309-.771,4.355,4.355,0,0,1,2.907-1.111,4.355,4.355,0,0,1,2.907,1.111,3.066,3.066,0,0,0,2.309.771c.126-.011.254-.016.38-.016a4.4,4.4,0,0,1,4.395,4.395c0,.126-.005.254-.016.38a3.08,3.08,0,0,0,.771,2.309,4.354,4.354,0,0,1,1.111,2.907,4.354,4.354,0,0,1-1.111,2.907,3.08,3.08,0,0,0-.771,2.309c.011.126.016.254.016.38a4.4,4.4,0,0,1-4.395,4.395c-.126,0-.254-.005-.38-.016a3.066,3.066,0,0,0-2.309.771A4.355,4.355,0,0,1-303.659-606.468Zm-4.953-3.593a4.762,4.762,0,0,1,3.18,1.215,2.657,2.657,0,0,0,1.774.678,2.657,2.657,0,0,0,1.774-.678,4.763,4.763,0,0,1,3.18-1.215c.135,0,.273.006.408.018.078.007.157.01.235.01a2.7,2.7,0,0,0,2.695-2.695c0-.078,0-.157-.01-.235a4.786,4.786,0,0,1,1.2-3.588,2.657,2.657,0,0,0,.678-1.774,2.657,2.657,0,0,0-.678-1.774,4.786,4.786,0,0,1-1.2-3.588c.007-.078.01-.157.01-.235a2.7,2.7,0,0,0-2.695-2.695c-.078,0-.157,0-.235.01-.136.012-.273.018-.408.018a4.762,4.762,0,0,1-3.18-1.215,2.657,2.657,0,0,0-1.774-.678,2.657,2.657,0,0,0-1.774.678,4.763,4.763,0,0,1-3.18,1.215c-.135,0-.272-.006-.408-.017-.078-.007-.157-.01-.235-.01a2.7,2.7,0,0,0-2.695,2.695c0,.078,0,.157.01.235a4.786,4.786,0,0,1-1.2,3.588,2.657,2.657,0,0,0-.678,1.774,2.657,2.657,0,0,0,.678,1.774,4.786,4.786,0,0,1,1.2,3.588c-.007.078-.01.157-.01.235a2.7,2.7,0,0,0,2.695,2.695c.078,0,.157,0,.235-.01C-308.885-610.055-308.748-610.061-308.612-610.061Z" transform="translate(315.598 630.265)"  />
      <path id="path2530" d="M1.866-48.4-.6-50.867l1.2-1.2L1.866-50.8,5-53.934l1.2,1.2Z" transform="translate(9.141 63.407)"  />
      <path id="path2534" d="M-180.137-359.423a7.388,7.388,0,0,1-7.38-7.38,7.388,7.388,0,0,1,7.38-7.38,7.388,7.388,0,0,1,7.38,7.38A7.388,7.388,0,0,1-180.137-359.423Zm0-13.059a5.686,5.686,0,0,0-5.68,5.68,5.686,5.686,0,0,0,5.68,5.68,5.686,5.686,0,0,0,5.68-5.68A5.686,5.686,0,0,0-180.137-372.482Z" transform="translate(192.076 378.743)" />
    </g>
  </svg>
);

export default IconFastTrackAssessment;