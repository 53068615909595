import React from "react";
import range from "lodash/range";
import "./pagination.css";
import PropTypes from "prop-types";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";

const Pagination = (props) => {
  const { itemsCount, pagesLimit, currentPage, onPageChange } = props;
  const pageCount = Math.ceil(itemsCount / pagesLimit);
  if (pageCount === 1) return null;
  const pages = range(1, pageCount + 1);

  const englishToArabicDigits = (w) => {
    const arabicNumber = w.toString().replace(/\d/g, (d) => "۰۱۲۳٤٥٦۷۸۹"[d]);
    return arabicNumber;
  };

  return (
    <div className="pagination">
      <button
        className="button-style"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <img
          src={"img/pagination-white-arrow.svg"}
          style={{ width: "7px", height: "13px" }}
        />
      </button>

      {pages.map((page) =>
        pageCount <= 3 ||
        page === 1 ||
        page === pageCount ||
        page === currentPage ||
        (currentPage !== 1 && page === currentPage - 1) ||
        (currentPage !== pageCount && page === currentPage + 1) ? (
          <span key={page} className={"pagination-text"}>
            <a href={null} onClick={() => onPageChange(page)}>
              <Text
                Tag="span"
                textType="h8"
                className={"pagination-text"}
                isBold={page === currentPage ? true : false}
              >
                {englishToArabicDigits(page)}
              </Text>
            </a>
          </span>
        ) : (currentPage !== 1 && page === currentPage - 2) ||
          (currentPage !== pageCount && page === currentPage + 2) ? (
          <a key={page}>...</a>
        ) : null
      )}

      <button
        className="button-style left-button"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === pageCount}
      >
        <img
          src={"img/pagination-white-arrow.svg"}
          style={{ width: "7px", height: "13px" }}
        />
      </button>
    </div>
  );
};
Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pagesLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
