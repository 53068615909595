import React from "react";
import PropTypes from "prop-types";
import "../home/homepage.css";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import AnimationTopToBottom from "../AnimationTopToBottom/AnimationTopToBottom";
import AnimationRightToLeft from "../AnimationRightToLeft/AnimationRightToLeft";

const TitleWithSubTitle = ({ title, subtitle, customStyle, withAnimation }) => (
  <span>
    {withAnimation ? (
      <span>
        {title && (
          <AnimationTopToBottom>
            <Text
              Tag="div"
              textType="roman"
              fontSize={"font-40"}
              mobileFontSize={"font-24-mobile"}
              className="custom-h2"
            >
              {title}
            </Text>
          </AnimationTopToBottom>
        )}
        {subtitle && (
          <AnimationRightToLeft>
            <Text
              Tag="div"
              textType="roman"
              fontSize={"font-56"}
              mobileFontSize={"font-24-mobile"}
              className={`underlined-yellow custom-h1 ${customStyle}`}
            >
              {subtitle}
            </Text>
          </AnimationRightToLeft>
        )}
      </span>
    ) : (
      <span>
        {title && (
          <Text
            Tag="div"
            textType="roman"
            fontSize={"font-40"}
            mobileFontSize={"font-24-mobile"}
            className="custom-h2"
          >
            {title}
          </Text>
        )}
        {subtitle && (
          <Text
            Tag="div"
            textType="roman"
            fontSize={"font-56"}
            mobileFontSize={"font-24-mobile"}
            className={`underlined-yellow custom-h1 ${customStyle}`}
          >
            {subtitle}
          </Text>
        )}
      </span>
    )}
  </span>
);

TitleWithSubTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  customStyle: PropTypes.string,
  withAnimation: PropTypes.bool,
};
TitleWithSubTitle.defaultProps = {
  customStyle: "",
  withAnimation: false,
};
export default TitleWithSubTitle;
