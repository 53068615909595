import React from "react";
import PropTypes from "prop-types";

import "./switch-button.scss";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

export const SwitchButton = ({ value, onChange, disabled, label, id }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <div className="switch-button">
      <label className="toggle-control">
        <input
          type="checkbox"
          checked={value}
          onChange={handleChange}
          disabled={disabled}
          id={id}
        />
        <span className="control"></span>
      </label>
      {label && (
        <Text textType="p4" className="switch-label">
          {label}
        </Text>
      )}
    </div>
  );
};

SwitchButton.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
};

SwitchButton.defaultProps = {
  disabled: false,
  label: "",
};

export default SwitchButton;
