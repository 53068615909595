import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import RichEditor, { styleMap } from "./RichEditor";
import { stateToHTML } from "draft-js-export-html";

function FakeEvent({ name, type, value }) {
  this.target = {
    name,
    type,
    value,
  };
}

function isValidJSON(string) {
  try {
    return typeof JSON.parse(string) !== "number";
  } catch (e) {
    return false;
  }
}

export default class RichTextComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: createEditorValueFromString(props.value),
      toolbarConfig: {
        // Optionally specify the groups to display (displayed in the order listed).
        display: [
          "INLINE_STYLE_BUTTONS",
          "BLOCK_TYPE_DROPDOWN",
          "BLOCK_TYPE_BUTTONS",
          "HISTORY_BUTTONS",
          "COLOR",
        ],
        INLINE_STYLE_BUTTONS: [
          { label: "Bold", style: "BOLD", className: "custom-css-class" },
          { label: "Italic", style: "ITALIC" },
          { label: "Underline", style: "UNDERLINE" },
        ],
        BLOCK_TYPE_DROPDOWN: [
          { label: "Normal", style: "unstyled" },
          { label: "Heading Large", style: "header-one" },
          { label: "Heading Medium", style: "header-two" },
          { label: "Heading Small", style: "header-three" },
        ],
        BLOCK_TYPE_BUTTONS: [
          { label: "UL", style: "unordered-list-item" },
          { label: "OL", style: "ordered-list-item" },
        ],
        COLOR: [
          { label: "Red", style: "red" },
          { label: "Orange", style: "orange" },
          { label: "Yellow", style: "yellow" },
          { label: "Green", style: "green" },
          { label: "Blue", style: "blue" },
          { label: "Indigo", style: "indigo" },
          { label: "Violet", style: "violet" },
        ],
      },
    };

    this.onEditorChange = this.onEditorChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const rawContent = JSON.stringify(
      convertToRaw(this.state.value.getCurrentContent())
    );
    if (nextProps.value && nextProps.value !== rawContent) {
      this.setState({
        value: createEditorValueFromString(nextProps.value),
      });
    }
  }

  onEditorChange(value) {
    this.setState({ value });
    const { name } = this.props;

    const rawContent = JSON.stringify(convertToRaw(value.getCurrentContent()));
    // const fakeEvent = new FakeEvent({ name, type: 'text', value: rawContent });
    this.props.onChange(rawContent);
  }

  render() {
    const { value, toolbarConfig } = this.state;
    return (
      <div>
        <RichEditor
          value={value}
          label={this.props.label}
          onChange={this.onEditorChange}
        />
      </div>
    );
  }
}

export function convertRawContentToHtml(string) {
  const contentState = createEditorValueFromString(string);
  const editorContentHtml = stateToHTML(contentState.getCurrentContent());

  let list = [];
  let typeList = [];
  if (isValidJSON(string)) {
    const obj = JSON.parse(string);

    obj.blocks.map((a) => {
      typeList.push(a.type);
      if (a.inlineStyleRanges.length !== 0) {
        a.inlineStyleRanges.map((inlineStyle) => {
          if (styleMap[inlineStyle.style])
            list.push(styleMap[inlineStyle.style].color);
        });
      } else {
        list.push(null);
      }
    });
    const htmlTags = editorContentHtml.split("\n");

    let colorIndex = 0;
    let result = "";
    for (let i = 0; i < htmlTags.length; i++) {
      if (
        htmlTags[i].startsWith("<ol>") ||
        htmlTags[i].startsWith("</ol>") ||
        htmlTags[i].startsWith("<ul>") ||
        htmlTags[i].startsWith("</ul>")
      ) {
        result = result + htmlTags[i] + "\n";
        continue;
      }
      if (list[colorIndex]) {
        htmlTags[i] = htmlTags[i].replace(
          ">",
          ` style=\"color: ${list[colorIndex]} ;${
            typeList[i] === "font-size-18"
              ? " font-size: 18px"
              : "font-size: 16px"
          }\" >`
        );
      } else if (typeList[i] === "font-size-18") {
        htmlTags[i] = htmlTags[i].replace(
          ">",
          ` style=\" ${
            typeList[i] === "font-size-18"
              ? "font-size: 18px"
              : "font-size: 16px"
          }\" >`
        );
      }
      colorIndex++;
      result = result + htmlTags[i] + "\n";
    }

    return result;
  } else {
    return editorContentHtml;
  }
}
export function createEditorValueFromString(string) {
  const contentState = contentStateFromString(string);
  const editorState = EditorState.moveFocusToEnd(
    EditorState.createWithContent(contentState)
  );
  return editorState;
}

export function contentStateFromString(string) {
  if (isValidJSON(string)) {
    return convertFromRaw(JSON.parse(string));
  } else {
    return stateFromHTML(string);
  }
}

export function convertRawContentToText(string) {
  if (isValidJSON(string)) {
    return JSON.parse(string).blocks.reduce(
      (prev, cur) => `${prev} ${cur.text}`,
      ""
    );
  } else {
    return string;
  }
}

RichTextComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};
