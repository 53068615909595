import React, { useState } from "react";
import { Text } from "../../shared-components/my-scope/atoms/text/index";
import "./maintenance.scss";

const Maintenance = () => {
  return (
    <div className="maintenance-wrapper">
      <div className="maintenance-background-wrapper">
        <img
          src={"img/maintenance_background_gear.svg"}
          className="logo"
          alt="maintenance gear "
        />
      </div>
      <div className="maintenance-logo-wrapper">
        <img src={"img/IMS-MHRSD-logo.svg"} alt="mlsd logo" className="logo" />
      </div>
      <div className="maintenance-icon-wrapper">
        <img src={"img/maintenance.svg"} className="icon" />
      </div>
      <div className="maintenance-text-wrapper">
        <Text Tag="div" textType="h4" className="title">
          المنصة تحت الصيانة
        </Text>
        <Text Tag="div" textType="p3" className="subtitle">
        عذرا، المنصة غير متاحة حاليا لغرض الصيانة والتطوير، يمكنكم الاستفادة من خدمات المنصة بعد الانتهاء من ذلك.
        </Text>
      </div>
    </div>
  );
};

export default Maintenance;
