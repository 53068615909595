import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function violationCategories(state = initialState.violationCategories, action) {
    switch (action.type) {
        case types.LOAD_VIOLATIONCATEGORIES_SUCCESS:
            return action.violationCategories;

        case types.CREATE_VIOLATIONCATEGORY_SUCCESS:
            return [
                ...state,
                Object.assign({}, action.violationCategory)
            ];

        case types.UPDATE_VIOLATIONCATEGORY_SUCCESS:
            return [
                ...state.filter(violationCategory => violationCategory.id !== action.violationCategory.id),
                Object.assign({}, action.violationCategory)
            ];

        default:
            return state;
    }
}

