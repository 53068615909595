import React, {useEffect} from "react";
import Content from "../../containers/Content/Content";
import "./ContactUsPage.css";
import TitleWithSubTitle from "../reusableComponents/TitleWithSubTitle";
import {Text} from "../../../../shared-components/my-scope/atoms/text";
import ContactItem from "./ContactItem";

const ContactUsPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Content history={props.history}>
      <div className="container-fluid-size ">
        <div className="justify-content-center">
          <div className="animated fadeIn">
            <TitleWithSubTitle
              subtitle="تواصل معنا"
            />
            <Text
              Tag="div"
              textType="roman"
              fontSize={"font-35"}
              mobileFontSize={"font-18-mobile"}
              className="frame-contact-us-sub-title-text"
            >
              نحن متواجدون لخدمتك عبر القنوات التالية
            </Text>

            <div className="frame-contact-us-cards-section">

                <ContactItem
                  iconSrc="img/x.svg"
                  headingText="منصة X"
                  paragraphText="تواصل معنا عبر منصة التواصل الاجتماعي X"
                  linkHref="https://x.com/ims_hrsd?lang=ar"
                  linkText="Ims_hrsd@"
                  linkType="link"
                />

                <ContactItem
                  iconSrc="img/feather-mail.svg"
                  headingText="البريد الإلكتروني"
                  paragraphText="نسعد بتواصلك معنا عبر البريد الالكتروني"
                  linkHref="ims@takamol.com.sa"
                  linkText="ims@takamol.com.sa"
                  linkType="email"
                />

                <ContactItem
                  iconSrc="img/contact-phone.svg"
                  headingText="الرقم الموحد"
                  paragraphText="يمكنك التواصل معنا على مدار الساعة 24/7"
                  linkHref="920033411"
                  linkText="920033411"
                  linkType="phone"
                />

              </div>

          </div>
        </div>
      </div>


    </Content>
  );
};

export default ContactUsPage;
