import jsPDF from "jspdf";
import {
  FrutigerLTArabic45Light,
  FrutigerLTArabic55Roman,
  FrutigerLTArabic65Bold,
  Frutigerltarabic75black,
} from "./fonts";
import { CertificateBackground, MHRSDLogo, ImsLogo } from "./images";

export function PdfTrainingCertificate(
  establishmentName = "hhhh",
  trainingName = " hhh",
  establishmentSequenceId= "2222",
  establishmentLaborOfficeId = "1"
) {
  const pdf = new jsPDF("l", "pt", "a4", true);
  pdf.setR2L(true);

  let width = pdf.internal.pageSize.getWidth();
  let height = pdf.internal.pageSize.getHeight();

  pdf.addFileToVFS("FrutigerLTArabic65Bold.ttf", FrutigerLTArabic65Bold);
  pdf.addFileToVFS("FrutigerLTArabic55Roman.ttf", FrutigerLTArabic55Roman);
  pdf.addFileToVFS("FrutigerLTArabic45Light.ttf", FrutigerLTArabic45Light);
  pdf.addFileToVFS("Frutigerltarabic75black.ttf", Frutigerltarabic75black);

  pdf.addFont("FrutigerLTArabic65Bold.ttf", "FrutigerLTArabic65Bold", "bold");
  pdf.addFont(
    "FrutigerLTArabic55Roman.ttf",
    "FrutigerLTArabic55Roman",
    "roman"
  );
  pdf.addFont(
    "FrutigerLTArabic45Light.ttf",
    "FrutigerLTArabic45Light",
    "light"
  );
  pdf.addFont(
    "Frutigerltarabic75black.ttf",
    "Frutigerltarabic75black",
    "black"
  );

  const replaceParentheses = (text) => {
    let newText = text
      .replace(/،/g, " ، ")
      .replace(/؟/g, " ؟ ")
      .replace(/\s\s/g, " ")
      .replace(/\s{3,}/g, "\n")
      .replace(/[ؐ-ًؕ-ٖٓ-ٟۖ-ٰٰۭ]/g, "")
      .replace(/(?<=[0-9])-/, ".")
      .replace(")", "(")
      .replace("(", ")");
    return newText;
    // return text.replace(")", "(").replace("(", ")");
  };

  pdf.setFont("FrutigerLTArabic65Bold");

  const processArabicText = (arabicText, x, y, alignment = "right") => {
    const options = {
      isInputVisual: false,
      isOutputVisual: true,
      isInputRtl: true,
      isOutputRtl: true,
      align: alignment,
    };
    return pdf.text(arabicText, x, y, options);
  };

  pdf.addImage(
    CertificateBackground,
    "PNG",
    0,
    0,
    width,
    height,
    "CertificateBackground"
  );

  pdf.addImage(MHRSDLogo, "PNG", width - 180, 50, 130, 40, "MHRSDLogo", "FAST");
  pdf.addImage(ImsLogo, "PNG", 50, 50, 80, 50, "ImsLogo", "FAST");

  pdf.setFont("FrutigerLTArabic65Bold", "bold");
  pdf.setFontSize(30);
  pdf.setTextColor("#083349");
  const title = "شهادة حضور";

  const xTitle = width / 2;
  processArabicText(title, xTitle, 210, "center");
  pdf.setFontSize(22);
  pdf.setFont("FrutigerLTArabic55Roman", "roman");

  const text1 = "يمنح برنامج التقييم الذاتي هذه الشهادة";
  const xText1 = width / 2;
  processArabicText(text1, xText1, 272, "center");

  pdf.setFont("FrutigerLTArabic55Roman"); 
  const text2 =   replaceParentheses(`لـ${establishmentName} (${establishmentLaborOfficeId} - ${establishmentSequenceId})`);
  const xText2 = width / 2;
  processArabicText(text2, xText2, 324, "center");

  pdf.setFont("FrutigerLTArabic55Roman", "roman");
  const textPart1 = "وذلك لإنهائها برنامج ";
  const textPart2 = `${trainingName}`;
  const textPart3 = " التدريبي ";


  const widthPart1 = pdf.getTextDimensions(textPart1);
  const widthPart2 = pdf.getTextDimensions(textPart2);
  const widthPart3 = pdf.getTextDimensions(textPart3);
  
  const totalWidth = widthPart1.w + widthPart2.w + widthPart3.w;
  
  let startX2 = (width - totalWidth) / 2 ; 

  let x = width - startX2; // Right-aligned starting position

  pdf.setFont("FrutigerLTArabic55Roman", "roman");
  processArabicText(textPart1, x, 374 );

    x = x - widthPart1.w; 

  pdf.setFont("FrutigerLTArabic65Bold", "bold");
  processArabicText(textPart2, x, 374);

    x = x - widthPart2.w; 

  pdf.setFont("FrutigerLTArabic55Roman", "roman");
  processArabicText(textPart3, x, 374);

  const qrImage = document.getElementById("qrCodeTraining");
  const dataURL = qrImage.toDataURL();
  pdf.addImage(
    dataURL,
    "PNG",
    60,
    height - 110,
    50,
    50,
    "qrCodeTraining",
    "FAST"
  );

  pdf.setFontSize(8);

  pdf.setFont("FrutigerLTArabic55Roman", "roman");
  const qrText = "التحقق من صحة الشهادة";
  processArabicText(qrText, 85, height - 50, "center");

  pdf.save("شهادة حضور.pdf");
}
