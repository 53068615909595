import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as EstablishmentProfileActions from "../../actions/establishmentProfileActions";
import EstablishmentTrainingCard from "./EstablishmentTrainingCard";
import chunk from "lodash/chunk";
import isEqual from "lodash/isEqual";
import { Row } from "reactstrap";
import { TRAINING } from "../../common/constants/paymentStatuses";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import { STATUS_REGISTER } from "../../common/constants/trainingStatuses";
import Loader from "../Loader/Loader";
import { createTraining } from "../../actions/trainingActions";

class EstablishmentTrainings extends React.Component {
  constructor(props) {
    super(props);

    this.step = 3;

    this.state = {
      trainingsList: this.props.trainings || [],
      limit: this.step,
      tabSelected: 0,
      isLoading: false,
    };

    this.onLoadMore = this.onLoadMore.bind(this);
    this.redirectToEditTraining = this.redirectToEditTraining.bind(this);
    this.showTrainingDetails = this.showTrainingDetails.bind(this);
    this.redirectToSelfAssessmentPayment =
      this.redirectToSelfAssessmentPayment.bind(this);
    // this.addNewTraining = this.addNewTraining.bind(this);
    this.checkout = this.checkout.bind(this);
    this.onClickPayment = this.onClickPayment.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.trainings, nextProps.trainings)) {
      this.setState({ trainingsList: nextProps.trainings });
    }
  }

  redirectToEditTraining(training_id, status) {
    const establishmentProfile = this.props.establishmentProfile;
    this.props.history.push(
      `/establishmentTrainingRegister/${establishmentProfile.id}/${training_id}/${status}`
    );
  }

  checkout(url) {
    window.location.href = url;
  }

  // addNewTraining(form_type = 0) {
  //   const establishmentProfile = this.props.establishmentProfile;

  //   this.props.history.push(
  //     `/establishmentTraining/${establishmentProfile.id}/${form_type}`
  //   );
  // }

  showTrainingDetails(training_id, status = STATUS_REGISTER) {
    const { establishmentProfile, isAvailableCourses } = this.props;

    this.props.history.push(
      `/establishmentTraining/${establishmentProfile.id}/${training_id}/${status}`
    );
  }

  redirectToSelfAssessmentPayment(training_id, withoutResult = true) {
    const establishmentProfile = this.props.establishmentProfile;
    this.props.history.push(
      `/establishmentAssessmentPayment/${training_id}/${training_id}/${establishmentProfile.id}/0/${withoutResult}/${TRAINING}`
    );
  }

  onLoadMore() {
    this.setState((state, props) => {
      const limit = state.limit + this.step;
      return {
        limit,
        trainingsList: props.trainings.slice(0, limit),
      };
    });
  }

  getRows(totalItems) {
    return chunk(totalItems, this.step);
  }
  onClickPayment(training) {
    const { history, establishmentProfile } = this.props;
    this.setState({ isLoading: true });
    const trainingData = {
      status: training.status,
      id: training.subject_id,
      amount: training.subject_amount,
      vat: training.subject_vat,
      name: training.subject_name,
    };
    this.props
      .onCreateTraining(establishmentProfile.id, training, trainingData)
      .then((val) => {
        this.setState({ isLoading: false });
        history.push(
          `/checkout/${val.training.id}/${TRAINING}/${val.sadad_number}/${val.payment_resp_bill_number}`
        );
      });
  }
  render() {
    const { trainingsList, isLoading } = this.state;
    const { history } = this.props;

    return (
      <div>
        {isLoading ? (
          <div className="loader-wrapper">
            <Loader />
          </div>
        ) : (
          <div className="establishment-profile-card">
            <Row
              style={{
                marginTop: "8px",
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="establishmnet-assessments-wrap establishment-training-cards-wrapper ">
                  {trainingsList.map((training, i) => (
                    <div key={i}>
                      <EstablishmentTrainingCard
                        trainingIndex={i}
                        isAvailableCourses={this.props.isAvailableCourses}
                        training={training}
                        redirectToEditTraining={this.redirectToEditTraining}
                        checkout={this.checkout}
                        showTrainingDetails={this.showTrainingDetails}
                        redirectToSelfAssessmentPayment={
                          this.redirectToSelfAssessmentPayment
                        }
                        onClickPayment={this.onClickPayment}
                        history={history}
                      />
                    </div>
                  ))}
                </div>
                {this.props.trainings.length === 0 && (
                  <Row style={{ margin: "auto" }}>
                    <Text Tag="span" textType="p5">
                      لا يوجد محتوى تدريبي حاليا
                    </Text>
                  </Row>
                )}
              </div>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

EstablishmentTrainings.propTypes = {
  actions: PropTypes.object.isRequired,
  trainings: PropTypes.array.isRequired,
  establishmentProfile: PropTypes.object,
  history: PropTypes.object.isRequired,
  isAvailableCourses: PropTypes.bool,
  onCreateTraining: PropTypes.func,
};
EstablishmentTrainings.defaultProps = {
  isAvailableCourses: false,
};
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(EstablishmentProfileActions, dispatch),
    onCreateTraining: (establishment_id, training) =>
      dispatch(createTraining(establishment_id, training)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstablishmentTrainings);
