


import * as types from '../../actions/actionTypes';
import initialState from '../initialState';


export default function assessment(state = initialState.assessment, action) {
    switch (action.type) {
        case types.LOAD_ASSESSMENTS_SUCCESS:
            return Object.assign({}, state, {
                assessments: action.assessments
            });

      case types.LOAD_SELECTEDASSESSMENTS_SUCCESS:
        return Object.assign({}, state, {
          selectedAssessments: action.assessments
        });

        case types.CREATE_ASSESSMENT_SUCCESS:
            return Object.assign({}, state, {
                assessments: [...state.assessments,
                    Object.assign({}, action.assessment)]
            });

        case types.UPDATE_ASSESSMENT_SUCCESS:
            return Object.assign({}, state, {
                assessments: [...state.assessments.filter(assessment => assessment.id !== action.assessment.id),
                    Object.assign({}, action.assessment)]
            });

        default:
            return state;
    }
}
