export default [
    // معايير عامة للعاملين
    {
            id: 1,
            label: 'عقد العمل',
            parentId: 1,
            description: `يحتوي هذا المعيار على الضوابط والاشتراطات اللازم اتباعها في آلية التعاقد بين العامل وصاحب العمل`,
        },
    {
            id: 2,
            label: 'التدريب والتأهيل',
            parentId: 1,
            description: `يجب على صاحب العمل توفير التدريب والتأهيل للعاملين السعوديين لديه واحلالهم تدريجيا في الأعمال التي يقوم بها غير السعوديين، ويتضمن هذا المعيار الاشتراطات اللازم اتباعها عند كتابة عقود التأهيل والتدريب`,
        },
    {
            id: 3,
            label: 'ساعات العمل وفترات الراحة',
            parentId: 1,
            description: `ويوضح المعيار ساعات العمل وايامه وايام الراحة واحكامها`,
        },
    {
            id: 4,
            label: 'الإجازات',
            parentId: 1,
            description: `يفصل في هذا المعيار الاجازات المستحقة للعاملين وضوابطها وشروطها واحكامها`,
        },
    {
            id: 5,
            label: 'الأجور',
            parentId: 1,
            description: `يوضح المعيار الفترات والمواعيد التي يتم دفع أجور العاملين فيها، وينبغي على أصحاب العمل دفع أجور العاملين في حسابات العمال عن طريق البنوك المعتمدة ورفع ملف حماية الأجور بشكل شهري`,
        },
    {
            id: 6,
            label: 'مكافأة نهاية الخدمة',
            parentId: 1,
            description: `يجب على صاحب العمل أن يدفع الى العامل مكافأة نهاية الخدمة عند انتهاء علاقة العمل، ويوضح المعيار احكام مكافأة نهاية الخدمة وطريقة احتسابها`,
        },
// معايير عامة للمشآت
    {
        id: 7,
        label: 'الأحكام العامة',
        parentId: 2,
        description: 'يتضمن هذا المعيار الاحكام العامة التي ينبغي للمنشأة الالتزام بها مثل استعمال اللغة العربية وإعداد لائحة تنظيم العمل.',
    },
    {
        id: 8,
        label: 'الواجبات وقواعد التأديب',
        parentId: 2,
        description: 'الواجبات اللازم اتباعها من قبل صاحب العمل والعاملين وقواعد التأديب التي تضمن حقوق الطرفين',
    },
    {
        id: 9,
        label: `قواعد الاستقدام وتقديم الخدمات العمالية للغير`,
        parentId: 2,
        description: `الواجبات والاشتراطات والضوابط لممارسة نشاط توظيف السعوديين ونشاط الاستقدام وتقديم الخدمات العمالية للغير، مثل وجوب الحصول على الترخيص المناسب لنوع النشاط والالتزام بنماذج العقود الموحدة التي تضعها الوزارة `,
    },
    {
        id: 10,
        label: 'الوقاية من مخاطر العمل',
        parentId: 2,
        description: `وتتضمن الاحكام العامة للحفاظ على سلامة العاملين وتوفير بيئة عمل آمنة لهم، مثل النظافة العامة لمقر العمل واتخاذ الاحتياطات اللازمة للوقاية من الحرائق والزام العاملين باتخاذ التدابير الواقائية لحمايتهم من اخطار مهنتهم`,
    },
     {
        id: 11,
        label: 'الخدمات الصحية والاجتماعية',
        parentId: 2,
        description: `يجب على صاحب العمل توفير الرعاية الصحية للعامل وأسرته وذلك بحسب نظام التأمين التعاوني الصحي ، وتوفير الخدمات الصحية والاجتماعية مثل توفير المساكن والمخيمات والوجبات الغذائية للعمال الذين يؤدون عملا في أماكن بعيدة عن العمران`,
    },
// معايير خاصة للعاملين 
    {
        id: 12,
        label: 'توظيف غير السعوديين',
        parentId: 3,
        description: `أحكام توظيف غير السعوديين، مثل اشتراط حصول العامل على رخصة عمل، وعدم ترك العامل يعمل في غير المهنة المدونة في رخصة عمله أو تشغيل غير السعوديين في مهن مقصورة على السعوديين`,
    },
    {
        id: 13,
        label: 'توظيف المعوقين',
        parentId: 3,
        description: `ينبغي على كل صاحب عمل لديه 25 عامل فأكثر أن يشغل 4% على الأقل من ذوي الإعاقة ضمن عمال منشأته، اذا كانت طبيعة العمل لديه تمكنه من تشغيلهم`,
    },
    {
        id: 14,
        label: 'تشغيل النساء',
        parentId: 3,
        description: `يوضح المعيار الاشتراطات التي ينبغي على صاحب العمل اتباعها عند تشغيل النساء في المنشأة`,
    },
     {
        id: 15,
        label: 'تشغيل الأحداث',
        parentId: 3,
        description: `يوضح المعيار الاشتراطات التي ينبغي على صاحب العمل اتباعها عند تشغيل الأحداث في مقر العمل`,
    },
    {
        id: 16,
        label: 'عقد العمل البحري',
        parentId: 3,
        description: `الواجبات اللازم اتباعها عند العمل على ظهر السفينة وآلية ابرام العقود مع العاملين عليها`,
    },
    {
        id: 17,
        label: 'العمل في المناجم والمحاجر',
        parentId: 3,
        description: `الواجبات اللازم اتباعها عند العمل في المناجم والمحاجر`,
    }, 
];