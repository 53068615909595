// @ts-check

import {
  DETAILEDREPORT,
  FASTTRACK,
  GRACE_PERIOD,
  RE_ASSESSMENT,
} from "../../../common/constants/paymentStatuses";
export const AdditionalServicesCardsList = [
  {
    title: "المسار المميز",
    icon: "/img/AssessmentCertificate.svg",
    desc: "هي خدمة تسمح للمنشأة بالحصول على شهادة الامتثال والتي يمكن اعتبارها كإثبات معتمد من قبل وزارة الموارد البشرية والتنمية الاجتماعية بأن المنشأة ملتزمة بالمعايير المحددة.",
    name: "special_track",
  },
  {
    title: "التقييم الموحد",
    icon: "/img/UnifiedAssessment.svg",
    desc: "هي خدمة تتيح للمنشأة إجراء التقييم بشكل أسرع بحيث يتم توحيد نموذج التقييم لجميع المنشآت المماثلة في النشاط الاقتصادي بحسب اختيار العميل.",
    name: "unified_assessment",
  },
  {
    title: "التقرير المفصل",
    icon: "/img/DetailedReport.svg",
    desc: "يوضح التقرير المفصل الوضع الحالي للمنشأة بما في ذلك نسبة الامتثال والخطوات الإرشادية الواجب عملها لرفع هذه النسبة لتفادي الغرامات المالية.",
    //  `/establishmentAssessmentResult/${duration.id}/${duration.assessment.id}/${profileId}/${duration.assessment_version}` PAID
    link: "",
    name: "detailed_report",
  },
  {
    title: "إعادة التقييم",
    icon: "/img/ReAssessment.svg",
    desc: "تتيح هذه الخدمة للمنشأة إمكانية إعادة التقييم بعد قيامها بتصحيح المخالفات ورفع نسبة الامتثال.",
    link: "",
    name: "reassessment",
  },
  {
    title: "المهلة التصحيحية",
    icon: "/img/Clock.svg",
    desc: "تتيح هذه الخدمة للمنشأة الحصول على مهلة تصحيحية مدتها شهرين بهدف تأجيل الزيارات التفتيشية التابعة للوزارة.",
    link: "",
    name: "grace_period",
  },
  {
    title: "التفتيش",
    icon: "/img/Inspection.svg",
    desc: "تمكنك هذه الخدمة من طلب تفتيش ميداني لتقييم المنشأة، حيث تتم جدولة الزيارة بحسب الفترة المتاحة، علمًا أنه لا يمكن طلب هذه الخدمة بعد طلب خدمة إعادة التقييم أو خدمة المسار المميز.",
    link: "",
    name: "inspection",
  },
];

const getLink = ({ name, profileId, current }) => {
  if (name === "unified_assessment") {
    // no order for that, will return the link directly
    return `/establishmentUnifiedAssessment/${current.period_id}/${current.assessment_id}/${profileId}/${current.assessment_version}/${current.period_assessment_id}`;
    /* const withoutResult = true;
      if (
       !isReturnedAssessment &&
       duration.state == STATUS_SUBMITTED &&
       duration.unified_assessment_status ===
         UNIFIED_ASSESSMENT_STATUS_NOT_PAID &&
       ![
         INSPECTION_REQUEST_STATUS_NEW,
         INSPECTION_REQUEST_STATUS_INITIATED,
         INSPECTION_REQUEST_STATUS_ON_PROGRESS,
         INSPECTION_REQUEST_STATUS_COMPLETED,
       ].includes(duration.inspection_assessment_status.String)
     ) {
       return `/establishmentAssessmentPayment/${duration.id}/${duration.assessment.id}/${profileId}/${duration.assessment_version}/${withoutResult}/${UNIFIED_ASSESSMENT}/${duration.period_assessment_id}`;
     } else {
       return `/establishmentUnifiedAssessment/${duration.id}/${duration.assessment.id}/${profileId}/${duration.assessment_version}/${duration.period_assessment_id}`;
     }*/
  }

  // Detailed Report
  if (name === "detailed_report") {
    if (current.is_detailed_report) {
      return `/establishmentAssessmentResult/${current.period_id}/${current.assessment_id}/${profileId}/${current.assessment_version}`;
    } else if (
      current.have_active_period &&
      (current?.assessment_status === "Completed" ||
        current?.assessment_status === "UnderGracePeriod" ||
        current?.assessment_status === "UnderInspection")
    ) {
      const withoutResult = true;
      return `/establishmentAssessmentPayment/${current.period_id}/${current.assessment_id}/${profileId}/${current.assessment_version}/${withoutResult}/${DETAILEDREPORT}/${current.period_assessment_id}/${current.assessment_type}`;
    } else {
      return "invisible";
    }
  }

  if (name === "grace_period") {
    if (
      current.have_active_period &&
      (current?.assessment_status === "Completed" ||
        current?.assessment_status === "UnderGracePeriod" ||
        current?.assessment_status === "UnderInspection")
    ) {
      const withoutResult = true;
      return `/establishmentAssessmentPayment/${current.period_id}/${current.assessment_id}/${profileId}/${current.assessment_version}/${withoutResult}/${GRACE_PERIOD}/${current.period_assessment_id}`;
    } else {
      return "invisible";
    }
  }

  if (name === "special_track") {
    if (
      current.have_active_period &&
      !current.is_fast_track &&
      (current?.assessment_status === "Completed" ||
        current?.assessment_status === "UnderGracePeriod" ||
        current?.assessment_status === "UnderInspection")
    ) {
      const withoutResult = false;
      return `/establishmentAssessmentPayment/${current.period_id}/${current.assessment_id}/${profileId}/${current.assessment_version}/${withoutResult}/${FASTTRACK}/${current.period_assessment_id}`;
    } else if (
      current.have_active_period &&
      current.is_fast_track &&
      (current?.assessment_status === "Completed" ||
        current?.assessment_status === "UnderGracePeriod" ||
        current?.assessment_status === "UnderInspection")
    ) {
      return `/establishmentFastTrackPage`;
    } else {
      return "invisible";
    }
  }

  if (name === "reassessment") {
    if (!current.is_reassessment) {
      const withoutResult = false;
      return `/establishmentAssessmentPayment/${current.period_id}/${current.assessment_id}/${profileId}/${current.assessment_version}/${withoutResult}/${RE_ASSESSMENT}/${current.period_assessment_id}`;
    } else if (current.is_reassessment) {
      return "reassessment";
    }
  }
};

export const filterCards = ({ estProfile, isReturnedAssessment, current }) => {
  const features = [];
  const ordered = [];
  AdditionalServicesCardsList.forEach((card) => {
    if (card.name === "special_track") {
      if (current.is_fast_track) {
        const link = getLink({
          name: card.name,
          profileId: estProfile?.id,
          current,
        });
        ordered.push({ ...card, link, isPurchased: true });
      } else {
        const link = getLink({
          name: card.name,
          profileId: estProfile?.id,
          current,
        });
        features.push({ ...card, link });
      }
    } else if (card.name === "grace_period") {
      if (current.is_grace_period) {
        const link = getLink({
          name: card.name,
          profileId: estProfile?.id,
          current,
        });
        ordered.push({ ...card, link, isPurchased: true });
      }
    } else if (card.name === "detailed_report") {
      if (current.is_detailed_report) {
        const link = getLink({
          ...card,
          profileId: estProfile?.id,
          current,
        });
        ordered.push({ ...card, isPurchased: true, link });
      } else {
        const link = getLink({
          ...card,
          profileId: estProfile?.id,
          current,
        });
        features.push({ ...card, link });
      }
    } else if (card.name === "inspection") {
      // Only_Inspection means if didn't pay for additional assessment and has requested inspection and not completed yet
      // Only_Assessment means if didn't pay for additional assessment and has requested inspection and completed
      // Already_Requested means already requested inspection in current period
      if (
        current.inspection_status === "Only_Assessment" ||
        current.inspection_status === "Only_Inspection" ||
        current.inspection_status === "Already_Requested"
      ) {
        ordered.push({ ...card, isPurchased: true });
      } else {
        if (current.inspection_status === "Both") {
          // both means if didn't pay for additional assessment and has not requested inspection before
          features.push({ ...card, link: "create_inspection_request" });
        } else if (current.inspection_status === "Disable") {
          features.push({ ...card, link: "Disable" });
        } else if (
          current?.assessment_status !== "Completed" ||
          current?.assessment_status !== "UnderGracePeriod" ||
          current?.assessment_status !== "UnderInspection"
        ) {
          features.push({ ...card, link: "invisible" });
        } else {
          features.push({ ...card, link: "invisible" });
        }
      }
    } else if (card.name === "reassessment") {
      if (
        current.is_reassessment &&
        current.assessment_type === RE_ASSESSMENT
      ) {
        const link = getLink({
          ...card,
          profileId: estProfile?.id,
          current,
        });
        ordered.push({ ...card, link, isPurchased: true });
      } else {
        if (
          current.have_active_period &&
          (current?.assessment_status === "Completed" ||
            current?.assessment_status === "UnderGracePeriod" ||
            current?.assessment_status === "UnderInspection")
        ) {
          const link = getLink({
            ...card,
            profileId: estProfile?.id,
            current,
          });
          features.push({ ...card, link });
        } else {
          features.push({ ...card, link: "invisible" });
        }
      }
    } else {
      if (card?.name === "unified_assessment") {
        const link = getLink({
          ...card,
          profileId: estProfile?.id,
          current,
        });
        features.push({ ...card, link });
      }
      //  else {
      //   features.push(card);
      // }
    }
  });
  return { ordered, features };
};
