import {
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_FAILURE,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_SUCCESS,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_SUCCESS,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_FAILURE,
  CHANGE_RESULT_FILTER,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_SUCCESS,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_FAILURE,
} from "../actions/actionTypes";
import initialState from "./initialState";

export default function establishmentSelfAssessmentResult(
  state = initialState.establishmentSelfAssessmentResult,
  action
) {
  switch (action.type) {
    case CHANGE_RESULT_FILTER: {
      return {
        ...state,
        selectedResultFilterValue: {
          ...state.selectedResultFilterValue,
          ...action.payload,
        },
      };
    }
    case FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT:
      return {
        ...state,
        establishmentSelfAssessmentResult: null,
        establishmentSelfAssessmentResultFetching: true,
        establishmentSelfAssessmentResultFetchingError: null,
      };

    case FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_FAILURE:
      return {
        ...state,
        establishmentSelfAssessmentResultFetching: false,
        establishmentSelfAssessmentResultFetchingError: action.payload.error,
      };

    case FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_SUCCESS:
      return {
        ...state,
        establishmentSelfAssessmentResult:
          action.payload.establishmentSelfAssessmentResult,
        establishmentSelfAssessmentResultFetching: false,
      };
    case FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT:
      return {
        ...state,
        establishmentSelfAssessmentFastTrackResult: null,
        establishmentSelfAssessmentFastTrackResultFetching: true,
        establishmentSelfAssessmentFastTrackResultFetchingError: null,
      };

    case FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_FAILURE:
      return {
        ...state,
        establishmentSelfAssessmentFastTrackResultFetching: false,
        establishmentSelfAssessmentFastTrackResultFetchingError:
          action.payload.error,
      };

    case FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_SUCCESS:
      return {
        ...state,
        establishmentSelfAssessmentFastTrackResult:
          action.payload.establishmentSelfAssessmentResult,
        establishmentSelfAssessmentFastTrackResultFetching: false,
      };

    case FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION:
      return {
        ...state,
        establishmentSelfAssessmentDurationFetching: true,
      };

    case FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_SUCCESS:
      return {
        ...state,
        establishmentSelfAssessmentDuration: action.payload,
        establishmentSelfAssessmentDurationFetching: false,
      };

    case FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_FAILURE:
      return {
        ...state,
        establishmentSelfAssessmentDurationFetching: false,
        establishmentSelfAssessmentDurationFetchingError: true,
      };

    default:
      return state;
  }
}
