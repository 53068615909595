import React from "react";
import { StyledText, clssSizes } from "./styles";
import { twMerge } from "../../tw-merge";

export const Text = ({
  size = "base",
  as = "p",
  className,
  children,
  style,
}) => {
  const clss = twMerge(
    "text-ims-dark_gray font-effra",
    clssSizes[size],
    className
  );
  return (
    <StyledText as={as} className={clss} style={style}>
      {children}
    </StyledText>
  );
};
