import React from "react";
import { NavLink } from "reactstrap";
import PropTypes from "prop-types";
import "./header.css";
const { bool } = PropTypes;

const HeaderLogo = ({ isLogin, center = false }) => (
  <NavLink
    className={`header-logo ${center ? "header-logo-center" : ""}`}
    href={`/#/${isLogin ? "establishmentProfile" : "home"}`}
  >
    <img
      src={"img/IMS-MHRSD-logo.svg"}
      className="header-logo-image "
      alt="mlsd logo"
    />
  </NavLink>
);

HeaderLogo.propTypes = {
  isLogin: PropTypes.bool,
  center: PropTypes.bool,
};

export default HeaderLogo;
