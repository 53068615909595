import {
  SA_FORM_SET_PERIOD,
  SA_FORM_UPDATE,
  SA_FORM_UPDATE_LEGAL_AGREEMENT,
  SA_FORM_PERIOD_LOADING,
  SA_FORM_PERIOD_STOP_LOADING,
  SA_FORM_RESET_PERIOD,
} from "../actions/actionTypes";
import cloneDeep from "lodash/cloneDeep";
import initialState from "./initialState";

export default function (state = initialState.SAform, action) {
  function updateItem(item) {
    if (item.id !== action.payload.val.id) {
      return item;
    } else {
      return cloneDeep({
        ...item,
        ...action.payload.val,
      });
    }
  }

  switch (action.type) {
    case SA_FORM_RESET_PERIOD: {
      return Object.assign({}, initialState.SAform);
    }
    case SA_FORM_SET_PERIOD: {
      const {
        id,
        assessment_version,
        assessment_type,
        is_open_reassessment,
        assessment,
        period_assessment_id,
        isReturned,
      } = action.payload;
      let standardCategories = [],
        attachments = [],
        caliberCategories = [],
        defaultCaliberCategoriesAnswers = [],
        calibers = [];

      if (assessment?.qiwa_calibers?.length > 0) {
        standardCategories.push({
          id: "qiwa_calibers",
          secondary_id: "00000000-0000-0000-0000-000000000000",
          name: "البنود المؤتمتة",
          order: 0,
          completion_percentage: 100,
          is_deleted: false,
          is_optional: false,
          is_updated: false,
          weight: 0,
        });
      }
      assessment?.standard_categories?.forEach?.((standardCategory) => {
        standardCategories?.push?.(cloneDeep(standardCategory));
        standardCategory?.caliber_categories?.forEach?.((caliberCategory) => {
          caliberCategories?.push?.({
            ...cloneDeep(caliberCategory),
            standardCategoryId: standardCategory.id,
          });
          caliberCategory?.labels?.forEach?.((label) =>
            label?.calibers?.forEach?.((caliber) => {
              calibers?.push?.({
                ...cloneDeep(caliber),
                caliberCategoryId: caliberCategory?.id,
                is_optional: standardCategory?.is_optional,
                answer: isReturned
                  ? caliber?.new_answer
                  : standardCategory?.is_optional
                  ? "empty"
                  : caliber?.answer,
              });
              if (caliber?.attachments) {
                caliber?.attachments?.forEach?.((attachment) => {
                  const clonedAttachment = isReturned
                    ? {
                        id: attachment?.id,
                        name: attachment?.name,
                        mandatory: attachment?.mandatory,
                        submission_attachment_id:
                          attachment?.submission_attachment_id,
                        assessment_version: attachment?.assessment_version,
                      }
                    : cloneDeep(attachment);
                  attachments.push({
                    ...clonedAttachment,
                    caliberId: caliber?.id,
                  });
                });
              }
            })
          );
        });
      });

      if (defaultCaliberCategoriesAnswers?.length === 0) {
        caliberCategories?.forEach?.((caliberCategory) => {
          caliberCategory?.labels?.forEach((label) => {
            label?.calibers?.forEach((caliber) => {
              defaultCaliberCategoriesAnswers?.push?.({
                id: caliber?.id,
                answer: caliber?.answer,
                attachments: caliber?.attachments,
              });
            });
          });
        });
      }
      const result = {
        ...state,
        periodId: id ?? assessment?.id,
        assessmentId: assessment?.id,
        assessmentVersion: assessment_version,
        assessmentType: assessment_type,
        isOpenReassessment: is_open_reassessment,
        PeriodAssessmentId: period_assessment_id,
        legalAgreementChecked: false, // tmp
        standardCategories,
        caliberCategories,
        calibers,
        attachments,
        defaultCaliberCategoriesAnswers,
        qiwa_calibers: assessment?.qiwa_calibers,
      };
      return result;
    }
    case SA_FORM_UPDATE_LEGAL_AGREEMENT: {
      return {
        ...state,
        legalAgreementChecked: action.payload,
      };
    }
    case SA_FORM_UPDATE: {
      return {
        ...state,
        [action.payload.key]: state[action.payload.key].map(updateItem),
      };
    }
    case SA_FORM_PERIOD_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SA_FORM_PERIOD_STOP_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
