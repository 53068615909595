export const CATEGORY_DOCUMENTS = "documents";
export const CATEGORY_SYSTEMS = "systems";
export const CATEGORY_REGULATIONS = "regulations";
export const CATEGORY_DECISIONS = "decisions";
export const CATEGORY_STATISTICS = "statistics";

export const FILES_CATEGORY = {
  [CATEGORY_SYSTEMS]: "الأنظمة",
  [CATEGORY_DOCUMENTS]: "مكتبة الوثائق",
  [CATEGORY_REGULATIONS]: "اللوائح",
  [CATEGORY_DECISIONS]: "القرارات",
  [CATEGORY_STATISTICS]: "إحصائيات",
};