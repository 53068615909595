import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchEstablishmentSelfAssessmentResult } from "../../actions/establishmentSelfAssessmentResultActions";
import {
  loadPaymentBills,
  submitPayment,
  submitTrainingPayment,
} from "../../actions/establishmentProfileActions";

import isEqual from "lodash/isEqual";

import UnifiedAssessment from "../../views/Pages/UnifedAssessment/UnifiedAssessment";

import "./styles.css";
import {
  FASTTRACK,
  RE_ASSESSMENT,
  TRAINING,
  DETAILEDREPORT,
  UNIFIED_ASSESSMENT,
} from "../../common/constants/paymentStatuses";
import PageWrapper from "../PageWrapper/PageWrapper";

let emptyEstablishmentProfile = {
  establishment_id: "",
  establishment_name: "",
  english_name: "",
  labor_office_id: "",
  sequence_id: "",
  establishment_status_id: "",
  establishment_status: "",
  main_economic_activity_id: "",
  sub_economic_activity_id: "",
  economic_activity_id: "",
  economic_activity_name: "",
  color_id: "",
  nitaqat_color: "",
  size_id: "",
  size_name: "",
  color_code: "",
  saudis: "",
  foreigners: "",
  total_laborer_count: "",
};

const emptyEstablishmentSelfAssessmentResult = {
  standard_categories: [],
  assessment: {
    standard_categories: [],
  },
  standard_categories_scores: [],
  final_result: 0,
  max_penalty: 0,
  min_penalty: 0,
  period_details: {
    name: "",
    start_date: null,
    end_date: null,
  },
  user: {
    id: "",
    name_ar: { String: "" },
    name_en: { String: "" },
    phone_number: { String: "" },
    email: { String: "" },
    id_number: "",
  },
  questions: [],
  violations_count: 0,
  timestamps: {
    assigned_at: {
      String: "",
      Valid: false,
    },
    first_opened_at: {
      String: "",
      Valid: false,
    },
    submitted_at: {
      String: "",
      Valid: false,
    },
  },
};

class EstablishmentUnifiedAssessmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      establishmentProfile: { ...this.props.establishmentProfile },
      establishmentSelfAssessmentResult: {
        ...emptyEstablishmentSelfAssessmentResult,
      },
      period_id: "",
      assessment_id: "",
      collapsed: true,
      serviceId: "",
      DoYouWantToPay: false,
    };
    this.redirectToSelfAssessmentResult =
      this.redirectToSelfAssessmentResult.bind(this);
    this.checkOut = this.checkOut.bind(this);
    this.handlePaymentClick = this.handlePaymentClick.bind(this);
  }

  componentDidMount() {
    if (
      this.props.match.params.periodId &&
      this.props.match.params.assessmentId &&
      this.props.match.params.establishmentId &&
      this.props.match.params.assessment_version &&
      this.props.match.params.period_assessment_id &&
      this.props.match.params.withoutResult
    ) {
      if (this.props.match.params.withoutResult === "false") {
        this.props.fetchEstablishmentSelfAssessmentResult(
          this.props.match.params.periodId,
          this.props.match.params.assessmentId,
          this.props.match.params.establishmentId,
          this.props.match.params.assessment_version
        );
      }
      if (
        this.props.match.params.service_type === RE_ASSESSMENT ||
        this.props.match.params.service_type === FASTTRACK ||
        this.props.match.params.service_type === DETAILEDREPORT ||
        this.props.match.params.service_type === UNIFIED_ASSESSMENT
      ) {
        this.props.fetchAssessmentBills(
          this.props.match.params.establishmentId,
          this.props.match.params.periodId,
          this.props.match.params.assessmentId,
          this.props.match.params.assessment_version,
          this.props.match.params.service_type
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.establishmentProfile, this.props.establishmentProfile)
    ) {
      this.setState({
        establishmentProfile:
          this.props.establishmentProfile || emptyEstablishmentProfile,
      });
    }
    if (
      !isEqual(
        prevProps.establishmentSelfAssessmentResult,
        this.props.establishmentSelfAssessmentResult
      )
    ) {
      this.setState({
        establishmentSelfAssessmentResult:
          this.props.establishmentSelfAssessmentResult ||
          emptyEstablishmentSelfAssessmentResult,
      });
    }
  }

  redirectToSelfAssessmentResult() {
    const { periodId, assessmentId, establishmentId, assessment_version } =
      this.props.match.params;
    this.props.history.push(
      `/establishmentAssessmentResult/${periodId}/${assessmentId}/${establishmentId}/${assessment_version}`
    );
  }

  handlePaymentClick() {
    this.setState({ DoYouWantToPay: !this.state.DoYouWantToPay });
  }

  checkOut(url) {
    window.location.href = url;
  }

  render() {
    const {
      establishmentSelfAssessmentResult,
      establishmentProfile,
      collapsed,
    } = this.state;
    const { services, bills, match, location } = this.props;
    const { service_type } = this.props.match.params;
    const serviceList = services
      ? services.filter((service) => service.type === service_type)
      : null;
    const {
      periodId,
      assessmentId,
      establishmentId,
      assessment_version,
      withoutResult,
      period_assessment_id,
    } = match.params;
    const isBranchesSpecialTrack =
      location && location.isBranchesSpecialTrack ? true : false;
    return (
      <PageWrapper withBreadcrumbs>
        <UnifiedAssessment
          periodId={periodId}
          periodAssessmentId={period_assessment_id}
          assessmentId={assessmentId}
          establishmentId={establishmentId}
          assessmentVersion={assessment_version}
          withoutResult={!!withoutResult}
          isBranchesSpecialTrack={isBranchesSpecialTrack}
        />
      </PageWrapper>
    );
  }
}

EstablishmentUnifiedAssessmentPage.propTypes = {
  establishmentProfile: PropTypes.object,
  establishmentSelfAssessmentResult: PropTypes.object,
  establishmentSelfAssessmentResultFetchingError: PropTypes.object,
  services: PropTypes.array,
  bills: PropTypes.array,
  submitPayment: PropTypes.func,
  fetchAssessmentBills: PropTypes.func,
  ajaxStatus: PropTypes.object,
  history: PropTypes.object.isRequired,
};

EstablishmentUnifiedAssessmentPage.defaultProps = {
  establishmentProfile: null,
  establishmentSelfAssessmentResult: null,
  establishmentSelfAssessmentResultFetchingError: null,
};

const mapStateToProps = (state) => ({
  establishmentProfile: state.establishmentSelfAssessmentResult
    .establishmentSelfAssessmentResult
    ? state.establishmentSelfAssessmentResult.establishmentSelfAssessmentResult
        .establishment
    : emptyEstablishmentProfile,
  establishmentSelfAssessmentResult:
    state.establishmentSelfAssessmentResult.establishmentSelfAssessmentResult,
  establishmentSelfAssessmentResultFetchingError:
    state.establishmentSelfAssessmentResult
      .establishmentSelfAssessmentResultFetchingError,
  services: state.payment.reopen_assessment_services,
  bills: state.payment.bills,
  ajaxStatus: state.ajaxStatus,
});

const mapDispatchToProps = {
  // fetchEstablishmentProfile: fetchEstablishmentProfile,
  fetchEstablishmentSelfAssessmentResult:
    fetchEstablishmentSelfAssessmentResult,
  submitPayment: submitPayment,
  submitTrainingPayment: submitTrainingPayment,
  fetchAssessmentBills: loadPaymentBills,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstablishmentUnifiedAssessmentPage);
