import { useSpring, animated } from "react-spring";
import React, { useState, useEffect, useRef } from "react";
function useIntersectionObserver(
  elementRef,
  { threshold = 0, root = null, rootMargin = "0%", freezeOnceVisible = false }
) {
  const [entry, setEntry] = useState();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]) => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
}
function useAnimatedPath({ isIntersecting, delay }) {
  const [length, setLength] = useState(null);
  const animatedStyle = useSpring({
    delay: isIntersecting ? delay : 0,
    config: { duration: 600 },
    from: { strokeDashoffset: 0, strokeDasharray: 0 },
    to: {
      strokeDashoffset: isIntersecting ? 0 : length,
      strokeDasharray: length,
    },
  });

  return {
    style: animatedStyle,
    ref: (ref) => {
      if (ref) {
        setLength(ref.getTotalLength());
      }
    },
  };
}

function Checkmark({ isIntersecting }) {
  const animationProps = useAnimatedPath({
    isIntersecting,
    delay: 2800,
  });

  return (
    <animated.path
      {...animationProps}
      stroke="#9a8e8e"
      strokeWidth="8"
      transform="translate(17933.443 -14529.296) rotate(-90)"
      d="M-14529.3-17710.291v-37.875a111.911,111.911,0,0,0-2.413-23.6c-1.363-7.027-8.275-20.9-21.84-25.307-24.638-9.09-23.037-47.279-13.242-59.369,17.725-20.055,27.623-67.719-27.131-67.027-9.716-.225-18.628-.238-24.668-.238-4.9,0,5.727,10.145,5.727-9.736l-17.8,9.736,6.847,3.949,10.949,6.314v-20"
    />
  );
}

export default function AnimatedDownArrow() {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const triggerRef = useRef();
  const dataRef = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: false,
  });

  useEffect(() => {
    setImmediate(() => {
      setIsIntersecting(true);
    });
  }, []);

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width="233.9"
        height="113.528"
        viewBox="0 0 233.9 113.528"
      >
        <g
          id="Component_246_2"
          data-name="Component 246 – 2"
          transform="translate(6.748 4)"
        >
          <Checkmark isIntersecting={dataRef?.isIntersecting} />
        </g>
      </svg>
      <div ref={triggerRef} />
    </div>
  );
}
