import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function periodSelfAssessment(state = initialState.periodSelfAssessment, action) {
  switch (action.type) {
    case types.LOAD_PERIODSELFASSESSMENTS_SUCCESS:
      return Object.assign({}, state, {
        periodSelfAssessments: action.periodSelfAssessments
      });

    case types.SEARCH_PERIODSELFASSESSMENTS_SUCCESS:
      return Object.assign({}, state, {
        periodSelfAssessments: action.periodSelfAssessments
      });
    case types.LOAD_PERIODSELFASSESSMENTSTATUSES_SUCCESS:
      return Object.assign({}, state, {
        periodSelfAssessmentStatuses: action.periodSelfAssessmentStatuses
      });

    case types.CREATE_PERIODSELFASSESSMENT_SUCCESS:
      return Object.assign({}, state, {
        periodSelfAssessments: [...state.periodSelfAssessments,
          Object.assign({}, action.periodSelfAssessment)]
      });

    case types.UPDATE_PERIODSELFASSESSMENT_SUCCESS:
      return Object.assign({}, state, {
        periodSelfAssessments: [...state.periodSelfAssessments.filter(periodSelfAssessment => periodSelfAssessment.id !== action.periodSelfAssessment.id),
          Object.assign({}, action.periodSelfAssessment)]
      });

    default:
      return state;
  }
}
