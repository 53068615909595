import React from "react";
import PropTypes from "prop-types";

import { Button, Card, CardHeader, Col, Row } from "reactstrap";
import moment from "moment";
import {
  STATUS_IN_PROGRESS,
  STATUS_CHECKOUT,
  STATUS_COMPLETE,
  STATUS_READY,
  STATUS_SUBMITTED,
  STATUSES,
} from "../../common/constants/trainingStatuses";
import PageWrapper from "../PageWrapper/PageWrapper";

const EstablishmentTrainingDetails = ({ training }) => {
  const start_training = moment(training.start_date);
  const end_training = moment(training.end_date);
  const duration = moment.duration(end_training.diff(start_training));
  const hours = duration.asHours();

  return (
    <PageWrapper withBreadcrumbs>
      <div className="container-fluid-size">
        <Card className="shadow" style={{ minWidth: "500px" }}>
          <CardHeader className="card-header-style">
            <strong className="underlined-green-wide">تفاصيل التدريب</strong>
          </CardHeader>

          <div className="establishment-profile-card">
            <Row className="establishmnet-profile-wrap">
              <Col md={3}>
                <Row>
                  <Col>
                    <div className="profile-info-blk verticalLine">
                      <div className="establishment-profile-side-title ">
                        بيانات المنشأة
                      </div>
                    </div>
                  </Col>
                  <Col />
                </Row>
              </Col>

              <Col>
                <div className="profile-info-blk">
                  <div className="establishment-profile-side-title">
                    اسم المنشأة
                  </div>
                  <div className="establishment-profile-text">
                    {training.establishment_name}
                  </div>
                  <div className="establishment-profile-text">
                    {training.establishment_english_name}
                  </div>
                </div>
                <div className="profile-info-blk">
                  <div className="establishment-profile-side-title">
                    رقم المنشأة
                  </div>
                  <div className="establishment-profile-text">
                    {training.establishment_labor_office_id +
                      "/" +
                      training.establishment_sequence_id}
                  </div>
                </div>
              </Col>

              <Col>
                <div className="profile-info-blk">
                  <div className="establishment-profile-side-title ">
                    حجم المؤسسة
                  </div>
                  <div className="establishment-profile-text">
                    {training.establishment_size_name}
                  </div>
                </div>

                <div className="profile-info-blk">
                  <div className="establishment-profile-side-title">
                    الرقم الضريبي
                  </div>
                  <div className="establishment-profile-text">
                    {training.establishment_tax_number}
                  </div>
                </div>
              </Col>

              <Col>
                <div className="profile-info-blk">
                  <div className="establishment-profile-side-title ">
                    نشاط المؤسسة
                  </div>
                  <div className="establishment-profile-text">
                    {training.establishment_economic_activity_name}
                  </div>
                </div>

                <div className="profile-info-blk">
                  <div className="establishment-profile-side-title ">
                    عنوان المنشأة
                  </div>
                  <div className="establishment-profile-text">
                    {training.establishment_google_address}
                  </div>
                </div>
              </Col>

              <Col>
                <div className="profile-info-blk">
                  <div className="establishment-profile-side-title ">
                    نطاق المؤسسة
                  </div>
                  <div className="establishment-profile-text">
                    {training.establishment_nitaqat_color}
                  </div>
                </div>
              </Col>
            </Row>

            <Row style={{ paddingTop: "22px", paddingBottom: "22px" }}>
              <Col>{/*<hr className="green-line"/>*/}</Col>
            </Row>
            {training.trainer_first_name && (
              <Row className="establishmnet-profile-wrap">
                <Col Col md={3}>
                  <Row>
                    <Col>
                      <div className="profile-info-blk verticalLine">
                        <div className="establishment-profile-side-title ">
                          بيانات المدرب
                        </div>
                      </div>
                    </Col>
                    <Col />
                  </Row>
                </Col>

                <Col>
                  <div className="profile-info-blk">
                    <div className="establishment-profile-side-title">
                      اسم المدرب
                    </div>
                    <div className="establishment-profile-text">{`${training.trainer_first_name} ${training.trainer_last_name} `}</div>
                  </div>
                </Col>

                <Col>
                  <div className="profile-info-blk">
                    <div className="establishment-profile-side-title">
                      رقم الجوال
                    </div>
                    <div className="establishment-profile-text">
                      {training.trainer_mobile_number}
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="profile-info-blk">
                    <div className="establishment-profile-side-title ">
                      البريد الإلكتروني
                    </div>
                    <div className="establishment-profile-text">
                      {training.trainer_email}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row style={{ paddingTop: "22px", paddingBottom: "22px" }}>
              <Col>{/*<hr className="green-line"/>*/}</Col>
            </Row>

            <Row className="establishmnet-profile-wrap">
              <Col Col md={3}>
                <Row>
                  <Col>
                    <div className="profile-info-blk verticalLine">
                      <div className="establishment-profile-side-title ">
                        بيانات التدريب
                      </div>
                    </div>
                  </Col>
                  <Col />
                </Row>
              </Col>

              <Col>
                <div className="profile-info-blk">
                  <div className="establishment-profile-side-title">
                    نوع التدريب
                  </div>
                  <div className="establishment-profile-text">{`${training.subject_name}`}</div>
                </div>
              </Col>
              {training.start_date && (
                <Col>
                  <div className="profile-info-blk">
                    <div className="establishment-profile-side-title">
                      تاريخ بداية الفترة التدريبية
                    </div>
                    <div className="establishment-profile-text">
                      {moment(training.start_date).format("YYYY-MM-DD hh:mm A")}
                    </div>
                  </div>
                </Col>
              )}
              {training.end_date && (
                <Col>
                  <div className="profile-info-blk">
                    <div className="establishment-profile-side-title ">
                      تاريخ نهاية الفترة التدريبية
                    </div>
                    <div className="establishment-profile-text">
                      {moment(training.end_date).format("YYYY-MM-DD hh:mm A")}
                    </div>
                  </div>
                </Col>
              )}
              {training.end_date && (
                <Col>
                  <div className="profile-info-blk">
                    <div className="establishment-profile-side-title ">
                      المدة المستغرقة
                    </div>
                    <div className="establishment-profile-text">{`${hours} ساعة `}</div>
                  </div>
                </Col>
              )}
            </Row>
            {training.status === STATUS_CHECKOUT && (
              <Row style={{ paddingTop: "22px", paddingBottom: "22px" }}>
                <Col>
                  <hr className="green-line" />
                </Col>
              </Row>
            )}
            <Row className="establishmnet-profile-wrap">
              <Col>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {training.status === STATUS_CHECKOUT && (
                    <Button
                      color="primary"
                      onClick={() => {
                        window.location = training.payment_resp_checkout_url;
                      }}
                      className="btn-round div-float"
                    >
                      ادفع
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </PageWrapper>
  );
};

EstablishmentTrainingDetails.propTypes = {
  training: PropTypes.object.isRequired,
};

export default EstablishmentTrainingDetails;
