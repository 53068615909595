export default {
  assessment: {
    assessments: [],
    selectedAssessments: [],
  },
  establishment: {
    establishmentProfile: {},
    establishmentActiveAssessments: [],
    establishmentActiveAssessmentsTotal: 0,
    establishmentAssessments: [],
    establishmentCategories: [],
    mainEstablishmentCategories: [],
    selectEstablishmentCategories: [],
    establishmentSpecializations: [],
    establishmentSelfAssessmentResult: {},
    activeAssessment: null,

    majorViolationResult: {},
    establishments: [],
  },
  payment: {
    reopen_assessment_services: [],
    training_services: [],
    bills: [],
    establishmentAllBills: [],
    billsCount: 0,
    isLoadingServices: false,
    paymentServices: [], 
    isAlreadyPaid: false,
  },
  paymentInitiate: {
    isLoadingPayment: false,
    establishmentId: null,
    serviceId: null,
    periodId: null,
    assessmentId: null,
    assessmentVersion: null,
  },
  trainings: {
    training: null,
    trainingDeleting: false,
    trainingDeletingError: null,
    trainingData: null,

    trainingFetching: false,
    trainingFetchingError: null,
    trainings: [],
    trainingsList: [],
    trainingsListFetching: false,
    trainingsListFetchingError: null,
    trainingsTotal: 0,
    trainingUpdating: false,
    trainingUpdatingError: null,

    trainingCreating: false,
    trainingCreatingError: null,
  },
  trainingSubjects: {
    trainingSubjectsListFetching: false,
    trainingSubjectsListFetchingError: false,
    trainingSubjectsList: [],
    trainingSubjectsTotal: 0,
    trainingSubject: null,
    trainingSubjectFetching: false,
    trainingSubjectFetchingError: null,
  },
  establishmentSelfAssessmentResult: {
    establishmentSelfAssessmentResult: null,
    establishmentSelfAssessmentResultFetching: false,
    establishmentSelfAssessmentResultFetchingError: null,
    establishmentSelfAssessmentFastTrackResult: null,
    establishmentSelfAssessmentFastTrackResultFetching: false,
    establishmentSelfAssessmentFastTrackResultFetchingError: null,
    selectedResultFilterValue: {},
    establishmentSelfAssessmentDuration: {},
    establishmentSelfAssessmentDurationFetching: false,
    establishmentSelfAssessmentDurationFetchingError: false,
  },
  periodSelfAssessment: {
    periodSelfAssessments: [],
    periodSelfAssessmentStatuses: [],
  },
  report: {
    establishmentSelfAssessmentReportResult: [],
  },
  SAform: {},
  caliberCategories: [],
  caliberSubjects: [],
  violationCategories: [],
  calibers: [],
  attachmentCategories: [],
  questionCategories: [],
  caliberSpecializations: [],
  ajaxStatus: {
    isLoading: false,
    error: null,
  },
  auth: {
    token: null,
    userInfo: null,
  },
  notifications: {
    notification: null,
    notificationFetching: false,
    notificationFetchingError: null,
    notifications: [],
    notificationsList: [],
    notificationsListFetching: false,
    notificationsListFetchingError: null,
    notificationsTotal: 0,
    list: [],
    listFetching: false,
    listFetchingError: null,
    listTotal: 0,
  },
  SAreport: {
    attachment: {},
  },
  unified: {
    establishments: [],
    selected: [],
    service: {},
    gracePeriodStatus: false,
    isLoading: false,
    error: false,
    isLoadingStatus: false,
  },

  certificate: {
    auditReport: {},
    reports: {},
    isVisible: false,
    isLoading: false,
    error: false,
  },
  history: {
    locations: [],
  },
  blockedEstablishmentsPopUp: {
    showPopUp: true,
  },
  FastTrackForBranches: {
    establishments: [],
    isLoading: false,
    error: false,
  },
  billInfo: {
    isLoading: false,
    error: false,
  },
  imsInfo: {
    isLoading: false,
    error: false,
    data: {
      establishments_count: 0,
      completed_assessments_count: 0,
      assessments_count: 0,
    },
  },
  duration: {
    selectedDuration: null,
  },
  trackType: null,
};
