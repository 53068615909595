import "./fonts/Effra/Effra-Bold.ttf";
import "./fonts/Effra/Effra-Regular.ttf";
import "./fonts/MolArabic/Mol-Arabic-Regular.ttf";
import "./fonts/MolArabic/Mol-Arabic-Bold.ttf";

import React from "react";
import PropTypes from "prop-types";
import "./text.module.scss";

export function Text(props) {
  const {
    children,
    className,
    textType,
    isBold,
    Tag,
    style,
    fontSize,
    mobileFontSize,
    mobileTextType,
  } = props;
  const fontWeight = isBold ? "bold" : "normal";
  return (
    <Tag
      style={style}
      className={`${className} ${textType} ${fontWeight} ${fontSize} ${mobileFontSize} ${mobileTextType}`}
    >
      {children}
    </Tag>
  );
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  Tag: PropTypes.string,
  className: PropTypes.string,
  isBold: PropTypes.bool,
  textType: PropTypes.string.isRequired,
  mobileTextType: PropTypes.string,
};

Text.defaultProps = {
  Tag: "p",
  children: null,
  className: "",
  isBold: false,
  fontSize: "",
  mobileFontSize: "",
  mobileTextType: "",
};
