import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as EstablishmentProfileActions from "../../actions/establishmentProfileActions";
import EstablishmentTrainings from "./EstablishmentTrainings";
import "./tabs.css";

import { error, success } from "toastr";
import { Label } from "reactstrap";
import * as TrainingActions from "../../actions/trainingActions";
import * as TrainingSubjectActions from "../../actions/trainingSubjectActions";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

import "./establishment-training-page.css";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import Loader from "../Loader/Loader";

const RIYADH_LOCATION_LAT = 24.7162642;
const RIYADH_LOCATION_LNG = 46.6865202;

export const defaultMapLocation = {
  lat: RIYADH_LOCATION_LAT,
  lng: RIYADH_LOCATION_LNG,
};

export const DEFAULT_NITAQAT_COLOR = "80ac87";

const MANDATORY_FIELD_ERROR_MSG = "الرجاء ادخال جميع الحقول المطلوبة";
const TAX_MUST_CONSIST_OF_15_NUMBERS_MSG =
  "عذرا ،الرقم الضريبي المدخل غير صحيح يتكون الرقم الضريبي من 15 رقم";

export function handleLocationChange(location) {
  const { address, position } = location;
  this.props.updateEstablishmentProfile({
    key: "google_address",
    value: address,
  });
  this.props.updateEstablishmentProfile({
    key: "lat",
    value: { Float64: position.lat, Valid: true },
  });
  this.props.updateEstablishmentProfile({
    key: "lng",
    value: { Float64: position.lng, Valid: true },
  });
}

export function validateProfileEditing() {
  const { establishmentProfile } = this.props;
  let formIsValid = true;
  let errors = {};

  if (
    !establishmentProfile.tax_number ||
    !establishmentProfile.tax_number.trim().length
  ) {
    errors.tax_number = MANDATORY_FIELD_ERROR_MSG;
    formIsValid = false;
  } else if (establishmentProfile.tax_number.length !== 15) {
    errors.tax_number = TAX_MUST_CONSIST_OF_15_NUMBERS_MSG;
    formIsValid = false;
  }

  // if (!establishmentProfile.lat.Valid || !establishmentProfile.lng.Valid) {
  //   errors.google_address = MANDATORY_FIELD_ERROR_MSG;
  //   formIsValid = false;
  // }

  this.setState({
    profileEditingValidationErrors: { ...errors },
  });

  return formIsValid;
}

export function saveProfile() {
  const { establishmentProfile } = this.props;

  return this.props.saveEstablishmentProfile(establishmentProfile.id, {
    establishment_details: {
      tax_number: establishmentProfile.tax_number,
      lat: establishmentProfile.lat.Float64,
      lng: establishmentProfile.lng.Float64,
      google_address: establishmentProfile.google_address,
      english_name: establishmentProfile.english_name,
    },
  });
}

export function onProfileFieldChange(e) {
  const key = e.target.name;
  const value = e.target.value;

  // Fields restrictions:
  switch (key) {
    case "tax_number": {
      if (!/^\d*$/g.test(value)) {
        return false;
      }
    }
  }

  this.props.updateEstablishmentProfile({ key, value });
}

class EstablishmentTrainingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      establishmentProfile: Object.assign({}, this.props.establishmentProfile),
      profileEditing: false,
      profileEditingValidationErrors: {},
      tabSelected: 1,
    };

    this.onProfileSaveClick = this.onProfileSaveClick.bind(this);
    this.validateProfileEditing = validateProfileEditing.bind(this);
    this.saveProfile = saveProfile.bind(this);
    this.onProfileFieldChange = onProfileFieldChange.bind(this);
    this.handleLocationChange = handleLocationChange.bind(this);
    this.addNewTraining = this.addNewTraining.bind(this);
  }

  componentDidMount() {
    const { establishmentProfile } = this.props;

    const { isLogin } = this.props;
    if (!isLogin) {
      window.location = process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
      window.location.reload();
    }
    this.setState({
      establishmentProfile: Object.assign({}, establishmentProfile),
    });
    this.props.onFetchTrainings(establishmentProfile.id);
    this.props.onFetchTrainingSubjects(establishmentProfile.id);
  }

  componentDidUpdate() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onProfileSaveClick() {
    const profileIsValid = this.validateProfileEditing();
    if (!profileIsValid) {
      return;
    }

    this.saveProfile()
      .then(() => {
        success("تم حفظ المدخلات بنجاح");
        this.setState({ profileEditing: false });
      })
      .catch((err) => {
        error("لم يتم الحفظ");
      });
  }

  setAssessmentDurationsRef = (container) => {
    this.assessmentDurationsRef = container;
  };

  enableEditing = () => this.setState({ profileEditing: true });
  handleChange = (event, newValue) => {
    this.setState({ tabSelected: newValue });
  };
  addNewTraining(form_type = 0) {
    const establishmentProfile = this.state.establishmentProfile;

    this.props.history.push(
      `/establishmentTraining/${establishmentProfile.id}/${form_type}/${training_id}`
    );
  }
  render() {
    const { profileEditing, profileEditingValidationErrors } = this.state;
    const { establishmentProfile, trainings, availableTrainings } = this.props;
    const mapLocationSpecifed =
      establishmentProfile.lat.Valid && establishmentProfile.lng.Valid;
    const establishmentMapLocation = {
      lat: establishmentProfile.lat.Float64,
      lng: establishmentProfile.lng.Float64,
    };
    return (
      <PageWrapper overrideStyle={{ padding: "0" }}>
        <div id="divToPrint" className="establishment-training-page">
          <div
            style={{
              backgroundColor: "white",
            }}
          >
            <div className="website-page-title-padding ">
              <Text Tag="div" isBold textType="h5" className="page-title-style">
                التدريب
              </Text>

              <div className="Panel">
                <nav>
                  <ul className="Tabs">
                    <li
                      className={
                        this.state.tabSelected === 0
                          ? "Tabs__tab  activeTab Tab "
                          : "Tabs__tab Tab"
                      }
                      onClick={(e) => this.handleChange(e, 0)}
                      id="registered-courses-button"
                    >
                      <Text Tag="span" textType="h8">
                        الدورات المسجلة
                      </Text>
                    </li>
                    <li
                      className={
                        this.state.tabSelected === 1
                          ? " Tabs__tab  activeTab Tab "
                          : " Tabs__tab Tab"
                      }
                      onClick={(e) => this.handleChange(e, 1)}
                      id="available-courses-button"
                    >
                      <Text Tag="span" textType="h8">
                        الدورات المتاحة
                      </Text>
                    </li>

                    <li
                      className="Tabs__presentation-slider"
                      role="presentation"
                    ></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="Panel__body website-page-content-padding">
            {this.state.tabSelected === 0 && (
              <div ref={this.setAssessmentDurationsRef}>
                <EstablishmentTrainings
                  trainings={trainings}
                  history={this.props.history}
                  establishmentProfile={this.state.establishmentProfile}
                />
              </div>
            )}
            {this.state.tabSelected === 1 &&
            !this.props.trainingSubjectsListFetching ? (
              <div>
                <EstablishmentTrainings
                  isAvailableCourses
                  trainings={availableTrainings}
                  history={this.props.history}
                  establishmentProfile={this.state.establishmentProfile}
                />
              </div>
            ) : (
              this.state.tabSelected !== 0 && (
                <div className="loader-wrapper-height">
                  <div className="loader-wrapper">
                    <Loader />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </PageWrapper>
    );
  }
}

EstablishmentTrainingsPage.propTypes = {
  establishmentProfile: PropTypes.object.isRequired,
  availableTrainings: PropTypes.array,
  trainings: PropTypes.array,
  ajaxStatus: PropTypes.object,
  isLogin: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export const emptyEstablishmentProfile = {
  establishment_id: "",
  establishment_name: "",
  labor_office_id: "",
  sequence_id: "",
  establishment_status_id: "",
  establishment_status: "",
  main_economic_activity_id: "",
  sub_economic_activity_id: "",
  economic_activity_id: "",
  economic_activity_name: "",
  color_id: "",
  nitaqat_color: "",
  size_id: "",
  size_name: "",
  color_code: "",
  saudis: "",
  foreigners: "",
  total_laborer_count: "",
  tax_number: "",
  lat: {
    Float64: null,
    Valid: false,
  },
  lng: {
    Float64: null,
    Valid: false,
  },
};

function mapStateToProps(state) {
  let establishmentProfile = { ...emptyEstablishmentProfile };

  let userInfo = {
    id: "",
    name_ar: "",
    name_en: "",
    phone_number: "",
    email: "",
    id_number: "",
  };

  if (state.establishment.establishmentProfile.establishment_name) {
    establishmentProfile = state.establishment.establishmentProfile;
  }
  if (state.auth.userInfo) {
    userInfo = state.auth.userInfo;
  }

  return {
    trainingSubjectsListFetching:
      state.trainingSubjects.trainingSubjectsListFetching,
    establishmentProfile: establishmentProfile,
    isLogin: state.auth.token !== null,
    userInfo: userInfo,
    ajaxStatus: state.ajaxStatus,
    availableTrainings: state.trainingSubjects.trainingSubjectsList,
    trainings: state.trainings.trainingsList,
  };
}

const mapDispatchToProps = {
  updateEstablishmentProfile:
    EstablishmentProfileActions.updateEstablishmentProfile,
  saveEstablishmentProfile:
    EstablishmentProfileActions.saveEstablishmentProfile,
  onFetchTrainings: TrainingActions.fetchTrainings,
  onFetchTrainingSubjects: TrainingSubjectActions.fetchTrainingSubjects,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstablishmentTrainingsPage);
