import React from "react";
import {
  GoogleMap,
  Marker,
  Circle,
  Autocomplete,
  useJsApiLoader,
} from "@react-google-maps/api";
import MapControl from "./MapControl";
import TextInput from "./../../components/TextInput/TextInput";
function Map(props) {
  const {
    position,
    defaultZoom,
    handleMarkerDragEnd,
    onZoomChanged,
    radius,
    circleOptions,
    mapControlsEnabled = true,
    onGeolocationClick,
    center,
    onLoad,
    onPlaceChanged,
    withInputSearch,
    GoogleAddress,
  } = props;

  const options = {
    streetViewControl: false,
    scaleControl: mapControlsEnabled,
    mapTypeControl: false,
    panControl: false,
    zoomControl: mapControlsEnabled,
    rotateControl: mapControlsEnabled,
    fullscreenControl: mapControlsEnabled,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.LEFT_CENTER,
    },
  };
  return (
    <div>
      {withInputSearch && (
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          restrictions={{ country: "SA" }}
        >
          <div>
            <TextInput
              placeholder={
                GoogleAddress ? GoogleAddress : "إبحث عن الموقع . . ."
              }
              type="text"
              icon={"img/search.svg"}
              inputWrapperStyle={{ width: "100%" }}
            />
          </div>
        </Autocomplete>
      )}

      <GoogleMap
        onZoomChanged={onZoomChanged}
        center={center}
        defaultZoom={defaultZoom}
        defaultCenter={position}
        options={options}
        mapContainerStyle={{
          height: "270px",
          borderRadius: "16px",
        }}
        zoom={defaultZoom}
      >
        {/* Map marker */}
        <Marker
          draggable={mapControlsEnabled} // Allow marker to drag
          position={position}
          onDragEnd={handleMarkerDragEnd}
        />

        {/* Circle  */}
        <Circle center={position} radius={radius} options={circleOptions} />
      </GoogleMap>
    </div>
  );
}

export default Map;
