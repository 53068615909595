import * as React from 'react';
export const AttachmentIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.839"
        height="17.994"
        viewBox="0 0 15.839 17.994"
        {...props}
    >
        <g id="Attachment_icon" transform="translate(-4 -3)">
            <path id="Shape" d="M1.714,7.794,8.239,1.269A4.333,4.333,0,1,1,14.367,7.4L7.842,13.923a2.667,2.667,0,0,1-3.728-3.815L10.6,3.626A1,1,0,1,1,12.01,5.04L5.529,11.522a.667.667,0,0,0,.943.943l6.482-6.482a2.333,2.333,0,1,0-3.3-3.3L3.172,9.165a4,4,0,0,0,5.657,5.657l5.3-5.3a1,1,0,1,1,1.414,1.414l-5.3,5.3A6,6,0,0,1,1.714,7.795Z" transform="translate(4 3)" fill="#92929d" />
        </g>
    </svg>
);
