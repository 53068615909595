import React from 'react';
import {Text} from "../../../../shared-components/my-scope/atoms/text";

const ContactItem = ({ iconSrc, headingText, paragraphText, linkHref, linkText, linkType }) => {
  const renderLink = () => {
    switch (linkType) {
      case 'email':
        return <a href={`mailto:${linkHref}`}>
          <Text
            Tag="span"
            textType="roman"
            fontSize={"font-26"}
            mobileFontSize={"font-24-mobile"}
            className="frame-contact-us-card-text-wrapper-item"
          >
            {linkText}
          </Text>
        </a>;
      case 'phone':
        return <a href={`tel:${linkHref}`}>
          <Text
            Tag="span"
            textType="roman"
            fontSize={"font-26"}
            mobileFontSize={"font-24-mobile"}
            className="frame-contact-us-card-text-wrapper-item"
          >
            {linkText}
          </Text>
        </a>;
      case 'link':
        return (
          <a href={linkHref} className="frame-contact-us-card-text-wrapper-item link-text" target="_blank" rel="noopener noreferrer">
            <Text
              Tag="span"
              textType="roman"
              fontSize={"font-26"}
              mobileFontSize={"font-24-mobile"}
            >
              {linkText}
            </Text>
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <div className="frame-contact-us-card">
      <img className="icon-feather-mail" src={iconSrc} alt={headingText} />
      <div>
        <div>
          <Text
            Tag="div"
            textType="roman"
            fontSize={"font-24"}
            mobileFontSize={"font-22-mobile"}
            className="frame-contact-us-card-text-wrapper-header"
          >
            {headingText}
          </Text>
          <Text
            Tag="div"
            textType="roman"
            fontSize={"font-20"}
            mobileFontSize={"font-18-mobile"}
            className="frame-contact-us-card-text-wrapper-paragraph"
          >
            {paragraphText}
          </Text>
        </div>
        <div className="div-wrapper">
          {renderLink()}
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
