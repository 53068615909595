import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function caliberSubjects(state = initialState.caliberSubjects, action) {
    switch (action.type) {
        case types.LOAD_CALIBERSUBJECTS_SUCCESS:
            return action.caliberSubjects;

        case types.CREATE_CALIBERSUBJECT_SUCCESS:
            return [
                ...state,
                Object.assign({}, action.caliberSubject)
            ];

        case types.UPDATE_CALIBERSUBJECT_SUCCESS:
            return [
                ...state.filter(caliberSubject => caliberSubject.id !== action.caliberSubject.id),
                Object.assign({}, action.caliberSubject)
            ];

        default:
            return state;
    }
}

