// @ts-check
import React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StepsData } from "../../assets/data/mapper";
import { useDispatch, useSelector } from "react-redux";
import { useAssessmentContextHandler } from "./ctx-handler";
import Loader from "../../../../../components/Loader/Loader";

export const SelfAssessmentContextProvider = createContext({
  steps: [],
  innerActiveStep: 0,
  activeStep: 0,
  data: [],
});

// ** exports

// ** active step and handlers
export const useActiveStep = () =>
  useContext(SelfAssessmentContextProvider).activeStep;
export const usePeriod = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).period;
// @ts-ignore
export const useCalibers = () =>
  useContext(SelfAssessmentContextProvider).calibers;

// @ts-ignore
export const useSetCalibers = () =>
  useContext(SelfAssessmentContextProvider).setCalibers;
export const useAssessmentLoading = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).loading;
export const useIsViewAgreement = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).viewAgreement === true;
export const useSelfAssessmentContent = () =>
  useContext(SelfAssessmentContextProvider);
export const useSetNextStep = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).setNextStepHandler;
export const useSetPrevStep = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).setPrevStepHandler;

// ** current step
export const useCurrentStep = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).currentStep;

// ** getting steps
export const useSteps = () => useContext(SelfAssessmentContextProvider).steps;

// ** setting active step
// @ts-ignore
export const useSetActiveStep = () =>
  useContext(SelfAssessmentContextProvider).setActiveStepHandler;

// ** setting steps
export const useSetSteps = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).setStepsHandler;

// ** getting data
export const useData = () => useContext(SelfAssessmentContextProvider).data;
// ** setting data
export const useSetData = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).setDataHandler;

// ** inner steps and handlers
export const useSetNextInnerStep = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).setNextInnerStepHandler;
export const useSetPrevInnerStep = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).setPrevInnerStepHandler;
export const useActiveInnerStep = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).innerActiveStep;
export const usePassedSteps = () =>
  // @ts-ignore
  useContext(SelfAssessmentContextProvider).passedSteps;

export const SelfAssessmentContext = ({ params, ...props }) => {
  // @ts-ignore
  const establishmentProfileId = useSelector(
    // @ts-ignore
    (state) => state?.establishment.establishmentProfile.id
  );
  const { data, period, loading } = useAssessmentContextHandler(
    establishmentProfileId,
    params
  );
  // // @ts-ignore
  const [steps, setSteps] = useState([]);
  const [calibers, setCalibers] = useState({});
  const [viewAgreement, setViewAgreement] = useState(false);
  const [activeStep, setActiveStep] = useState({});
  const [passedSteps, setPassedSteps] = useState({}); // it should be { [index]: [{ categoryIndex: caliberIndex }] }

  const getStepsHandler = useCallback((data) => {
    // call api to get steps
    // console.log("data-to-set", data);
    let stepsToSet = [];
    if (data?.qiwa_calibers?.length) {
      stepsToSet.push({
        standard_category_name: "البنود المؤتمتة",
        type: "qiwa",
        items: data?.qiwa_calibers,
      });
    }
    if (data?.standard_categories?.length) {
      stepsToSet = [...stepsToSet, ...data?.standard_categories];
    }

    stepsToSet.push({
      standard_category_name: "الاضافات والاقرارات",
      isDetailedReportAllowed: data?.is_detailed_report_allowed,
      type: "addons",
      hasAgreed: false,
    });
    // @ts-ignore
    setSteps(stepsToSet);
    setActiveStep({ index: 0, categoryIndex: 0, caliberIndex: 0, y: -40 });
  }, []);

  const setStepsHandler = useCallback((steps) => setSteps(steps), []);

  const setNextStepHandler = useCallback(() => {
    // @ts-ignore
    const { index, categoryIndex, caliberIndex } = activeStep;
    // @ts-ignore
    const nextCaliber =
      steps?.[index]?.caliber_categories?.[categoryIndex]?.calibers?.[
        caliberIndex + 1
      ];
    // @ts-ignore
    const nextCategory = steps[index]?.caliber_categories?.[categoryIndex + 1];
    const nextStep = steps?.[index + 1];

    // console.log("nextCaliber", nextCaliber);
    if (nextCaliber) {
      setActiveStep({
        index,
        categoryIndex: categoryIndex,
        caliberIndex: caliberIndex + 1,
        y: -40,
      });
      setPassedSteps((prev) => ({
        ...prev,
        [index]: {
          ...prev?.[index],
          [categoryIndex]: {
            ...prev?.[index]?.[categoryIndex],
            [caliberIndex]: true,
          },
        },
      }));
    } else if (nextCategory) {
      setActiveStep({
        index,
        categoryIndex: categoryIndex + 1,
        caliberIndex: 0,
        y: -40,
      });

      setPassedSteps((prev) => ({
        ...prev,
        [index]: {
          ...prev?.[index],
          [categoryIndex]: {
            ...prev?.[index]?.[categoryIndex],
            [caliberIndex]: true,
          },
        },
      }));
    } else if (nextStep) {
      setActiveStep({
        index: index + 1,
        categoryIndex: 0,
        caliberIndex: 0,
        y: -40,
      });

      setPassedSteps((prev) => ({
        ...prev,
        [index]: {
          ...prev?.[index],
          0: {
            ...prev?.[index]?.[0],
            0: true,
          },
        },
      }));
    }
  }, [activeStep, steps]);

  const setPrevStepHandler = useCallback(() => {
    // @ts-ignore
    const { index, categoryIndex, caliberIndex } = activeStep;
    // check if their prev caliber
    // @ts-ignore
    const prevCaliber =
      steps?.[index]?.caliber_categories?.[categoryIndex]?.calibers?.[
        caliberIndex - 1
      ];
    // @ts-ignore
    const prevCategory =
      steps?.[index]?.caliber_categories?.[categoryIndex - 1];
    const prevStep = steps?.[index - 1];

    if (prevCaliber) {
      setActiveStep({
        index,
        categoryIndex,
        caliberIndex: caliberIndex - 1,
        y: 40,
      });
    } else if (prevCategory) {
      if (prevCategory?.calibers) {
        setActiveStep({
          index,
          categoryIndex: categoryIndex - 1,
          caliberIndex: prevCategory?.calibers?.length - 1,
          y: 40,
        });
      } else {
        setActiveStep({
          index,
          categoryIndex: categoryIndex - 1,
          caliberIndex: 0,
          y: 40,
        });
      }
    } else if (prevStep?.caliber_categories) {
      if (
        prevStep?.caliber_categories?.[prevStep?.caliber_categories?.length - 1]
          ?.calibers
      ) {
        setActiveStep({
          index: index - 1,
          categoryIndex: prevStep?.caliber_categories?.length - 1,
          caliberIndex:
            prevStep?.caliber_categories?.[
              prevStep?.caliber_categories?.length - 1
            ]?.calibers?.length - 1,
          y: 40,
        });
      } else if (
        prevStep?.caliber_categories?.[prevStep?.caliber_categories?.length - 1]
      ) {
        setActiveStep({
          index: index - 1,
          categoryIndex: prevStep?.caliber_categories?.length - 1,
          caliberIndex: 0,
          y: 40,
        });
      }
    } else if (prevStep) {
      setActiveStep({
        index: index - 1,
        categoryIndex: 0,
        caliberIndex: 0,
        y: 40,
      });
    } else {
      alert("no prev step");
    }
  }, [activeStep, steps, viewAgreement]);

  const setActiveStepHandler = useCallback((payload) => {
    setActiveStep(payload);
  }, []);

  useEffect(() => {
    getStepsHandler(data);
  }, [data]);

  const currentStep = {};
  const providerValues = useMemo(
    () => ({
      steps,
      activeStep,
      data,
      setStepsHandler,
      setNextStepHandler,
      setPrevStepHandler,
      currentStep,
      setActiveStepHandler,
      viewAgreement,
      loading,
      period,
      calibers,
      setCalibers,
      passedSteps,
    }),
    [
      steps,
      activeStep,
      data,
      setStepsHandler,
      setNextStepHandler,
      setPrevStepHandler,
      currentStep,
      setActiveStepHandler,
      viewAgreement,
      loading,
      period,
      calibers,
      setCalibers,
      passedSteps,
    ]
  );

  // console.log("providerValues", providerValues);

  return (
    // @ts-ignore
    <SelfAssessmentContextProvider.Provider value={providerValues}>
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "600px",
            alignItems: "center",
            justifyContent: "center",
          }}
          // className={"flex-1 w-full flex items-center justify-center"}
        >
          <Loader />
        </div>
      ) : (
        props.children
      )}
    </SelfAssessmentContextProvider.Provider>
  );
};
