import React from "react";
// import { Text } from "../../../lib/components/index";
import { useActiveStep } from "../../self-assessment-form-context";
import { AgreeRow } from "../agree-row";
import { AttachmentsSection } from "../attachments";
import {
  CheckIcon,
  PrevAnswersWithHistory,
} from "../../../../../../components/establishmentprofile/PrevAnswers/index";
import { Stack } from "@mui/material";
import { TYPE_QUALITATIVE_ID } from "../../../../../../common/constants/caliberTypes";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
import "./term-section.css";
const boldFont = { fontWeight: "bold", fontSize: 16, color: "#0E2A37" };

const hashNumbers = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
  11: "11",
  12: "12",
};

function fixAndSplitString(inputString) {
  // Fixing the numbering by adding a line break after each number and hyphen
  const strArr = [];
  inputString
    .replace("-", "")
    .replace("- .", "")
    .replace("-.", "")
    .split("\n")
    .forEach((str) => {
      if (
        (str?.length > 2 && str !== "\n" && !hashNumbers[str]) ||
        str !== "-" ||
        !Number(str)
      ) {
        strArr.push(str);
      }
    });

  return strArr;
}

export const TermSection = ({ data = {}, step, refreshCaliber, errors }) => {
  const {
    description,
    corrections,
    answer,
    new_answer,
    attachments,
    new_attachments,
    is_answered,
    integrationServiceValue,
    is_returned,
  } = data ?? {};
  const { categoryIndex, caliberIndex } = useActiveStep();
  const caliberIsQualitative = data.caliber_type.id === TYPE_QUALITATIVE_ID;

  return (
    <div className="">
      {/* Title Section */}
      {/* Start of Terms - loop should start from here for terms */}
      <div
        // className="py-3 px-4 bg-gray-100 rounded-[10px]"
        className="term-section-caliber-name "
      >
        <Text
          Tag={"div"}
          textType="roman"
          fontSize={"font-20"}
          mobileFontSize={"font-16-mobile"}
          style={{ color: "#000" }}
          className="mb-0"
        >
          {"البند"} {categoryIndex + 1}.{caliberIndex + 1}
        </Text>
      </div>
      {/* Start of Terms - loop should start from here for calibers */}
      <div className="mt-9 flex flex-col">
        <Text
          Tag={"div"}
          textType="bold"
          fontSize={"font-18"}
          mobileFontSize={"font-16-mobile"}
          style={{ color: "#14415A" }}
        >
          {"شرح البند"}
        </Text>

        {fixAndSplitString(description)?.map((str, index) => (
          <Text
            key={index}
            Tag={"div"}
            textType="roman"
            fontSize={"font-17"}
            mobileFontSize={"font-15-mobile"}
            style={{ color: "#000", textAlign: "justify" }}
            className="mb-0 leading-8"
          >
            {str}
          </Text>
        ))}
      </div>
      <div className="mt-9 flex flex-col">
        <Text
          Tag={"div"}
          textType="bold"
          fontSize={"font-18"}
          mobileFontSize={"font-16-mobile"}
          style={{ color: "#14415A" }}
        >
          {"المرجعية النظامية"}
        </Text>
        {fixAndSplitString(corrections)?.map((str, index) => (
          <Text
            key={index}
            Tag={"div"}
            textType="roman"
            fontSize={"font-17"}
            mobileFontSize={"font-15-mobile"}
            style={{ color: "#000", textAlign: "justify" }}
            className="mb-0 leading-8"
          >
            {str}
          </Text>
        ))}
      </div>

      <div className=" w-full h-[1px] bg-gray-100 caliber-divider-line-mobile" />
      {is_returned && (
        <div className={"pointer-events-none"}>
          <PrevAnswersWithHistory {...data} />
        </div>
      )}

      {is_returned && (
        <Stack
          display={"flex"}
          direction="row"
          alignItems={"center"}
          columnGap={1}
          style={{ marginTop: "80px" }}
        >
          <span className="check-icon-size">
            <CheckIcon />
          </span>
          <Text
            Tag={"div"}
            textType="bold"
            fontSize={"font-18"}
            mobileFontSize={"font-16-mobile"}
            style={{ color: "#000" }}
          >
            {"الإجابة الحالية للمنشأة"}
          </Text>
        </Stack>
      )}
      <div id="answer">
        <AgreeRow
          isError={errors?.answer}
          step={step}
          data={data}
          answer={data?.is_returned ? data?.new_answer : data?.answer}
          caliberIsQualitative={caliberIsQualitative}
        />
      </div>
      {attachments && (
        <div id="attachments">
          <AttachmentsSection
            errors={errors}
            refreshCaliber={refreshCaliber}
            step={step}
            data={data}
            attachments={is_returned ? new_attachments : attachments}
          />
        </div>
      )}
    </div>
  );
};
