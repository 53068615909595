import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function questionCategories(state = initialState.questionCategories, action) {
    switch (action.type) {
        case types.LOAD_QUESTIONCATEGORIES_SUCCESS:
            return action.questionCategories;

        case types.CREATE_QUESTIONCATEGORY_SUCCESS:
            return [
                ...state,
                Object.assign({}, action.questionCategory)
            ];

        case types.UPDATE_QUESTIONCATEGORY_SUCCESS:
            return [
                ...state.filter(questionCategory => questionCategory.id !== action.questionCategory.id),
                Object.assign({}, action.questionCategory)
            ];

        default:
            return state;
    }
}

