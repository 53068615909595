import * as React from 'react';

export const LikeIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.662"
        height="14.136"
        viewBox="0 0 15.662 14.136"
        {...props}
    >
        <g id="Like"
            data-name="Like"
            transform="translate(-0.218 -0.059)">
            <path id="Fill_516"
                data-name="Fill 516"
                d="M11.163,14.136H6.9a2.922,2.922,0,0,1-1.753-.58.764.764,0,0,1-.739.58H.765A.768.768,0,0,1,0,13.368V5.853a.768.768,0,0,1,.765-.77H4.412a.768.768,0,0,1,.765.77V6a1.228,1.228,0,0,0,.981-.859l1.12-3.77a1.907,1.907,0,0,1,3.732.66l-.144,2.507H13.59a2.076,2.076,0,0,1,1.666.845,2.1,2.1,0,0,1,.316,1.848L14.144,11.92A3.1,3.1,0,0,1,11.163,14.136ZM9.107,1.313a.6.6,0,0,0-.578.432L7.409,5.515a2.535,2.535,0,0,1-2.232,1.8v4.467l.556.559a1.64,1.64,0,0,0,1.171.487h4.259A1.8,1.8,0,0,0,12.9,11.535l1.427-4.69a.766.766,0,0,0-.118-.686.758.758,0,0,0-.615-.31H10.334a.806.806,0,0,1-.8-.854l.175-3.042a.6.6,0,0,0-.3-.557A.59.59,0,0,0,9.107,1.313Z"
                transform="translate(0.218 0.059)"
            // fill="#92929d" 
            />
        </g>
    </svg>
);
