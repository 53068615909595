import React, { useEffect, useRef } from "react";
import { twMerge } from "../../tw-merge";
import "./mobile-stepper.css";
import { useCallback } from "react";
import { usePassedSteps } from "../../../self-components/self-assessment-form-context";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
export const MobileStepper = (props) => {
  const { steps, activeStep, setActiveStep } = props;
  const innerActiveStepRef = useRef(activeStep.y);
  const passedSteps = usePassedSteps();
  useEffect(() => {
    innerActiveStepRef.current = activeStep.y;
  }, [activeStep]);

  const isAllowedToMove = useCallback(
    (nextStep, nextCategory, nextCaliber) => {
      if (nextStep > activeStep.index) return false;
      if (nextStep < activeStep.index) return true;
      if (nextStep === activeStep.index) {
        if (nextCategory > activeStep.categoryIndex) return false;
        if (nextCategory < activeStep.categoryIndex) return true;
        if (nextCategory === activeStep.categoryIndex) {
          if (nextCaliber > activeStep.caliberIndex) return false;
          if (nextCaliber < activeStep.caliberIndex) return true;
          if (nextCaliber === activeStep.caliberIndex) return false;
        }
      }
    },
    [activeStep?.index, activeStep?.categoryIndex, activeStep?.caliberIndex]
  );
  const handleOnClick = (step, category, caliber) => {
    if (passedSteps?.[step]?.[category]?.[caliber]) {
      setActiveStep({
        index: step,
        categoryIndex: category,
        caliberIndex: caliber,
      });
      return;
    }
    if (isAllowedToMove(step, category, caliber)) {
      setActiveStep({
        index: step,
        categoryIndex: category,
        caliberIndex: caliber,
      });
    }
  };

  return (
    <div
      style={{
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        scrollbarWidth: "none",
      }}
      className="relative w-full  overflow-auto"
    >
      <section className="text-gray-600 body-font pt-8">
        <div className="container flex flex-wrap" style={{ padding: 0 }}>
          <div className="flex flex-wrap w-full">
            <div
              className="w-full"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {steps?.map((step, idx) => {
                const { caliber_categories, standard_category_name } =
                  step ?? {};

                const containerClss = twMerge(
                  "flex relative min-h-[96px] mobile-stepper-wrapper",
                  activeStep.index - 1 === idx && "cursor-pointer",
                  "w-full"
                );

                const isLastItem = steps && idx === steps.length - 1;
                const itemClss = twMerge(
                  "w-full absolute  flex items-center justify-center",
                  idx >= steps.length && "flex-shrink-0 ",
                  idx >= steps.length - 1 && "mt-0"
                );
                return (
                  <div
                    key={"standard_category_" + idx}
                    className={containerClss}
                    style={{
                      padding: "15px 0 15px 0",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={
                      activeStep.index - 1 === idx
                        ? () => {
                            handleOnClick(idx, 0, 0);
                          }
                        : () => {}
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "30px",
                      }}
                    >
                      <div
                        className={twMerge(
                          "flex-shrink-0 w-5 h-5 rounded-full inline-flex items-center justify-center text-white relative z-10",
                          activeStep?.index < idx && "w-4 h-4 "
                        )}
                        style={{
                          height: idx > activeStep?.index ? "20px" : "30px",
                          width: idx > activeStep?.index ? "20px" : "30px",
                          marginTop: "6px",
                          backgroundColor: "#148287",
                          opacity: activeStep.index >= idx ? "1" : "0.9",
                        }}
                      >
                        {activeStep.index > idx && (
                          <img src="img/check-icon-white.svg" />
                        )}
                        {activeStep.index === idx && (
                          <div
                            className="w-2 h-2 rounded-full"
                            style={{
                              height: "12px",
                              width: "12px",
                              backgroundColor: "#FFFFFF",
                            }}
                          />
                        )}
                      </div>
                      <div
                        className={itemClss}
                        style={{
                          marginRight:
                            activeStep.index === idx - 1 ? "0" : "5px",
                        }}
                      >
                        <div
                          className={twMerge(
                            activeStep?.index > idx
                              ? "border-solid"
                              : "border-dashed",
                            "w-full",
                            isLastItem && "border-none"
                          )}
                          style={{
                            borderTopWidth: "2px",
                            marginRight: "20px",
                            marginTop: "6px",
                            borderColor: "rgba(0, 0, 0, 0.50)",
                          }}
                        />
                      </div>
                    </div>

                    {(activeStep.index === idx ||
                      activeStep.index + 1 === idx ||
                      (steps.length == activeStep.index + 1 &&
                        activeStep.index - 1 == idx)) && (
                      <div
                        style={{
                          width: "76px",
                          color:
                            activeStep.index < idx ||
                            (steps.length == activeStep.index + 1 &&
                              activeStep.index == idx + 1)
                              ? "rgba(20, 65, 90, 0.65)"
                              : "#14415A",
                        }}
                      >
                        {/* {console.log("activeStep.index", activeStep.index)}
                        {console.log("idx", idx)}
                        {console.log("steps.length", steps.length)} */}

                        <div className={"w-full"}>
                          <Text
                            Tag="div"
                            textType="roman"
                            fontSize={"font-15"}
                            className="font-medium font-mol text-start title-font text-lg mb-[8px] tracking-wider mobile-stepper-text"
                          >
                            {standard_category_name}
                          </Text>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
