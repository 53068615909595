import React from "react";

import "./loader.scss";

export class Loader extends React.Component {
  render() {
    return (
      <div className="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
}

Loader.propTypes = {};

Loader.defaultProps = {};

export default Loader;
