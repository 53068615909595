import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function establishment(
  state = initialState.establishment,
  action
) {
  switch (action.type) {
    case types.UPDATE_ESTABLISHMENT_PROFILE: {
      return {
        ...state,
        establishmentProfile: {
          ...state.establishmentProfile,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case types.LOAD_ESTABLISHMENTPROFILE_SUCCESS:
      return Object.assign({}, state, {
        establishmentProfile: action.establishmentProfile,
      });

    case types.LOAD_ESTABLISHMENTS_SUCCESS:
      return Object.assign({}, state, {
        establishments: action.establishments,
      });

    case types.LOAD_ESTABLISHMENTACTIVEASSESSMENT_SUCCESS:
      return Object.assign({}, state, {
        activeAssessment: action.establishmentActiveAssessment,
      });

    case types.LOAD_ESTABLISHMENTACTIVEASSESSMENTS_SUCCESS:
      return Object.assign({}, state, {
        establishmentActiveAssessments: action.establishmentAssessments,
      });

    case types.LOAD_ESTABLISHMENTACTIVEASSESSMENTS_TOTAL_SUCCESS:
      return Object.assign({}, state, {
        establishmentActiveAssessmentsTotal: action.total,
      });

    case types.RESET_ESTABLISHMENTACTIVEASSESSMENTS:
      return Object.assign({}, state, {
        establishmentActiveAssessments: [],
      });

    case types.LOAD_ESTABLISHMENTASSESSMENTS_SUCCESS:
      return Object.assign({}, state, {
        establishmentAssessments: action.establishmentAssessments,
      });

    case types.GET_ESTABLISHMENTSELFASSESSMENTRESULT_SUCCESS:
      return Object.assign({}, state, {
        establishmentSelfAssessmentResult:
          action.establishmentSelfAssessmentResult,
      });

    case types.GET_MAJORVIOLATIONRESULT_SUCCESS:
      return Object.assign({}, state, {
        majorViolationResult: action.majorViolationResult,
      });

    case types.LOAD_SELECTESTABLISHMENTCATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        selectEstablishmentCategories: action.establishmentCategories,
      });

    case types.LOAD_ESTABLISHMENTSPECIALIZATIONS_SUCCESS:
      return Object.assign({}, state, {
        establishmentSpecializations: action.establishmentSpecializations,
      });

    case types.CREATE_ESTABLISHMENTSPECIALIZATION_SUCCESS:
      return Object.assign({}, state, {
        establishmentSpecializations: [
          ...state.establishmentSpecializations,
          Object.assign({}, action.establishmentSpecialization),
        ],
      });

    case types.UPDATE_ESTABLISHMENTSPECIALIZATION_SUCCESS:
      return Object.assign({}, state, {
        establishmentSpecializations: [
          ...state.establishmentSpecializations.filter(
            (establishmentSpecialization) =>
              establishmentSpecialization.id !==
              action.establishmentSpecialization.id
          ),
          Object.assign({}, action.establishmentSpecialization),
        ],
      });

    default:
      return state;
  }
}
