import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import "./breadcrumbs.scss";

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props);

    this.getTranslatedLabel = this.getTranslatedLabel.bind(this);
    this.getBreadcrumbs = this.getBreadcrumbs.bind(this);
  }

  getTranslatedLabel(rawPath) {
    if (rawPath === "establishmentAssessmentPayment") {
      return "تفاصيل الخدمة";
    }

    if (rawPath === "establishmentAssessmentResult") {
      return "نتيجة التقييم";
    }

    if (rawPath === "establishmentDurationsPage") {
      return "التقييم الذاتي";
    }

    if (rawPath === "establishmentUnifiedAssessment") {
      return "تفاصيل الخدمة";
    }
    if (rawPath === "establishmentAssessmentInstruction") {
      return "الفتره التقييمية";
    }
    if (rawPath === "establishmentAssessment") {
      return "ابدأ التقييم";
    }

    if (rawPath === "establishmentTraining") {
      return "بيانات التسجيل في الدورة التدريبية";
    }

    if (rawPath === "establishmentTrainings") {
      return "التدريب";
    }

    if (rawPath === "establishmentTrainingRegister") {
      return "بيانات التسجيل في الدورة التدريبية";
    }

    if (rawPath === "establishmentTrainingDetails") {
      return "تفاصيل التدريب";
    }

    return "";
  }

  getBreadcrumbs() {
    const { locations } = this.props;

    return locations.map((path) => {
      const rawPath = path.split("/")[1];
      const label = this.getTranslatedLabel(rawPath);

      return { label, path };
    });
  }

  render() {
    const { label } = this.props;

    return (
      <ul className="establishment-breadcrumbs">
        {this.getBreadcrumbs()
          .slice(-2)
          .map((item, idx, arr) => (
            <React.Fragment key={`${item.label}-${idx}`}>
              {idx === arr.length - 1 ? (
                <Text
                  Tag="li"
                  textType="h9"
                  isBold
                  className="establishment-breadcrumbs__item"
                >
                  {label || item.label}
                </Text>
              ) : (
                <Text
                  Tag="li"
                  textType="h9"
                  className="establishment-breadcrumbs__item"
                  isBold
                >
                  <Link to={item.path}>{item.label}</Link>
                </Text>
              )}
            </React.Fragment>
          ))}
      </ul>
    );
  }
}

Breadcrumbs.propTypes = {
  label: PropTypes.string,
  locations: PropTypes.array.isRequired,
};

Breadcrumbs.defaultProps = {
  label: "",
};

const mapStateToProps = (state) => ({
  locations: state.history.locations,
});

export default connect(mapStateToProps)(Breadcrumbs);
