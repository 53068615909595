import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import QRCode from "qrcode.react";
import moment from "moment";

const ComplianceCertPDF = ({
  establishmentSelfAssessmentResult,
  certificateLogo,
  MOL_logo,
  IMS,
}) => {
  return (
    <div>
      <div className="pdfWrapper page">
        <div
          className="establishment-assessment-card bgImgCenter"
          style={{
            border: `6px solid  ${establishmentSelfAssessmentResult.audit_report.color}`,
            borderRadius: "2px",
          }}
        >
          {establishmentSelfAssessmentResult.audit_report.certificate_status ===
            "Certified" && (
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Row>
                      <Col>{MOL_logo}</Col>
                      <Col>{IMS}</Col>
                    </Row>
                    <Row>
                      <Col className="card-header-style">
                        <strong
                          style={{ fontSize: "56px" }}
                          className="certificate-main-title"
                        >
                          شهادة الامتثال
                        </strong>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "200px", marginBottom: "50px" }}>
                      <Col className="card-header-style">
                        <strong style={{ fontSize: "36px" }}>
                          تمنح وزارة الموارد البشرية والتنمية الاجتماعية هذه
                          الشهادة لشركة
                        </strong>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="card-header-style">
                        <strong
                          style={{ fontSize: "40px" }}
                        >{`${establishmentSelfAssessmentResult.reports.establishment.establishment_name} ( ${establishmentSelfAssessmentResult.reports.establishment.labor_office_id} / ${establishmentSelfAssessmentResult.reports.establishment.sequence_id} ) `}</strong>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
                      <Col className="card-header-style">
                        <strong style={{ fontSize: "36px" }}>
                          وذلك لامتثالها لقوانيين وأنظمة الوزارة بنسبة{" "}
                          {
                            establishmentSelfAssessmentResult.audit_report
                              .auditing_score
                          }
                          %
                        </strong>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "36px", marginBottom: "50px" }}>
                      <Col className="card-header-style">
                        <div className="circlee">{certificateLogo}</div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="bottom-content">
                        <Col>
                          <Row className="certificate-barcode-text-align-right">
                            <Col>
                              رقم الشهادة{" "}
                              {
                                establishmentSelfAssessmentResult.audit_report
                                  .certificate_number
                              }
                            </Col>
                          </Row>
                        </Col>
                        <Row className="certificate-barcode-text-align-right">
                          <Col>
                            تاريخ انتهاء الشهادة{" "}
                            {moment(
                              establishmentSelfAssessmentResult.audit_report
                                .certificate_end_date.String
                            ).format("YYYY-MM-DD")}
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row style={{ color: "#000000" }}>
                          <Col>
                            {establishmentSelfAssessmentResult.audit_report
                              .certificate_number !== null && (
                              <QRCode
                                style={{ float: "left" }}
                                value={
                                  establishmentSelfAssessmentResult.audit_report
                                    .certificate_number
                                }
                                size={128}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"L"}
                                includeMargin={false}
                                renderAs={"svg"}
                                imageSettings={{
                                  src: "img/IMS-Logo.png",
                                  x: null,
                                  y: null,
                                  height: 30,
                                  width: 30,
                                  excavate: false,
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row className="certificate-barcode-text-align">
                          <Col>التحقق من صحة الشهادة</Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {establishmentSelfAssessmentResult.audit_report.certificate_status !==
            "Certified" && (
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Row>
                      <Col>{MOL_logo}</Col>
                      <Col>{IMS}</Col>
                    </Row>
                    <Row>
                      <Col className="pdfTitle-not-cert">
                        <strong className="certificate-main-title pdfTitle-not-cert-font-size">
                          إشعار نتيجة التدقيق
                        </strong>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="card-header-style">
                        <Row
                          style={{
                            border: `2px solid darkgreen`,
                            borderRadius: "20px",
                            marginLeft: "200px",
                            marginRight: "200px",
                            marginTop: "100px",
                            marginBottom: "100px",
                            padding: "40px",
                          }}
                        >
                          <Col>
                            <Row style={{ color: "#000000", fontSize: "14px" }}>
                              <Col>نسبة الامتثال</Col>
                            </Row>
                            <Row
                              className="certificate-main-title"
                              style={{
                                color: "#000000",
                                fontSize: "14px",
                              }}
                            >
                              <Col>
                                {
                                  establishmentSelfAssessmentResult.audit_report
                                    .auditing_score
                                }
                                %
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row style={{ color: "#000000", fontSize: "14px" }}>
                              <Col>حالة المنشأة</Col>
                            </Row>
                            <Row
                              className="certificate-main-title"
                              style={{ color: "#000000", fontSize: "14px" }}
                            >
                              <Col>لم تجتاز النسبة المطلوبة</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: "25px" }}>
                      <Col className="card-header-style">
                        <strong style={{ fontSize: "20px" }}>
                          تمنح وزارة الموارد البشرية والتنمية الاجتماعية هذا
                          الاشعار لشركة
                        </strong>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="card-header-style certificate-est-name">
                        <strong
                          style={{ fontSize: "20px" }}
                          className="certificate-main-title"
                        >{`${establishmentSelfAssessmentResult.reports.establishment.establishment_name} ( ${establishmentSelfAssessmentResult.reports.establishment.labor_office_id} / ${establishmentSelfAssessmentResult.reports.establishment.sequence_id} ) `}</strong>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <Col className="card-header-style">
                        <strong style={{ fontSize: "18px" }}>
                          وذلك لعدم اجتيازها النسبة المطلوبة للحصول على شهادة
                          الامتثال،
                        </strong>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <Col className="card-header-style">
                        <strong style={{ fontSize: "18px" }}>
                          علماً بأن الحد الأدنى للحصول على الشهادة هو 80%
                        </strong>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <Col className="card-header-style">
                        <div className="circlee">{certificateLogo}</div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="bottom-content">
                        <Row className="certificate-barcode-text-align-right">
                          <Col>
                            رقم الشهادة{" "}
                            {
                              establishmentSelfAssessmentResult.audit_report
                                .certificate_number
                            }
                          </Col>
                        </Row>
                        <Row className="certificate-barcode-text-align-right">
                          <Col>
                            تاريخ انتهاء الشهادة{" "}
                            {moment(
                              establishmentSelfAssessmentResult.audit_report
                                .certificate_end_date.String
                            ).format("YYYY-MM-DD")}
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row style={{ color: "#000000" }}>
                          <Col>
                            {establishmentSelfAssessmentResult.audit_report
                              .certificate_number && (
                              <QRCode
                                style={{ float: "left" }}
                                value={
                                  establishmentSelfAssessmentResult.audit_report
                                    .certificate_number
                                }
                                size={128}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"L"}
                                includeMargin={false}
                                renderAs={"svg"}
                                imageSettings={{
                                  src: "img/IMS-Logo.png",
                                  x: null,
                                  y: null,
                                  height: 20,
                                  width: 20,
                                  excavate: false,
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row className="certificate-barcode-text-align">
                          <Col>التحقق من صحة الشهادة</Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};
ComplianceCertPDF.prototype = {
  establishmentSelfAssessmentResult: PropTypes.object.isRequired,
  certificateLogo: PropTypes.string,
  MOL_logo: PropTypes.string,
  IMS: PropTypes.string,
};
ComplianceCertPDF.default = {};
export default ComplianceCertPDF;
