import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LocationPicker from "../maps/LocationPicker";
import "./establishmentTrainingRegister.scss";
import { submitTrainingPayment } from "../../actions/establishmentProfileActions";
import * as TrainingSubjectActions from "../../actions/trainingSubjectActions";
import isEqual from "lodash/isEqual";

import {
  createTraining,
  fetchTraining,
  updateTraining,
} from "../../actions/trainingActions";
import PaymentCard from "./PaymentCard";
import PageWrapper from "../PageWrapper/PageWrapper";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import {
  STATUS_CHECKOUT,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETE,
  STATUS_READY,
  STATUS_REGISTER,
} from "../../common/constants/trainingStatuses";
import TitledTextInput from "../TitledTextInput/TitledTextInput";
import {
  digitsOnly,
  emailOnly,
  isEmailFormatOnly,
  isPhoneNumberFormat,
} from "../../../utilities/helpers";
import Loader from "../Loader/Loader";
import { TRAINING } from "../../common/constants/paymentStatuses";
import PopUp from "../../views/Components/PopUp/PopUp";
import LabeledCheckbox from "../LabeledCheckBox/LabeledCheckbox";

export const emptyTraining = {
  id: "",
  email: "",
  address: "",
  lat: RIYADH_LOCATION_LAT,
  lng: RIYADH_LOCATION_LNG,
  subject_id: "",
  mobile: "",
  status: "",
};
const RIYADH_LOCATION_LAT = 24.7162642;
const RIYADH_LOCATION_LNG = 46.6865202;
export const defaultMapLocation = {
  lat: RIYADH_LOCATION_LAT,
  lng: RIYADH_LOCATION_LNG,
};

export const emptyTrainingSubject = {
  amount: 0,
  benefits: "",
  description: "",
  details: "",
  id: "",
  name: "",
  service_id: "",
  status: "",
  total_hours: 0,
  training_id: null,
  userId: null,
  vat: 0,
};

class EstablishmentTrainingRegister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trainingUserInfo: { ...emptyTraining },
      trainingSubject: { ...emptyTrainingSubject },
      errors: {},
      address: null,
      disabled: false,
      courseName: null,
      isLoading: false,
      trainingPaymentId: null,
      sadadNumber: null,
      isShowPopUp: false,
      DoYouWantToPay: false,
    };
    this.saveTraining = this.saveTraining.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.updateTrainingState = this.updateTrainingState.bind(this);
    this.redirectToPaymentGateway = this.redirectToPaymentGateway.bind(this);
    this.emailValidation = this.emailValidation.bind(this);
    this.mobileValidation = this.mobileValidation.bind(this);
    this.handlePaymentClick = this.handlePaymentClick.bind(this);
  }

  handlePaymentClick(val) {
    this.setState({ DoYouWantToPay: val });
  }
  componentDidMount() {
    const { isLogin } = this.props;
    const { establishmentId, status, trainingId } = this.props.match.params;

    if (!isLogin) {
      window.location =
        process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
      window.location.reload();
    }

    if (trainingId && status === STATUS_REGISTER) {
      this.props.onFetchTrainingSubject(establishmentId, trainingId);
    } else if (trainingId) {
      this.props.onFetchTraining(establishmentId, trainingId);
    }
  }

  componentDidUpdate(prevProps) {
    const { training } = this.props;
    const { status } = this.props.match.params;
    const { trainingPaymentId, sadadNumber, billNumber } = this.state;

    if (status !== STATUS_REGISTER) {
      if (!isEqual(prevProps.training, this.props.training)) {
        this.setState({
          trainingUserInfo: training || emptyTraining,
        });
      }
    } else {
      if (!isEqual(prevProps.trainingSubject, this.props.trainingSubject)) {
        this.setState({
          trainingSubject: this.props.trainingSubject || emptyTrainingSubject,
        });
      }
    }
    if (
      prevProps.trainingCreating &&
      !this.props.trainingCreating &&
      trainingPaymentId
    ) {
      if (!this.props.trainingCreatingError) {
        toastr.success("تم إضافة الدورة التدريبية بنجاح.");

        this.redirectToPaymentGateway(
          trainingPaymentId,
          sadadNumber,
          billNumber
        );
      } else {
        toastr.error(this.props.trainingCreatingError.reason);
      }
    }
  }
  redirectToPaymentGateway(trainingPaymentId, sadadNumber, billNumber) {
    const { history } = this.props;
    const { status } = this.props.match.params;

    history.push(
      `/checkout/${trainingPaymentId}/${TRAINING}/${sadadNumber}/${billNumber}`
    );
  }
  trainingFormIsValid() {
    const email_rex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    const re = /^[0-9]+/;

    let formIsValid = true;
    let errors = {};

    if (
      !this.state.trainingUserInfo.address ||
      this.state.trainingUserInfo.address.trim() === ""
    ) {
      errors.address = "الرجاء إدخال العنوان";
      formIsValid = false;
    }

    if (
      this.state.trainingUserInfo.email.trim().length == 0 ||
      !this.state.trainingUserInfo.email.trim().match(email_rex)
    ) {
      errors.email = "الرجاء إدخال بريد إلكتروني صحيح";
      formIsValid = false;
    }
    if (
      this.state.trainingUserInfo.mobile.trim().length !== 10 ||
      !re.test(this.state.trainingUserInfo.mobile.trim())
    ) {
      errors.mobile = "الرجاء إدخال هاتف صحيح";
      formIsValid = false;
    }
    if (!this.state.trainingUserInfo.mobile.trim().startsWith("05")) {
      errors.mobile = "الرجاء إدخال هاتف صحيح";
      formIsValid = false;
    }

    this.setState({ errors: errors });

    return formIsValid;
  }

  mobileValidation() {
    const { trainingUserInfo, errors } = this.state;

    const isCorrectFormat = isPhoneNumberFormat(trainingUserInfo.mobile);
    if (trainingUserInfo.mobile === "") {
      this.setState({
        errors: { ...errors, mobile: null },
      });
    } else if (!isCorrectFormat) {
      this.setState({
        errors: {
          ...errors,
          mobile: "الرجاء إدخال رقم تواصل يبدأ بـ05",
        },
      });
    } else {
      this.setState({
        errors: { ...errors, mobile: null },
      });
    }
  }
  emailValidation() {
    const { trainingUserInfo, errors } = this.state;

    const isCorrectFormat = isEmailFormatOnly(trainingUserInfo.email);
    if (trainingUserInfo.email === "") {
      this.setState({
        errors: { ...errors, email: null },
      });
    } else if (!isCorrectFormat) {
      this.setState({
        errors: {
          ...errors,
          email: "الرجاء إدخال بريد إلكتروني صحيح",
        },
      });
    } else {
      this.setState({
        errors: { ...errors, email: null },
      });
    }
  }

  saveTraining(event) {
    event.preventDefault();
    const { status } = this.props.match.params;

    if (!this.trainingFormIsValid()) {
      return;
    }
    const { id, ...trainingUserInfo } = this.state.trainingUserInfo;
    const establishmentId = this.props.match.params.establishmentId;
    const { trainingSubject } = this.props;

    if (this.props.match.params.trainingId) {
      trainingUserInfo.subject_id = this.props.match.params.trainingId;
      this.setState({ isLoading: true });
      this.props
        .onCreateTraining(establishmentId, trainingUserInfo, trainingSubject)
        .then((val) => {
          this.redirectToPaymentGateway(
            val.training.id,
            val.sadad_number,
            val.payment_resp_bill_number
          );
          this.setState({
            isLoading: false,
            trainingPaymentId: val.training.id,
            sadadNumber: val.sadad_number,
            billNumber: val.payment_resp_bill_number,
          });
        })
        .catch((error) => {
          if (error == 424) {
            this.setState({
              isLoading: false,
              isShowPopUp: true,
            });
          } else {
            this.setState({
              isLoading: false,
            });
            toastr.error(error);
          }
        });
    }
  }

  updateTrainingState(event) {
    const re = /^[0-9\b]+$/;
    const field = event.target.name;
    let training = { ...this.state.trainingUserInfo }; // prevent mutating

    switch (field) {
      case "subject_id": {
        training.subject_id = event.target.value;
        break;
      }
      case "mobile": {
        const digits = digitsOnly(event.target.value);
        training[field] = digits;
        break;
      }
      case "email": {
        const email = emailOnly(event.target.value);
        training[field] = email;
        break;
      }
      default: {
        training[field] = event.target.value;
      }
    }
    return this.setState({ trainingUserInfo: training });
  }

  handleLocationChange = (location) => {
    const { address, position } = location;
    let training = { ...this.state.trainingUserInfo }; // prevent mutating
    training.address = address;
    training.lat = position.lat;
    training.lng = position.lng;
    return this.setState({ trainingUserInfo: training });
  };
  // redirectToSelfAssessmentPayment() {
  //   this.props
  //     .submitTrainingPayment(
  //       this.props.match.params.establishmentId,
  //       this.props.trainingSubject.service_id,
  //       this.props.match.params.trainingId
  //     )
  //     .then(() => {})
  //     .catch((error) => {
  //       toastr.error("تعذر الدفع نسبة لوجود طلب مسبق");
  //     });
  // }
  getCourseName = () => {
    const courseName = "اسم الدورة التدريبية";
    if (this.props.trainingSubjects) {
      this.props.trainingSubjects.map((course) => {
        if (course.id === this.props.match.params.trainingId) {
          this.setState({ courseName: course.name });
          return;
        }
      });
      this.setState({ courseName: courseName });
    } else this.setState({ courseName: courseName });
  };
  render() {
    const {
      errors,
      address,
      trainingSubject,
      trainingUserInfo,
      isLoading,
      DoYouWantToPay,
    } = this.state;
    const { status } = this.props.match.params;
    const { trainingSubjectFetching, trainingFetching } = this.props;
    const mapLocationSpecified = address && address.google_address;

    const establishmentMapLocation = address && {
      lat: address.lat,
      lng: address.lng,
    };
    const disabled =
      status === STATUS_CHECKOUT ||
      status === STATUS_READY ||
      status === STATUS_IN_PROGRESS ||
      status === STATUS_COMPLETE;

    const isStatusRegister = status === STATUS_REGISTER;

    const trainingName =
      status === STATUS_REGISTER
        ? trainingSubject.name
        : trainingUserInfo.subject_name;
    return (
      <PageWrapper withBreadcrumbs breadcrumbLabel={trainingName}>
        {isLoading ? (
          <div className="loader-wrapper">
            <Loader />
          </div>
        ) : (
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <div className="training-details-header ">
              <Text
                Tag="div"
                isBold
                textType="h5"
                className="training-details-header__text"
              >
                بيانات التسجيل في الدورة التدريبية
              </Text>
            </div>
            {(isStatusRegister && !trainingSubjectFetching) ||
            (!isStatusRegister && !trainingFetching) ? (
              <div className="establishment-training-boxes-wrapper">
                <div style={{ flex: 2, display: "flex", maxWidth: "1096px" }}>
                  <div className="establishment-training-register-wrapper ">
                    <div>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: 20,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <div className="flex-nowrap establishmnet-assessments-wrap establishment-training-register-text-inputs-wrapper">
                            <div className="establishment-training-register-mobile ">
                              <TitledTextInput
                                title="رقم التواصل"
                                type="text"
                                onChange={this.updateTrainingState}
                                onBlur={this.mobileValidation}
                                onFocus={() =>
                                  this.setState({
                                    errors: { ...errors, mobile: null },
                                  })
                                }
                                placeholder="05xx-xxx-xxxx"
                                value={trainingUserInfo.mobile}
                                name="mobile"
                                errorText={errors.mobile}
                                maxLength="10"
                                disabled={disabled}
                                id="input-register-mobile"
                              />
                            </div>
                            <div className="establishment-training-register-email">
                              <TitledTextInput
                                title="البريد الإلكتروني"
                                type="text"
                                onChange={this.updateTrainingState}
                                onBlur={this.emailValidation}
                                onFocus={() => {
                                  this.setState({
                                    errors: { ...errors, email: null },
                                  });
                                }}
                                placeholder="example@company.com"
                                value={trainingUserInfo.email}
                                name="email"
                                errorText={errors.email}
                                maxLength="70"
                                disabled={disabled}
                                id="input-register-email"
                              />
                            </div>
                          </div>
                          <div className="establishment-training-register-address-container">
                            <Text
                              Tag="div"
                              className="address-text"
                              textType="p6"
                              isBold
                            >
                              العنوان
                            </Text>
                            {/* <Text
                              Tag="div"
                              textType="p6"
                              className="training-user-address-title"
                            >
                              {trainingUserInfo && trainingUserInfo.address
                                ? trainingUserInfo.address
                                : address && address.google_address
                                ? address.google_address
                                : "يرجى تحديد الموقع على خرائط قوقل"}
                            </Text> */}
                            <div style={{ width: "854px" }}>
                              <LocationPicker
                                googleMapURL={
                                  "https://maps.googleapis.com/maps/api/js?key=" +
                                  process.env.REACT_APP_GOOGLE_MAPS_API_KEY +
                                  "&language=en&v=3&libraries=geometry,drawing,places"
                                }
                                loadingElement={<div>Loading...</div>}
                                containerElement={
                                  <div
                                    className={`gmap-wrap ${
                                      !disabled ? "editing" : ""
                                    }`}
                                    style={{
                                      marginBottom: "20px",
                                      display: "flex",
                                      flex: 1,
                                    }}
                                  />
                                }
                                mapElement={
                                  <div
                                    className="gmap-elem"
                                    style={{
                                      height: "250px",
                                      display: "flex",
                                      flex: 1,
                                    }}
                                  />
                                }
                                defaultPosition={
                                  mapLocationSpecified
                                    ? establishmentMapLocation
                                    : defaultMapLocation
                                }
                                mapControlsEnabled={!disabled}
                                onChange={this.handleLocationChange}
                                withInputSearch={true}
                              />
                            </div>
                            {errors.address && (
                              <div className="alert-message">
                                <Text
                                  className="div-float"
                                  Tag="span"
                                  textType="p5"
                                >
                                  {errors.address}
                                </Text>
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              alignContent: "center",
                              marginTop: "30px",
                            }}
                          >
                            <LabeledCheckbox
                              type="checkbox"
                              onChange={this.handlePaymentClick}
                              checked={DoYouWantToPay}
                              label=" أقر وأوافق على أن المبالغ المدفوعة للخدمة غير خاضعة لسياسة
                الاسترداد."
                              id="training-register-agreement-checkbox"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1, maxWidth: "400px" }}>
                  <PaymentCard
                    match={this.props.match}
                    onButtonClick={this.saveTraining}
                    isError={errors.email || errors.mobile || errors.address}
                    course={
                      isStatusRegister ? trainingSubject : trainingUserInfo
                    }
                    isStatusRegister={isStatusRegister}
                    trainingName={trainingName}
                    DoYouWantToPay={DoYouWantToPay}
                  />
                </div>
              </div>
            ) : (
              <div className="loader-div">
                <Loader />
              </div>
            )}
          </div>
        )}
        <PopUp
          isOpen={this.state.isShowPopUp}
          title="إضافة عنوان المنشأة"
          body="لإتمام عملية الدفع يرجى إضافة العنوان الوطني للمنشأة ، و ذلك عن طريق الانتقال لصفحة ملف المنشأة."
          onClickCancel={() => this.setState({ isShowPopUp: false })}
          cancelButtonText="حسنًا"
        />
      </PageWrapper>
    );
  }
}

EstablishmentTrainingRegister.propTypes = {
  disabled: PropTypes.bool,
  training: PropTypes.instanceOf(Object),
  trainingSubject: PropTypes.instanceOf(Object),
  trainingCreating: PropTypes.bool.isRequired,
  trainingCreatingError: PropTypes.instanceOf(Object),
  trainingFetching: PropTypes.bool.isRequired,
  trainingFetchingError: PropTypes.instanceOf(Object),
  trainingUpdating: PropTypes.bool.isRequired,
  trainingUpdatingError: PropTypes.instanceOf(Object),
  onCreateTraining: PropTypes.func.isRequired,
  onFetchTraining: PropTypes.func.isRequired,
  onUpdateTraining: PropTypes.func.isRequired,
  onFetchTrainingSubject: PropTypes.func.isRequired,
  trainingSubjects: PropTypes.array.isRequired,
  isLogin: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

EstablishmentTrainingRegister.defaultProps = {
  training: null,
  trainingSubject: null,
  trainingCreatingError: null,
  trainingFetchingError: null,
  trainingUpdatingError: null,
  disabled: false,
};

const mapStateToProps = (state) => ({
  training: state.trainings.training,
  trainingCreating: state.trainings.trainingCreating,
  trainingCreatingError: state.trainings.trainingCreatingError,
  trainingFetching: state.trainings.trainingFetching,
  trainingFetchingError: state.trainings.trainingFetchingError,
  trainingUpdating: state.trainings.trainingUpdating,
  trainingUpdatingError: state.trainings.trainingUpdatingError,
  isLogin: state.auth.token !== null,
  trainingSubject: state.trainingSubjects.trainingSubject,
  trainingSubjects: state.trainingSubjects.trainingSubjectsList,
  trainingSubjectFetching: state.trainingSubjects.trainingSubjectFetching,
});

const mapDispatchToProps = {
  onCreateTraining: createTraining,
  onFetchTraining: fetchTraining,
  onUpdateTraining: updateTraining,
  onFetchTrainingSubjects: TrainingSubjectActions.fetchTrainingSubjects,
  submitTrainingPayment: submitTrainingPayment,
  onFetchTrainingSubject: TrainingSubjectActions.fetchTrainingSubject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstablishmentTrainingRegister);
