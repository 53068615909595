export default [
  //معايير عامة للعاملين 
  {
      id: 1,
      name: "المادة 109 من نظام العمل",
      description: `1. يستحق العامل عن كلّ عام إجازة سنوية لا تقلّ مدّتها عن واحد وعشرين يومًا، تُزاد إلى مدّة لا تقل عن ثلاثين يومًا إذا أمضى العامل في خدمة صاحب العمل خمس سنوات متّصلة، وتكون الإجازة بأجر يدفع مقدمًا.
          2. يجب أن يتمتع العامل بإجازته في سنة استحقاقها، ولا يجوز النزول عنها، أو أن يتقاضى بدلًا نقديًّا عوضًا عن الحصول عليها أثناء خدمته، ولصاحب العمل أن يحدّد مواعيد هذه الإجازات وفقًا لمقتضيات العمل، أو يمنحها بالتناوب لكي يؤمّن سير عمله، وعليه إشعار العامل بالميعاد المحدّد لتمتّعه بالإجازة بوقت كافٍ لا يقل عن ثلاثين يومًا.`,
      recommendations: `تأكد مما يلي:
          1. استحقاق العامل عن كلّ عام إجازة سنوية لا تقلّ مدّتها عن واحد وعشرين يومًا، وتزيد هذه الإجازة إلى مدّة لا تقلّ عن ثلاثين يومًا إذا أمضى العامل خمس سنوات.
          2. عدم تقاضي بدل نقدي عوضًا عن الحصول على الإجازة أثناء خدمة العامل.
          3. عدم تأخير فترة الإجازة لمدّة لا تزيد على تسعين يومًا والحصول على موافقة العامل كتابةً في حال استمرار التأجيل على ألّا يتعدّى التأجيل نهاية السنة التالية لسنة استحقاق الإجازة متّصلة في الخدمة.`,
      parentId: 1,
    },
    {
        id: 2,
        name: "المادة 112 من نظام العمل",
        description: `لكلّ عامل الحق – في الحالات المشار إليها - في إجازة بأجر كامل في الأعياد والمناسبات التي تحددها اللائحة.`,
        recommendations: `تأكد من التالي:
            1. تحديد اجازات الأعياد والمناسبات وفق ما يلي:
            - إجازة عيد الفطر لمدة أربعة أيام تبدأ من اليوم التالي لليوم التاسع والعشرين من شهر رمضان حسب تقويم أم القرى
            - إجازة عيد الأضحى لمدة أربعة أيام تبدأ من يوم الوقوف بعرفة
            - إجازة اليوم الوطني للمملكة لمدة يوم واحد، في اول يوم من برج الميزان حسب تقويم أم القرى
            2. في حال تداخل أيام اجازات الاعياد والمناسبات مع الآتي:
            - الراحة الاسبوعية: يعوض العامل عنها بما يعادلها قبل ايام تلك الاجازة او بعدها
            - الاجازة السنوية: تمدد الاجازة السنوية بقدر أيام هذه الاجازات
            - الاجازة المرضية: يستحق العامل الأجر الكامل عن أيام هذه الإجازات دون النظر للأجر المستحق عن أيام الإجازة المرضية
            - اليوم الوطني مع ايام إجازة أحد العيدين: لا يعوض العامل عن هذا اليوم`,
        parentId: 1,
      },
      {
        id: 3,
        name: "المادة 113 من نظام العمل",
        description: `مع مراعاة إجازات المرأة العاملة المحدّدة بموجب نظام العمل، للعامل الحق في إجازة بأجر كامل لمدّة خمسة أيام في حالة وفاة زوجه أو أحد أصوله أو فروعه، أو عند زواجه، وثلاثة أيام في حالة ولادة مولود له، ويحق لصاحب العمل أن يطلب الوثائق المؤيّدة.`,
        recommendations: `تأكد من حصول العاملين في المنشأة على إجازة بأجر كامل في الحالات التالية:
            - إجازة مدة 5 أيام في حالة وفاة أحد أصول أو فروع العامل
            - إجازة مدة 5 أيام عند زواج العامل
            - إجازة 3 أيام حال ولادة مولود للعامل`,
        parentId: 1,
      },
      {
        id: 4,
        name: "المادة 114 من نظام العمل",
        description: `للعامل الحق في الحصول على إجازة بأجر لا تقلّ مدّتها عن عشرة أيام ولا تزيد على خمسة عشر يومًا بما فيها إجازة عيد الأضحى، وذلك لأداء فريضة الحج لمرة واحدة طوال مدّة خدمته إذا لم يكن قد أدّاها من قبل، ويشترط لاستحقاق هذه الإجازة أن يكون العامل قد أمضى في العمل لدى صاحب العمل سنتين متّصلتين على الأقل، ولصاحب العمل أن يحدّد عدد العمّال الذين يُمنحون هذه الإجازة سنويًّا وفقًا لمقتضيات العمل.`,
        recommendations: `تأكد من التالي:
            تضمين وتطبيق إجازة الحج بأجر كامل المقدرة بما لا يقل عن 10 أيام ولا تزيد عن 15 يوم ضمن لائحة تنظيم العمل للمنشأة واستحقاق جميع العاملين الذين لم يؤدوا فريضة الحج من قبل لها على أن يكون العامل قد أمضى سنتين متصلتين على الأقل مع صاحب العمل`,
        parentId: 1,
      },
      {
        id: 5,
        name: "المادة 115 من نظام العمل",
        description: `1. للعامل - إذا وافق صاحب العمل على انتسابه إلى مؤسسة تعليمية أو قبل استمراره فيها - الحق في إجازة بأجر كامل لتأدية الامتحان عن سنة غير معادة تحدّد مدّتها بعدد أيام الامتحان الفعليّة. أمّا إذا كان الامتحان عن سنة معادة فيكون للعامل حق في إجازة دون أجر بعدد أيام الامتحان الفعليّة. ويُحرم العامل من أجر الإجازة إذا ثبت أنه لم يؤدّي الامتحان مع عدم إخلال بحقّ صاحب العمل في مساءلته تأديبًّا.
            2. إذا لم يحصل العامل على موافقة صاحب العمل على انتسابه إلى مؤسسة تعليمية، فللعامل أن يحصل على إجازة لتأدية الامتحان بعدد أيام الامتحان الفعليّة تُحتسب من إجازته السنويّة في حال توفّرها، وعند تعذّر ذلك فللعامل أن يحصل على إجازة دون أجر بعدد أيام الامتحان الفعليّة.
            3. على العامل أن يتقدم بطلب الإجازة قبل موعدها بخمسة عشر يومًا على الأقل.
            4. لصاحب العمل أن يطلب من العامل تقديم الوثائق المؤيّدة لطلب الإجازة، وكذلك على ما يدل على أدائه الامتحان.
            `,
        recommendations: `تأكد من التالي:
            تضمين وتطبيق إجازة  لأداء الامتحانات ضمن لائحة تنظيم العمل في المنشأة بحسب ما توضحه المادة 115 من نظام العمل`,
        parentId: 1,
      },
      {
        id: 6,
        name: "المادة 117 من نظام العمل",
        description: `للعامل الذي يثبت مرضه الحق في إجازة مَرضيّة بأجر عن الثلاثين يومًا الأولى، وبثلاثة أرباع الأجر عن الستين يومًا التالية، ودون أجر للثلاثين يومًا التي تلي ذلك خلال السنة الواحدة، سواء أكانت هذه الإجازات متّصلة أم متقطّعة. ويُقصد بالسنة الواحدة: السنة التي تبدأ من تاريخ أول إجازة مَرضيّة.`,
        recommendations: `تأكد من التالي:
            استحقاق العامل الذي يثبت مرضه على إجازة مَرضيّة بأجر كامل عن الثلاثين يومًا الأولى، و 75% من الأجر عن الستين يومًا التالية، ودون أجر للثلاثين يومًا التي تلي ذلك خلال السنة الواحدة، سواء أكانت هذه الإجازات متّصلة أم متقطّعة. ويُقصد بالسنة الواحدة: السنة التي تبدأ من تاريخ أول إجازة مَرضيّة.`,
        parentId: 1,
      },
      {
    id: 7,
    name: "المادة 90 في نظام العمل (2/1)",
    description: `1. يجب دفع أجر العامل وكلّ مبلغ مستحق له بالعملة الرسميّة للبلاد، كما يجب دفع الأجر في ساعات العمل ومكانه طبقًا للأحكام الآتية:
        ‌- العمّال باليوميّة تُصرف أجورهم مرة كل أسبوع على الأقل.
        ‌- العمّال ذوو الأجور الشهريّة تُصرف أجورهم مرّة في الشهر.
        ‌- إذا كان العمل يؤدّى بالقطعة ويحتاج لمدة تزيد على أسبوعين، فيجب أن يحصل العامل على دفعة كلّ أسبوع تتناسب مع ما أتمّه من العمل، ويُصرف باقي الأجر كاملًا خلال الأسبوع التالي لتسليم العمل.
        ‌- في غير ما ذكر تؤدّى إلى العمّال أجورهم مرة كل أسبوع على الأقل.
        `,
    recommendations: `تأكّد من أنّ المنشأة تقوم بما يلي:
        1. دفع أجر العامل وكلّ مبلغ مستحق له بالعملة الرسميّة للبلاد.
        2. دفع أجر العامل في ساعات العمل ومكانه طبقًا للأحكام المنصوصة في نظام العمل الآتية:
        - يتم صرف أجور العمّال باليوميّة مرّة كلّ أسبوع على الأقل.
        - يتم صرف أجور العمّال ذوي الأجور الشهرية مرّة في الشهر.
        - إذا كان العمل يؤدّى بالقطعة ويحتاج لمدة تزيد على أسبوعين، فيجب أن يحصل العامل على دفعة كلّ أسبوع تتناسب مع ما أتمّه من العمل، ويُصرف باقي الأجر كاملًا خلال الأسبوع التالي لتسليم العمل.
        - في غير ما ذكر تؤدى إلى العمال أجورهم مرة كل أسبوع على الأقل
        `,
    parentId: 2,
  },
  {
    id: 8,
    name: "المادة 90 في نظام العمل (2/2)",
    description: `2. تلتزم المنشآت بدفع الأجور في حسابات العمّال عن طريق البنوك المعتمدة بشرط ألّا يتجاوز استحقاقها الموعد المحدّد في الفقرة الأولى من المادة 90 في نظام العمل.
        `,
    recommendations: `تأكد من أن المنشأة تقوم بما يلي:
        1. دفع الأجور في حسابات العمال عن طريق البنوك المعتمدة بشرط ألا يتجاوز استحقاقها الموعد المحدّد في الفقرة الأولى من المادة 90 في نظام العمل.
        2. رفع ملف حماية الأجور بشكل شهري`,
    parentId: 2,
  },
  {
      id: 9,
      name: "القرار الوزاري رقم 75506 وتاريخ 05/05/1444هـ",
      description: `يجب أن يُكتب عقد العمل من نسختين ويحتفظ كل من طرفيه بنسخة. ويُعدّ العقد قائمًا ولو كان غير مكتوب، وفي هذه الحالة يجوز للعامل وحده إثبات العقد وحقوقه التي نشأت عنه بجميع طرق الإثبات. ويكون لكلّ من الطرفين أن يطلب كتابة العقد في أيّ وقت، أمّا عمّال الحكومة والمؤسسات العامّة فيقوم قرار أو أمر التعيين الصادر من الجهة المختصّة مقام العقد.`,
      recommendations: `تأكد مما يلي:
      يجب أن يُكتب عقد العمل من نسختين ويحتفظ كل من طرفيه بنسخة. ويُعدّ العقد قائمًا ولو كان غير مكتوب، وفي هذه الحالة يجوز للعامل وحده إثبات العقد وحقوقه التي نشأت عنه بجميع طرق الإثبات. ويكون لكلّ من الطرفين أن يطلب كتابة العقد في أيّ وقت، أمّا عمّال الحكومة والمؤسسات العامّة فيقوم قرار أو أمر التعيين الصادر من الجهة المختصّة مقام العقد.`,
      parentId: 3,
    },
  // معايير عامة للمنشآت
{
    id: 10,
    name: "المادة 9 من نظام العمل",
    description: `اللغة العربية هي الواجبة الإستعمال في البيانات والسجلات والملفات وعقود العمل وغيرها مما هو منصوص عليه في هذا النظام، أو في أي قرار صادر تطبيقاً لأحكامه، وكذلك التعليمات التي يصدرها صاحب العمل لعماله. وإذا استعمل صاحب العمل لغة أجنبية إلى جانب اللغة العربية في أي من الحالات المذكورة، فإن النص العربي هو المعتمد دون غيره`,
    recommendations: `تأكّد من أنّ النصّ العربي مستخدم كلغة رسميّة ضمن مستندات المنشأة ويجوز استعمال لغة أجنبية إلى جانب اللغة العربية بشرط أن تكون اللغة العربية هي اللغة المعتمدة.`,
    parentId: 4,
  },
  {
    id: 11,
    name: "المادة 13 من نظام العمل",
    description: `1. يلتزم كلّ صاحب عمل بإعداد لائحة لتنظيم العمل في منشأته وفق النّموذج المعدّ من الوزارة، ويجوز للوزير الاستثناء من ذلك.
        2. يجوز لصاحب العمل تضمين اللائحة شروطًا وأحكامًا إضافية بما لا يتعارض مع أحكام نظام العمل، ولائحته والقرارات الصادرة تنفيذًا له.
        3. يجب على صاحب العمل أن يعلن لائحة تنظيم العمل وأيّ تعديل يطرأ عليها في مكان ظاهر في المنشأة أو أيّ وسيلة أخرى تكفل علم الخاضعين لها بأحكامها.`,
    recommendations: `تأكّد من التالي:
        أن المنشأة قامت بإعداد لائحة تنظيم العمل وفق النموذج المعتمد من الوزارة في ملحق (1) من اللائحة التنظيمية لنظام العمل
         أنّ المنشأة قد قامت بإعلان لائحة تنظيم العمل بعد اعتمادها من وزارة الموارد البشرية في مكان ظاهر في المنشأة أو بأيّ وسيلة أخرى تكفل معرفة العاملين بها (ويجوز للمنشأة إعلان اللائحة من خلال البريد الإلكتروني أو الشبكة الداخلية).`,
    parentId: 4,
  },
  {
    id: 12,
    name: "المادة 15 من نظام العمل",
    description: `على صاحب العمل عند بدء العمل في أي منشأة أن يبلغ مكتب العمل المختص كتابة بالبيانات الآتية:
        اسم المنشأة ونوعها ومركزها، والعنوان الذي توجه إليه المراسلات، وأي معلومة يكون من شأنها سهولة الاتصال بالمنشأة.
        النشاط الاقتصادي المرخص له بمزاولته مع ذكر رقم السجل التجاري أو الترخيص وتاريخه، وجهة إصداره، وإرفاق صورة منه.
        عدد العمال المراد تشغيلهم في المنشأة.
        اسم مدير المنشأة المسؤول.
        أي بيانات أخرى تطلبها الوزارة.
        `,
    recommendations: `تأكد من قيامك بفتح ملف للمنشأة لدى مكتب العمل متضمنا المعلومات التالي وتحديثها باستمرار:
        اسم المنشأة ونوعها ومركزها، والعنوان الذي توجه إليه المراسلات، وأي معلومة يكون من شأنها سهولة الاتصال بالمنشأة.
        النشاط الاقتصادي المرخص له بمزاولته مع ذكر رقم السجل التجاري أو الترخيص وتاريخه، وجهة إصداره، وإرفاق صورة منه.
        عدد العمال المراد تشغيلهم في المنشأة.
        اسم مدير المنشأة المسؤول.
        أي بيانات أخرى تطلبها الوزارة.
        كما يجب مراعاة التالي:
        إذا لم يتمكّن صاحب العمل من ممارسة العمل بنفسه، فعليه أن يعيّن مسؤولًا يمثّله في مكان العمل. وفي حالة تعدّد الشركاء أو المديرين في المنشأة، يُسمّى أحدهم من المقيمين في مكان العمل، يمثّل صاحب العمل ويكون مسؤولًا عن أيّ مخالفة لأحكام نظام العمل.
        على صاحب العمل أن يبلّغ مكتب العمل المختصّ كتابةً باسم الشريك أو المدير، وعليه في حالة تغييره أن يُبلغ المكتب باسم الشريك أو المدير الجديد خلال سبعة أيام على الأكثر من تولّي هذا الأخير عمله.
        إذا لم يوجد شخص معيّن بصفة مدير مسؤول للمنشأة - أو إذا لم يباشر الشخص المعيّن عمله - فإنّ من يقوم فعلًا بأعمال المدير أو صاحب العمل نفسه يُعدّ مديرًا مسؤولًا عن المنشأة.
        وفي كلّ الأحوال تبقى مسؤولية صاحب العمل قائمة بصفة أصليّة.`,
    parentId: 4,
  },
  {
    id: 13,
    name: "المادة 17 في نظام العمل",
    description: `على صاحب العمل أن يحتفظ في مكان العمل بالسّجلات والكشوف والملفات التي تحدّد ماهيتها، والبيانات التي يجب أن تتضمّنها اللائحة.
        وعليه أن يضع في مكان ظاهر بموقع العمل جدولًا بمواعيد العمل، وفترات الرّاحة، ويوم الرّاحة الأسبوعي، ومواعيد بدء كل نوبة وانتهائها في حالة العمل بأسلوب المناوبة.`,
    recommendations: `تأكد من التالي:
        أن المنشأة تحتفظ بالملفات التالية في مكان العمل، سواء كانت بشكل ورقي أم الكتروني،: (كشف بأسماء العمال، كشف بأجور العمال، سجل قيد الغرامات الموقعة على العمال سجل الحضور والانصراف، سجل تدريب السعوديين، سجلات الفحص الطبي للعمال، ملف عمل لكل عامل)
        قم بالتأكّد من أنّ المنشأة تضع في مكان ظاهر بموقع العمل جدولًا بمواعيد العمل، وفترات الرّاحة، ويوم الرّاحة الأسبوعي، ومواعيد بدء كل نوبة وانتهائها في حالة العمل بأسلوب المناوبة.
        `,
    parentId: 4,
  },
   {
    id: 14,
    name: "الفقرة 26 من جدول المخالفات رقم 75913",
    description: `لا يجوز قيام صاحب المنشأة بتقديم معلومات أو بيانات غير صحيحة الى الوزارة ترتب عليها الحصول على أي خدمة من خدمات الوزارة أو تأشيرات عمل`,
    recommendations: `أن جميع المعلومات المقدمة الى الوزارة هي معلومات صحيحة تماما ويشمل ذلك المعلومات المقدمة من خلال منصة التقييم الذاتي`,
    parentId: 4,
  },
  {
    id: 15,
    name: "الفقرة 33 من جدول المخالفات رقم 75913",
    description: `لا يجوز قيام صاحب العمل بتسجيل شخص سعودي ضمن عمال المنشأة دون علمه أو موافقته`,
    recommendations: `تأكد مما يلي:
        - وجود عقد عمل بين صاحب العمل والعامل موقع من الطرفين
        - عدم تسجيل أي مواطن سعودي في نظام التأمينات الاجتماعية دون علمه
        - عدم تسجيل أي مواطن سعودي بعلمه والاتفاق على مرتب شهري يصرف للمواطن بدون أي عمل أو وبدون علمه بمقر المنشأة`,
    parentId: 4,
  },
  {
    id: 16,
    name: "المادة 30 في نظام العمل (1/2)",
    description: `لا يجوز لأي شخص طبيعي أو اعتباري أن يمارس نشاط توظيف السعوديين مالم يكن مرخصاً له بذلك من الوزارة.`,
    recommendations: `تأكد مما يلي:
        أن المنشأة لديها ترخيص من الوزارة لمزاولة نشاط توظيف السعوديين، وتوضح اللائحة التنظيمية لنظام العمل ضوابط وقواعد ممارسة نشاط التوسط في توظيف السعوديين.`,
    parentId: 5,
  },
  {
    id: 17,
    name: "المادة 30 في نظام العمل (2/2)",
    description: `لا يجوز لأي شخص طبيعي أو اعتباري أن يمارس نشاط استقدام العمال مالم يكن مرخصاً له بذلك من الوزارة.`,
    recommendations: `تأكد مما يلي:
        أن المنشأة لديها ترخيص من الوزارة لمزاولة نشاط الاستقدام، وتوضح قواعد ممارسة نشاط الاستقدام وتقديم الخدمات العمالية الاشتراطات اللازمة للحصول على الترخيص`,
    parentId: 5,
  },
  {
    id: 18,
    name: "الفقرة 83 من جدول مخالفات رقم 75913",
    description: `لا يجوز قيام المرخص له بأنشطة الاستقدام بتأجير الترخيص للغير أو منحه حق الانتفاع به لممارسة النشاط، أو بيع تأشيرات العمل أو التوسط في بيعها سواء بطريقة مباشرة أو غير مباشرة`,
    recommendations: `تأكد من التالي:
        أنه لم يتم تأجير ترخيص ممارسة أنشطة الاستقدام للغير أو منح الغير حق الانتفاع به سواء بطريقة مباشرة أو غير مباشرة`,
    parentId: 5,
  },
  {
    id: 19,
    name: "الفقرة 56 من جدول مخالفات رقم 75913",
    description: ` لا يجوز استمرار المرخص له بمزاولة نشاط التوسط في الاستقدام أو تقديم الخدمات العمالية بعد إيقاع عقوبة الإغلاق.`,
    recommendations: `تأكد من التالي:
        التوقف عن مزاولة نشاط التوسط في الاستقدام أو تقديم الخدمات العمالية للغير بعد إيقاع عقوبة الاغلاق`,
    parentId: 5,
  },
  {
    id: 20,
    name: "قواعد ممارسة نشاط الاستقدام وتقديم الخدمات العمالية 1439هـ مادة 12",
    description: `تلتزم شركة الاستقدام باستقبال، وإيواء العمالة التي يتم التوسط في استقدامها لحين تسليمها لأصحاب العمل؛ وفقًا للإجراءات التي تضعها الوزارة بالتنسيق مع الجهات ذات العلاقة`,
    recommendations: `تأكد مما يلي:
        تأمين إسكان لإيواء العمالة المستقدمة خلال 30 يوما من تاريخ الحصول على تأشيرات استقدام العمالة، مع مراعاة تناسب الطاقة الاستيعابية للسكن مع عدد العاملين من كل جنس، على ألا تقل نسبة الطاقة الاستيعابية عن 10% من عدد التأشيرات الممنوحة للشركة`,
    parentId: 5,
  },
  {
    id: 21,
    name: "قواعد ممارسة نشاط الاستقدام وتقديم الخدمات العمالية 1439هــ مادة 46 (1/2)",
    description: `يجب على المرخص له بممارسة تقديم الخدمات العمالية الالتزام بما يلي:-
        ممارسة النشاط بمكان مستقل، دون ممارسة أي نشاط آخر بهذا المكان، طوال مدة الترخيص.`,
    recommendations: `تأكد من التالي:
        أن المنشأة المرخص لها بممارسة نشاط تقديم الخدمات العمالية تقوم بممارسة النشاط في مكان مستقل دون ممارسة أي نشاط آخر في نفس المكان طوال مدة الترخيص`,
    parentId: 5,
  },
  {
    id: 22,
    name: "قواعد ممارسة نشاط الاستقدام وتقديم الخدمات العمالية 1439هـ مادة 46 (2/2)",
    description: `يجب على المرخص له بممارسة نشاط الاستقدام وتقديم الخدمات العمالية الالتزام بما يلي:-
        نماذج العقود الموحدة التي تضعها وتعتمدها وزارة الموارد البشرية والتنمية الاجتماعية`,
    recommendations: `تأكد من التزام المنشأة بنماذج العقود الموحدة التي تضعها الوزارة لأنشطة الاستقدام وتقديم الخدمات العمالية للغير:
        النموذج الموحد لعقد الارتباط (عقد ينظم العلاقة بين المرخص له ومكاتب الارسال)
        نموذج العقود الخاصة مع العملاء: عقود خاصة بنشاط تقديم الخدمات العمالية العامة - عقود خاصة بنشاط تقديم الخدمات العمالية المنزلية، على أن يتضمن العقد الآتي:
        عدد العمال، ووظائفهم، ومؤهلاتهم، وخبراتهم في العمل الذي يُطلَبون من أجله.
        حق العميل بأن يطلب من المرخص له استبدال العمال المقدمة خدماتهم بعمال آخرين يملكون نفس المؤهلات، والخبرات الضرورية للقيام بالعمل الذي طُلِبوا من أجله.
        مدة العقد لتقديم الخدمات.
        المقابل المالي الذي يحصل عليه المرخص له لقاء تقديم خدمات العمالة.`,
    parentId: 5,
  },
  {
    id: 23,
    name: "قواعد ممارسة نشاط الاستقدام وتقديم الخدمات العمالية والفقرة 67 و68 من جدول مخالفات رقم 75913",
    description: `يجب على المرخص له بأنشطة الاستقدام الالتزام بالتجاوب مع الوزارة وذلك بالرد على الاستفسارات الكتابية ومعالجة الشكاوى`,
    recommendations: `تأكد مما يلي:- تخصيص أو إنشاء مركز اتصال وقنوات لاستقبال وحل الشكاوى من العمالة، أو أصحاب العمل، أو العملاء`,
    parentId: 5,
  },
  // معايير عامة للمنشآت
  {
    id: 24,
    name: "المادة 121 في نظام العمل",
    description: `على صاحب العمل حفظ المنشأة في حالة صحيّة ونظيفة، وإنارتها، وتأمين المياه الصالحة للشّرب والاغتسال.`,
    recommendations: `تأكّد من التالي:
        1. هل بيئة العمل (المكاتب-خطوط الانتاج-المستودعات-الخ..) نظيفة؟
        2. هل مياه الشرب والغسيل متوفرة ونظيفة بالشكل المناسب؟
        3. هل دورات المياه نظيفة ومناسبة لعدد العاملين؟
        4. هل أماكن الغسيل نظيفة ومناسبة لعدد العاملين؟
        5. هل أماكن تغيير الملابس نظيفة ومناسبة لعدد العاملين؟
        6. هل أماكن الأكل نظيفة ومناسبة لعدد العاملين؟
        7. هل الروائح الكريهة يتم تصريفها من خلال التهوية بشكلٍ مناسب؟
        8. هل بيئة العمل ذات درجة حرارة مناسبة؟
        9. هل بيئة العمل منارة (مضاءة) بالشكل المناسب؟
        10. هل بيئة العمل يوجد فيها تهوية بالشكل المناسب؟
        11. هل يتم إزالة نفايات العمل بالشكل المناسب؟`,
    parentId: 6,
  },
  {
    id: 25,
    name: "المادة 122 في نظام العمل (1/2)",
    description: `على كلّ صاحب عمل أن يتّخذ الاحتياطات اللازمة لحماية العمّال من الأخطار، والأمراض الناجمة عن العمل، والآلات المستعملة، ووقاية العمل وسلامته. ولا يجوز لصاحب العمل أن يحمّل العمّال أو يقتطع من أجورهم أيّ مبلغ لقاء توفير هذه الحماية.`,
    recommendations: `تأكّد من التالي:
        أولاً) هل يقوم صاحب العمل بتحميل العمال لديه أو يقتطع من أجورهم أي مبلغ لقاء توفير أي احتياطات أو وسائل حماية ؟
        ثانيا) اتخاذ الاحتياطات اللازمة لحماية العمال من الاخطار والامراض الناجمة عن العمل من خلال التأكد من:
        1. هل يوجد هناك أي انزلاقات مخاطر تنقل أو سقوط مثل: سجادات متعرجة، أراضي مبللة، أو أي تغيرات غير محمية على مستوى الأرض؟
        2. هل الأرضيات سليمة ومحافظ عليها بشكلٍ جيد؟
        3. هل الممرات خالية من الحواجز والمعوقات؟
        4. هل تم تطبيق نظام تحديد السرعة داخل مواقع التخزين والشحن؟
        5. هل كواشف الدخان منتشرة في أماكنها؟
        6. هل كواشف الدخان تسير حسب جدولة صيانتها؟
        7. هل بيئة العمل خالية من الضوضاء والاهتزازات الزائدة؟
        8. هل مستويات الإنارة (الإضاءة) كافية؟ استبدال العطلان.
        9. في حالة تخزين المواد الكيميائية، هل يتم التخزين بالشكل المناسب وحسب معايير معينة؟
        10. هل المواد والمعدات وغيرها في المستودعات مرتبة بشكلٍ آمن؟
        11. هل تم تخزين الأسطوانات المضغوطة بعيد عن أشعة الشمس المباشرة؟
        12. هل يوجد اجراءات فعالة للتعامل مع انسكاب السوائل؟
        13. هل المخلفات (الهدر) مخزنة بالشكل المناسب ولا يسمح لها بالتراكم؟
        14. هل محطات العمل موضوعة بالشكل الصحيح؟
        15. هل محطات العمل مزودة بمسند للقدمين، مسند للمعصم، حماية للعينين،.. الخ من تجهيزات، حسب الحاجة؟
        16. هل تم تدريب سائقي الرافعات ومنها الرفعة الشوكية وتأهليهم؟
        17. هل تم حماية العمال من أخطار الآلات والمعدات والأدوات بإحاطة الأجزاء الخطرة، وتصريف مسببات الأخطار كالغبار، والأبخرة، والدخان، الخ..
        18. هل يتم اختيار المعدات والأدوات على أساس معايير سلامة وصحة مهنية مناسبة لطبيعة العمل؟
        19. هل تم إحاطة العاملين بمخاطر مهنتهم قبل مزاولة العمل؟
        20. هل تم إلزام العاملين باستخدام وسائل الوقاية المقررة للعمل المناط بهم؟
        21. هل تم توفير أدوات الوقاية الشخصية المناسبة للعمال (وملابس الحماية)؟`,
    parentId: 6,
  },
  {
    id: 26,
    name: "المادة 122 في نظام العمل (2/2)",
    description: `على كل صاحب عمل أن يعلن في مكان ظاهر في المنشأة التعليمات الخاصّة بسلامة العمل والعمّال وذلك باللغة العربية وبأيّ لغة أخرى يفهمها العمّال - عند الاقتضاء.`,
    recommendations: `تأكّد من التالي:
        1. هل يتم الإعلان في أماكن ظاهرة عن التعليمات الخاصة بسلامة العمل والعمال؟
        2. هل يوجد لوحات أو إعلانات (مهما كانت الوسيلة: لوحة- تلفزيون- رول أب- الخ) حول تعليمات السلامة؟
        3. هل الاعلانات حول تعليمات السلامة والصحة المهنية تحوي قوانين وسياسة ومسؤوليات السلامة والصحة المهنية؟ وبوضح وباختصار؟
        4. بالنسبة للأماكن الخطرة، هل يوجد إعلان عن تعليمات السلامة والصحة المهنية؟
        5. هل اعلانات السلامة والصحة المهنية الرسمية يفهمها العاملين (اللغة مفهومة للعاملين)؟`,
    parentId: 6,
  },
  {
    id: 27,
    name: "المادة 123 في نظام العمل",
    description: `على صاحب العمل إحاطة العامل قبل مزاولة العمل بمخاطر مهنته، وإلزامه باستعمال وسائل الوقاية المقرّرة لها، وعليه أن يوفّر أدوات الوقاية الشخصيّة المناسبة للعمّال وتدريبهم على استخدامها.`,
    recommendations: `تأكّد من التالي:
        1. هل تم إخبار العامل بالأخطار المحيطة بعمله المباشر؟
        2. هل تم إلزام العامل باستعمال وسائل الوقاية المقررة له؟
        3. هل تم توفير معدات الوقاية الشخصية المناسبة؟
        4. هل تم تدريب العامل أو العمال على استخدام معدات الوقاية الشخصية؟`,
    parentId: 6,
  },
  {
    id: 28,
    name: "المادة 124 في نظام العمل",
    description: `على العامل أن يستعمل الوسائل الوقائيّة المخصّصة لكل عملية وأن يحافظ عليها، وأن ينفّذ التعليمات الموضوعة للمحافظة على صحته ووقايته من الإصابات والأمراض، وعليه أن يمتنع عن ارتكاب أيّ فعل أو تقصير يتسبب عنه عدم تنفيذ التعليمات، أو إساءة استعمال الوسائل المعدّة لحماية مقرّ العمل وصحّة العمّال المشتغلين معه وسلامتهم أو تعطيلها.`,
    recommendations: `تأكّد من التالي:
    1. هل يستعمل العامل الوسائل الوقائية المخصصة لكل عملية؟
    2. هل يحافظ العامل على الوسائل الوقائية المخصصة لكل عملية؟
    3. هل ينفذ العامل التعليمات الموضوعة للمحافظة على صحته ووقايته من الاصابات والأمراض؟
    4. هل يسيء العامل استعمال الوسائل المعدة لحماية مقر العمل ولحماية سلامة وصحة العمال من تعطيل، من عدم اتباع التعليمات، الخ..بما قد يؤثر على صحة العمال والمشتغلين معه وسلامتهم`,
    parentId: 6,
  },
  {
    id: 29,
    name: "المادة 125 في نظام العمل",
    description: `على صاحب العمل أن يتّخذ الاحتياطات اللازمة للوقاية من الحريق وتهيئة الوسائل الفنيّة لمكافحته، بما في ذلك تأمين منافذ للنجاة وجعلها صالحة للاستعمال في أيّ وقت، وأن يعلّق في مكان ظاهر من أماكن العمل تعليمات مفصّلة بشأن وسائل منع الحريق.`,
    recommendations: `تأكّد من التالي:
        1. هل طفايات الحريق واضحة وسهلة الوصول إليها؟
        2. هل طفايات الحريق مناسبة لمخاطر الحريق المحتملة وكافية العدد؟
        3. هل تم التأكد من صلاحية وعمل طفايات الحريق؟ مع ملاحظة تتابع آخر عملية فحص لحالة الطفايات؟
        4. هل كواشف الدخان منتشرة في أماكنها؟
        5. هل كواشف الدخان تسير حسب جدولة صيانتها؟
        6. هل مخارج الطوارئ مغلقة في الحالات الطبيعية وتعمل فوراً عند الحاجة؟
        7. هل يوجد نقاط تجمع في حالات الطوارئ؟
        8. هل أنوار  (إضاءة) الطوارئ تشتغل؟
        9. هل خطط الطوارئ ومنها الإخلاء معلنة في الأماكن المناسبة؟
        10. هل الممرات خالية من الحواجز والمعوقات؟
        11. هل يوجد وسائل الإطفاء المناسبة (الذاتية)- حسب نوع العمل-؟
        12. هل أسماء ومواقع ممثلي أو المسؤولين عن السلامة والصحة المهنية ومسؤولي الإسعافات الأولية أثناء حوادث الحريق معلنة ومعروفة للعاملين؟
        13. هل لدى المنشأة رخصة الدفاع المدني (للمحلات أو المنشآت التي تتطلب رخصة من الدفاع المدني)؟
        14. هل لدى المنشأة تصريح أو شهادة سلامة من مدن (للمنشآت بداخل المدن الصناعية التابعة لمدن)؟
        15. هل لدى المنشأة عقد صيانة ساري المفعول لأنظمة الإطفاء والحرائق وموضح فيه جدول الصيانة وما تم من أعمال صيانة؟
        16. هل خطط الطوارئ ومنها الإخلاء معلنة في الأماكن المناسبة؟ هل الممرات خالية من الحواجز والمعوقات؟`,
    parentId: 6,
  },
  {
    id: 30,
    name: "القرار الوزاري رقم 3337 تاريخ 15/7/1435",
    description: `لا يجوز تشغيل العامل في الأعمال المكشوفة تحت أشعة الشمس من الساعة الثانية عشر ظهرا الى الساعة الثالثة مساء خلال الفترة الواقعة بين اليوم الخامس والعشرين من برج الجوزاء الموافق الخامس عشر من شهر يونية، إلى نهاية اليوم الرابع والعشرين من برج السنبلة الموافق الخامس عشر من شهر سبتمبر من كل عام ميلادي`,
    recommendations: `تأكّد من التالي:
        عدم تشغيل العاملين في الأعمال المكشوفة تحت أشعة الشمس من الساعة الثانية عشر ظهرا الى الساعة الثالثة مساء خلال الفترة الواقعة بين اليوم الخامس والعشرين من برج الجوزاء الموافق الخامس عشر من شهر يونية، إلى نهاية اليوم الرابع والعشرين من برج السنبلة الموافق الخامس عشر من شهر سبتمبر من كل عام ميلادي`,
    parentId: 6,
  },
  {
    id: 31,
    name: "المادة 142 في نظام العمل",
    description: `على كل صاحب عمل أن يعدّ خزانة أو أكثر للإسعافات الطبية، مزوّدة بالأدوية وغيرها، مما يلزم للإسعافات الطبية الأوّلية. وتحدّد اللائحة ما يجب أن تحتويه هذه الخزانة من وسائل الإسعافات الأوليّة وعددها، وكميّات الأدوية، وكذلك تنظيم وسائل حفظها وشروط من يقوم بمهمة الإسعافات.`,
    recommendations: `تأكّد من التالي:
        1. هل يوجد عدد كافي من صناديق الاسعافات الأولية؟ 
        2. هل تحوي صناديق الاسعافات الأولية المحتويات الصحيحة والصالحة للاستعمال؟ 
        3. هل حجم خزانة الإسعاف مناسباً لما تحتويه من أدوية ومعدات؟
        4. هل يتم حفظ خزانة الإسعاف في مكان تتوافر فيه الشروط الصحية ودرجة حرارة مناسبة؟
        5. هل تم تعليق إعلانات بشكل ظاهر في أمكنة العمل تدل على مكان الخزانة واسم المسئول عنها؟  
        (بالنسبة للفقرات 2،3،4،5 فإنها حسب المادة 30 من اللائحة التنفيذية لنظام العمل)`,
    parentId: 7,
  },
  {
    id: 32,
    name: "المادة 143 في نظام العمل",
    description: `على كل صاحب عمل أن يعهد إلى طبيب أو أكثر بفحص عماله المعرضين لاحتمال الإصابة بأحد الأمراض المهنية المحددة في جداول الأمراض المهنية - المنصوص عليها في نظام التأمينات الاجتماعية - فحصاً شاملًا مرة كل سنة على الأقل، وأن يثبت نتيجة ذلك الفحص في سجلاته، وكذلك في ملفات أولئك العمال.`,
    recommendations: `تأكّد من التالي:
        1. هل تم فحص العمال المعرضين لاحتمال الاصابة بأحد الامراض المهنية في جداول الامراض المهنية - المنصوص عليها في نظام التأمينات الاجتماعية- مرة في كل سنة على الأقل؟
        2. هل تم تسجيل نتيجة ذلك الفحص في ملفات وسجلات العمال؟`,
    parentId: 7,
  },
  {
    id: 33,
    name: "المادة 144 في نظام العمل",
    description: `على صاحب العمل أن يوفر لعمّاله العناية الصحيّة الوقائيّة والعلاجيّة طبقًا للمستويات التي يقرّرها الوزير، مع مراعاة ما يوفّره نظام الضمان الصحي التعاوني.`,
    recommendations: `تأكّد من التالي:
        1. من حصول العامل وأسرته (حسب نظام التأمين التعاوني الصحي) على التأمين الطبي.
        2. المنشأة التي يكون فيها عدد العاملين 50 فأكثر قد قامت بتعيين مُسعف أوّلي متفرّغ يحمل مؤهّلات مُصدّقة من الجهات المختصّة، وذلك بغض النظر عمّا إذا كان هنالك طبيب زائر أو طبيب يعمل بدوام كامل أو وجود رعاية طبيّة متكاملة، على أن يتوفّر بمكان العمل غرفة للإسعافات الاوّلية وفقًا للشروط:
        2.1– أن تكون مستوفية للمتطلّبات الصحيّة.
        2.2– أن تقع في مكان مناسب يمكن وصول المصابين أو نقلهم إليها بسرعة وبسهولة مع وضع لافتات إرشاديّة على مكان هذه الغرف في أماكن متعددة بموقع العمل للتعرّف عليها بسهولة.
        2.3– ألّا تزيد المسافة بينها وبين أقصى مكان للعمل على مائتي متر ما لم تتوافر وسيلة النّقل السريع للمصابين.
        2.4– أن يتوافر عدد مناسب من النقّالات لنقل المصابين إلى مكان الإسعاف.
        2.5– غرفة للتمريض وغرفة أو أكثر لتغيير الملابس في المناجم أو المحاجر التي يشتغل فيها خمسين عاملًا أو أكثر.
        3. المنشأة التي فيها 500 عامل فأكثر والتي تبعد مسافة لا تقلّ عن عشرة كيلومترات عن أقرب وحدة صحيّة قد قامت بتأمين سيارة إسعاف لنقل المصابين، وأنه تم تخصيص هاتف لتأمين الاتصالات اللازمة المتعلقة بالطوارئ عند الضرورة`,
    parentId: 7,
  },
  // معايير خاصة للعاملين 
{
    id: 34,
    name: "المادة 33 في نظام العمل",
    description: `لا يجوز لغير السعودي أن يمارس عملًا، ولا يجوز أن يسمح له بمزاولته، إلّا بعد الحصول على رخصة عمل من الوزارة وفق النموذج الذي تعدّه لهذا الغرض.
        ويشترط لمنح الرخصة ما يأتي:
        1. أن يكون العامل قد دخل البلاد بطريقة مشروعة ومصرحًا له بالعمل.
        2. أن يكون من ذوي الكفايات المهنيّة أو المؤهلات الدراسيّة التي تحتاج إليها البلاد، ولا يوجد من أبناء البلاد من يحملها أو كان العدد الموجود منهم لا يفي بالحاجة، أو يكون من فئة العمّال العاديين التي تحتاج إليها البلاد.
        3. أن يكون متعاقدًا مع صاحب عمل وتحت مسؤوليته.
        ويقصد بكلمة (العمل) في هذه المادة كلّ عمل صناعي، أو تجاري، أو زراعي، أو مالي، أو غيره، وأيّ خدمة بما في ذلك الخدمة المنزلية.
        `,
    recommendations: `تحقق من حصول العمّال على رخصة عمل من الوزارة.
        ويشترط لمنح الرخصة ما يأتي:
        1. أن يكون العامل قد دخل البلاد بطريقة مشروعة ومصرحًا له بالعمل.
        2. أن يكون من ذوي الكفايات المهنيّة أو المؤهلات الدراسيّة التي تحتاج إليها البلاد، ولا يوجد من أبناء البلاد من يحملها أو كان العدد الموجود منهم لا يفي بالحاجة، أو يكون من فئة العمّال العاديين التي تحتاج إليها البلاد.
        3. أن يكون متعاقدًا مع صاحب عمل وتحت مسؤوليته.
        ويقصد بكلمة (العمل) في هذه المادة كلّ عمل صناعي، أو تجاري، أو زراعي، أو مالي، أو غيره، وأيّ خدمة بما في ذلك الخدمة المنزلية.`,
    parentId: 8,
  },
  {
    id: 35,
    name: "المادة 37 في نظام العمل",
    description: `يجب أن يكون عقد عمل غير السعودي مكتوبًا ومحدّد المدة، وإذا خلا العقد من بيان مدته تعدّ مدة رخصة العمل هي مدّة العقد.`,
    recommendations: `تحقق مما يلي:
        1. يجب أن يكون عقد عمل غير السعودي مكتوب ومحدّد المدة، أو مدد تجديده، ولا يتحول إلى عقد غير محدد المدة حتى لو اتفق الطرفان على ذلك
        2. يجب أن يكتب عقد العمل من نسختين.
        3. يجب أن يحتوي عقد العمل بصورة أساسية على:
        - اسم صاحب العمل ومكانه.
        - اسم العامل وجنسيّته.
        - ما يلزم لإثبات شخصيّته.
        - الأجر المتّفق عليه.
        - نوع العمل ومكانه.
        - تاريخ الالتحاق به.
        - مدّة العقد و/أو مدد تجديده.`,
    parentId: 8,
  },
  {
    id: 36,
    name: "المادة 38 في نظام العمل",
    description: `لا يجوز لصاحب العمل توظيف العامل في مهنة غير المهنة المدوّنة في رخصة عمله، ويحظر على العامل الاشتغال في غير مهنته قبل اتّخاذ الإجراءات النظاميّة لتغيير المهنة.        `,
    recommendations: `1. تأكّد من عدم توظيف العامل في مهنة غير المهنة المدوّنة في رخصته.
        2. في حال كان العامل يشتغل في غير مهنته تأكّد من وجود ما يثبت ما يلي:
        - أن يكون لدى صاحب العمل ترخيص مزاولة نشاط ساري المفعول، أو سحل تجاري ساري المفعول مسجلا لدى مكتب العمل وأن يكون النشاط قائما
        - يقدم طلب تغيير المهنة عن طريق موقع الخدمات الالكترونية لدى وزارة الموارد البشرية والتنمية الاجتماعية
        - أن يكون اسم المهنة المطلوب التغيير لها محددا حسب دليل التصنيف والتوصيف السعودي
        - أن تتوفر لدى العامل المؤهلات العلمية والعملية المؤيدة لإجادته للمهنة
        ولا يجوز تكليف العامل بعمل يختلف اختلافا جوهرياً عن العمل المتفق عليه بغير موافقته الكتابية، إلا في حالات الضرورة التي قد تقتضيها ظروف عارضة ولمدة لا تتجاوز ثلاثين يوماً في السنة.`,
    parentId: 8,
  },
  {
    id: 37,
    name: "المادة 39 في نظام العمل",
    description: `1 ) لا يجوز - بغير اتباع القواعد والاجراءات النظامية المقررة – أن يترك صاحب العمل عامله يعمل لدى الغير، ولا يجوز للعامل أن يعمل لدى صاحب عمل آخر، كما لا يجوز لصاحب العمل توظيف عامل غيره، وتتولى وزارة العمل التفتيش على المنشآت، والتحقيق في المخالفات التي يتم ضبطها من قبل مفتشيها، ومن ثم إحالتها إلى وزارة الداخلية لتطبيق العقوبات المقررة بشأنها.
    2 ) لا يجوز لصاحب العمل أن يترك عامله يعمل لحسابه الخاص، كما لا يجوز للعامل أن يعمل لحسابه الخاص، وتتولى وزارة الداخلية ضبط وإيقاف وترحيل وإيقاع العقوبات على المخالفين من العاملين لحسابهم الخاص (العمالة السائبة) في الشوارع والميادين والمتغيبين عن العمل (الهاربين) وكذلك أصحاب العمل والمشغلين لهؤلاء والمتسترين عليهم والناقلين لهم وكل من له دور في المخالفة وتطبيق العقوبات المقررة بحقهم ".`,
    recommendations: `قم بالتأكد من التالي:
        1. أنّ المنشأة تقوم بالإجراءات اللازمة لعدم ترك عاملها يعمل لدى غيرها، والسّماح للعامل أن يعمل لدى صاحب عمل آخر، أو توظيف عامل غيره من غير الحصول على الموافقات الرسميّة اللازمة.
        2. عند وجود عمالة مستأجرة بنظام أجير، يتم التأكّد عن طريق عقد العامل أو إشعار أجير.
        3. قم بالتأكّد من أنّ المنشأة تقوم بالإجراءات اللازمة لعدم ترك عاملها أن يعمل لحسابه الخاص`,
    parentId: 8,
  },
  {
    id: 38,
    name: "المادة 40 في نظام العمل",
    description: `1. يتحمل صاحب العمل رسوم استقدام العامل غير السعودي، ورسوم الإقامة ورخصة العمل وتجديدهما وما يترتب على تأخير ذلك من غرامات، ورسوم تغيير المهنة، والخروج والعودة، وتذكرة عودة العامل إلى موطنه بعد انتهاء العلاقة بين الطرفين.
        2. يتحمل العامل تكاليف عودته إلى بلده في حالة عدم صلاحيته للعمل أو إذا رغب في العودة دون سبب مشروع.
        3. يتحمل صاحب العمل رسوم نقل خدمات العامل الذي يرغب في نقل خدماته إليه.
        4. يلزم صاحب العمل بنفقات تجهيز جثمان العامل ونقله إلى الجهة التي أبرم فيها العقد أو استقدم العامل منها، مالم يدفن بموافقة ذويه داخل المملكة. ويعفى صاحب العمل في حالة التزام المؤسسة العامة للتأمينات الاجتماعية بذلك.`,
    recommendations: `تحقق من أنّ المنشأة تقوم بتحمّل الرسوم التالية:
        1. رسوم استقدام العامل غير السعودي.
        2. رسوم الإقامة ورخصة العمل وتجديدهما وما يترتّب على تأخير ذلك من غرامات.
        3. رسوم تغيير المهنة، والخروج والعودة، وتذكرة عودة العامل إلى موطنه بعد انتهاء العلاقة بين الطرفين.
        4. رسوم نقل خدمات العامل الذي يرغب في نقل خدماته إليه.
        5. نفقات تجهيز جثمان العامل ونقله إلى الجهة التي أبرم فيها العقد أو استقدم العامل منها، ما لم يدفن بموافقة ذويه داخل المملكة (ويُعفى صاحب العمل في حالة التزام المؤسسة العامة للتأمينات الاجتماعية بذلك).
        ولا يجوز تحميل العامل أو ذويه أي من هذه التكاليف أو الرسوم الملزم بها صاحب العمل`,
    parentId: 8,
  },
  {
    id: 39,
    name: "الفقرة 20 من جدول المخالفات رقم 75913",
    description: `قيام صاحب العمل بتشغيل عامل غير سعودي دون حصوله على رخصة عمل أو إشعار أجير`,
    recommendations: `تأكد من التالي:
    أن المنشأة لديها تصريح او اشعار أجير ساري المفعول لتوظيف المرافقين`,
    parentId: 8,
  },
  {
    id: 40,
    name: "الفقرة 31 من جدول المخالفات رقم 75913",
    description: `الاحتفاظ بجواز السفر أو الإقامة للعامل أو أفراد أسرته`,
    recommendations: `تأكد من أن المنشأة لم تقم بحجز أي من ممتلكات العامل دون موافقته بغرض فرض أعمال أو خدمات على العامل عنوة أو تحت التهديد بأي عقاب، على سبيل المثال لا الحصر، احتفاظ المنشأة بجواز سفر العامل أو اقامته أو بطاقة التأمين الطبي دون موافقة العامل في سبيل فرض أعمال عليه لم ينص عليها في عقد العمل`,
    parentId: 8,
  },
  {
    id: 41,
    name: "المادة 61 من نظام العمل",
    description: `بالإضافة إلى الواجبات المنصوص عليها في نظام العمل واللوائح والقرارات الصادرة تطبيقًا له، يجب على صاحب العمل ما يأتي:
        1. أن يمتنع عن تشغيل العامل سخرة، وألّا يحتجز دون سند قضائي أجر العامل أو جزءٍ منه، وأن يعامل عمّاله بالاحترام اللائق، وأن يمتنع عن كلّ قول أو فعل يمسّ كرامتهم ودينهم.
        2. أن يعطي العمّال الوقت اللازم لممارسة حقوقهم المنصوص عليها في نظام العمل دون تنزيل من الأجور لقاء هذا الوقت، وله أن ينظّم ممارسة هذا الحق بصورة لا تخلّ بسير العمل.
        3. أن يسهّل لموظفي الجهات المختصّة كلّ مهمة تتعلّق بتطبيق أحكام نظام العمل.`,
    recommendations: `يقصد بعبارة "تشغيل العامل سخرة" : جميع الأعمال، أو الخدمات التي تفرض عنوة على العامل ، ولم ينص عليها في عقد العمل، وتتم تحت التهديد بأي عقاب، والتي لا يكون العامل قد أداها بمحض اختياره، ويستثنى من ذلك أي عمل أو خدمة تفرضها حالات الطوارئ أو القوة القاهرة، مثل حالات الحروب والحرائق والزلازل وغيرها
        ويجب على صاحب العمل التأكد من إعطاء العمال الوقت اللازم لممارسة حقوقهم دون تنزيل من أجورهم`,
    parentId: 8,
  },
  {
    id: 42,
    name: "المادة 36 من نظام العمل وفقرة 29 من جدول المخالفات رقم 75913",
    description: `توظيف عمالة غير سعودية في مهن أو أنشطة مقصورة على السعوديين`,
    recommendations: `1. تأكد أن الوظائف التالية لا يشغلها أي شخص غير سعودي:
    1- كبير اداري موارد بشرية 2- مدير شؤون موظفين 3- مدير شؤون عمل وعمال 4- مدير علاقات افراد 5-اختصاصي شؤون افراد 6-كاتب شؤون افراد 7-كاتب توظيف 8- كاتب شؤون موظفين 9- كاتب دوام 10-كاتب استقبال عام 11- كاتب استقبال فندقي 12-كاتب استقبال مرضى 13-كاتب شكاوى 14- أمين صندوق 15-حارس أمن خاص 16-معقب 17- ناسخ أو مصلح مفاتيح 18- مخلص جمركي

    2. يقتصر العمل في محلات بيع المستلزمات النسائية على المرأة السعودية بحسب قرارات تأنيث الأنشطة
    3. يقتصر العمل في منافذ البيع على السعوديين من الجنسين بحسب القرار الوزاري 95296 والقاضي بتوطين منافذ البيع في 12 نشاطا اقتصاديا (منافـــذ البيـــع فـــي محلات الملابس الجاهـــزة وملابس الأطفال والمســـتلزمات الرجاليـــة - منافذ البيع في محلات السيارات والدراجات النارية - منافذ البيع في محلات الأثاث المنزلي والمكتبي الجاهزة - منافذ البيع في محلات الأواني المنزلية - منافذ البيع في محلات الساعات - منافذ البيع في محلات النظارات - منافذ البيع في محلات الأجهزة الكهربائية والإلكترونية - منافذ البيع في محلات الأجهزة والمعدات الطبية - منافذ البيع في محلات الحلويات - منافذ البيع في محلات قطع غيار السيارات - منافذ البيع في محلات مواد الإعمار والبناء - منافذ البيع في محلات السجاد بكافة أنواعه)`,
    parentId: 8,
  },
  {
    id: 43,
    name: "المادة 3 من نظام العمل وفقرة 22 من جدول المخالفات رقم 75913",
    description: `بجب على المنشأة بالامتناع عن القيام بأي عمل يحوي أي ممارسة من ممارسات التمييز ضد العاملين لديها من حيث الجنس أو السن أو الأجر أو ممارسة التمييز من خلال شروط وضوابط التوظيف وفرص العمل والإعلانات الوظيفية؟`,
    recommendations: `تأكد من أن المنشأة  تلتزم  في عدم القيام بأي عمل يحوي أي ممارسة من ممارسات التمييز ضد العاملين لديها من حيث الجنس أو السن أو الأجر أو ممارسة التمييز من خلال شروط وضوابط التوظيف وفرص العمل والإعلانات الوظيفية.`,
    parentId: 9,
  },
];
