import React from "react";
import PropTypes from "prop-types";

export const IconTraining = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21">
    <g fill="none" fillRule="evenodd">
        <g fill="#FFFFFF" fillRule="nonzero" stroke="#FFFFFF" strokeWidth=".2">
            <g>
                <g>
                    <g>
                        <path d="M15.889 3.667H6.11v14.678h9.778V3.667zm1.222 0v14.678h1.833c1.013 0 1.834-.82 1.834-1.833V5.5c0-1.013-.821-1.833-1.834-1.833h-1.833zm-12.222 0H3.056c-1.013 0-1.834.82-1.834 1.833v11.012c0 1.012.821 1.833 1.834 1.833h1.833V3.667zm2.444-1.223v-.61C7.333.82 8.154 0 9.167 0h3.666c1.013 0 1.834.82 1.834 1.833v.611h4.277C20.632 2.444 22 3.812 22 5.5v11.012c0 1.687-1.368 3.055-3.056 3.055H3.056C1.368 19.567 0 18.2 0 16.512V5.5c0-1.688 1.368-3.056 3.056-3.056h4.277zm6.111 0v-.61c0-.338-.273-.612-.61-.612H9.166c-.338 0-.611.274-.611.611v.611h4.888z" transform="translate(-1835 -494) translate(1707) translate(129 495)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

);

export default IconTraining;
