import React from "react";
import PropTypes from "prop-types";
import "./papper.scss";

export class Papper extends React.Component {
  constructor() {
    super();

    this.getClassNames = this.getClassNames.bind(this);
  }

  getClassNames() {
    const { withBorder, isRounded } = this.props;
    const classes = ["establishment-papper"];

    if (withBorder) {
      classes.push("establishment-papper--bordered");
    }

    if (isRounded) {
      classes.push("establishment-papper--rounded");
    }

    return classes.join(" ");
  }

  render() {
    const { children, height } = this.props;

    return (
      <div style={{ height: height }} className={this.getClassNames()}>
        {children}
      </div>
    );
  }
}

Papper.propTypes = {
  children: PropTypes.node,
  withBorder: PropTypes.bool,
  isRounded: PropTypes.bool,
  height: PropTypes.string,
};

Papper.defaultProps = {
  children: null,
  withBorder: false,
  isRounded: true,
  height: "auto",
};

export default Papper;
