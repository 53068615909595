import React, {Component} from "react";
import {Col, Container, Row} from "reactstrap";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";


class Unauthorized extends Component {
  render() {
    return (
      <PageWrapper>
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
              <span className="clearfix">
                <h4 className="pt-3">خطأ</h4>
                <p className="text-muted float-right">
                  {" "}
                  لا تملك صلاحيات الوصول لهذه الصفحة
                </p>
              </span>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    );
  }
}

export default Unauthorized;
