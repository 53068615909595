import React from "react";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";

const GuideMainPage = (props) => (
  <div className="guide-page-wrap">
    <div className="animated fadeIn main-page">
      <div
        className="row jumbotron banner-1"
        style={{
          marginBottom: 0,
          background: " url('/img/mask.jpg') 50% 100% no-repeat",
          backgroundSize: "cover",
          display: "flex",
        }}
      >
        <div className="banner-content">
          <Text Tag="div" textType="h2" className="main-title">
            الدليل التفاعلي لمنصة التقييم الذاتي
          </Text>
          <Text Tag="div" textType="p2" className="main-description">
            يمكنك من خلال هذا الدليل التعرف على معايير وزارة الموارد البشرية
            والتنمية الاجتماعية واجراءات التحقق من كل معيار، كما يحتوي الدليل
            على العديد من المستندات والقرارات الوزارية الهامة، وعدد من
            الفيديوهات التعريفية التي تساعدك في التعرف على منصة التقييم الذاتي
          </Text>
        </div>
      </div>
    </div>
  </div>
);

export default GuideMainPage;
