import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as beforeLoadActions from "../../actions/beforeLoadActions";
import { confirmUserInfo } from "../../actions/authActions";
import {
  loadEstablishments,
  saveEstablishmentProfile,
  updateEstablishmentProfile,
  loadPaymentServices,
} from "../../actions/establishmentProfileActions";
import { fetchToolBarNotifications } from "../../actions/notificationActions";

import toastr from "toastr";

import Content from "../Content/Content";
//  Components
import {
  emptyEstablishmentProfile,
  handleLocationChange,
  onProfileFieldChange,
  validateProfileEditing,
} from "../../components/establishmentprofile/EstablishmentProfilePage";
import EstablishmentTrainingsPage from "../../components/training/EstablishmentTrainingsPage";
import ManageEstablishmentAssessmentPage from "../../components/establishmentprofile/ManageEstablishmentAssessmentPage";
import EstablishmentFastTrackResultPage from "../../components/establishmentfasttrackresult/EstablishmentFastTrackResultPage";
import EstablishmentAssessmentPaymentPage from "../../components/establishmentassesmentpayment/EstablishmentAssessmentPaymentPage";
import SuccessPaymentPage from "../../components/successpaymentpage/SuccessPaymentPage";
import FailedPaymentPage from "../../components/failedpaymentpage/FailedPaymentPage";
import LocationPicker from "../../components/maps/LocationPicker";
import { DEFAULT_MAP_LOCATION } from "../../common/constants/mapLocation";

class Full extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disclaimer: { status: false },
      profileDataSaved: false,
      profileEditingValidationErrors: {},
    };
    this.chooseEstablishment = this.chooseEstablishment.bind(this);
    this.changeDisclaimerStatus = this.changeDisclaimerStatus.bind(this);
    this.confirmAgreement = this.confirmAgreement.bind(this);
    this.confirmUserInfo = this.confirmUserInfo.bind(this);
    this.onProfileSaveClick = this.onProfileSaveClick.bind(this);
    this.onStepBackClick = this.onStepBackClick.bind(this);

    this.validateProfileEditing = validateProfileEditing.bind(this);
    this.onProfileFieldChange = onProfileFieldChange.bind(this);
    this.saveProfile = saveProfile.bind(this);
    this.handleLocationChange = handleLocationChange.bind(this);
  }

  componentDidMount() {
    const { isLogin, establishments } = this.props;
    if (!isLogin) {
      window.location = process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
      window.location.reload();
    }
  }

  chooseEstablishment(establishment) {
    this.props.actions.chooseEstablishment(establishment);
    this.props.actions.fetchToolBarNotifications(establishment.id);
    this.props.actions.loadPaymentServices(establishment.id);
  }

  changeDisclaimerStatus(e) {
    let disclaimer = this.state.disclaimer;

    disclaimer.status = !disclaimer.status;

    this.setState({ disclaimer: disclaimer });
  }

  confirmAgreement() {
    let disclaimer = this.state.disclaimer;
    this.props.actions.confirmDisclaimer();
  }

  confirmUserInfo() {
    const establishment = this.props.establishmentProfile;

    this.props.actions
      .confirmUserInfo(establishment.id)
      .then(() =>
        this.props.actions.loadEstablishments().then(() => {
          const updatedEstablishment = this.props.establishments.find(
            (e) => e.id === establishment.id
          );
          return updatedEstablishment;
        })
      )
      .then(this.chooseEstablishment);
  }

  onProfileSaveClick() {
    const profileIsValid = this.validateProfileEditing();
    if (!profileIsValid) {
      return;
    }

    this.saveProfile()
      .then(() => {
        toastr.success("تم حفظ المدخلات بنجاح");
        this.setState({ profileDataSaved: true });
      })
      .catch((err) => {
        toastr.error("لم يتم الحفظ");
      });
  }

  onStepBackClick() {
    this.setState({ profileDataSaved: false });
  }

  render() {
    const { userInfo } = this.props.auth;
    const { profileDataSaved, profileEditingValidationErrors } = this.state;
    const { establishmentProfile } = this.props;

    const mapLocationSpecifed =
      establishmentProfile.lat.Valid && establishmentProfile.lng.Valid;
    const establishmentMapLocation = {
      lat: establishmentProfile.lat.Float64,
      lng: establishmentProfile.lng.Float64,
    };
    return (
      <Content history={this.props.history}>
        <Switch>
          <Route
            path="/establishmentAssessment/:id/:assessment_version"
            name="ViewEstablishmentAssessment"
            component={ManageEstablishmentAssessmentPage}
          />

          <Route
            path="/establishmentAssessment"
            name="EstablishmentAssessment"
            component={ManageEstablishmentAssessmentPage}
          />

          <Route
            path="/establishmentFastTrackResult/:periodId/:assessmentId/:establishmentId/:assessment_version"
            name="establishmentFastTrackResult"
            component={EstablishmentFastTrackResultPage}
          />

          <Route
            path="/establishmentTrainings/:section?"
            name="establishmentTrainings"
            component={EstablishmentTrainingsPage}
          />

          <Route
            path="/success_payment"
            name="successPayment"
            component={SuccessPaymentPage}
          />
          <Route
            path="/failed_payment"
            name="failedPayment"
            component={FailedPaymentPage}
          />
        </Switch>

        <Modal
          isOpen={
            this.props.auth.userInfo !== null &&
            !establishmentProfile.labor_office_id &&
            !establishmentProfile.sequence_id &&
            !this.props.auth.userInfo.disclaimer_approved
          }
          className="modal-danger window-width"
        >
          <ModalBody className="Content">
            <Row>
              <Col>
                <h3
                  style={{ textAlign: "center", fontWeight: "bold" }}
                  className="thread-title underlined-green-wide"
                >
                  {" "}
                  الإقرارات
                </h3>
              </Col>
            </Row>
            <Col>
              <br />
              <br />
              <br />
              <div>
                <h2 className="panel-title">
                  للاستمرار في عملية التسجيل في منصة التقييم الذاتي فإنك تقّر
                  وتوافق على التالي:
                </h2>
                <ul className="a">
                  <li>
                    أن جميع البيانات والمعلومات التي سيتم إدخالها و إدراجها في
                    الحساب الخاص بالمستخدم على موقع التقييم الذاتي دقيقة وصحيحة
                    ومحدثة.
                  </li>
                  <li>
                    {" "}
                    يقر المستخدم بأنه في حال قيامه بتقديم أي معلومات غير صحيحة
                    أو مضللة فإن المنشأة و/أو المستخدم سيتحمل المسؤولة عما يترتب
                    على ذلك من غرامات أو أضرار.
                  </li>
                  <li>
                    {" "}
                    يقر المستخدم بأحقية الوزارة بطلب إعادة التقييم الذاتي
                    للأسباب التي يراها مناسبة دون اعتراض من المستخدم.
                  </li>
                  <li>
                    {" "}
                    يقر المستخدم بأنه المفوض الرسمي لتمثيل المنشأة لغرض إتمام
                    عملية التقييم الذاتي للمنشأة.
                  </li>
                  <li>
                    {" "}
                    يقر المستخدم باطلاعه وموافقته على كافة الشروط والأحكام
                    المضمنة في بوابة التقييم الذاتي.
                  </li>
                  <li>
                    {" "}
                    يقر المستخدم بتصحيح المخالفات، إن وجدت، وذلك بعد الانتهاء من
                    عملية التقييم الذاتي للمنشأة.
                  </li>
                  <li>
                    {" "}
                    يقر المستخدم بأن إكمال الطلب وتقديمه عبر البوابة هو مجرد
                    تسجيل في منصة التقييم الذاتي، ولا يشكّل هذا الطلب بأي حال من
                    الأحوال أي ضمان من وزارة الموارد البشرية والتنمية الاجتماعية
                    ("الوزارة") بقبول تسجيل المستخدم في خدمة التقييم الذاتي.
                  </li>
                  <li>
                    {" "}
                    يقر المستخدم بحق الوزارة في إلغاء نتائج التقييم الحالي
                    وإلغاء النتائج السابقة في حال مخالفة أي بند من هذه البنود.
                  </li>
                  <li>
                    {" "}
                    لا تنوي الوزارة نشر أية معلومات خاصة بالمعلومات المقدمة من
                    المستخدم في البوابة للعامة من خلال الموقع، و مع ذلك يقر
                    المستخدم بكامل الحق للوزارة في تعديل أو تكييف أو تهيئة أو
                    نشر أو نقل تلك المعلومات المقدمة.
                  </li>
                  <li>
                    {" "}
                    يوافق المستخدم على أن للوزارة الحق في جمع واستخدام المعلومات
                    الشخصية المقدمة من قبله في البوابة حسب ما ذكر في الشروط
                    والأحكام الخاصة ببوابة/منصة التقييم الذاتي.
                  </li>
                </ul>
              </div>
              <input
                type="checkbox"
                name="vehicle1"
                value={this.state.disclaimer.status}
                onChange={this.changeDisclaimerStatus}
              />{" "}
              &emsp; أقر انا المستخدم و الممثل للمنشأة بالاطلاع والموافقة على
              كافة الشروط والأحكام الخاصة ببوابة/منصة التقييم الذاتي والإقرارات
              التابعة لها و الالتزام بها والعمل بموجبها.
              <br />
              <br />
              <Row>
                <Col>
                  <hr
                    style={{ borderColor: "#679b27" }}
                    className="verticalLine"
                  />
                </Col>
              </Row>
              <Row>
                <Button
                  color="primary"
                  disabled={!this.state.disclaimer.status}
                  className="btn-round"
                  style={{ margin: "30px auto" }}
                  onClick={this.confirmAgreement}
                >
                  تأكيد
                </Button>
              </Row>
            </Col>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={
            !establishmentProfile.labor_office_id &&
            !establishmentProfile.sequence_id &&
            this.props.auth.userInfo.disclaimer_approved
          }
          className="modal-danger window-width"
        >
          <ModalBody className="Content">
            <Col>
              <Row>
                <Col>
                  <h3
                    style={{ textAlign: "center", fontWeight: "bold" }}
                    className="thread-title underlined-green-wide"
                  >
                    {" "}
                    اختيار المنشأة
                  </h3>
                </Col>
              </Row>
              {this.props.establishments.map((establishment, i) => (
                <Row style={{ paddingBottom: "10px" }} key={i}>
                  <div
                    className="profile"
                    onClick={() => {
                      this.chooseEstablishment(establishment);
                    }}
                  >
                    <div className="photo">
                      <img
                        style={{ width: "70px" }}
                        src={"img/MLSD_LOGO.png"}
                      />
                    </div>
                    <div className="content">
                      <div>
                        <h6>{`( ${establishment.labor_office_id} - ${establishment.sequence_id} ) ${establishment.establishment_name}`}</h6>
                        <span>{establishment.economic_activity_name}</span>
                        <span>{` - ${establishment.size_name}`}</span>
                      </div>
                      <div className="btn">
                        <i className="fa fa-angle-up fa-lg mt-4"></i>
                      </div>
                    </div>
                  </div>
                </Row>
              ))}
              <Row style={{ paddingBottom: "30px" }} />
            </Col>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={
            establishmentProfile.id &&
            !establishmentProfile.is_confirmed &&
            !profileDataSaved
          }
          className="modal-danger window-width"
          style={{ maxWidth: "600px", margin: "5vh auto" }}
        >
          <ModalBody className="user-info-approve">
            <Col style={{ padding: "0 60px" }}>
              <Row>
                <Col className="dashed-circles">
                  <span className="circled active">1</span>
                  <span className="circled">2</span>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="profile-info-blk">
                    <div className="establishment-profile-side-title">
                      اسم المنشأة بالإنجلزي
                    </div>
                    <div>
                      <input
                        type="text"
                        value={establishmentProfile.english_name || ""}
                        name="english_name"
                        onChange={this.onProfileFieldChange}
                      />
                    </div>
                  </div>

                  <div className="profile-info-blk">
                    <div className="establishment-profile-side-title">
                      الرقم الضريبي
                    </div>
                    <div>
                      <input
                        type="text"
                        value={establishmentProfile.tax_number.trim() || ""}
                        name="tax_number"
                        onChange={this.onProfileFieldChange}
                      />
                    </div>
                    {profileEditingValidationErrors.tax_number && (
                      <div className="profile-editing-err">
                        {profileEditingValidationErrors.tax_number}
                      </div>
                    )}
                  </div>

                  <div className="profile-info-blk">
                    <div className="establishment-profile-side-title">
                      تحديد مكان المنشأة
                    </div>
                    <LocationPicker
                      googleMapURL={
                        "https://maps.googleapis.com/maps/api/js?key=" +
                        process.env.REACT_APP_GOOGLE_MAPS_API_KEY +
                        "&language=en&v=3&libraries=geometry,drawing,places"
                      }
                      loadingElement={<div>Loading...</div>}
                      containerElement={
                        <div
                          className={`gmap-wrap editing`}
                          style={{ marginBottom: "10px" }}
                        />
                      }
                      mapElement={
                        <div
                          className="gmap-elem"
                          style={{ height: "200px" }}
                        />
                      }
                      defaultPosition={
                        mapLocationSpecifed
                          ? establishmentMapLocation
                          : DEFAULT_MAP_LOCATION
                      }
                      onChange={this.handleLocationChange}
                    />

                    <div className="establishment-profile-side-title">
                      {establishmentProfile.google_address}
                    </div>

                    {profileEditingValidationErrors.google_address && (
                      <div className="profile-editing-err">
                        {profileEditingValidationErrors.google_address}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <button
                    className="text-button"
                    onClick={this.onProfileSaveClick}
                  >
                    حفظ{" "}
                    <i
                      style={{ fontSize: "18px" }}
                      className="fa fa-angle-left"
                    />
                  </button>

                  <button className="back-button disabled">
                    <i className="fa fa-angle-right" />
                  </button>
                </Col>
              </Row>

              <Row style={{ paddingBottom: "30px" }} />
            </Col>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={profileDataSaved && !establishmentProfile.is_confirmed}
          className="modal-danger window-width"
          style={{ maxWidth: "600px", margin: "5vh auto" }}
        >
          <ModalBody className="user-info-approve">
            <Col>
              <Row>
                <Col style={{ margin: "10px 0 20px" }}>
                  <span className="circled active">1</span>
                  <span className="circled active">2</span>
                </Col>
              </Row>

              <Row className="info-section">
                <Col
                  md="4"
                  className="vert-green-line"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 30px",
                  }}
                >
                  <img src="img/est.svg" style={{ height: 80 }} />
                </Col>
                <Col
                  md="8"
                  style={{
                    padding: "0 30px",
                  }}
                >
                  <div className="info-row">
                    <div className="title">إسم المنشأة</div>
                    <div className="text">
                      {establishmentProfile.establishment_name}
                    </div>
                  </div>

                  <div className="info-row">
                    <div className="title">نشاط المنشأة</div>
                    <div className="text">
                      {establishmentProfile.economic_activity_name}
                    </div>
                  </div>

                  <div className="info-row">
                    <div className="title">الرقم الضريبي</div>
                    <div className="text">
                      {establishmentProfile.tax_number}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="info-section">
                <Col
                  md="4"
                  className="vert-green-line"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 30px",
                  }}
                >
                  <img src="img/info.svg" style={{ height: 80 }} />
                </Col>
                <Col
                  md="8"
                  style={{
                    padding: "0 30px",
                  }}
                >
                  <div className="info-row">
                    <div className="title">البريد الالكتروني</div>
                    <div className="text">{userInfo.email}</div>
                  </div>

                  <div className="info-row">
                    <div className="title">رقم الجوال</div>
                    <div className="text">{userInfo.phone_number}</div>
                  </div>

                  <div className="info-row">
                    <div className="title">العنوان</div>
                    <div className="text">
                      {establishmentProfile.google_address}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: "50px" }}>
                <Col>
                  <button
                    className="text-button"
                    style={{ marginLeft: "-30px" }}
                    onClick={this.confirmUserInfo}
                  >
                    تأكيد <i className="fa fa-angle-left" />
                  </button>
                  <button
                    className="back-button"
                    style={{ marginRight: "-30px" }}
                    onClick={this.onStepBackClick}
                  >
                    {" "}
                    <i className="fa fa-angle-right" />
                    السابق
                  </button>
                </Col>
              </Row>

              <Row style={{ marginTop: "30px" }}>
                <Col
                  style={{
                    textAlign: "center",
                    color: "#876d3f",
                  }}
                >
                  <p>ي حال رغبتكم في تحديث معلوماتكم يمكنكم زيارة هذه الصفحة</p>
                  <a
                    style={{
                      color: "#876d3f",
                      textDecoration: "underline",
                    }}
                    href="https://www.mol.gov.sa/SecureSSL/Login.aspx"
                  >
                    تم تغيير البيانات
                  </a>
                </Col>
              </Row>

              <Row style={{ paddingBottom: "30px" }} />
            </Col>
          </ModalBody>
        </Modal>
      </Content>
    );
  }
}

Full.propTypes = {
  actions: PropTypes.object.isRequired,
  ajaxCall: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  isLogin: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  establishments: PropTypes.array.isRequired,
  establishmentProfile: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  let userInfo = {
    id: "",
    name_ar: { String: "" },
    name_en: { String: "" },
    phone_number: { String: "" },
    email: { String: "" },
    id_number: "",
    disclaimer_approved: true,
  };
  if (state.auth.userInfo) {
    userInfo = state.auth.userInfo;
  }

  let establishmentProfile = { ...emptyEstablishmentProfile };

  if (state.establishment.establishmentProfile.establishment_name) {
    establishmentProfile = state.establishment.establishmentProfile;
  }

  return {
    ajaxCall: state.ajaxStatus,
    auth: { userInfo: userInfo },
    isLogin: state.auth.token !== null,
    establishments: state.establishment.establishments,
    establishmentProfile: establishmentProfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...beforeLoadActions,
        confirmUserInfo,
        loadEstablishments,
        updateEstablishmentProfile,
        saveEstablishmentProfile,
        loadPaymentServices,
        fetchToolBarNotifications,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Full);
