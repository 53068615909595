import jiraRequest from "../common/jiraRequest";

export const jiraHelpWidget =
  (name, email, type, description, attachments, phoneNumber) => (dispatch) => {
    const formData = new FormData();
    attachments.map((attachment, index) => {
      formData.append(`attachments[${index}]`, attachment, attachment.name);
    });
    formData.append("name", name);
    formData.append("email", email);
    formData.append("type", type);
    formData.append("description", description);
    formData.append("phone_number", phoneNumber);
    formData.append("audience", "ESTABLISHMENT");

    return jiraRequest(`/ticket/support/ims/create`, "POST", formData, {
      upload: true,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.issueKey;
      })
      .catch((error) => {
        throw error;
      });
  };
