import React from "react";

import PropTypes from "prop-types";
import "./titledTextInput.scss";
import TextInput from "../TextInput/TextInput";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

const TitledTextInput = (props) => {
  const {
    disabled,
    title,
    type,
    value,
    placeholder,
    onChange,
    errorText,
    onBlur,
    name,
    maxLength,
    onFocus,
    id,
  } = props;

  return (
    <span>
      {title && (
        <Text
          Tag="div"
          textType="p5"
          className="titled-text-input__title"
          isBold
        >
          {title}
        </Text>
      )}
      <TextInput
        type={type}
        value={value ? value : ""}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        errorText={errorText}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        maxLength={maxLength}
        id={id}
      />
    </span>
  );
};
TitledTextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  errorText: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.string,
  onFocus: PropTypes.func,
  id: PropTypes.string,
};
TitledTextInput.defaultProps = {
  type: "text",
  errorText: null,
  onBlur: () => {},
};

export default TitledTextInput;
