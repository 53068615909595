import React from "react";
import Loader from "../Loader/Loader";
import { Text } from "../../../../shared-components/my-scope/atoms/text"; // Import the CSS file
import "./PublicSpecialTrackCertificateDetailsPage.scss";
import { fetchPublicFastTrackCertificateDetails } from "../../actions/certificateActions";
import { withRouter } from "react-router-dom";
class SpecialTrackCertificateDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fastTrackCertificateDetails: null,
      isLoading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.loadFastTrackCertificateDetails();
  }

  loadFastTrackCertificateDetails = async () => {
    const { audit_log_id } = this.props.match.params;
    try {
      const response = await fetchPublicFastTrackCertificateDetails(
        audit_log_id
      );
      if (response.ok) {
        const val = await response.json();
        this.setState({ fastTrackCertificateDetails: val, isLoading: false });
      } else {
        this.setState({ error: "لا توجد شهادة", isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching fast-track certificate details:", error);
      this.setState({ error: "فشل في تحميل التفاصيل", isLoading: false });
    }
  };

  render() {
    const { fastTrackCertificateDetails, isLoading, error } = this.state;

    if (isLoading) {
      return (
        <div className="loader-wrapper">
          <Loader />
        </div>
      );
    }

    if (error) {
      return <div className="error-message">{error}</div>;
    }

    if (!fastTrackCertificateDetails) {
      return (
        <div className="error-message">لا توجد تفاصيل لشهادة الامتثال.</div>
      );
    }

    const {
      establishment_name,
      labor_office_id,
      sequence_id,
      auditing_score,
      submission_date,
    } = fastTrackCertificateDetails;
    const formattedDate =
      new Date(submission_date).toLocaleDateString() !== "1/1/1970"
        ? new Date(submission_date).toLocaleDateString()
        : "لم تصدر";

    return (
      <div className="certificate-container">
        <div className="fast-track-certificate">
          <div className="fast-track-certificate-header">
            <Text
              Tag={"div"}
              textType="bold"
              fontSize={"font-24"}
              className="fast-track-certificate-header__text"
            >
              نتيجة المسح لشهادة المسار المميز
            </Text>
          </div>
          <div className="fast-track-certificate-card">
            <div className="fast-track-certificate-card__image">
              <div className="fast-track-certificate-card__container">
                <div className="certificate-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="certificate-content__header"
                  >
                    اسم الجهة المانحة
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="certificate-content__text"
                  >
                    وزارة الموارد البشرية والتنمية الاجتماعية
                  </Text>
                </div>

                <div className="certificate-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="certificate-content__header"
                  >
                    تاريخ اصدار الشهادة
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="certificate-content__text"
                  >
                    {`${formattedDate}`}
                  </Text>
                </div>

                <div className="certificate-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="certificate-content__header"
                  >
                    اسم المنشأة
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="certificate-content__text"
                  >
                    {`${establishment_name}`}
                  </Text>
                </div>

                <div className="certificate-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="certificate-content__header"
                  >
                    نسبة الامتثال
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="certificate-content__text"
                  >
                    %{`${auditing_score}`}
                  </Text>
                </div>

                <div className="certificate-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="certificate-content__header"
                  >
                    الرقم التسلسلي
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="certificate-content__text"
                  >
                    {`${sequence_id}`}
                  </Text>
                </div>

                <div className="certificate-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="certificate-content__header"
                  >
                    رقم مكتب العمل
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="certificate-content__text"
                  >
                    {`${labor_office_id}`}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SpecialTrackCertificateDetails);
