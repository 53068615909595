import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_SUCCESS,
  LOAD_NOTIFICATIONS_SUCCESS,
  LOAD_NOTIFICATIONS_FAILURE,
  LOAD_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
} from "./actionTypes";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "./ajaxStatusActions";
import request, { parseError } from "../common/request";
import qs from "query-string";

/**
 * Fetch notifications failure action
 * @param {Object} error
 * @returns {{ type: String, payload: { error } }}
 */
export const fetchNotificationsToolBarFailure = (error) => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload: { error },
});

/**
 * Fetch notifications success action
 * @param {Array} notifications list of notifications
 * @returns {{ type: String, payload: { notifications } }}
 */
export const fetchNotificationsToolBarSuccess = (notifications, total) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: { notifications, total },
});

export const fetchNotificationsFailure = (error) => ({
  type: LOAD_NOTIFICATIONS_FAILURE,
  payload: { error },
});

export const fetchNotificationsSuccess = (notifications, total) => ({
  type: LOAD_NOTIFICATIONS_SUCCESS,
  payload: { notifications, total },
});

/**
 * Fetch notifications action
 * @param {Number} start
 * @param {Number} limit
 */
export const fetchNotifications =
  (establishment_id, start = 0, limit = 10) =>
  (dispatch) => {
    dispatch({
      type: FETCH_NOTIFICATIONS,
      payload: { start, limit },
    });

    const query = { start, limit };

    if (establishment_id === undefined) return;

    const url = `/v1/establishments/${establishment_id}/notifications?start=${start}&limit=${limit}`;
    request(url)
      .then((response) => {
        if (!response.ok) {
          return parseError(response);
        }
        return response;
      })
      .then((res) => res.json())
      .then(
        (json) => {
          dispatch(
            fetchNotificationsToolBarSuccess(
              json.notifications || [],
              json.total_results
            )
          );
        },
        (error) => {
          dispatch(fetchNotificationsToolBarFailure(error));
        }
      );
  };

export const markAsRead = (establishment_id, notificationId) => (dispatch) => {
  dispatch(beginAjaxCall());

  return request(
    `/v1/establishments/${establishment_id}/notifications/${notificationId}`,
    "PUT"
  )
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: MARK_NOTIFICATION_AS_READ_SUCCESS,
      });
      dispatch(fetchToolBarNotifications(establishment_id));
      dispatch(endAjaxCall());
    })
    .catch((err) => {
      ajaxCallError(err);
      dispatch(endAjaxCall());
      throw new Error(err);
    });
  // .finally(() => {
  //     dispatch(endAjaxCall());
  // })
};

export const fetchToolBarNotifications =
  (establishment_id, start = 0, limit = 5) =>
  (dispatch) => {
    dispatch({
      type: LOAD_NOTIFICATIONS,
      payload: { start, limit },
    });
    dispatch(beginAjaxCall());
    const url = `/v1/establishments/${establishment_id}/notifications/show?start=${start}&limit=${limit}`;
    return request(url)
      .then((res) => res.json())
      .then((res) => {
        dispatch(
          fetchNotificationsSuccess(res.notifications || [], [] /*res.total_results*/)
        );
        dispatch(endAjaxCall());
        return res;
      })
      .catch((err) => {
        ajaxCallError(err);
        dispatch(endAjaxCall());
      });
    // .finally(() => {
    //   dispatch(endAjaxCall());
    // })
  };
