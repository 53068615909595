import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";

function useIntersectionObserver(
  elementRef,
  { threshold = 0, root = null, rootMargin = "0%", freezeOnceVisible = false }
) {
  const [entry, setEntry] = useState();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]) => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
}
const AnimationTopToBottom = (props) => {
  const triggerRef = useRef();
  const dataRef = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: false,
  });
  const headerStyle = useSpring({
    config: { duration: 600 },
    from: { opacity: 0, top: "-300px", position: "relative" },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      top: dataRef?.isIntersecting ? "0px" : "-300px",
    },
    leave: { opacity: 0, bottom: "-300px" },
  });

  const { children } = props;
  return (
    <div>
      <animated.div style={headerStyle}>{children}</animated.div>
      <div ref={triggerRef} />
    </div>
  );
};
export default AnimationTopToBottom;
