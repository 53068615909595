import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as unifiedActions from "../../../actions/unifiedActions";
import * as branchesFastTrackActions from "../../../actions/FastTrackForBranchesActions";

import Loader from "../../../components/Loader/Loader";
import Papper from "../../../components/Papper/Papper";
import UnifiedAssessmentForm from "./step1/UnifiedAssessmentForm";
import PaymentService from "./step2/PaymentService";

import "./unified-assessment.scss";

export class UnifiedAssessment extends React.Component {
  constructor() {
    super();

    this.state = {
      step: 1,
    };

    this.changePage = this.changePage.bind(this);
  }

  componentDidMount() {
    const {
      periodId,
      assessmentId,
      periodAssessmentId,
      establishmentId,
      assessmentVersion,
      fetchUnifiedEstablishments,
      isBranchesSpecialTrack,
      fetchFastTrackForBranchesEstablishments,
    } = this.props;

    if (isBranchesSpecialTrack) {
      fetchFastTrackForBranchesEstablishments(
        establishmentId,
        periodId,
        assessmentId,
        periodAssessmentId,
        assessmentVersion
      );
    } else {
      fetchUnifiedEstablishments(
        establishmentId,
        periodId,
        assessmentId,
        periodAssessmentId,
        assessmentVersion
      );
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  changePage(pageNumber) {
    this.setState({ step: pageNumber });
  }

  render() {
    const {
      periodId,
      assessmentId,
      establishmentId,
      assessmentVersion,
      withoutResult,
      periodAssessmentId,
      isLoading,
      isLoadingFastTrackForBranches,
      isBranchesSpecialTrack,
    } = this.props;
    const { step } = this.state;

    if (isLoading || isLoadingFastTrackForBranches) {
      return (
        <section className="unified-assessment">
          <div className="ua-empty">
            <Loader />
          </div>
        </section>
      );
    }

    if (step === 1) {
      return (
        <UnifiedAssessmentForm
          periodId={periodId}
          assessmentId={assessmentId}
          periodAssessmentId={periodAssessmentId}
          establishmentId={establishmentId}
          assessmentVersion={assessmentVersion}
          withoutResult={withoutResult}
          changePage={this.changePage}
          isBranchesSpecialTrack={isBranchesSpecialTrack}
        />
      );
    }

    return (
      <PaymentService
        periodId={periodId}
        assessmentId={assessmentId}
        periodAssessmentId={periodAssessmentId}
        establishmentId={establishmentId}
        assessmentVersion={assessmentVersion}
        withoutResult={withoutResult}
        changePage={this.changePage}
        isBranchesSpecialTrack={isBranchesSpecialTrack}
      />
    );
  }
}

UnifiedAssessment.propTypes = {
  periodId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  establishmentId: PropTypes.string.isRequired,
  assessmentVersion: PropTypes.string.isRequired,
  withoutResult: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingFastTrackForBranches: PropTypes.bool.isRequired,
  fetchUnifiedEstablishments: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  location: PropTypes.object,
  isBranchesSpecialTrack: PropTypes.bool.isRequired,
  fetchFastTrackForBranchesEstablishments: PropTypes.func.isRequired,
};

UnifiedAssessment.defaultProps = {};

export const mapStateToProps = (state) => ({
  unifiedEstablishments: state.unified.establishments,
  isLoading: state.unified.isLoading,
  isLoadingFastTrackForBranches: state.FastTrackForBranches.isLoading,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchUnifiedEstablishments: (
    establishmentId,
    periodId,
    assessmentId,
    periodAssessmentId,
    assessmentVersion
  ) =>
    dispatch(
      unifiedActions.fetchUnifiedEstablishments(
        establishmentId,
        periodId,
        assessmentId,
        periodAssessmentId,
        assessmentVersion
      )
    ),
  fetchFastTrackForBranchesEstablishments: (
    establishmentId,
    periodId,
    assessmentId,
    periodAssessmentId,
    assessmentVersion
  ) =>
    dispatch(
      branchesFastTrackActions.fetchFastTrackForBranchesEstablishments(
        establishmentId,
        periodId,
        assessmentId,
        periodAssessmentId,
        assessmentVersion
      )
    ),

  reset: () => dispatch(unifiedActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnifiedAssessment);
