import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import { useJsApiLoader } from "@react-google-maps/api";
import Map from "./GoogleMap";
import Loader from "../Loader/Loader";

const google = window.google;

/* Default configuration */
const DEFAULT_RADIUS = 1000;
const DEFAULT_ZOOM = 10;

/* Circle options */
// https://developers.google.com/maps/documentation/javascript/3.exp/reference#CircleOptions
const DEFAULT_CIRCLE_OPTIONS = {
  fillColor: "red",
  fillOpacity: 0.2,
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 1.2,
};

function LocationPicker(props) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries:['places','geometry'],
    // ,
    // ...otherOptions
  });
  const [position, setPosition] = useState(props.defaultPosition);
  const [center, setCenter] = useState(props.defaultPosition);

  const [searchResult, setSearchResult] = useState("");

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();

      const position = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
       };

      // Get address
    geocodePosition(position).then((address) => {
      // Set new latitude and longitude
      setPosition(position);
      setCenter(position);

      const location = {
        position,
        address,
      };

      // Pass location to application change listener
      props.onChange(location);
    });
    }
  }

  useEffect(() => {
    setPosition(props.defaultPosition);
  }, [props.defaultPosition]);

  const handleMarkerDragEnd = (mouseEvent) => {

    const lat = mouseEvent.latLng.lat();
    const lng = mouseEvent.latLng.lng();

    const position = { lat, lng };


    geocodePosition(position).then((address) => {

      setPosition(position);

      const location = {
        position,
        address,
      };

      props.onChange(location);
    });
  };


  // /**
  //  * Geocode position to address
  //  * @param { Object } position
  //  * @return { Promise }
  //  */
  const geocodePosition = (position) => {
    // Geocoder instance
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK)
          resolve(results[0].formatted_address);
        else reject(status);
      });
    });
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (geoPosition) => {
        const position = {
          lat: geoPosition.coords.latitude,
          lng: geoPosition.coords.longitude,
        };
        geocodePosition(position).then((address) => {
          setPosition(position);
          setCenter(position);

          const location = {
            position,
            address,
          };

          // Pass location to application change listener
          props.onChange(location);
        });
      },
      (err) => {}
    );
  };

  /* Get props */
  const {
    zoom,
    radius,
    circleOptions,
    containerElement,
    mapElement,
    mapControlsEnabled,
    defaultPosition,
    withInputSearch,
    GoogleAddress,
  } = props;

  /* Use default if not passed */
  const _zoom = zoom || DEFAULT_ZOOM;
  const _radius = radius || DEFAULT_RADIUS;
  const _circleOptions = circleOptions || DEFAULT_CIRCLE_OPTIONS;

  return isLoaded ? (
    <div>
      <Map
        containerElement={containerElement}
        mapElement={mapElement}
        handleMarkerDragEnd={handleMarkerDragEnd}
        position={position||defaultPosition}
        circleOptions={_circleOptions}
        radius={_radius}
        defaultZoom={_zoom}
        mapControlsEnabled={mapControlsEnabled}
        onGeolocationClick={getCurrentLocation}
        center={center||defaultPosition}
        onPlaceChanged={onPlaceChanged}
        onLoad={onLoad}
        withInputSearch={withInputSearch}
        GoogleAddress={GoogleAddress}
      />
    </div>
  ) : (
    <Loader />
  );
}

/* Check props */
LocationPicker.propTypes = {
  // containerElement: PropTypes.node.isRequired,
  // mapElement: PropTypes.node.isRequired,
  // onChange: PropTypes.func.isRequired,
  // defaultPosition: PropTypes.object.isRequired,
  // zoom: PropTypes.number,
  // radius: PropTypes.number,
  // circleOptions: PropTypes.object
};

export default LocationPicker;
