import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import toastr from "toastr";

import "./profile.scss";

import { confirmUserInfo, getUserInfo } from "../../../actions/authActions";
import * as profileActions from "../../../actions/establishmentProfileActions";

import Lottie from "react-lottie";
import { bindActionCreators } from "redux";
import {
  arabicLettersOnly,
  digitsOnly,
  emailOnly,
  englishLettersOnly,
  isDigitsOnly,
  isEmailFormatOnly,
  isEnglishLettersOnly,
  lettersWithNumbersAndSpecialCharacters,
  removeExtraSpace,
  // englishLettersAndDigitsOnly,
  EnglishLettersWithNumbers,
} from "../../../../utilities/helpers";
import { chooseEstablishment } from "../../../actions/beforeLoadActions";
import {
  BanksList,
  loadEstablishments,
  loadPaymentServices,
} from "../../../actions/establishmentProfileActions";
import { fetchToolBarNotifications } from "../../../actions/notificationActions";
import * as animationLoader from "../../../../public/animation/loading.json";
import { DEFAULT_MAP_LOCATION } from "../../../common/constants/mapLocation";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import ProfileContent from "./ProfileContent";

const TAX_MUST_CONSIST_OF_15_NUMBERS_MSG =
  "الرجاء إدخال رقم ضريبي يتكون من 15 رقم";
const TAX_NUMBER_ERROR_MESSAGE =
  "عذرًا، الرقم الضريبي المدخل غير صحيح، يرجى التأكد من صحة الرقم الضريبي";
const TAX_MANDATORY_FIELD = "الرجاء إدخال الرقم الضريبي";
const ENGLISH_NAME_MANDATORY_FIELD = "الرجاء إدخال اسم المنشأة بالإنجليزي";
const ENGLISH_NAME_CONSIST_OF_TWO_OR_MORE_LETTERS =
  "الرجاء إدخال اسم المنشأة بالإنجليزي، ويجب أللا يقل عن حرفين";
const ADDRESS_DISTRICT_MANDATORY_FIELD = "الرجاء إدخال الحي";
const ADDRESS_CITY_MANDATORY_FIELD = "الرجاء إدخال المنطقه";
const ADDRESS_STREET_MANDATORY_FIELD = "الرجاء إدخال اسم الشارع";
const ADDRESS_BUILDING_NUMBER_MANDATORY_FIELD =
  "الرجاء ادخال رقم واحد على الاقل";
// const ADDRESS_ADDITIONAL_NUMBER_MANDATORY_FIELD = "الرجاء إدخال الرقم الإضافي";
const ADDRESS_POSTAL_CODE_MANDATORY_FIELD = "الرجاء إدخال الرمز البريدي";
const ADDRESS_POSTAL_CODE_FIVE_NUMBERS_FIELD =
  "يجب أن يتكون الرمز البريدي من خمس أرقام";
const ADDRESS_ADDITIONAL_FOUR_NUMBER_FIELD =
  "يجب أن يتكون الرقم الاضافي من اربع أرقام";

const EMAIL_MANDATORY_FIELD = "حقل البريد الإلكتروني مطلوب";
const EMAIL_FORMAT_FIELD = " يرجى التحقق من البريد الإلكتروني";

const PAYMENT_REQUESTER_NAME_MANDATORY_FIELD = "حقل اسم مقدم الطلب مطلوب";
const IBAN_BANK_MANDATORY_FIELD = "حقل رقم الايبان مطلوب";
const IBAN_BANK_VALIDATION_FAILED = "يرجى التحقق من الايبان المدخل";
const BANK_ID_MANDATORY_FIELD = "حقل اسم البنك مطلوب";
const BANK_CERTIFICATE_ATTACHMENT_MANDATORY_FIELD =
  "حقل خطاب الايبان المصدق من الغرفة التجارية مطلوب";
const IBAN_DISCLAIMER_MANDATORY_FIELD =
  "يرجى الموافقة على الإقرار لإكمال إضافة البيانات البنكية";
export class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      isEdit: false,
      profileEditingValidationErrors: {},
      targetId: undefined,
      isEstablishmentInfo: true,
      userInfo: {
        is_email_editable: false,
        disclaimer_approved: false,
        email: "",
        external_id: "",
        id: "",
        id_number: "",
        local_information_approved: false,
        name_ar: "",
        phone_number: "",
      },
      establishmentInfo: {
        english_name: "",
        tax_number: "",
        address_district: "",
        address_city: "",
        address_street: "",
        address_building_number: "",
        address_additional_number: "",
        address_postal_code: "",
        is_address_editable: false,
        payment_requester_name: "",
        IBAN_number: "",
        bank_id: "",
        bank_certificate_attachment: null,
        iban_disclaimer: false,
        bank_info: {},
      },
      // BanksList: "",
      banks: [],
      isLoading: false,
    };

    this.onEdit = this.onEdit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.onProfileFieldChange = this.onProfileFieldChange.bind(this);
    this.chooseEstablishment = this.chooseEstablishment.bind(this);
    this.validateProfileEditing = this.validateProfileEditing.bind(this);
    this.OnBlurInput = this.OnBlurInput.bind(this);
    this.setValue = this.setValue.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }
  componentDidMount() {
    this.setState({
      establishmentInfo: {
        english_name: this.props.establishmentProfile.english_name,
        tax_number: this.props.establishmentProfile.tax_number,
        address_district: this.props.establishmentProfile.address_district,
        address_city: this.props.establishmentProfile.address_city,
        address_street: this.props.establishmentProfile.address_street,
        address_building_number:
          this.props.establishmentProfile.address_building_number,
        address_additional_number:
          this.props.establishmentProfile.address_additional_number,
        address_postal_code:
          this.props.establishmentProfile.address_postal_code,
        is_address_editable:
          this.props.establishmentProfile.is_address_editable,
        payment_requester_name:
          this.props.establishmentProfile.payment_requester_name,
        IBAN_number: this.props.establishmentProfile.IBAN_number,
        bank_id: this.props.establishmentProfile.bank_id,
        bank_certificate_attachment:
          this.props.establishmentProfile.bank_certificate_attachment,
        iban_disclaimer: this.props.establishmentProfile.iban_disclaimer,
        bank_info: this.props.establishmentProfile.bank_info,
      },
      userInfo: { ...this.props.userInfo },
      isLoading: true,
    });
    if (this.props.userInfo && this.props.userInfo.is_email_editable) {
      this.props.getUserInfo(true);
    }

    const selected = JSON.parse(localStorage.getItem("selected"));
    if (selected) this.props.loadEstablishmentProfile(selected);

    this.props.fetchBanks().then((banks) => {
      this.setState({
        banks,
        isLoading: false,
      });
    });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.establishmentProfile.id !== this.props.establishmentProfile.id
    ) {
      const { updateEstablishmentProfile } = this.props;

      this.setState({
        isEdit: false,
        isEstablishmentInfo: true,
        profileEditingValidationErrors: {},
        establishmentInfo: {
          english_name: this.props.establishmentProfile.english_name,
          tax_number: this.props.establishmentProfile.tax_number,
          address_district: this.props.establishmentProfile.address_district,
          address_city: this.props.establishmentProfile.address_city,
          address_street: this.props.establishmentProfile.address_street,
          address_building_number:
            this.props.establishmentProfile.address_building_number,
          address_additional_number:
            this.props.establishmentProfile.address_additional_number,
          address_postal_code:
            this.props.establishmentProfile.address_postal_code,
          is_address_editable:
            this.props.establishmentProfile.is_address_editable,
          payment_requester_name:
            this.props.establishmentProfile.payment_requester_name,
          IBAN_number: this.props.establishmentProfile.IBAN_number,
          bank_id: this.props.establishmentProfile.bank_id,
          bank_certificate_attachment:
            this.props.establishmentProfile.bank_certificate_attachment,
          iban_disclaimer: this.props.establishmentProfile.iban_disclaimer,
          bank_info: this.props.establishmentProfile.bank_info,
        },
        userInfo: { ...this.props.userInfo },
      });
    }
  }
  onEdit({ targetId }) {
    console.log("setting is edit with target id ", targetId);
    this.setState({
      isEdit: true,
      targetId: targetId || undefined,
      establishmentInfo: { ...this.props.establishmentProfile },
    });
  }
  onCancel() {
    const selected = JSON.parse(localStorage.getItem("selected"));
    localStorage.getItem("selected");
    this.props.loadEstablishmentProfile(selected);
    const { updateEstablishmentProfile } = this.props;
    this.props.actions.loadEstablishments();
    this.setState({
      profileEditingValidationErrors: {},
      isEdit: false,
      establishmentInfo: {
        english_name: selected.english_name,
        tax_number: selected.tax_number,
        address_district: selected.address_district,
        address_city: selected.address_city,
        address_street: selected.address_street,
        address_building_number: selected.address_building_number,
        address_additional_number: selected.address_additional_number,
        address_postal_code: selected.address_postal_code,
        is_address_editable: selected.is_address_editable,
        payment_requester_name: selected.payment_requester_name,
        IBAN_number: selected.IBAN_number,
        bank_id: selected.bank_id,
        bank_certificate_attachment: selected.bank_certificate_attachment,
        iban_disclaimer: false,
        bank_info: selected.bank_info,
      },
      userInfo: { ...this.props.userInfo },
    });
  }

  async onSave() {
    const profileIsValid = this.validateProfileEditing();
    if (!profileIsValid) {
      return;
    }

    const {
      establishmentProfile,
      saveEstablishmentProfile,
      dispatch,
      getUserInfo,
      loadEstablishmentProfile,
      userInfo,
    } = this.props;

    const { establishmentInfo } = this.state;

    this.setState({ isLoading: true });

    // Check if IBAN number should be empty
    const IBAN_number =
      establishmentProfile.IBAN_number === "SA"
        ? ""
        : establishmentProfile.IBAN_number;

    const payload = {
      establishment_details: {
        tax_number: establishmentProfile.tax_number,
        lat: establishmentProfile.lat.Float64,
        lng: establishmentProfile.lng.Float64,
        google_address: establishmentProfile.google_address,
        english_name: establishmentProfile.english_name,
        address_district: establishmentProfile.address_district,
        address_city: establishmentProfile.address_city,
        address_street: establishmentProfile.address_street,
        address_building_number: parseInt(
          establishmentProfile.address_building_number
        ),
        address_additional_number:
          establishmentProfile.address_additional_number
            ? parseInt(establishmentProfile.address_additional_number)
            : null,
        address_postal_code: parseInt(establishmentProfile.address_postal_code),
        email: this.state.userInfo.email,
        payment_requester_name: establishmentProfile.payment_requester_name,
        IBAN_number, // Use the conditional IBAN_number
        bank_id: establishmentProfile.bank_info.id,
        bank_certificate_attachment: {},
        iban_disclaimer: establishmentProfile.iban_disclaimer,
        bank_info: establishmentProfile.bank_info,
      },
    };

    try {
      const response = await saveEstablishmentProfile(
        establishmentProfile.id,
        payload,
        establishmentInfo.bank_certificate_attachment,
        userInfo.id
      );

      const res = await response.json();

      const updatedUserInfo = { ...userInfo, email: this.state.userInfo.email };

      dispatch({ type: "UPDATE_USER_INFO", payload: updatedUserInfo });

      if (userInfo.is_email_editable) {
        getUserInfo(false);
      }

      localStorage.setItem("selected", JSON.stringify(res));
      loadEstablishmentProfile(res);

      toastr.success("تم حفظ المدخلات بنجاح");

      this.setState({
        isEdit: false,
        isLoading: false,
        establishmentInfo: res,
      });
    } catch (err) {
      const errorMessage =
        err.field === "IBANNumber" ? { IBAN_number: err.message } : {};

      this.setState({
        profileEditingValidationErrors: errorMessage,
        isLoading: false,
      });

      toastr.error("لم يتم الحفظ");
    }
  }

  onLocationChange({ address, position }) {
    const { updateEstablishmentProfile } = this.props;

    updateEstablishmentProfile({
      key: "google_address",
      value: address,
    });
    updateEstablishmentProfile({
      key: "lat",
      value: { Float64: position.lat, Valid: true },
    });
    updateEstablishmentProfile({
      key: "lng",
      value: { Float64: position.lng, Valid: true },
    });
  }
  setValue(key, value) {
    const { establishmentInfo } = this.state;
    const { updateEstablishmentProfile } = this.props;
    this.setState({
      establishmentInfo: { ...establishmentInfo, [key]: value },
    });

    updateEstablishmentProfile({ key, value });
  }

  onProfileFieldChange(e) {
    const { updateEstablishmentProfile } = this.props;
    const { profileEditingValidationErrors, userInfo } = this.state;
    const key = e.target.name;
    const value = e.target.value;

    // Fields restrictions:
    switch (key) {
      case "tax_number": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.tax_number
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              tax_number: null,
            },
          });
        }

        if (isDigitsOnly(value)) {
          updateEstablishmentProfile({ key, value });
          return value;
        } else {
          const digits = digitsOnly(value);
          updateEstablishmentProfile({ key, digits });
          return digits;
        }
      }
      case "english_name": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.english_name
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              english_name: null,
            },
          });
        }
        let englishLetters = value;
        if (!isEnglishLettersOnly(value)) {
          englishLetters = englishLettersOnly(value);
        }
        englishLetters = removeExtraSpace(englishLetters);
        updateEstablishmentProfile({ key, englishLetters });
        return englishLetters;
      }
      case "address_district": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.address_district
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              address_district: null,
            },
          });
        }

        const arabicLetters = arabicLettersOnly(value);
        updateEstablishmentProfile({ key, arabicLetters });
        return arabicLetters;
      }
      case "address_city": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.address_city
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              address_city: null,
            },
          });
        }

        const arabicLetters = arabicLettersOnly(value);
        updateEstablishmentProfile({ key, arabicLetters });
        return arabicLetters;
      }
      case "address_street": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.address_street
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              address_street: null,
            },
          });
        }

        const arabicLetters = arabicLettersOnly(value);
        updateEstablishmentProfile({ key, arabicLetters });
        return arabicLetters;
      }
      case "address_building_number": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.address_building_number
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              address_building_number: null,
            },
          });
        }

        const digits = digitsOnly(value);
        updateEstablishmentProfile({ key, digits });
        return digits;
      }
      case "address_additional_number": {
        // if (
        //   profileEditingValidationErrors !== {} &&
        //   profileEditingValidationErrors.address_additional_number
        // ) {
        //   this.setState({
        //     profileEditingValidationErrors: {
        //       ...profileEditingValidationErrors,
        //       address_additional_number: null,
        //     },
        //   });
        // }

        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.address_additional_number
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              address_additional_number: null,
            },
          });
        }
        const digits = digitsOnly(value);
        updateEstablishmentProfile({ key, digits });
        return digits;
      }
      case "address_postal_code": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.address_postal_code
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              address_postal_code: null,
            },
          });
        }

        const digits = digitsOnly(value);
        updateEstablishmentProfile({ key, digits });
        return digits;
      }
      case "email": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.email
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              email: null,
            },
          });
        }
        const emailValue = emailOnly(value);
        this.setState({ userInfo: { ...userInfo, email: emailValue } });
      }
      case "payment_requester_name": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.payment_requester_name
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              payment_requester_name: null,
            },
          });
        }

        const arabicLetters = removeExtraSpace(arabicLettersOnly(value));

        updateEstablishmentProfile({ key, arabicLetters });
        return arabicLetters;
      }
      case "IBAN_number": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.IBAN_number
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              IBAN_number: null,
            },
          });
        }
        const sanitizedValue = EnglishLettersWithNumbers(value);       
        updateEstablishmentProfile({ key, value: sanitizedValue });
        return sanitizedValue;
      }
      case "bank_info": {
        if (
          profileEditingValidationErrors !== {} &&
          profileEditingValidationErrors.bank_info
        ) {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              bank_info: null,
            },
          });
        }
        updateEstablishmentProfile({ key, value });
        return value;
      }
    }
    if (key !== "email") updateEstablishmentProfile({ key, value });
  }

  validateProfileEditing() {
    const { establishmentProfile } = this.props;
    const { establishmentInfo } = this.state;

    let formIsValid = true;
    let errors = {};
    if (
      establishmentProfile &&
      establishmentProfile.tax_number &&
      establishmentProfile.tax_number.trim() !== ""
    ) {
      if (establishmentProfile.tax_number.trim().length < 15) {
        errors.tax_number = TAX_NUMBER_ERROR_MESSAGE;
        formIsValid = false;
      }

      if (
        establishmentProfile.tax_number.charAt(
          establishmentProfile.tax_number.length - 1
        ) !== "3"
      ) {
        errors.tax_number = TAX_NUMBER_ERROR_MESSAGE;
        formIsValid = false;
      }
    }
    if (establishmentProfile.english_name?.trim().length <= 1) {
      errors.english_name = ENGLISH_NAME_MANDATORY_FIELD;
      formIsValid = false;
    }
    if (establishmentProfile.is_address_editable) {
      if (
        !establishmentProfile.address_district?.trim() ||
        establishmentProfile.address_district?.trim().length <= 1
      ) {
        errors.address_district = ADDRESS_DISTRICT_MANDATORY_FIELD;
        formIsValid = false;
      }
      if (
        !establishmentProfile.address_city?.trim() ||
        establishmentProfile.address_city?.trim().length <= 1
      ) {
        errors.address_city = ADDRESS_CITY_MANDATORY_FIELD;
        formIsValid = false;
      }
      if (
        !establishmentProfile.address_street.trim() ||
        establishmentProfile.address_street.trim()?.length <= 1
      ) {
        errors.address_street = ADDRESS_STREET_MANDATORY_FIELD;
        formIsValid = false;
      }
      if (
        !establishmentProfile.address_building_number.trim() ||
        establishmentProfile.address_building_number.trim()?.length == 0 ||
        establishmentProfile.address_building_number == "0"
      ) {
        errors.address_building_number =
          ADDRESS_BUILDING_NUMBER_MANDATORY_FIELD;
        formIsValid = false;
      }
      // if (
      // !establishmentProfile.address_additional_number.trim() ||
      // establishmentProfile.address_additional_number.trim().length
      // ) {
      //   errors.address_additional_number = ADDRESS_ADDITIONAL_FOUR_NUMBER_FIELD;
      //   formIsValid = false;
      if (
        establishmentProfile.address_additional_number != null &&
        establishmentProfile.address_additional_number?.trim() !== "" &&
        establishmentProfile.address_additional_number?.trim()?.length <= 3
      ) {
        errors.address_additional_number = ADDRESS_ADDITIONAL_FOUR_NUMBER_FIELD;
        formIsValid = false;
      } else {
        errors.address_additional_number = "";
      }
      if (
        !establishmentProfile.address_postal_code.trim() ||
        !establishmentProfile.address_postal_code?.trim()?.length
      ) {
        errors.address_postal_code = ADDRESS_POSTAL_CODE_MANDATORY_FIELD;
        formIsValid = false;
      } else if (
        establishmentProfile.address_postal_code?.trim()?.length <= 4
      ) {
        errors.address_postal_code = ADDRESS_POSTAL_CODE_FIVE_NUMBERS_FIELD;
        formIsValid = false;
      }
    }
    if (this.props.userInfo.is_email_editable) {
      const isCorrectFormat = isEmailFormatOnly(this.state.userInfo.email);
      if (
        this.state.userInfo?.email?.trim() &&
        this.state.userInfo?.email?.trim()?.length > 0 &&
        !isCorrectFormat
      ) {
        errors.email = EMAIL_FORMAT_FIELD;
        formIsValid = false;
      } else if (this.state.userInfo?.email?.trim()?.length === 0) {
        errors.email = EMAIL_MANDATORY_FIELD;
        formIsValid = false;
      }
    }
    if (
      establishmentProfile.payment_requester_name?.trim().length > 0 ||
      establishmentProfile.IBAN_number?.length > 2 ||
      establishmentProfile.bank_info?.label?.trim() ||
      establishmentInfo.bank_certificate_attachment?.file_name?.length > 0 ||
      (!establishmentInfo.bank_certificate_attachment?.file_name &&
        establishmentInfo.bank_certificate_attachment?.name?.length > 0)
    ) {
      if (
        !establishmentProfile.payment_requester_name ||
        establishmentProfile.payment_requester_name.trim().length === 0
      ) {
        errors.payment_requester_name = PAYMENT_REQUESTER_NAME_MANDATORY_FIELD;
        formIsValid = false;
      }

      if (
        !establishmentProfile.IBAN_number ||
        establishmentProfile.IBAN_number === "SA"
      ) {
        errors.IBAN_number = IBAN_BANK_MANDATORY_FIELD;
        formIsValid = false;
      } else {
        // Check if IBAN number starts with "SA" and consists of 22 alphanumeric characters
        const ibanNumber = establishmentProfile.IBAN_number.trim();
        const saRegex = /^SA[a-zA-Z0-9]{22}$/; // Regex to match "SA" followed by 22 alphanumeric characters
        if (!saRegex.test(ibanNumber)) {
          errors.IBAN_number = IBAN_BANK_VALIDATION_FAILED;
          formIsValid = false;
        }
      }
      if (
        !establishmentProfile.bank_info ||
        !establishmentProfile.bank_info.label ||
        !establishmentProfile.bank_info.label.trim()
      ) {
        errors.bank_info = BANK_ID_MANDATORY_FIELD;
        formIsValid = false;
      }
      if (
        !establishmentInfo.bank_certificate_attachment ||
        establishmentInfo.bank_certificate_attachment?.file_name?.length == 0
      ) {
        errors.bank_certificate_attachment =
          BANK_CERTIFICATE_ATTACHMENT_MANDATORY_FIELD;
        formIsValid = false;
      }

      if (!establishmentProfile.iban_disclaimer) {
        errors.iban_disclaimer = IBAN_DISCLAIMER_MANDATORY_FIELD;
        formIsValid = false;
      }
    }
    this.setState({
      profileEditingValidationErrors: Object.assign({}, errors),
    });

    return formIsValid;
  }

  async OnBlurInput(name, value) {
    const { profileEditingValidationErrors } = this.state;
    if (name === "english_name") {
      if (value?.trim()?.length === 0) {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            english_name: ENGLISH_NAME_MANDATORY_FIELD,
          },
        });
      } else if (value?.trim()?.length <= 1) {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            english_name: ENGLISH_NAME_CONSIST_OF_TWO_OR_MORE_LETTERS,
          },
        });
      }
    } else if (name === "tax_number") {
      if (value && value.trim() !== "" && value.trim().length !== 15) {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            tax_number: TAX_NUMBER_ERROR_MESSAGE,
          },
        });
      } else if (name === "tax_number") {
        if (value && value.charAt(value.length - 1) !== "3") {
          this.setState({
            profileEditingValidationErrors: {
              ...profileEditingValidationErrors,
              tax_number: TAX_NUMBER_ERROR_MESSAGE,
            },
          });
        }
      }
    }
    if (name === "address_district") {
      if (!value || (value.trim && value.trim().length <= 1)) {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            address_district: ADDRESS_DISTRICT_MANDATORY_FIELD,
          },
        });
      }
    }
    if (name === "address_city") {
      // Check if value is defined and not an empty string
      if (!value || value.trim().length <= 1) {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            address_city: ADDRESS_CITY_MANDATORY_FIELD,
          },
        });
      }
    }
    if (name === "address_street") {
      // Check if value is defined and not an empty string
      if (!value || value.trim().length <= 1) {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            address_street: ADDRESS_STREET_MANDATORY_FIELD,
          },
        });
      }
    } else if (name === "address_building_number") {
      // Check if value is defined
      if (value === undefined || value.trim() === "") {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            address_building_number: ADDRESS_BUILDING_NUMBER_MANDATORY_FIELD,
          },
        });
      }
    }
    // else if (name === "address_additional_number") {
    //   if (value === "") {
    //     this.setState({
    //       profileEditingValidationErrors: {
    //         ...profileEditingValidationErrors,
    //         address_additional_number:
    //           ADDRESS_ADDITIONAL_NUMBER_MANDATORY_FIELD,
    //       },
    //     });
    //   }
    // }
    else if (name === "address_additional_number") {
      if (!value || value.trim().length !== 4) {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            address_additional_number: ADDRESS_ADDITIONAL_FOUR_NUMBER_FIELD,
          },
        });
      }
    } else if (name === "address_postal_code") {
      if (!value || value.trim() === "") {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            address_postal_code: ADDRESS_POSTAL_CODE_MANDATORY_FIELD,
          },
        });
      } else if (value.trim().length !== 5) {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            address_postal_code: ADDRESS_POSTAL_CODE_FIVE_NUMBERS_FIELD,
          },
        });
      }
    }
    if (name === "email") {
      const isCorrectFormat = isEmailFormatOnly(this.state.userInfo.email);
      if (
        this.state.userInfo?.email?.trim() &&
        this.state.userInfo?.email?.trim()?.length > 0 &&
        !isCorrectFormat
      ) {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            email: EMAIL_FORMAT_FIELD,
          },
        });
      } else if (this.state.userInfo?.email?.trim()?.length === 0) {
        this.setState({
          profileEditingValidationErrors: {
            ...profileEditingValidationErrors,
            email: EMAIL_MANDATORY_FIELD,
          },
        });
      }
    }

    // if (name === "payment_requester_name") {
    //   if (value?.trim()?.length === 0) {
    //     this.setState({
    //       profileEditingValidationErrors: {
    //         ...profileEditingValidationErrors,
    //         payment_requester_name: PAYMENT_REQUESTER_NAME_MANDATORY_FIELD,
    //       },
    //     });
    //   }
    // }

    // if (name === "IBAN_number") {
    //   if (value === "SA") {
    //     this.setState({
    //       profileEditingValidationErrors: {
    //         ...profileEditingValidationErrors,
    //         IBAN_number: IBAN_BANK_MANDATORY_FIELD,
    //       },
    //     });
    //   } else if (value.trim().length !== 24) {
    //     this.setState({
    //       profileEditingValidationErrors: {
    //         ...profileEditingValidationErrors,
    //         IBAN_number: IBAN_BANK_VALIDATION_FAILED,
    //       },
    //     });
    //   }
    // }

    // if (name === "bank_info") {
    //   if (!value || !value.label || !value.label.trim()) {
    //     this.setState({
    //       profileEditingValidationErrors: {
    //         ...profileEditingValidationErrors,
    //         bank_info: BANK_ID_MANDATORY_FIELD,
    //       },
    //     });
    //   }
    // }
    return profileEditingValidationErrors;
  }

  chooseEstablishment(establishment) {
    this.props.actions.chooseEstablishment(establishment);
    this.props.actions.fetchToolBarNotifications(establishment.id);
    this.props.actions.loadPaymentServices(establishment.id);
    this.props.history.push("/establishmentBasicDurationsPage");
  }

  confirmUserInfo() {
    const { establishmentProfile } = this.props;
    this.props.actions
      .confirmUserInfo(establishmentProfile.id)
      .then(() =>
        this.props.actions.loadEstablishments().then(() => {
          const updatedEstablishment = this.props.establishments.find(
            (e) => e.id === establishmentProfile.id
          );
          return updatedEstablishment;
        })
      )
      .then(this.chooseEstablishment);
  }
  // onNextClick = () => {
  //   const profileIsValid = this.validateProfileEditing();
  //   const { isEstablishmentInfo } = this.state;
  //   if (!isEstablishmentInfo) {
  //     this.confirmUserInfo();
  //   } else if (isEstablishmentInfo && profileIsValid) {
  //     this.setState({ isEstablishmentInfo: false });
  //     this.onSave();
  //   }
  // };
  render() {
    const {
      isEdit,
      profileEditingValidationErrors,
      isEstablishmentInfo,
      banks,
      isLoading,
      establishmentInfo,
    } = this.state;
    const { establishmentProfile, userInfo } = this.props;
    const isProfileFirstLogin =
      establishmentProfile.id && !establishmentProfile.is_confirmed;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationLoader,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <PageWrapper>
        <div>
          {
            isLoading ? (
              <div className="loader-div">
                <Lottie options={defaultOptions} height={160} width={160} />
                <p className="assessment-submission-loading-text">
                  يرجى الانتظار..
                </p>
              </div>
            ) : (
              establishmentProfile.id &&
              userInfo &&
              banks.length > 0 && (
                <ProfileContent
                  userInfo={this.state.userInfo}
                  BanksList={this.state.banks}
                  targetId={this.state.targetId}
                  establishmentProfile={establishmentProfile}
                  validationErrors={profileEditingValidationErrors}
                  defaultMapLocation={DEFAULT_MAP_LOCATION}
                  isEdit={isEdit || isProfileFirstLogin}
                  isProfileFirstLogin={isProfileFirstLogin}
                  isAddressEditable={
                    this.state.establishmentInfo.is_address_editable
                  }
                  onEdit={this.onEdit}
                  onSave={this.onSave}
                  onCancel={this.onCancel}
                  onLocationChange={this.onLocationChange}
                  onProfileFieldChange={this.onProfileFieldChange}
                  OnBlurInput={this.OnBlurInput}
                  setValue={this.setValue}
                  establishmentInfo={this.state.establishmentInfo}
                  setUserValueInfo={this.setUserValueInfo}
                  handleGetAndUploadFile={(attachment) => {
                    this.setState({
                      establishmentInfo: {
                        ...this.state.establishmentInfo,
                        bank_certificate_attachment: attachment,
                      },
                    });

                    // this.props.updateEstablishmentProfile({
                    //   key: "bank_certificate_attachment",
                    //   value: { file: attachment },
                    // });
                  }}
                  handleRemoveFileById={(attachmentId) => {
                    if (
                      establishmentProfile.bank_certificate_attachment
                        ?.url_attachment
                    ) {
                      this.props.deleteBankCertificate(attachmentId);
                      this.props.updateEstablishmentProfile({
                        key: "bank_certificate_attachment",
                        value: null,
                      });
                    }
                    this.setState({
                      establishmentInfo: {
                        ...this.state.establishmentInfo,
                        bank_certificate_attachment: null,
                      },
                    });
                  }}
                />
              )
            )
            // ))
          }
        </div>
      </PageWrapper>
    );
  }
}

Profile.propTypes = {
  establishmentProfile: PropTypes.object.isRequired,
  userInfo: PropTypes.PropTypes.object,
  saveEstablishmentProfile: PropTypes.func.isRequired,
  updateEstablishmentProfile: PropTypes.func.isRequired,
  establishments: PropTypes.array.isRequired,
  saveSelectedEstablishment: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  fetchBanks: PropTypes.func.isRequired,
  deleteBankCertificate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  establishmentProfile: state.establishment.establishmentProfile,
  userInfo: state.auth.userInfo,
  establishments: state.establishment.establishments,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch, // Add this line to map dispatch to props
  actions: bindActionCreators(
    {
      chooseEstablishment,
      confirmUserInfo,
      loadEstablishments,
      loadPaymentServices,
      fetchToolBarNotifications,
    },
    dispatch
  ),

  saveEstablishmentProfile: (
    establishmentId,
    profileData,
    attachment,
    userId
  ) =>
    dispatch(
      profileActions.saveEstablishmentProfile(
        establishmentId,
        profileData,
        attachment,
        userId
      )
    ),
  updateEstablishmentProfile: (payload) =>
    dispatch(profileActions.updateEstablishmentProfile(payload)),
  saveSelectedEstablishment: (establishment) =>
    dispatch(profileActions.loadEstablishmentProfile(establishment)),
  loadEstablishmentProfile: (establishmentProfile) =>
    dispatch(profileActions.loadEstablishmentProfile(establishmentProfile)),
  getUserInfo: (isLoadEstablishments) =>
    dispatch(getUserInfo(isLoadEstablishments)),
  fetchBanks: () => dispatch(BanksList()),
  postBankCertificate: (attachment, establishmentId) =>
    dispatch(profileActions.postBankCertificate(attachment, establishmentId)),
  deleteBankCertificate: (attachmentId) =>
    dispatch(profileActions.deleteBankCertificate(attachmentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
