export function listFormattedForDropdown(list) {
  return list.map((item) => {
    if (item.label) return item;
    else
      return {
        value: item.id,
        label: item.name,
        item: item,
      };
  });
}

export function listFormattedForMultiSelectDropdown(list) {
  return list.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
}
export function listFormattedForEstablishmentCategoriesDropdown(list) {
  return list.map((item) => {
    return {
      value: item.id,
      label: item.compositeName,
      item: item,
    };
  });
}

export function listFormattedForSearchDropdown(list) {
  return list.map((item) => ({
    value: item.id,
    label: item.name,
    item: item,
  }));
}

export function listFormattedForSearchEstablishmentCategoriesDropdown(list) {
  return list.map((item) => ({
    value: item.id,
    label: item.compositeName,
    item: item,
  }));
}

export function listTrainersForSearchDropdown(list) {
  return list.map((item) => ({
    value: item.id,
    label: `${item.first_name} ${item.last_name}`,
    item: item,
  }));
}

export function listCommitteesForSearchDropdown(list) {
  return list.map((item) => ({
    value: item.id,
    label: `${item.arabic_name}`,
    item: item,
  }));
}

export function listDropdown(list) {
  return list.map((item) => ({
    value: item.id,
    label: `${item.name}`,
    item: item,
  }));
}

export function listFormattedForInspectionPeriod(list) {
  if (list && list.periods)
    return list.periods.map((item) => {
      return {
        value: item.name,
        label: item.name,
        item: item,
      };
    });
}
export function listFormattedForLaborOfficesDropdown(list) {
  return list.map((item) => ({
    value: item.id,
    label: item.city_name,
    item: item,
  }));
}
