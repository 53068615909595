import request from "../common/request";
import { beginAjaxCall, ajaxCallError, endAjaxCall } from "./ajaxStatusActions";

export function paymentVerify(establishmentId, checkoutId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());

    return request(
      `/payments/establishments/${establishmentId}/verify_hyperpay/${checkoutId}`
    )
      .then((res) => {
        if (!res.ok) {
          return res.json().then((text) => {
            throw text.result.code;
          });
        }
        return res.json();
      })
      .then((res) => {
        dispatch(endAjaxCall());
        return res;
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}
