import initialState from "./initialState";

import {
  FAST_TRACK_BRANCHES_START_LOADING,
  FAST_TRACK_BRANCHES_STOP_LOADING,
  FAST_TRACK_BRANCHES_SET_ERROR,
  FAST_TRACK_BRANCHES_ESTABLISHMENT_SUCCESS,
} from "../actions/actionTypes";
export default function FastTrackForBranchesReducer(
  state = initialState.FastTrackForBranches,
  action
) {
  switch (action.type) {
    case FAST_TRACK_BRANCHES_START_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
      });
    case FAST_TRACK_BRANCHES_STOP_LOADING:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case FAST_TRACK_BRANCHES_SET_ERROR:
      return Object.assign({}, state, {
        error: true,
        isLoading: false,
      });
    case FAST_TRACK_BRANCHES_ESTABLISHMENT_SUCCESS:
      return Object.assign({}, state, {
        establishments: action.establishments,
        total: action.total,
      });
    default:
      return state;
  }
}
