export const STATUS_ENABLED = "Enabled";
export const STATUS_SUBMITTED = "Submitted";
export const STATUS_TIMEOUT = "Timeout";
export const STATUS_CANCELLED = "Terminated";
export const STATUS_ACTIVE = "Active";

// # New status for this banner https://xd.adobe.com/view/d01f8075-a5e0-4915-9b39-527a23938470-854c/
export const RE_SUBMIT_STATUS = "ReturnedToConfirm";
export const RE_SUBMIT_STATUS_API_VALUE = "Returned To Confirm";

export const ASSESSMENT_TYPE_FAST_TRACK = "Fast-Track";
export const ASSESSMENT_TYPE_RE_ASSESSMENT = "Re-Assessment";
export const ASSESSMENT_TYPE_BASIC = "Basic";
export const ASSESSMENT_TYPE_UNIFIED_ASSESSMENT = "Unified-Assessment";
export const ASSESSMENT_TYPE_FIELD_INSPECTION = "Field-Inspection";

export const AUDIT_STATUS_SHOWAUDITRESULT = "ShowAuditResult";
export const AUDIT_STATUS_REAUDIT = "ReAudit";
export const AUDIT_STATUS_NOAUDITING = "NoAuditing";
export const AUDIT_STATUS_UNDERAUDITING = "UnderAuditing";
export const AUDIT_STATUS_SHOWAUDITRESULTANDREAUDITT =
  "ShowAuditResultAndReAudit";
export const ON_HOLD = "OnHold";
export const AUDIT_STATUS_COMPLETED = "Completed";
export const AUDIT_STATUS_UNDERGRACPERIOD = "UnderGracePeriod";
export const AUDIT_STATUS_UNDERINSPECTION = "UnderInspection";

export const UNIFIED_ASSESSMENT_STATUS_ENABLE = "Enable";
export const UNIFIED_ASSESSMENT_STATUS_DISABLE = "Disable";
export const UNIFIED_ASSESSMENT_STATUS_NOT_PAID = "Not-Paid";

export const TAMAM_INSPECTION_STATUS_BOTH = "Both";
export const TAMAM_INSPECTION_STATUS_DISABLE = "Disable";
export const TAMAM_INSPECTION_STATUS_ONLY_INSPECTION = "Only_Inspection";
export const TAMAM_INSPECTION_STATUS_ONLY_ASSESSMENT = "Only_Assessment";

export const INSPECTION_REQUEST_STATUS_NEW = "New";
export const INSPECTION_REQUEST_STATUS_INITIATED = "Initiated";
export const INSPECTION_REQUEST_STATUS_ON_PROGRESS = "OnProgress";
export const INSPECTION_REQUEST_STATUS_COMPLETED = "Completed";

export const STATUS_EMPTY = "Empty";
export const STATUS_PARTIALLY_FILLED_API_VALUE = "Partially Filled";
export const STATUS_PARTIALLY_FILLED = "PartiallyFilled";

export const STATUSES = {
  [STATUS_ENABLED]: "بإنتظار التقييم ",
  [STATUS_SUBMITTED]: "اكتمال التقييم الاساسي",
  [STATUS_TIMEOUT]: "انتهت الفترة",
  [STATUS_CANCELLED]: "تم إيقاف الفترة",
  [STATUS_ACTIVE]: "نشطة",
  [RE_SUBMIT_STATUS]: "معاد للإفادة",
};

export const ASSESSMENT_TYPES = {
  [ASSESSMENT_TYPE_FAST_TRACK]: "مسار مميز",
  [ASSESSMENT_TYPE_RE_ASSESSMENT]: "إعادة تقييم",
  [ASSESSMENT_TYPE_BASIC]: "الاساسي",
  [ASSESSMENT_TYPE_UNIFIED_ASSESSMENT]: "التقييم الموحد",
  [ASSESSMENT_TYPE_FIELD_INSPECTION]: "التفتيش الميداني",
};

export const AUDIT_STATUSES = {
  [AUDIT_STATUS_SHOWAUDITRESULT]: "إكتمال المسار المميز",
  [AUDIT_STATUS_REAUDIT]: "إكتمال المسار المميز",
  [AUDIT_STATUS_NOAUDITING]: "قيد التنفيذ",
  [AUDIT_STATUS_UNDERAUDITING]: "جاري التدقيق",
  [AUDIT_STATUS_SHOWAUDITRESULTANDREAUDITT]: "إكتمال المسار المميز",
  [ON_HOLD]: "معاد للتعديل",
  [RE_SUBMIT_STATUS]: "معاد للتعديل",
  [AUDIT_STATUS_COMPLETED]: "مكتمل",
  [AUDIT_STATUS_UNDERGRACPERIOD]: "تحت المهله التصحيحية",
  [AUDIT_STATUS_UNDERINSPECTION]: "تحت التفتيش",
};
export const INSPECTION_REQUEST_STATUSES = {
  [INSPECTION_REQUEST_STATUS_NEW]: "تفتيش جديد",
  [INSPECTION_REQUEST_STATUS_INITIATED]: "تفتيش جديد",
  [INSPECTION_REQUEST_STATUS_ON_PROGRESS]: "تحت التفتيش",
  [INSPECTION_REQUEST_STATUS_COMPLETED]: "تم التفتيش",
};
export const getStatusName = (
  state,
  assessmentType,
  auditStatus,
  inspectionStatus,
  durationStatus
) => {
  if (
    [
      INSPECTION_REQUEST_STATUS_NEW,
      INSPECTION_REQUEST_STATUS_INITIATED,
      INSPECTION_REQUEST_STATUS_ON_PROGRESS,
      INSPECTION_REQUEST_STATUS_COMPLETED,
    ].includes(inspectionStatus)
  ) {
    return INSPECTION_REQUEST_STATUSES[inspectionStatus];
  } else if (assessmentType === ASSESSMENT_TYPE_FAST_TRACK) {
    return AUDIT_STATUSES[auditStatus];
  } else if (auditStatus === RE_SUBMIT_STATUS) {
    return AUDIT_STATUSES[RE_SUBMIT_STATUS];
  } else if (
    assessmentType === ASSESSMENT_TYPE_BASIC &&
    auditStatus === AUDIT_STATUS_UNDERAUDITING &&
    state === STATUS_SUBMITTED &&
    durationStatus === STATUS_ACTIVE
  ) {
    return AUDIT_STATUSES[auditStatus];
  } else return STATUSES[state];
};

export const getStatusColor = (
  state,
  assessmentType,
  auditStatus,
  inspectionStatus,
  durationStatus
) => {
  if (
    [
      INSPECTION_REQUEST_STATUS_NEW,
      INSPECTION_REQUEST_STATUS_INITIATED,
      INSPECTION_REQUEST_STATUS_ON_PROGRESS,
      INSPECTION_REQUEST_STATUS_COMPLETED,
    ].includes(inspectionStatus)
  ) {
    switch (inspectionStatus) {
      case INSPECTION_REQUEST_STATUS_NEW:
        return "blue";
      case INSPECTION_REQUEST_STATUS_INITIATED:
        return "blue";
      case INSPECTION_REQUEST_STATUS_ON_PROGRESS:
        return "purple";
      case INSPECTION_REQUEST_STATUS_COMPLETED:
        return "green";
    }
  } else if (
    assessmentType === ASSESSMENT_TYPE_FAST_TRACK ||
    (assessmentType === ASSESSMENT_TYPE_BASIC &&
      auditStatus === AUDIT_STATUS_UNDERAUDITING &&
      state === STATUS_SUBMITTED &&
      durationStatus === STATUS_ACTIVE) ||
    (assessmentType === ASSESSMENT_TYPE_BASIC &&
      auditStatus === RE_SUBMIT_STATUS)
  ) {
    switch (auditStatus) {
      case AUDIT_STATUS_SHOWAUDITRESULT:
        return "green";
      case AUDIT_STATUS_REAUDIT:
        return "green";
      case AUDIT_STATUS_NOAUDITING:
        return "blue";
      case AUDIT_STATUS_UNDERAUDITING:
        return "purple";
      case AUDIT_STATUS_SHOWAUDITRESULTANDREAUDITT:
        return "green";
      case ON_HOLD:
        return "green";
      case RE_SUBMIT_STATUS:
        return "darkgreen";
    }
  } else {
    switch (state) {
      case STATUS_ENABLED:
        return "gold";
      case STATUS_SUBMITTED:
        return "green";
      case STATUS_TIMEOUT:
        return "gray";
      case STATUS_CANCELLED:
        return "red";
    }
  }
};
