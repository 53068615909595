import React from "react";
import { twMerge } from "../../tw-merge";
import "./checkbox.css";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
export const CheckBox = ({ checked, onChange, label, id }) => {
  const lid = id;
  const lblClss = twMerge(
    "text-lg mt-[6px] text-lg font-medium font-effra text-ims-dark_gray mobile-checkbox-label"
    // checked ? "text-ims-turquoise_blue" : "text-ims-dark_gray"
  );
  return (
    <div className="relative flex items-center gap-x-2 mobile-checkbox-wrapper">
      <div className="flex h-6 items-center relative cursor-pointer">
        <input
          id={lid}
          aria-describedby="comments-description"
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange?.(e.target.checked)}
          className="absolute h-6 w-6 cursor-pointer checkbox-style focus:border-[#148287] checked:border-[#148287]"
        />
      </div>

      <Text
        Tag={"label"}
        fontSize={"font-18"}
        textType="roman"
        mobileFontSize={"font-16-mobile"}
        style={{ color: "#000" }}
        className="mt-[6px] mobile-checkbox-label"
      >
        {label}
      </Text>
    </div>
  );
};
