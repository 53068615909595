import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer/WhiteFooter"
import SpecialTrackCertificateDetails from"./SpecialTrackCertificateDetails"
class PublicSpecialTrackCertificateDetailsPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <Header />
       <SpecialTrackCertificateDetails/>
       <Footer />
      </>
    );
  }
}

export default PublicSpecialTrackCertificateDetailsPage;
