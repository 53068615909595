import { STATUSES } from "../../../../../../common/constants/paymentStatuses";
export const getTableHeader = (isMobile = false) => {
  if (isMobile) {
    return [
      {
        Header: "نوع الخدمة",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "المبلغ (شامل الضريبة)",
        accessor: "col2",
      },
    ];
  } else {
    return [
      {
        Header: "نوع الخدمة",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "المبلغ",
        accessor: "col2",
      },
      {
        Header: "ضريبة القيمة المضافة",
        accessor: "col3",
      },
      {
        Header: "المبلغ الإجمالي",
        accessor: "col4",
      },
    ];
  }
};
export const getTableRows = (
  serviceList,
  isReAssessment = false,
  isMobile = false
) => {
  if (isMobile) {
    if (isReAssessment) {
      return [
        {
          col1: STATUSES[serviceList[0]?.type],
          col2: `${
            serviceList[0]?.amount * serviceList[0]?.vat +
            serviceList[0]?.amount
          } ريال `,
        },

        {
          col1: "المجموع",
          col2: `${
            serviceList[0]?.amount * serviceList[0]?.vat +
            serviceList[0]?.amount
          } ريال `,
        },
      ];
    } else {
      return [
        {
          col1: STATUSES[serviceList[0]?.type],
          col2: `${
            serviceList[0]?.amount * serviceList[0]?.vat +
            serviceList[0]?.amount
          } ريال `,
        },
        {
          col1: STATUSES[serviceList[1]?.type],
          col2: `${
            serviceList[1]?.amount * serviceList[1]?.vat +
            serviceList[1]?.amount
          } ريال `,
        },
        {
          col1: "المجموع",
          col2: `${serviceList.reduce(
            (p, c) => p + c.amount * c.vat + c.amount,
            0
          )} ريال `,
        },
      ];
    }
  } else {
    if (isReAssessment)
      return [
        {
          col1: STATUSES[serviceList[0]?.type],
          col2: `${serviceList[0]?.amount} ريال`,
          col3: `${serviceList[0]?.amount * serviceList[0]?.vat} ريال`,
          col4: `${
            serviceList[0]?.amount * serviceList[0]?.vat +
            serviceList[0]?.amount
          } ريال سعودي`,
        },

        {
          col1: "المجموع",
          col2: "",
          col3: "",
          col4: `${
            serviceList[0]?.amount * serviceList[0]?.vat +
            serviceList[0]?.amount
          } ريال سعودي`,
        },
      ];
    else
      return [
        {
          col1: STATUSES[serviceList[0]?.type],
          col2: `${serviceList[0]?.amount} ريال`,
          col3: `${serviceList[0]?.amount * serviceList[0]?.vat} ريال`,
          col4: `${
            serviceList[0]?.amount * serviceList[0]?.vat +
            serviceList[0]?.amount
          } ريال سعودي`,
        },
        {
          col1: STATUSES[serviceList[1]?.type],
          col2: `${serviceList[1]?.amount} ريال`,
          col3: `${serviceList[1]?.amount * serviceList[1]?.vat} ريال`,
          col4: `${
            serviceList[1]?.amount * serviceList[1]?.vat +
            serviceList[1]?.amount
          } ريال سعودي`,
        },
        {
          col1: "المجموع",
          col2: "",
          col3: "",
          col4: `${serviceList.reduce(
            (p, c) => p + c.amount * c.vat + c.amount,
            0
          )} ريال سعودي`,
        },
      ];
  }
};
