import React from "react";
import PropTypes from "prop-types";

import CheckIcon from "./assets/checkmark-green.svg";

import "./caliber-description.scss";
import { Text } from "../../../../../../shared-components/my-scope/atoms/text/index";

export const CaliberDescription = ({ description }) => {
  return (
    <div className="caliber-description">
      <header className="caliber-description-header">
        <img className="caliber-description-header__icon" src={CheckIcon} />
        <Text
          Tag="div"
          textType="bold"
          fontSize="font-18"
          mobileFontSize="font-16-mobile"
          className="caliber-description-header__title"
        >
          الإجراء التصحيحي
        </Text>
      </header>
      <main className="caliber-description-main">
        <Text
          Tag="div"
          textType="roman"
          fontSize="font-18"
          mobileFontSize="font-16-mobile"
          className="caliber-description-main__desc"
        >
          {description}
        </Text>
      </main>
    </div>
  );
};

CaliberDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default CaliberDescription;
