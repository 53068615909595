export default [
  {
    id: 1,
    label: "المعايير العامة للعاملين",
  },
  {
    id: 2,
    label: "المعايير العامة للمنشآت",
    imgURL: "/img/all.svg",
  },
  {
    id: 3,
    label: "المعايير الخاصة للعاملين",
  },
];
