import initialState from "./initialState";
import {
  BILL_INFO_START_LOADING,
  BILL_INFO_STOP_LOADING,
  BILL_INFO_SET_ERROR,
} from "../actions/actionTypes";

export default function billInfo(state = initialState.billInfo, action) {
  switch (action.type) {
    case BILL_INFO_START_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
      });
    case BILL_INFO_STOP_LOADING:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case BILL_INFO_SET_ERROR:
      return Object.assign({}, state, {
        error: true,
        isLoading: false,
      });

    default:
      return state;
  }
}
