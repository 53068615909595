import React from "react";
import Content from "../../containers/Content/Content";

import { Card, CardBody, CardGroup, CardHeader, Col, Row } from "reactstrap";

const TERMS_AND_DISCLAIMERS_LINK =
  "https://mlsd.gov.sa/ar/%D8%B5%D9%81%D8%AD%D8%A9/%D8%A7%D9%84%D8%A5%D8%B3%D8%AA%D8%AE%D8%AF%D8%A7%D9%85-%D9%88%D8%A5%D8%AE%D9%84%D8%A7%D8%A1-%D8%A7%D9%84%D9%85%D8%B3%D8%A4%D9%88%D9%84%D9%8A%D8%A9";
const PRIVACY_POLICY_LINK =
  "https://mlsd.gov.sa/ar/%D8%B5%D9%81%D8%AD%D8%A9/%D8%B3%D9%8A%D8%A7%D8%B3%D8%A9-%D8%A7%D9%84%D8%AE%D8%B5%D9%88%D8%B5%D9%8A%D8%A9";
const TECHNICAL_SUPPORT_EMAIL = "";
const CONTACT_LINK = "#";

const TermsPage = () => (
  <Content>
    <div className="container-fluid-size">
      <Row className="justify-content-center">
        <Col>
          <CardGroup className="mb-0">
            <Card>
              <CardHeader className="card-header-style">
                <strong className="underlined-green-wide">
                  الشروط والأحكام الخاصة بخدمة "التقييم الذاتي"
                </strong>
              </CardHeader>
              <CardBody className="card-body static-content">
                <h2>مقدمة</h2>
                <p>
                  يُرجى قراءة الشروط والأحكام التالية بعناية تامّة قبل البدء في
                  استخدام الموقع والاستفادة من منصة التقييم الذاتي، ويوصّى
                  باستخراج نسخة مطبوعة من هذه الشروط والأحكام لإمكانيّة الرجوع
                  إليها عند الحاجة في المستقبل.
                  <br />
                  <br />
                  يؤكّد مستخدم بوابة التقييم الذاتي ("البوابة") على قبوله
                  والتزامه بشروط وأحكام الاستخدام التالية وفهمها جيدًا،
                  والموافقة على الامتثال لما تتضمّنه البوابة من شروط وأحكام
                  ليتمكّن من الاستفادة منها.
                  <br />
                  <br />
                  تسعى وزارة الموارد البشرية والتنمية الاجتماعية ("الوزارة") من
                  خلال البوابة إلى إيجاد آليّة تُمكّن المنشآت من قياس مدى
                  التزامها بأنظمة ولوائح الوزارة والقرارات ذات العلاقة،
                  ولإبقائهم على علمٍ بأيّ تحديثاتٍ قد تطرأ على هذه الأنظمة
                  واللوائح والقرارات.
                  <br />
                  <br />
                  تستطيع المنشآت القيام بعملية التقييم الذاتي عن طريق تسجيل
                  الدخول للبوابة وذلك باستخدام البيانات التي تمكّنهم من الدخول
                  إلى بوابة خدمات وزارة الموارد البشرية والتنمية الاجتماعية، على
                  أن يتم تعبئة بياناتهم قبل البدء بمرحلة التقييم. بعد إتمام
                  عمليّة التقييم الذاتي تُظهر نتائج نسبةَ إمتثال المنشأة لكلّ
                  معيار من معايير نظام العمل واللوائح والقرارات ذات العلاقة. قد
                  تكون عملية التقييم الذاتي متطلّب أساسي للمنشآت للاستفادة من
                  جميع خدمات الوزارة في المستقبل.
                </p>

                <h2>المصطلحات</h2>
                <p>
                  <ul className="list-number">
                    <li>
                      <strong>
                        يقصد بالكلمات والعبارات التالية أينما وردت هنا المعاني
                        المبينة إلى جانب كل منها ما لم يتضح من صراحة النص أو
                        يقتضي سياق الكلام غير ذلك:
                      </strong>
                      <ul className="list-none">
                        <li>
                          "بوابة التقييم الذاتي" أو "البوابة" هي البوابة
                          الإلكترونية التي تحتوي على منصة التقييم الذاتي.
                        </li>
                        <li>
                          "منصة التقييم الذاتي" هو قيام المنشأة بالإفصاح وتقديم
                          البيانات اللازمة والتي يتم على أساسها تحديد نسبة
                          إمتثال المنشأة مع أنظمة ولوائح نظام العمل والقرارات
                          ذات العلاقة.
                        </li>
                        <li>
                          "المستخدم" يقصد به المنشآت وممثليها ومشرفيها المخوّلين
                          بالدخول على البوابة من خلال حساب المستخدم.
                        </li>
                        <li>
                          "المنشأة/المنشآت" أيّ شخصية اعتباريّة لديها ملف ورقم
                          مُسجّل في وزارة الموارد البشرية والتنمية الاجتماعية.
                        </li>
                        <li>
                          "الوزارة" ويقصد بها وزارة الموارد البشرية والتنمية
                          الاجتماعية.
                        </li>
                        <li>
                          "الحساب" هو حساب المستخدم في البوابة ويتضمن بيانات
                          الدخول من اسم المستخدم وكلمة المرور والمطابقة للبيانات
                          والمعلومات المسجلة في بوابة خدمات وزارة الموارد
                          البشرية والتنمية الاجتماعية.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>
                        تدل الكلمات الواردة بصيغة المفرد على ذات المدلول بصيغة
                        الجمع، ويكون العكس صحيحاً أيضاً إذا تطلب النص ذلك. كما
                        أن العناوين والهوامش الواردة في هذه الشروط والأحكام لا
                        تؤخذ بعين الاعتبار عند تفسيرها.
                      </strong>
                    </li>
                  </ul>
                </p>

                <h2>شروط إضافية </h2>
                <p>
                  تشتمل الشروط والأحكام هذه أيضا على الشروط الإضافية التالية،
                  التي تنطبق كذلك على استخدام البوابة:
                  <ul>
                    <li>
                      <span>
                        شروط الاستخدام وإخلاء المسؤولية الخاصة ببوابة خدمات
                        وزارة الموارد البشرية والتنمية الاجتماعية
                      </span>
                      <span>
                        {" "}
                        (
                        <a href={TERMS_AND_DISCLAIMERS_LINK}>
                          الاستخدام وإخلاء المسؤولية
                        </a>
                        ){" "}
                      </span>
                    </li>
                    <li>
                      <span>
                        سياسة الخصوصية الخاصة ببوابة خدمات وزارة الموارد البشرية
                        والتنمية الاجتماعية
                      </span>
                      <span>
                        {" "}
                        (<a href={PRIVACY_POLICY_LINK}>سياسة الخصوصية</a>){" "}
                      </span>
                      <span>
                        التي تنص على الشروط التي نقوم بموجبها بمعالجة البيانات
                        الشخصية التي يقدمها المستخدم. وباستخدام البوابة، فإن
                        المستخدم يوافق على هذه المعالجة ويتعهد بأن جميع البيانات
                        التي قدمها هي بيانات صحيحة ودقيقة.{" "}
                      </span>
                    </li>
                  </ul>
                </p>

                <h2>شروط الاستخدام </h2>
                <p>
                  باستخدام البوابة يعتبر مستخدم هذه البوابة قد قرأ ووافق على
                  الشروط والأحكام التالية:
                  <ul>
                    <li>تقديم وإدخال بيانات ومعلومات كاملة وواضحة وصحيحة.</li>
                    <li>
                      التقيّد بكافّة تشريعات المملكة العربية السعودية ذات
                      العلاقة وتوفير كافّة التراخيص اللازمة.
                    </li>
                    <li>
                      التقيّد بكافّة متطلّبات الجهات الحكومية في المملكة العربية
                      السعودية، ومن ذلك على سبيل المثال لا الحصر متطلّبات وزارة
                      الموارد البشرية و التنمية الاجتماعية.
                    </li>
                    <li>
                      مراعاة الشروط والأحكام ذات العلاقة عند القيام بعملية
                      التقييم الذاتي.
                    </li>
                    <li>
                      الأخذ بعين الاعتبار أنّ استخدام المنصة وتقديم الطلب لا
                      يشكّل أيّ التزام تعاقدي على الوزارة.
                    </li>
                    <li>
                      توفير المستندات المتعلّقة بالتقييم الذاتي بصيغة إلكترونية.
                    </li>
                  </ul>
                </p>

                <h2>التغييرات على هذه الشروط والأحكام والموقع </h2>
                <p>
                  تخضع شروط وأحكام منصة التقييم الذاتي إلى تحديثات وتغييرات
                  مستمرّة حسب الحاجة، كما تحتفظ الوزارة بحقّها في تعديل أو تغيير
                  هذه الشروط والأحكام، أو مضمون أيّ جزء من موقعها على الشبكة في
                  أيّ وقت و بدون أيّ إشعار. ويصبح أي تعديل أو تغيير لأي من هذه
                  الشروط والأحكام نافذاً فور اعتماده من قبل الوزراة؛ وهو ما
                  يتطلّب من المستخدم مراجعة مستمرة للشروط والأحكام ومبادئ حدود
                  المسؤوليّة لمعرفة أيّ تحديثاتٍ تتمّ عليها، حيث إنّ استمرار
                  المستخدم في استخدام البوابة يعني اطلاعه وقبوله التام لأيّ
                  تعديل تمّ على هذه الشروط والأحكام، كما أن الوزارة غير مطالبة
                  بالإعلان عن أيّ تحديثات أو تعديلات تتمّ على هذه الشروط
                  والأحكام.
                  <br />
                  <br />
                  قد تقوم الوزارة بتحديث محتوى البوابة من حين لآخر. لذا يرجى
                  ملاحظة أن أي جزء من محتويات البوابة قد يصبح قديماً في أي وقت،
                  والوزارة غير ملزمة بتحديثه مطلقا.
                </p>

                <h2>صحة المعلومات التي يزودها المستخدم </h2>
                <p>
                  يضمن المستخدم أن جميع المعلومات التي يقوم بإضافتها في حسابه في
                  البوابة صحيحة تماماً، ويتحمّل المستخدم كامل المسؤولية عن أي
                  معلومات خاطئة يقوم بإضافتها.
                  <br />
                  <br />
                  ويقر المستخدم بالتزامه بإبلاغ الدعم الفني للبوابة فوراً بأي
                  تغيير يطرأ على البيانات والمعلومات المدخلة أثناء عملية تسجيل
                  الدخول.
                  <br />
                  <br />
                  لدى الوزارة الصلاحية في تعطيل أي حساب مستخدم أو كلمة مرور،
                  سواء تم اختيارها من قبل المستخدم أو تم تخصيصها له بواسطة
                  الوزارة، في أي وقت؛ حين ترى الوزارة أن المستخدم قد فشل في
                  التقيد بأحد شروط وأحكام البوابة. المستخدم وحده المسؤول عن
                  استخدام الحساب ومايترتب عليه من أنشطة. في حالة الشك بأن شخصًا
                  آخر على علم بحساب المستخدم أو كلمة المرور الخاصة به، يجب
                  الإبلاغ فورا على عنوان الدعم الفني التالي:{" "}
                  {TECHNICAL_SUPPORT_EMAIL}
                </p>

                <h2>التحقق</h2>
                <p>
                  يحق للوزارة التحقق من المعلومات المقدمة من المستخدم في
                  البوابة. ستخضع المعلومات المقدمة من المستخدم أو أية معلومات
                  أخرى تحصل عليها الوزارة من أي جهة أخرى لسياسة الخصوصية
                  وستستخدم للتحقق من صحة المعلومات المقدمة من المستخدم.
                </p>

                <h2>الدخول للبوابة</h2>
                <p>
                  لا تضمن الوزارة دوام استمرارية وإتاحة البوابة أو جزءًا من
                  محتواها. استخدام المستخدم للبوابة يُعتبر استخدامًا مؤقتًا؛ حيث
                  يحق للوزارة تعليق أو حجب أو إيقاف أو تغيير كل أو جزء من
                  البوابة دون إشعار مسبق. نُخلي مسؤوليتنا من عدم إتاحة موقعنا
                  لأي سباب وفي أي وقت. المستخدم مسؤول عن كافة اجراءات الدخول
                  اللازمة للبوابة. يتحمل المستخدم مسؤولية ضمان إحاطتهم وإمتثالهم
                  للبوابة عبر اتصال الإنترنت الخاص بهم بشروط الاستخدام والأحكام
                  المطبقة الأخرى. صُممت البوابة للإستخدام داخل المملكة العربية
                  السعودية ولا تضمن الوزارة توفر محتوى البوابة في أماكن أخرى.
                  يحق للوزارة الحد من توفير البوابة وتقنين خدماتها فيما يخص أي
                  مستخدم في أي وقت كان. يتحمل المستخدم كامل مسؤولية دخوله
                  للبوابة من خارج المملكة.
                </p>

                <h2>حالات استخدام وإفصاح المعلومات</h2>
                <p>
                  بالإضافة إلى ما ذكر في سياسة الخصوصية الخاصة ببوابة الوزارة،
                  فإن المعلومات التي يقدمها المستخدم أو التي تم جمعها عن
                  المستخدم، قد تُستخدم أو تفصح على النحو التالي:
                  <ul>
                    <li>
                      تطوير الخدمات التي تقدمها البوابة وخدمات الوزارة والجهات
                      التابعة لها والشركة المشغلة للبرنامج.
                    </li>
                    <li>
                      في حال التعاقد مع طرف ثالث لتطوير الخدمات التي نقدمها بما
                      يتوافق مع سياسة الخصوصية.
                    </li>
                    <li>
                      بناءً على أمر قضائي أو بطلب من الجهات الحكومية الأخرى.
                    </li>
                  </ul>
                </p>

                <h2>جمع المعلومات </h2>
                <p>
                  تقوم الوزارة بجمع معلومات المستخدم من خلال الصفحات التي يتمّ
                  تصفّحها، وطبيعة المعلومات التي تُجمع مشابهة لتقرير الحركة كعدد
                  مرّات الدخول للبوابة، وعدد زيارات الصفحات، والمدّة الزمنية
                  التي يقضيها المستخدم باستخدام البوابة . والغرض من جمع هذه
                  المعلومات هو لتقديم خدمة أفضل من خلال معرفة الخدمات التي
                  تتطلّع إليها المنشأة ومستخدمو الموقع، وكذلك للحفاظ على أن يبقى
                  الموقع مفيدًا و ملبيًّا لخدمات مستخدميه.
                </p>

                <h2>حدود مسؤوليتنا</h2>
                <p>
                  <ul className="list-number">
                    <li>
                      إن الوزارة وللحد الأقصى المسموح به قانونيًا لا تعطي أو
                      تقدم أي شروط أو ضمانات أو تعهدات أو أحكام أخرى قد تنطبق
                      على البوابة أو أي من محتوياتها،سواء كانت صريحة أم ضمنية.
                    </li>
                    <li>
                      الوزارة لا تتحمل مسؤولية مواجهة أي مستخدم بشأن أية خسارة
                      أو ضرر، سواء من الناحية التعاقدية أو المسؤولية التقصيرية
                      بما في ذلك الإهمال (سواء مباشرة أو غير مباشرة)، أو الإخلال
                      بالواجب القانوني أو خلاف ذلك، حتى لو كان متوقعًا وناشئًا
                      بموجب أو فيما يتعلق بما يلي دون قيد أو حصر:
                      <ul>
                        <li>
                          أي عمليات احتيال أو خداع أو تشويه للحقائق نتيجة أي فعل
                          أو تقصير أو إهمال من قبل المستخدم؛
                        </li>
                        <li>
                          استخدام البوابة أو عدم القدرة على استخدامها وفهم
                          محتواها؛
                        </li>
                        <li>
                          أي خسائر تنجم عن تأخير الاتصال بالإنترنت أو تعليق
                          المنصة أو الأخطاء الناجمة عن فشل أي من وسائل الاتصالات
                          السلكية واللاسلكية أو أي نظام لنقل البيانات بما في ذلك
                          عطل الخوادم مع مسؤولية الوزارة عن بذل العناية اللازمة
                          وتقديم الدعم الفني وإصلاح الخلل؛ و/أو
                        </li>
                        <li>
                          أي خسائر ناتجة عن أعمال أي سلطة حكومية أو غيرها، أو أي
                          فعل قاهر خارج عن إرادة الوزارة.
                        </li>
                      </ul>
                    </li>
                    <li>
                      الوزارة لن تتحمل أي خسارة أو ضرر ناجم عن الفيروسات أو
                      هجمات الحرمان من الخدمة أو المواد الأخرى الضارة التي قد
                      تؤثر على جهازك الحاسوب أو برامجه تقنيًا أو البيانات
                      والمواد الأخرى التي تحصل عليها أثناء استخدامك للبوابة أو
                      نشرك لأي محتوى من خلاله، أو على المواقع الإلكترونية
                      المرتبطة به.
                    </li>
                    <li>
                      رُغم أن الوزارة ستبذل العناية اللازمة لحماية البوابة
                      ومعلومات المستخدم وبياناته إلا أن دخول المستخدم للبوابة هو
                      على مسؤوليته الشخصية وبهذا يقر المستخدم بعلمه بجميع
                      المخاطر بأنظمة الدخول للبوابة بما في ذلك مخاطر اختراق
                      العمليات السرية لمستخدمي البوابة أو حدوث تدخل طرف ثالث أو
                      مخاطر أمنية أخرى.
                    </li>
                    <li>
                      قد تحتوي البوابة على روابط مُعلِنين لمواقع أخرى وبالتالي
                      فإن الوزارة لا تتحمل أي مسؤولية عن المواد أو الخدمات أو
                      عروض السلع التي تظهر على مثل هذه المواقع، كما لا تضمن خلو
                      أي من المواقع من الفيروسات أو البرامج الضارة أو غيرها ذات
                      الطبيعة المدمرة، ويقر ويوافق المستخدم على أن الوزارة غير
                      مسؤولة عن محتوى أو جودة أي من هذه المواقع.
                    </li>
                    <li>
                      يتعهد المستخدم بتعويض الوزارة عن أي إخلال يصدر منه بشأن
                      هذه الشروط والأحكام. مما يعني مسؤوليتك التامة في تعويض
                      الأضرار الناتجة عن إخلالك بهذه الشروط.
                    </li>
                  </ul>
                </p>

                <h2>
                  المعلومات الخاصّة بالملكيّة الفكريّة وحقوق الطّبع والعلامة
                  التجارية
                </h2>
                <p>
                  <ul className="list-number">
                    <li>
                      الحقوق الفكرية للمنهجية و/أو الطرق و/أو الوسائل و/أو
                      المستندات و/أو البرمجيات و/أو المواقع و/أو النطاقات و/أو
                      الأسماء و/أو العلامات التجارية التي تستخدم على البوابة
                      تخُصّ منصة التقييم الذاتي ولا يحق لأيّ شخص باستخدام أيّ
                      ملكيّة فكريّة لها أو حقوق طبع أو علامة بأيّ طريقة دون طلب
                      إذن كتابي مُسبق. و لا يجوز نقل، أو نشر، أو نسخ، أو تعديل،
                      أو عرض، أو استخدام الموادّ المعروضة على البوابة بدون إذن
                      كتابي صريح مُسبق.
                    </li>
                    <li>
                      تمتلك المنصة كافة حقوق الملكية الفكرية، وجميع المواد
                      المنشورة فيه تخضع لحماية قوانين حقوق الطبع والنشر
                      والمعاهدات العالمية، وجميع هذه الحقوق محفوظة.
                    </li>
                    <li>
                      ليس للمستخدم حق استخدام أي جزء من محتوى البوابة لأغراض
                      تجارية دون الحصول على إذن مسبق للقيام بذلك.
                    </li>
                  </ul>
                </p>

                <h2> الفيروسات </h2>
                <p>
                  <ul className="list-number">
                    <li>
                      لا تضمن الوزارة أن تكون البوابة آمنة أو خاليةً من الأخطاء
                      أو الفيروسات.
                    </li>
                    <li>
                      يتحمل المستخدم مسؤولية ضبط المعلومات التكنلوجية الخاصة به،
                      وبرامج وأنظمة الحاسوب من أجل دخول البوابة، واستخدام برامج
                      الحماية من الفيروسات الخاصة به.
                    </li>
                    <li>
                      يُمنع إساءة استخدام البوابة عمدًا بإدخال جميع أنواع
                      الفيروسات بلا استثناء، كما يُمنع محاولة الدخول غير المصرح
                      به والخادم الذي تُخزن البوابة عليه أو أي خادم آخر أوحاسوب
                      أو قاعدة بيانات تتعلق بالبوابة. يُمنع مهاجمة البوابة
                      بهجمات الحرمان من المنصة أو نشر محتوى المنصة. ستقوم
                      الوزارة بالتبليغ عن أي محاولة لخرق هذه الشروط والأحكام
                      للسلطات القانونية المختصة والتعاون معها والكشف لها عن هوية
                      المستخدم. في حال حدوث مثل هذه الإنتهاكات، فسيتم سلب
                      المستخدم حقه في استخدام البوابة في الحال.
                    </li>
                  </ul>
                </p>

                <h2>التعليق والإنهاء</h2>
                <p>
                  <ul className="list-number">
                    <li>
                      سيُحدد وفقا لتقدير الوزارة المطلق، ما إذا كان هناك إخلال
                      لهذه الشروط والأحكام أثناء استخدام المستخدم للبوابة. عندما
                      يتم إخلال هذه الشروط والأحكام فسيتم إتخاذ الإجراء الذي
                      تراه الوزارة مناسبًا دون قيد أو حصر:
                      <ul>
                        <li>
                          الإيقاف الفوري أو المؤقت أو الدائم لحق المستخدم في
                          استخدام البوابة؛
                        </li>
                        <li>
                          الحذف الفوري أو المؤقت أو الدائم لأي من المشاركات أو
                          المواد التي أضافها المستخدم للبوابة؛
                        </li>
                        <li>إصدار تحذير للمستخدم؛</li>
                        <li>
                          تنفيذ الإجراءات القانونية بحق المستخدم لتحمل جميع
                          تكاليف التعويض (بما في ذلك، على سبيل المثال لا الحصر
                          التكاليف الإدارية والقانونية) الناجمة عن الإخلال؛
                        </li>
                        <li>إتخاذ إجراءات قانونية إضافية بحق المستخدم؛ و/أو</li>
                        <li>
                          كشف المعلومات للسلطات القضائية المسؤولة التي نؤمن
                          بضرورتها.
                        </li>
                      </ul>
                    </li>
                    <li>
                      نتخلي الوزارة مسؤوليتها من الإجراءات المتبعة نتيجة الإخلال
                      بهذه الشروط والأحكام. ولا تقتصر تلك الإجرائات على ما جاء
                      ذكره أعلاه، بل يحق لها اتخاذ أي إجراء آخر تراه مناسبًا في
                      حدود المقبول.
                    </li>
                  </ul>
                </p>

                <h2>ساسة الاسترداد المالى</h2>
                <p>جميع المبالغ المدفوعة للخدمة غير خاضعة للاسترداد</p>

                <h2>القانون المطبق</h2>
                <p>
                  تخضع هذه الشروط والأحكام للقوانين والأنظمة المرعية في المملكة
                  العربية السعودية والإختصاص الحصري لمحاكم المملكة العربية
                  السعودية.
                </p>

                <h2>للاتصال بنا </h2>
                <p>
                  يمكنكم التواصل معنا بالنقر على أيقونة (مساعدة)، او الاتصال
                  مباشرة على الرقم (920033411)
                </p>

                <br />
                <br />

                <p>
                  <strong>شكراً لزيارتكم موقع منصة التقييم الذاتي </strong>
                </p>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </div>
  </Content>
);

export default TermsPage;
