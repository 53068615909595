// caliberSection,
// caliberSectionDescription,

import React from "react";
import PropTypes from "prop-types";

import CaliberItemContent from "./CaliberItemContent";

import "./caliberSectionContent.css";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";

const CaliberSectionContent = (props) => {
  const { caliberCategoryTitle, caliberCategoryDescription, calibers } = props;
  return (
    <div className="caliber-section-container">
      <div className="caliber-section-category-wrapper">
        <Text
          Tag="div"
          textType="h7"
          isBold
          className="caliber-section-category-title"
        >
          نوع المعيار: {caliberCategoryTitle}{" "}
        </Text>

        <div className="caliber-section-category-description">
          <Text
            Tag="div"
            textType="p4"
            isBold
            className="caliber-section-category-description-title"
          >
            شرح المعيار:
          </Text>
          <Text Tag="div" textType="p4">
            {caliberCategoryDescription}
          </Text>
        </div>
      </div>
      {calibers.map((caliber) => {
        return (
          <CaliberItemContent
            key={caliber.id}
            id={`${caliber.id}`}
            caliberItemTitle={caliber.name}
            caliberItemDescription={caliber.description}
            caliberItemSecondaryDescription={caliber.recommendations}
          />
        );
      })}
    </div>
  );
};
CaliberSectionContent.propTypes = {
  caliberCategoryTitle: PropTypes.string,
  caliberCategoryDescription: PropTypes.string,
  calibers: PropTypes.array,
};
export default CaliberSectionContent;
