import React from "react";
import { PdfIcon } from "./PdfIcon";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";
import "./attachmentItem.scss"

const itemStyle = {
  display: "flex",
  gap: 16,
  // minWidth: 580,
  width: "100%",
  height: "106px",
  flex: 1,
  backgroundColor: "#FFF",
  border: "1px solid #DFDFDFB3",
  padding: 24,
  borderRadius: 8,
};
export const AttachmentItem = ({ item }) => {
  return (
    <div className="attachmentItem" style={{ display: "flex", flexDirection: "column", gap: 8}}>
      <Text
        Tag="div"
        textType="roman"
        fontSize="font-18"
        mobileFontSize="font-16-mobile"
        style={{ fontSize: 18, color: "#505050", fontFamily: "Effra" , marginRight: "2px" }}
      >
        {item?.attachment_name}
      </Text>
      <a
        href={item?.url_attachment?.length ? item?.url_attachment : null}
        target="_top"
      >
        <div style={itemStyle}>
          <div
            style={{
              width: 40,
              height: 40,
            }}
          >
            <PdfIcon />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text
              Tag="span"
              textType="roman"
              fontSize="font-18"
              mobileFontSize="font-16-mobile"
              style={{ color: "#505050", fontFamily: "Effra", fontSize: 14 }}
            >
              {item?.file_name}
            </Text>
            <span
              style={{ color: "#92929D", fontFamily: "Effra", fontSize: 12 }}
            >
              {"..."} {"ميقا/بايت"}
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};
