import React from "react";
import PropTypes from "prop-types";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import "./textArea.scss";
const TextArea = ({
  name,
  label,
  onChange,
  placeholder,
  value,
  errorText,
  disabled,
  onFocus,
  onBlur,
  isResizable,
  customWrapper,
}) => {
  let wrapperClass = "form-group";

  return (
    <div className={`${wrapperClass} text-area-wrapper ${customWrapper}`}>
      <Text Tag="div" textType="p5" className="text-area__title" isBold>
        {label}
      </Text>

      <textarea
        type="textArea "
        name={name}
        className={`form-control text-area ${errorText && "text-area__error"} ${
          !isResizable ? "text-area__not-resizable" : ""
        }`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled ? "disabled" : ""}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {errorText && (
        <Text className="text-area__error-hint" Tag="span" textType="p6">
          {errorText}
        </Text>
      )}
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isResizable: PropTypes.bool,
  customWrapper: PropTypes.string,
};

TextArea.defaultProps = {
  onFocus: () => {},
  onBlur: () => {},
  isResizable: true,
};
export default TextArea;
