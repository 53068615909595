import React from "react";

export const DotsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5.166"
    height="24"
    viewBox="0 0 5.166 24"
  >
    <g fill="#92929d">
      <path
        id="Shape"
        d="M0,21.334a2.625,2.625,0,0,1,2.583-2.666,2.668,2.668,0,0,1,0,5.333A2.626,2.626,0,0,1,0,21.334ZM0,12A2.626,2.626,0,0,1,2.583,9.334a2.668,2.668,0,0,1,0,5.333A2.626,2.626,0,0,1,0,12ZM0,2.666A2.626,2.626,0,0,1,2.583,0,2.625,2.625,0,0,1,5.166,2.666,2.624,2.624,0,0,1,2.583,5.333,2.625,2.625,0,0,1,0,2.666Z"
      />
    </g>
  </svg>
);

export default DotsIcon;
