import * as types from "./actionTypes";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "./ajaxStatusActions";
import request from "../common/request";
import { getUserInfoSuccess } from "../../src/actions/authActions";
import {
  loadEstablishmentProfile,
  loadEstablishments,
} from "./establishmentProfileActions";
import { loadEstablishmentAciveAssessment } from "./establishmentAssessmentActions";

export function logInSuccess(token) {
  return { type: types.LOG_IN_SUCCESS, token };
}

export function logOutSuccess(token) {
  return { type: types.LOG_OUT_SUCCESS, token };
}

export function loadUserInfoSuccess(user) {
  return { type: types.LOAD_USERINFO_SUCCESS, user };
}

export function logIn(user, history) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return request("/v1/establishments/login", "POST", user)
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }
        return res.json();
      })
      .then((token) => {
        dispatch(logInSuccess(token));
        dispatch(endAjaxCall());
        history.push("/establishmentProfile");
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function register(establishment, history) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return request("/v1/establishments/register", "POST", {
      establishment: establishment,
    })
      .then((res) => {
        if (res.status === 500) {
          throw res.statusText;
        }
        dispatch(
          logIn(
            {
              email: establishment.user.email,
              password: establishment.user.password,
            },
            history
          )
        );
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function resetPassword(user) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return request("/v1/establishments/reset", "POST", user)
      .then((res) => {
        if (res.status === 500) {
          throw res.statusText;
        }
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function confirm(user, token) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return request("/v1/establishments/" + token, "POST", user)
      .then((res) => {
        if (res.status === 500) {
          throw res.statusText;
        }
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function getUserInfo(isLoadEstablishments = true) {
  return function (dispatch) {
    dispatch(beginAjaxCall());

    return request("/users/profile")
      .then((res) => res.json())
      .then((res) => {
        dispatch(loadUserInfoSuccess(res.user ? res.user : {}));
        if (isLoadEstablishments) dispatch(loadEstablishments());
        dispatch(endAjaxCall());
        return res.user;
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function logOut(token) {
  const refreshToken = sessionStorage.getItem(
    "mlsd_establishment_refresh_token"
  );
  let options;
  options = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    // body: {
    //   client_id: window.env.CLIENT_ID,
    //   refresh_token: refreshToken,
    // },
  };

  return (dispatch) => {
    dispatch(beginAjaxCall());
    return fetch(
      `${process.env.REACT_APP_URL}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/logout?client_id=${process.env.REACT_APP_CLIENT_ID}&refresh_token=${refreshToken}`,
      options
    )
      .then((token) => {
        dispatch(logOutSuccess(token));
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function loggedIn() {
  return !!window.kc;
}

export function chooseEstablishment(establishment) {
  return function (dispatch) {
    return dispatch(loadEstablishmentProfile(establishment));
  };
}
export function loadDurations(establishmentId) {
  return function (dispatch) {
    return dispatch(loadEstablishmentAciveAssessment(establishmentId));
  };
}

export const confirmUserInfo = (establishmentId) => (dispatch) => {
  dispatch(beginAjaxCall());

  return request(`/users/information_approve/${establishmentId}`, "PUT")
    .then((res) => res.json())
    .then((result) => {
      dispatch({ type: types.CONFIRM_USER_INFO_SUCCESS });
      dispatch(endAjaxCall());
      return result;
    })
    .catch((e) => {
      dispatch(ajaxCallError(e));
      dispatch(endAjaxCall());
    });
  // .finally(() => {
  //   dispatch(endAjaxCall());
  // })
};
