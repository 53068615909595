import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import FooterLogo from "../../../public/img/IMS-MHRSD-logo.svg";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import { AccessiblityContainer } from "../AccessibilityContainer";

class WhiteFooter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
         <footer className="establishment-footer">
            <Text textType="h9" className="establishment-footer-copyright">
              جميع الحقوق محفوظة لبرنامج التقييم الذاتي © 2024
            </Text>
            <div className="flex items-center justify-center gap-x-2 accessiblity">
              <AccessiblityContainer />
            </div>
            <div className="flex items-center justify-center gap-x-2 footer-links">
              <Link className="stroke" to="/privacyPolicy" id="main-page-link">
                <Text
                  Tag="div"
                  textType="h9"
                  isBold
                  className="text-link-wrapper-inside-system"
                >
                  سياسة الخصوصية
                </Text>
              </Link>
              <Link className="stroke" to="/faq" id="main-page-link">
                <Text
                  Tag="div"
                  textType="h9"
                  isBold
                  className="text-link-wrapper-inside-system"
                >
                  الأسئلة الشائعة
                </Text>
              </Link>
            </div>

            <div className=" flex items-center justify-center gap-x-4 establishment-footer-row ">
              <a href="http://raqmi.dga.gov.sa/platforms/DigitalStamp/ShowCertificate/6500">
                <img
                  className="establishment-footer-digital-stamp"
                  src="http://raqmi.dga.gov.sa/platforms/DigitalStamp/GetStampFile/3672"
                  alt="Digital Stamp"
                />
              </a>
              <img
                className="establishment-footer-logo-desktop"
                src={FooterLogo}
                alt="IMS MHRSD logo"
              />
            </div>
          </footer> 
    );
  }
}

export default withRouter(WhiteFooter);
