import React from "react";
import PropTypes from "prop-types";
import { englishToArabic } from "../../../utilities/helpers";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

class StepsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
    };
  }
  onEnter = (e) => {
    this.setState({ isHover: true });
  };
  onLeave = (e) => {
    this.setState({ isHover: false });
  };
  render() {
    const { isHover } = this.state;
    const { stepTitle, number, icon, description } = this.props;
    return (
      <div
        key={number}
        className={
          isHover ? " steps-component steps-component-hover" : "steps-component"
        }
        onMouseEnter={this.onEnter}
        onMouseLeave={this.onLeave}
      >
        <div className="hover-steps-component-wrapper">
          {!isHover && (
            <div className="steps-circle">
              <p className="step-number">{englishToArabic(number)}</p>
            </div>
          )}
          <div
            className={isHover ? " steps-data steps-data-hover" : "steps-data"}
          >
            {!isHover && (
              <span className="card-step-image">
                <img src={icon} style={{ height: "70%", height: "50%" }} />
              </span>
            )}
            <Text
              Tag="span"
              textType="p3"
              isBold={isHover}
              className={isHover ? "card-step-title-hover" : "card-step-title"}
            >
              {stepTitle}
            </Text>
            {isHover && (
              <Text Tag="span" textType="p4" className="steps-description">
                {description}
              </Text>
            )}
          </div>
        </div>
      </div>
    );
  }
}
StepsCard.propTypes = {
  stepTitle: PropTypes.string,
  number: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
};

export default StepsCard;
