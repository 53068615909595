import React from "react";
import { MobileStepper } from "../../../lib/components/mobile-stepper";
import {
  useActiveInnerStep,
  useActiveStep,
  useSetActiveStep,
  useSteps,
} from "../../self-assessment-form-context/index";

export const StepperTop = () => {
  const steps = useSteps();
  const activeStep = useActiveStep();
  const activeInnerStep = useActiveInnerStep();
  const setActiveStep = useSetActiveStep();

  return (
    <div
      style={{
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        overflowY: "auto",
        minHeight: "100%",
      }}
      className="flex  flex-grow min-w-fit "
    >
      <MobileStepper
        innerActiveStep={activeInnerStep}
        activeStep={activeStep}
        steps={steps ?? []}
        setActiveStep={setActiveStep}
      />
    </div>
  );
};
