import React, { useState } from "react";
import { DislikeIcon, LikeIcon } from "../../../lib/assets/icons/icons/index";
import { twMerge } from "../../../lib/tw-merge";
import { usePeriod, useSetCalibers } from "../../self-assessment-form-context";
import request from "../../../../../../common/request";
import { useSelector } from "react-redux";
import {
  RE_SUBMIT_STATUS,
  RE_SUBMIT_STATUS_API_VALUE,
} from "../../../../../../common/constants/assessmentStatuses";
import Loader from "../../../../../../components/Loader/Loader";
import "./agree-row.css";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
export const AgreeRow = ({
  answer: defaultAnswer,
  step,
  data,
  isError,
  caliberIsQualitative,
}) => {
  const [answer, setAnswer] = useState(defaultAnswer);
  const [isLoading, setIsLoading] = useState(false); // Initialize loading state
  const period = usePeriod();
  const status = period.assessment?.status?.split(" ")?.join("");
  const isReturned =
    period?.audit_status === RE_SUBMIT_STATUS ||
    period?.audit_status === RE_SUBMIT_STATUS_API_VALUE;
  const newAnswer = isReturned ? period?.new_answer : period?.answer;

  const setCalibers = useSetCalibers();
  const establishmentProfileId = useSelector(
    (state) => state?.establishment.establishmentProfile.id
  );
  const handleSendAnswer = async (answer) => {
    // /v1/establishments/c842e550-5c04-425a-98dd-44f413002a1d/assessments
    setIsLoading(true); // Set isLoading to true before making the request

    const periodId = period.id;
    const assessmentVersion = period.assessment_version;
    const periodsAssessmentId = period.period_assessment_id;
    const assessmentId = period.assessment.id;
    const payload = {
      period_id: periodId,
      assessment_id: assessmentId,
      assessment_caliber: step?.caliber_id,
      period_assessment_id: periodsAssessmentId,
      answer,
      assessment_version: assessmentVersion,
      note: "",
      violation_number: null,
      status,
    };

    const baseUrl = `/v1/establishments/${establishmentProfileId}/`;
    const url = isReturned
      ? baseUrl + "returned_assessment"
      : baseUrl + "assessments";
    try {
      const res = await request(url, "PATCH", { submission: payload });
      if (res?.status === 200) {
        setAnswer(answer);
        if (isReturned) {
          setCalibers({
            [step?.caliber_id]: { ...data, new_answer: answer },
          });
        } else {
          setCalibers({
            [step?.caliber_id]: { ...data, answer },
          });
        }
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false); // Set isLoading to false after the request is complete
    }
  };

  if (isLoading) {
    return (
      <div
        className={
          "flex-1 w-full min-h-[600px] flex items-center justify-center"
        }
      >
        <Loader />
      </div>
    );
  } else {
    return (
      <div>
        <div
          className={twMerge(
            "flex justify-between items-baseline mt-8 answer-flex-direction"
          )}
        >
          <Text
            Tag={"div"}
            textType="roman"
            fontSize={"font-18"}
            mobileFontSize={"font-16-mobile"}
            style={{ color: "#000" }}
            className={" answer-item-mobile"}
          >
            {"بعد الإطلاع على شرح البند الموجود أعلاه، هل توافق على هذا البند؟"}
          </Text>

          <div className={"flex gap-x-4 answer-item-mobile"}>
            <button
              // iconPosition="end"
              // title="نعم"
              className={twMerge(
                "min-w-[72px] h-10 rounded-[10px] bg-[#DFDFDF] flex items-center justify-center gap-x-2 font-effra text-[#92929D] focus:outline-none",
                answer === "yes" &&
                  "bg-[#FFF6E4] border-[1.5px] text-sm font-effra font-bold border-[#F5961E] text-[#F5961E] focus:outline-none"
              )}
              onClick={() => handleSendAnswer("yes")}
            >
              {"نعم"}
              <LikeIcon
                className={
                  answer === "yes" ? "fill-orange-400" : "fill-gray-400"
                }
              />
            </button>
            <button
              // iconPosition="end"
              // title="لا"
              className={twMerge(
                "min-w-[72px] h-10 rounded-[10px] bg-[#DFDFDF] flex items-center justify-center gap-x-2 font-effra text-[#92929D] focus:outline-none",
                answer === "no" &&
                  "bg-[#FFF6E4] border-[1.5px] text-sm font-effra font-bold border-[#F5961E] text-[#F5961E] focus:outline-none"
              )}
              onClick={() => handleSendAnswer("no")}
            >
              {"لا"}
              <DislikeIcon
                className={
                  answer === "no" ? "fill-orange-400" : "fill-gray-400"
                }
              />
            </button>
            {caliberIsQualitative && (
              <button
                // iconPosition="end"
                // title="لا ينطبق"
                className={twMerge(
                  "min-w-[72px] h-10 rounded-[10px] bg-[#DFDFDF] flex items-center justify-center gap-x-2 font-effra text-[#92929D] focus:outline-none",
                  answer === "not_applicable" &&
                    "bg-[#FFF6E4] border-[1.5px] text-sm font-effra font-bold border-[#F5961E] text-[#F5961E] focus:outline-none"
                )}
                onClick={() => handleSendAnswer("not_applicable")}
              >
                {"لا ينطبق"}
              </button>
            )}
          </div>
        </div>
        {isError ? (
          <div>
            <Text
              Tag={"div"}
              textType="roman"
              fontSize={"font-16"}
              style={{ color: "red" }}
              className={"leading-8 answer-error-mobile"}
            >
              * لم يتم الإجابة على هذا السؤال، يرجى اختيار الجواب المناسب
            </Text>
          </div>
        ) : null}
      </div>
    );
  }
};
