import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";
import "./notificationsList.scss";
import NotificationCard from "../../Components/NotificationCard/NotificationCard";
import * as NotificationActions from "../../../actions/notificationActions";
import Loader from "../../../components/Loader/Loader";
import Pagination from "../BillsList/Pagination";

const NotificationsList = (props) => {
  const {
    notificationsList,
    onFetchNotifications,
    establishmentProfile,
    notificationsListFetching,
    notificationsTotal,
  } = props;

  const limit = 10;

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    handlePageChanged(currentPage);
  }, []);

  const handlePageChanged = (selectedPage) => {
    const start = (selectedPage - 1) * limit;
    setCurrentPage(selectedPage);
    onFetchNotifications(establishmentProfile.id, start, limit);
  };
  return (
    <PageWrapper>
      <div className="notifications-list-header">
        <Text
          Tag="div"
          isBold
          textType="h5"
          className="notifications-list-header__text"
        >
          التنبيهات
        </Text>
      </div>
      <div>
        {notificationsListFetching && (
          <div className="loader-wrapper">
            <Loader />
          </div>
        )}
        {!notificationsListFetching &&
          (notificationsList.length > 0 ? (
            notificationsList.map((notification, id) => (
              <div key={id} className="notification-card-list">
                <NotificationCard
                  description={notification.description}
                  establishmentInfo={notification.establishment_information}
                  time={new Date(notification.created_at)}
                  isRead={notification.is_read}
                />
              </div>
            ))
          ) : (
            <Text Tag="div" textType="p4" className="notifications-list-header">
              لا يوجد تنبيهات حاليا
            </Text>
          ))}
      </div>
      {!notificationsListFetching && notificationsList.length > 0 && (
        <Pagination
          itemsCount={notificationsTotal}
          pagesLimit={limit}
          currentPage={currentPage}
          onPageChange={handlePageChanged}
        />
      )}
    </PageWrapper>
  );
};

NotificationsList.propTypes = {
  notificationsList: PropTypes.array.isRequired,
  notificationsListFetching: PropTypes.bool.isRequired,
  notificationsTotal: PropTypes.number.isRequired,
  establishmentProfile: PropTypes.object.isRequired,
  onFetchNotifications: PropTypes.func.isRequired,
};
NotificationsList.defaultProps = {};
const mapStateToProps = (state) => ({
  notificationsList: state.notifications.notificationsList,
  notificationsListFetching: state.notifications.notificationsListFetching,
  notificationsTotal: state.notifications.notificationsTotal,
  establishmentProfile: state.establishment.establishmentProfile,
});

const mapDispatchToProps = {
  onFetchNotifications: NotificationActions.fetchNotifications,
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
