import React from "react";
import PropTypes from "prop-types";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

const { string } = PropTypes;

const FeatureItem = ({ iconTitle }) => (
  <div className="feature-item flex-1">
    <img src={"img/checkIcon.svg"} className="icon" />
    <Text
      Tag="div"
      textType="roman"
      fontSize={"font-26"}
      mobileFontSize={"font-18-mobile"}
      className="icon-title "
    >
      {iconTitle}
    </Text>
  </div>
);

FeatureItem.propTypes = {
  iconTitle: string,
};

export default FeatureItem;
