import React from "react";
import PropTypes from "prop-types";

export const PrintStyle = ({ size, margin, styles }) => (
  <style type="text/css">
    {`
      @media print {
        @page {
          size: ${size};
          margin: ${margin};
        }

        html {
          height: 100% !important;
        }

        ${styles}
      }
    `}
  </style>
);

PrintStyle.propTypes = {
  size: PropTypes.string,
  margin: PropTypes.string,
  styles: PropTypes.string,
};

PrintStyle.defaultProps = {
  size: "portrait",
  margin: "0mm",
  styles: " ",
};

export default PrintStyle;
