import {
  BILL_INFO_START_LOADING,
  BILL_INFO_STOP_LOADING,
  BILL_INFO_SET_ERROR,
} from "../actions/actionTypes";
import request from "../common/request";

export const startLoading = () => ({
  type: BILL_INFO_START_LOADING,
});

export const stopLoading = () => ({
  type: BILL_INFO_STOP_LOADING,
});

export const setError = () => ({
  type: BILL_INFO_SET_ERROR,
});

export const fetchBillInfo = (billId) => (dispatch) => {
  dispatch(startLoading());

  return request(`/payments/invoices/${billId}`, "GET", null, {}, false)
    .then((res) => {
      if (res.status !== 200) throw res.json();
      else return res.json();
    })
    .then((data) => {
      dispatch(stopLoading());
      return data;
    })
    .catch((error) => {
      dispatch(stopLoading());
      dispatch(setError());
    });
};
export const getZatcaBillPdf = (establishmentId, billNumber) => (dispatch) => {
  dispatch(startLoading());

  return request(
    `/payments/zatca_invoice/${establishmentId}/receipt/${billNumber}`
  )
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      dispatch(stopLoading());
      return data;
    })
    .catch((error) => {
      dispatch(stopLoading());
      dispatch(setError());
    });
};
