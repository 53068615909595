import React from "react";
import "./mobileTableCards.css";
import PropTypes from "prop-types";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";

const MobileTableCards = (props) => {
  const {
    headerTitle,
    headerValue,
    headerStatus,
    statusColor,
    buttonComponent,
    bodyData,
  } = props;

  return (
    <div className="m-bill-card">
      <div className="m-bill-card-header">
        <Text Tag="span" fontSize={"font-16"} textType="roman">
          {headerTitle}
        </Text>
        <Text
          Tag="span"
          textType="bold"
          fontSize="font-16"
          className={`m-bill-payment-bill-number`}
        >
          {headerValue}
        </Text>
        <Text
          Tag="span"
          textType="roman"
          fontSize="font-15"
          className={`m-bill-payment-status m-bill-payment-status-${statusColor}`}
        >
          {headerStatus}
        </Text>
      </div>
      <div className="m-bill-card-body">
        {bodyData.map((row, index) => (
          <div className="m-bill-card-body-row" key={index}>
            {Array.isArray(row.title) ? (
              <Text
                Tag="div"
                fontSize={"font-16"}
                textType="roman"
                className={"m-bill-card-body-title"}
              >
                {row.title[0]}
                <Text
                  Tag="div"
                  fontSize={"font-11"}
                  textType="roman"
                  className={"m-bill-card-body-title"}
                >
                  {row.title[1]}
                </Text>
              </Text>
            ) : (
              <Text
                Tag="div"
                fontSize={"font-16"}
                textType="roman"
                className={"m-bill-card-body-title"}
              >
                {row.title}
              </Text>
            )}

            <Text
              Tag="div"
              fontSize={"font-16"}
              textType="roman"
              className={"m-bill-card-body-data"}
            >
              {row.value}
            </Text>
          </div>
        ))}
        <div className="m-bill-card-body-button">{buttonComponent}</div>
      </div>
    </div>
  );
};
MobileTableCards.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  headerValue: PropTypes.string.isRequired,
  headerStatus: PropTypes.string.isRequired,
  statusColor: PropTypes.string.isRequired,
  bodyData: PropTypes.array.isRequired,
};

export default MobileTableCards;
