import React from "react";
import { Container, Row, Col } from "reactstrap";

class Page404 extends React.Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                <h4 className="pt-3">الصفحة غير موجودة</h4>
                <p className="text-muted float-right">
                  الرجاء المحاولة مرة اخرى{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page404;
