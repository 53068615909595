import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function auth(state = initialState.auth, action) {
  switch (action.type) {
    case types.LOG_IN_SUCCESS: {
      return Object.assign({}, state, {
        token: action.token
      });

    }

    case types.LOG_OUT_SUCCESS: {
      return Object.assign({}, state, {
        token: action.token
      });
    }
    case types.LOAD_USERINFO_SUCCESS: {
      return Object.assign({}, state, {
        userInfo: action.user
      });
    }
    case types.UPDATE_USER_INFO: {
      return { ...state, userInfo: { ...state.userInfo, ...action.payload } };
    }
    default:
      return state;
  }
}
