import React from "react";
import { Stack } from "@mui/material";
import moment from "moment";
import PopUp from "../../../views/Components/PopUp/PopUp";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";

const RowWithLabelAndTitle = ({ label, value }) => {
  return (
    <Stack
      direction={"row"}
      sx={{ columnGap: "8px", display: "flex", alignItems: "baseline" }}
    >
      <Text
        Tag={"div"}
        fontSize="font-16"
        textType="bold"
        style={{ color: "#148287" }}
      >
        {label}
      </Text>
      <Text
        Tag={"div"}
        fontSize="font-16"
        textType="roman"
        style={{
          color: "#505050",
        }}
      >
        {value}
      </Text>
    </Stack>
  );
};

export const SdadModal = ({ isOpen, handleClose, bill }) => {
  const { name, sadad_number, due_date } = bill ?? {};
  return (
    <PopUp
      isOpen={isOpen}
      title={"بيانات السداد"}
      body={
        <Stack
          sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <RowWithLabelAndTitle label={"اسم الجهة :"} value={name} />
          <RowWithLabelAndTitle label={"رقم المفوتر :"} value={"903"} />
          <RowWithLabelAndTitle label={"رقم السداد :"} value={sadad_number} />
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              flexDirection: "column",
              rowGap: "6px",
            }}
          >
            <Text
              Tag={"span"}
              fontSize="font-16"
              textType="roman"
              style={{
                color: "#505050",
              }}
            >
              {" يرجى المسارعة بسداد الفاتورة علمًا أن آخر موعد للسداد هو "}{" "}
              {due_date && moment(due_date).format("YYYY/MM/DD")}، {"الساعة "}{" "}
              {due_date && moment(due_date).locale("ar-sa").format("hh:mm A")}
            </Text>
          </div>
        </Stack>
      }
      onClickCancel={handleClose}
      cancelButtonText="حسناً"
    />
  );
};
