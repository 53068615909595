import React from "react";
export const NoIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={17} {...props}>
    <path
      fill={props.fill || "#92929D"}
      fillRule="evenodd"
      d="M15.18 9.423h-3.29c-.466 0-.836.4-.81.874l.177 3.111a.622.622 0 0 1-.3.57.598.598 0 0 1-.306.085.61.61 0 0 1-.583-.442L8.936 9.765A2.553 2.553 0 0 0 6.68 7.93V3.36l.561-.57c.314-.32.74-.5 1.184-.5h4.302c.805 0 1.515.535 1.75 1.318l1.442 4.797a.785.785 0 0 1-.74 1.018zm2.002-1.41L15.74 3.214C15.333 1.86 14.122.948 12.728.948H8.426c-.647 0-1.26.21-1.771.594a.775.775 0 0 0-.747-.594H2.224a.78.78 0 0 0-.773.787V9.42a.78.78 0 0 0 .773.787h3.684a.78.78 0 0 0 .773-.787v-.149c.461.075.854.415.99.878l1.133 3.856c.245.838.988 1.4 1.847 1.4.34 0 .675-.092.97-.267a1.96 1.96 0 0 0 .953-1.807l-.145-2.565h2.75c.662 0 1.291-.323 1.684-.863a2.168 2.168 0 0 0 .319-1.89z"
    />
  </svg>
);
