import React, { createContext } from "react";

function createSteperContext() {
  // Default stepper context props
  return createContext({
    activeStep: 0,
    currentStep: {},
    steps: [],
    orientation: "horizontal",
    setOrientation: () => {},
  });
}

/** Stepper Conetext */
export const StepperContext = createSteperContext();

if (process.env.NODE_ENV !== "production") {
  StepperContext.displayName = "StepperContext";
}
