import {
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_FAILURE,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_SUCCESS,
  CHANGE_RESULT_FILTER,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_SUCCESS,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_FAILURE,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_SUCCESS,
  FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_FAILURE,
} from "./actionTypes";
import { beginAjaxCall, ajaxCallError, endAjaxCall } from "./ajaxStatusActions";
import request from "../common/request";

export const changeSelectedResultFilter = (selectedValue) => (dispatch) => {
  dispatch({
    type: CHANGE_RESULT_FILTER,
    payload: selectedValue,
  });
};

/**
 * Fetch self assessment result failure action
 * @param {Object} error
 * @returns {{ type: String, payload: { error } }}
 */
export const fetchEstablishmentSelfAssessmentResultFailure = (error) => ({
  type: FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_FAILURE,
  payload: { error },
});

/**
 * Fetch self assessment result success action
 * @param {Object} establishmentSelfAssessmentResult
 * @returns {{ type: String, payload: { caliber } }}
 */
export const fetchEstablishmentSelfAssessmentResultSuccess = (
  establishmentSelfAssessmentResult
) => ({
  type: FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_SUCCESS,
  payload: { establishmentSelfAssessmentResult },
});

/**
 * Fetch self assessment result action
 * @param {String} periodId period id
 * @param {String} assessmentId assessment id
 * @param {int} assessment_version
 */
export const fetchEstablishmentSelfAssessmentResult =
  (periodId, assessmentId, establishmentId, assessment_version) =>
  (dispatch) => {
    dispatch({
      type: FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT,
      payload: { periodId, assessmentId, establishmentId, assessment_version },
    });

    dispatch(beginAjaxCall());
    return request(
      `/v1/establishments/${establishmentId}/result/${periodId}/${assessmentId}/${assessment_version}`
    )
      .then((res) => res.json())
      .then((establishmentResult) => ({ reports: establishmentResult.reports }))
      .then((establishmentResult) => {
        dispatch(
          fetchEstablishmentSelfAssessmentResultSuccess(
            establishmentResult.reports
          )
        );
        dispatch(endAjaxCall());
        return establishmentResult.reports;
      })
      .catch((error) => {
        dispatch(fetchEstablishmentSelfAssessmentResultFailure(error));
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
      });
  };
/**
 * Fetch self assessment result failure action
 * @param {Object} error
 * @returns {{ type: String, payload: { error } }}
 */
export const fetchEstablishmentSelfAssessmentFastTrackResultFailure = (
  error
) => ({
  type: FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_FAILURE,
  payload: { error },
});

/**
 * Fetch self assessment result success action
 * @param {Object} establishmentSelfAssessmentResult
 * @returns {{ type: String, payload: { caliber } }}
 */
export const fetchEstablishmentSelfAssessmentFastTrackResultSuccess = (
  establishmentSelfAssessmentResult
) => ({
  type: FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_SUCCESS,
  payload: { establishmentSelfAssessmentResult },
});

/**
 * Fetch self assessment result action
 * @param {String} periodId period id
 * @param {String} assessmentId assessment id
 * @param {int} assessment_version
 */

export const fetchEstablishmentSelfAssessmentDurationSuccess = (duration) => ({
  type: FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_SUCCESS,
  payload: duration,
});

export const fetchEstablishmentSelfAssessmentDurationFailure = () => ({
  type: FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_FAILURE,
});

export const fetchEstablishmentSelfAssessmentDuration =
  (periodId, assessmentId, establishmentId, assessmentVersion) =>
  (dispatch) => {
    dispatch({ type: FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION });

    return request(
      `/v1/establishments/${establishmentId}/duration-statuses/${periodId}/${assessmentId}/${assessmentVersion}`
    )
      .then((res) => res.json())

      .then((data) => {
        dispatch(
          fetchEstablishmentSelfAssessmentDurationSuccess(data.duration)
        );
        return data.duration;
      })
      .catch((error) => {
        dispatch(fetchEstablishmentSelfAssessmentDurationFailure());
        dispatch(ajaxCallError(error));
      });
  };

/**
 * Fetch Fast Track result action
 * @param {String} periodId period id
 * @param {String} assessmentId assessment id
 * @param {int} assessment version
 */
export const fetchEstablishmentFastTrackResult =
  (periodId, assessmentId, establishmentId, assessment_version) =>
  (dispatch) => {
    dispatch({
      type: FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT,
      payload: { periodId, assessmentId, establishmentId, assessment_version },
    });

    dispatch(beginAjaxCall());
    return request(
      `/v1/establishments/${establishmentId}/fast_track_result/${periodId}/${assessmentId}/${assessment_version}`
    )
      .then((res) => res.json())
      .then((establishmentResult) => ({
        reports: establishmentResult.reports,
        audit_report: establishmentResult.audit_report,
      }))
      .then((establishmentResult) => {
        dispatch(
          fetchEstablishmentSelfAssessmentFastTrackResultSuccess({
            reports: establishmentResult.reports,
            audit_report: establishmentResult.audit_report,
          })
        );
        dispatch(endAjaxCall());
        return establishmentResult;
      })
      .catch((error) => {
        dispatch(fetchEstablishmentSelfAssessmentFastTrackResultFailure(error));
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
      });
  };
