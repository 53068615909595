import React from "react";
import { Stepper } from "../../../lib/components/stepper/index";
import {
  useActiveInnerStep,
  useActiveStep,
  useSetActiveStep,
  useSteps,
} from "../../self-assessment-form-context/index";

export const StepperSide = () => {
  const steps = useSteps();
  const activeStep = useActiveStep();
  const activeInnerStep = useActiveInnerStep();
  const setActiveStep = useSetActiveStep();

  return (
    <div
      style={{
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        overflowY: "auto",
        minHeight: "100%",
      }}
      className="flex flex-col flex-grow min-w-fit bg-ims-turquoise_blue"
    >
      <Stepper
        innerActiveStep={activeInnerStep}
        activeStep={activeStep}
        steps={steps ?? []}
        setActiveStep={setActiveStep}
      />
    </div>
  );
};
