import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "../InteractiveGuidePage.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
const GuideVideo = ({ video, isPause }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (isPause && !videoRef.current.paused) {
      videoRef.current.pause();
    }
  }, [isPause]);
  useEffect(() => {
    const pauseVideo = () => {
      videoRef.current.pause();
    };
    window.addEventListener("blur", pauseVideo);
    return () => window.removeEventListener("blur", pauseVideo);
  }, []);

  return (
    <div className="guide-video-wrapper">
      <div className="guide-video">
        <video
          width="900"
          height="100%"
          controls
          ref={videoRef}
          className="interactive-guide-video"
        >
          <source src={video.videoURL} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>

      <Text Tag="div" textType="h7" className="video-title">
        {video.label}

        {/* <a href={video.videoURL} download>
          download
        </a> */}
      </Text>
    </div>
  );
};

GuideVideo.propTypes = {
  video: PropTypes.object,
  isPause: PropTypes.bool,
};

export default GuideVideo;
