import {
  FAST_TRACK_BRANCHES_ESTABLISHMENT_SUCCESS,
  FAST_TRACK_BRANCHES_SET_ERROR,
  FAST_TRACK_BRANCHES_START_LOADING,
  FAST_TRACK_BRANCHES_STOP_LOADING,
} from "../actions/actionTypes";
import request from "../common/request";
import { loadEstablishmentAciveAssessment } from "./establishmentAssessmentActions";

export const startLoading = () => ({
  type: FAST_TRACK_BRANCHES_START_LOADING,
});

export const stopLoading = () => ({
  type: FAST_TRACK_BRANCHES_STOP_LOADING,
});
export const setError = () => ({
  type: FAST_TRACK_BRANCHES_SET_ERROR,
});
export const fetchUnifiedEstablishmentsSuccess = (establishments, total) => ({
  type: FAST_TRACK_BRANCHES_ESTABLISHMENT_SUCCESS,
  establishments,
  total,
});
export const fetchFastTrackForBranchesEstablishments =
  (
    establishmentId,
    periodId,
    assessmentId,
    periodAssessmentId,
    assessmentVersion
  ) =>
  (dispatch) => {
    dispatch(startLoading());

    return request(
      `/v1/establishments/${establishmentId}/branches/${periodId}/${assessmentId}/${assessmentVersion}/${periodAssessmentId}`
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(
          fetchUnifiedEstablishmentsSuccess(data.data, data.total_count)
        );
        dispatch(stopLoading());
      })
      .catch((error) => {
        dispatch(setError());

        throw error;
      });
  };
export const payForBranchedSpecialTrackService =
  (establishmentId, paymentBill) => (dispatch) => {
    dispatch(startLoading());

    return request(
      `/v1/establishments/${establishmentId}/branches/initiate-payment`,
      "POST",
      { payment_bill: paymentBill }
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status == 400) {
            throw response.json();
          }
          throw response.statusText;
        }
        response.json();
      })
      .then((data) => {
        dispatch(stopLoading());
        dispatch(loadEstablishmentAciveAssessment(establishmentId));

        return data;
      })
      .catch((error) => {
        dispatch(setError());

        throw error;
      });
  };
