export const STATUS_IS_BLOCKED = "is-blocked";
export const STATUS_IS_ACTIVE = "Not-Active";
export const STATUSES = {
  [STATUS_IS_BLOCKED]: "موقوفة",
  [STATUS_IS_ACTIVE]: "نشطة",
};
export const getStatusColor = (isBlocked) => {
  if (isBlocked) {
    return "red";
  } else {
    return "green";
  }
};
