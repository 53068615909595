import React, { useEffect, useState } from "react";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import { string, element, oneOfType, array } from "prop-types";
import { useGoogleMap } from "@react-google-maps/api";

function MapControl(props) {
  const [map, setMap] = useState(useGoogleMap());

  const controlDiv = document.createElement("div");
  const divIndex = map.controls[this.props.position].length;
  map.controls[props.position].push(controlDiv);

  useEffect(
    () => {
      const { className } = this.props;
      className && controlDiv.classList.add(className);
      unstable_renderSubtreeIntoContainer(
        this,
        React.Children.only(this.props.children),
        controlDiv
      );
    },
    map.controls[this.props.position].removeAt(divIndex),
    []
  );

  return false;
}
MapControl.propTypes = {
  position: string.isRequired,
  children: oneOfType([element, array]),
  className: string,
};

MapControl.defaultProps = {
  children: [],
  className: "",
};
