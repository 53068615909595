import React from "react";
import PropTypes from "prop-types";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import "./message.scss";
import { getMessageText } from "./MessageText";

const Message = (props) => {
  const {
    isStartFreeAssessment,
    isReturnedAssessment,
    isPayForAssessment,
    // isBlocked,
    showCloseButton,
    endDate,
    startDate,
    isBasicTrackNotComplete,
    isReassessment,
    returnedCaliberEndAt,
    isCompletedAndCertified,
    isCompletedAndNotCertified,
    isNewInspection,
  } = props;

  return (
    <div
      className={`message-box ${
        // isBlocked ||
        isReturnedAssessment ? "message-box__alert" : "message-box__inform"
      }`}
    >
      <div className="message-box-text">
        {
          // isBlocked ||
          isReturnedAssessment && (
            <img
              src={"img/alert_icon.svg"}
              alt="alert"
              className="alert-image"
            />
          )
        }
        <Text Tag="div" textType="h6" isBold>
          {getMessageText(
            isStartFreeAssessment,
            isReturnedAssessment,
            isPayForAssessment,
            // isBlocked,
            endDate,
            startDate,
            isBasicTrackNotComplete,
            returnedCaliberEndAt,
            isReassessment,
            isCompletedAndCertified,
            isCompletedAndNotCertified,
            isNewInspection
          )}
        </Text>
      </div>
      {showCloseButton && (
        <div className="message-close-button">
          <button onClick={props.onClose} aria-label="close">
            ×
          </button>
        </div>
      )}
    </div>
  );
};
Message.propTypes = {};
Message.defaultProps = {
  showCloseButton: false,
  isCompletedAndCertified: false,
  isCompletedAndNotCertified: false,
};
export default Message;
