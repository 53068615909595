import React from "react";
import { fetchPublicTrainingCertificateDetails } from "../../actions/trainingActions";
import Loader from "../Loader/Loader";
import "./PublicTrainingCertificateDetailsPage.scss";
import { Text } from "../../../../shared-components/my-scope/atoms/text"; // Import the CSS file
import { withRouter } from 'react-router-dom';
class TrainingCertificateDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trainingCertificateDetails: null,
      isLoading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.loadTrainingCertificateDetails();
  }

  loadTrainingCertificateDetails = async () => {
    const { training_id } = this.props.match.params;
    try {
      const response = await fetchPublicTrainingCertificateDetails(training_id);

      if (response.ok) {
        const val = await response.json();
        this.setState({ trainingCertificateDetails: val, isLoading: false });
      } else {
        this.setState({ error: "التدريب غير معتمد", isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching training certificate details:", error);
      this.setState({ error: "فشل في تحميل التفاصيل", isLoading: false });
    }
  };

  render() {
    const { trainingCertificateDetails, isLoading, error } = this.state;

    if (isLoading) {
      return (
        <div className="loader-wrapper">
          <Loader />
        </div>
      );
    }

    if (error) {
      return <div className="error-message">{error}</div>;
    }

    if (!trainingCertificateDetails) {
      return (
        <div className="error-message">لا توجد تفاصيل لشهادة التدريب.</div>
      );
    }

    const {
      course_name,
      hours,
      certificate_granted_at,
      establishment_name,
      labor_office_id,
      sequence_id,
    } = trainingCertificateDetails;
    const formattedDate =
      new Date(certificate_granted_at).toLocaleDateString() !== "1/1/1970"
        ? new Date(certificate_granted_at).toLocaleDateString()
        : "لم تصدر";

    return (
      <div className="training-certificate-container">
        <div className="training-details-certificate">
          <div className="training-details-certificate-header">
            <Text
              Tag={"div"}
              textType="bold"
              fontSize={"font-24"}
              className="training-details-certificate-header__text"
            >
              نتيجة المسح لشهادة التدريب
            </Text>
          </div>
          <div className="training-details-certificate-card">
            <div className="training-details-certificate-card__image">
              <div className="training-details-certificate-card__container">
                <div className="training-details-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="training-details-content__header"
                  >
                    اسم الدورة التدريبية
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="training-details-content__text"
                  >
                    {`${course_name}`}
                  </Text>
                </div>

                <div className="training-details-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="training-details-content__header"
                  >
                    اسم مقدم الخدمة
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="training-details-content__text"
                  >
                    برنامج التقييم الذاتي
                  </Text>
                </div>

                <div className="training-details-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="training-details-content__header"
                  >
                    تاريخ اصدار الشهادة
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="training-details-content__text"
                  >
                    {`${formattedDate}`}
                  </Text>
                </div>

                <div className="training-details-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="training-details-content__header"
                  >
                    عدد الساعات
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="training-details-content__text"
                  >
                    {`${hours}`}
                  </Text>
                </div>

                <div className="training-details-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="training-details-content__header"
                  >
                    اسم المنشأة
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="training-details-content__text"
                  >
                    {`${establishment_name}`}
                  </Text>
                </div>

                <div className="training-details-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="training-details-content__header"
                  >
                    الرقم التسلسلي
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="training-details-content__text"
                  >
                    {`${sequence_id}`}
                  </Text>
                </div>

                <div className="training-details-content">
                  <Text
                    Tag={"p"}
                    textType="roman"
                    fontSize={"font-16"}
                    className="training-details-content__header"
                  >
                    رقم مكتب العمل
                  </Text>
                  <Text
                    Tag={"p"}
                    textType="bold"
                    fontSize={"font-16"}
                    className="training-details-content__text"
                  >
                    {`${labor_office_id}`}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TrainingCertificateDetails);
