import React from "react";
export function EyeIcon(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" {...props}>
      <g
        id="Group_165558"
        data-name="Group 165558"
        transform="translate(-1403 -19)"
      >
        <g
          id="Icon_feather-plus-circle"
          data-name="Icon feather-plus-circle"
          transform="translate(1401 17)"
        >
          <path
            id="Path_48739"
            data-name="Path 48739"
            d="M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
        <g
          id="Icon_feather-eye"
          data-name="Icon feather-eye"
          transform="translate(1409.284 23.025)"
        >
          <path
            id="Path_48742"
            data-name="Path 48742"
            d="M1.5,11.975S4.488,6,9.716,6s8.216,5.975,8.216,5.975S14.944,17.95,9.716,17.95,1.5,11.975,1.5,11.975Z"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_48743"
            data-name="Path 48743"
            d="M17.981,15.741A2.241,2.241,0,1,1,15.741,13.5,2.241,2.241,0,0,1,17.981,15.741Z"
            transform="translate(-6.025 -3.766)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}
