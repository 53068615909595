import React, { Fragment, useEffect } from "react";
import Content from "../../containers/Content/Content";
import TitleWithSubTitle from "./TitleWithSubTitle";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import "./DocumentPage.scss";

function DocumentPage({ data, history }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Content history={history}>
      <div className="animated fadeIn document-page-margins">
        {data.title && (
          <TitleWithSubTitle
            subtitle={data.title}
            customStyle="document-title"
          />
        )}

        {data.description && (
          typeof data.description === "string" ? (
            // Handle single string, splitting by "<br />"
            data.description.split("<br />").map((line, i) => (
              <Text
                key={i}
                Tag="div"
                textType="roman"
                fontSize="font-24"
                mobileFontSize="font-18-mobile"
                className="document-description"
              >
                {line}
              </Text>
            ))
          ) : Array.isArray(data.description) ? (
            // Handle array case, with "<br/>" strings and acknowledgement objects
            data.description.map((item, index) => {
              if (item === "<br/>") {
                return <br key={index} />;
              }

              const isAcknowledgement = typeof item === "object" && item.acknowledgement;
              return (
                <Text
                  key={index}
                  Tag="div"
                  textType={isAcknowledgement ? "bold" : "roman"}
                  fontSize="font-24"
                  mobileFontSize="font-18-mobile"
                  className="document-description"
                >
                  {isAcknowledgement ? item.acknowledgement : item}
                </Text>
              );
            })
          ) : typeof data.description === "object" && data.description.acknowledgement ? (
            // Handle single object with acknowledgement property
            <Text
              Tag="div"
              textType="bold"
              fontSize="font-24"
              mobileFontSize="font-18-mobile"
              className="document-description"
            >
              {data.description.acknowledgement}
            </Text>
          ) : null
        )}

        {Array.isArray(data.sections) && data.sections.map((section, index) => (
          <Fragment key={index}>
            {section.title && (
              <Text
                Tag="div"
                textType="bold"
                fontSize="font-26"
                mobileFontSize="font-20-mobile"
                className="document-section-title"
              >
                {section.title}
              </Text>
            )}

            {Array.isArray(section.subsections) && section.subsections.map((subsection, i) => (
              <div key={i}>
                {subsection.title && (
                  <Text
                    Tag="div"
                    textType="bold"
                    fontSize="font-24"
                    mobileFontSize="font-18-mobile"
                    className="document-subsection-title"
                  >
                    {subsection.title}
                  </Text>
                )}

                {subsection.paragraph && (
                  typeof subsection.paragraph === "string" ? (
                    <Text
                      Tag="div"
                      textType="roman"
                      fontSize="font-24"
                      mobileFontSize="font-18-mobile"
                      className="document-subsection-description"
                    >
                      {subsection.paragraph}
                    </Text>
                  ) : (
                    Array.isArray(subsection.paragraph) && subsection.paragraph.map((item, index) => (
                      item === "<br/>" ? (
                        <br key={index} />
                      ) : (
                        <Text
                          key={index}
                          Tag="div"
                          textType="roman"
                          fontSize="font-24"
                          mobileFontSize="font-18-mobile"
                          className="document-subsection-description"
                        >
                          {typeof item === "string" ? (
                            item
                          ) : (
                            <>
                              {item.label && <b>{item.label}</b>} {item.description}
                            </>
                          )}
                        </Text>
                      )
                    ))
                  )
                )}
              </div>
            ))}
          </Fragment>
        ))}

        {data.contactUs && (
          <Text
            Tag="div"
            textType="bold"
            fontSize="font-26"
            mobileFontSize="font-18-mobile"
            className="document-contact-us"
          >
            {data.contactUs}
          </Text>
        )}
      </div>
    </Content>
  );
}

export default DocumentPage;
