import React, { Component } from "react";
import "./dropDownList.css";
import PropTypes from "prop-types";

const DropDownList = ({ items }) => {
  return (
    <div className={"main-div"}>
      <ul>
        {items.map((item, i) => {
          if (i == 0) {
            return (
              <a href={item.href} key={i}>
                <li>{item.label}</li>
              </a>
            );
          } else {
            return (
              <a href={item.href} className="border-top" key={i}>
                <li>{item.label}</li>
              </a>
            );
          }
        })}
      </ul>
    </div>
  );
};
DropDownList.propTypes = {
  items: PropTypes.array.isRequired,
};
export default DropDownList;
