function isValidJSON(string) {
  try {
    return typeof JSON.parse(string) !== "number";
  } catch (e) {
    return false;
  }
}

export function convertRawContentToText(string) {
  if (isValidJSON(string)) {
    return JSON.parse(string).blocks.reduce(
      (prev, cur) => `${prev} ${cur.text}`,
      ""
    );
  } else {
    return string;
  }
}
