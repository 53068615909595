import React from "react";
import { connect } from "react-redux";
import { submitTrainingPayment } from "../../actions/establishmentProfileActions";
import PropTypes from "prop-types";
import "./establishmentTrainingRegister.scss";
import TextWithIconAndDescription from "./TextWithIconAndDescription";

import LabeledCheckbox from "../LabeledCheckBox/LabeledCheckbox";
import Button from "../Button/Button";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
class PaymentCard extends React.Component {
  constructor(props) {
    super(props);
  }

  redirectToSelfAssessmentPayment = () => {
    if (!this.props.DoYouWantToPay) {
      toastr.error("يجب الموافقة على الشروط والاحكام");
      return;
    } else {
      this.props.submitTrainingPayment();
    }
  };

  render() {
    const { course, isStatusRegister, trainingName, DoYouWantToPay } =
      this.props;

    const amount = isStatusRegister ? course.amount : course.subject_amount;

    const vat = isStatusRegister ? course.vat : course.subject_vat;

    return (
      <div className="establishment-training-register-payment-card ">
        <Text
          textType="h7"
          Tag="div"
          isBold
          className="establishment-training-register-payment-card-text-bold establishment-training-register-payment-card-title"
        >
          ملخص الطلب لخدمة التدريب
        </Text>
        <div className="light-horizontal-line "></div>
        <div className="establishment-training-register-payment-card-details-wrapper ">
          <Text textType="p5" Tag="div">
            اسم الدورة التدريبية:
          </Text>
          <Text
            textType="p3"
            Tag="div"
            isBold
            className="establishment-training-register-payment-card-text-bold "
          >
            {trainingName ? trainingName : ""}
          </Text>
          <div style={{ marginTop: "40px" }}>
            <TextWithIconAndDescription
              description={`${Number(
                Math.round(amount + "e" + 2) + "e-" + 2
              )} ريال`}
              title="السعر"
              textType="p4"
            />

            <TextWithIconAndDescription
              description={`${Number(
                Math.round(amount * vat + "e" + 2) + "e-" + 2
              )} ريال`}
              title={`القيمة المضافة (${vat * 100} %)`}
              textType="p4"
            />
          </div>
        </div>
        <div className="light-horizontal-line "></div>
        <div className=" establishment-training-register-payment-card-details-wrapper ">
          <TextWithIconAndDescription
            description={`${Number(
              Math.round(amount + amount * vat + "e" + 2) + "e-" + 2
            )} ريال`}
            title=" المبلغ الإجمالي"
            isBold
            textType="p3"
          />
        </div>
        <div className="light-horizontal-line "></div>
        <div className=" establishment-training-register-payment-card-details-wrapper ">
          <div className="establishment-training-register-payment-agreement-wrap ">
            <Button
              onClick={(event) => {
                if (!DoYouWantToPay) {
                  toastr.error("يجب الموافقة على الشروط والاحكام");
                  return;
                } else this.props.onButtonClick(event);
              }}
              text="ادفع"
              id="training-register-payment-button"
              disabled={!DoYouWantToPay}
            />
          </div>
        </div>
      </div>
    );
  }
}
PaymentCard.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  price: PropTypes.string,
  vat: PropTypes.string,
  course: PropTypes.object,
};

export default PaymentCard;
