import {
  CATEGORY_SYSTEMS,
  CATEGORY_DOCUMENTS,
  CATEGORY_REGULATIONS,
  CATEGORY_DECISIONS,
  CATEGORY_STATISTICS,
} from "../../../common/constants/interActiveGuidesFiles";

export default [
  {
    id: 1,
    label: "إحصائيات",
    category: CATEGORY_STATISTICS,
  },
  {
    id: 2,
    label: `مكتبة 
        الوثائق`,
    category: CATEGORY_DOCUMENTS,
  },
  {
    id: 3,
    label: "الأنظمة",
    category: CATEGORY_SYSTEMS,
  },
  {
    id: 4,
    label: "اللوائح",
    category: CATEGORY_REGULATIONS,
  },
  {
    id: 5,
    label: "القرارات ",
    category: CATEGORY_DECISIONS,
  },
];
