import React from "react";

export const IconEstablishmentProfile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.998"
    height="32.997"
    viewBox="0 0 24.998 32.997"
  >
    <g
      id="Establishment-Site"
      stroke="none"
      strokeWidth="0.5"
      fill="none"
      fillRule="evenodd"
    >
      <g
        transform="translate(19435 -2238.002)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <path d="M-19416.5,2267h-16a2.5,2.5,0,0,1-2.5-2.5v-24a2.5,2.5,0,0,1,2.5-2.5h16a2.5,2.5,0,0,1,2.5,2.5v24A2.5,2.5,0,0,1-19416.5,2267Zm-9-8.321a.5.5,0,0,0-.5.5v6.282a5.017,5.017,0,0,0,2,.2,5.017,5.017,0,0,0,2-.2v-6.282a.5.5,0,0,0-.5-.5Zm0-1.176h3a1.82,1.82,0,0,1,1.682,1.678v6.2h4.316c.83,0,.85-.062.85-.878v-24c0-.818-.016-.834-.85-.834h-16c-.832,0-.848.016-.848.834v24c0,.857.059.878.848.878h5.326v-6.2A1.816,1.816,0,0,1-19425.5,2257.5Zm7.5-1.751h-4a.75.75,0,0,1-.746-.751.75.75,0,0,1,.746-.751h4a.753.753,0,0,1,.752.751A.753.753,0,0,1-19418,2255.751Zm-9,0h-4a.753.753,0,0,1-.752-.751.753.753,0,0,1,.752-.751h4a.752.752,0,0,1,.75.751A.752.752,0,0,1-19427,2255.751Zm9-4h-4a.75.75,0,0,1-.746-.751.75.75,0,0,1,.746-.751h4a.753.753,0,0,1,.752.751A.753.753,0,0,1-19418,2251.751Zm-9,0h-4a.753.753,0,0,1-.752-.751.753.753,0,0,1,.752-.751h4a.752.752,0,0,1,.75.751A.752.752,0,0,1-19427,2251.751Zm9-4h-4a.75.75,0,0,1-.746-.751.75.75,0,0,1,.746-.751h4a.753.753,0,0,1,.752.751A.753.753,0,0,1-19418,2247.752Zm-9,0h-4a.753.753,0,0,1-.752-.751.753.753,0,0,1,.752-.751h4a.752.752,0,0,1,.75.751A.752.752,0,0,1-19427,2247.752Zm9-4h-4a.75.75,0,0,1-.746-.751.75.75,0,0,1,.746-.751h4a.753.753,0,0,1,.752.751A.753.753,0,0,1-19418,2243.752Zm-9,0h-4a.753.753,0,0,1-.752-.751.753.753,0,0,1,.752-.751h4a.752.752,0,0,1,.75.751A.752.752,0,0,1-19427,2243.752Z" />
      </g>
    </g>
  </svg>
);

export default IconEstablishmentProfile;
