import React, { useState } from "react";

import PropTypes from "prop-types";
import "./button.scss";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

const Button = (props) => {
  const {
    secondary,
    primary,
    disabled,
    text,
    onClick,
    icon,
    iconHover,
    style,
    bigButton,
    isBold,
    id,
    customStyle,
    buttonIconCustomStyle,
    customTextSize,
    tertiary,
    iconLeft,
    fontSize,
    mobileFontSize,
  } = props;
  const [onHover, setOnHover] = useState(false);
  const defaultButton = primary || secondary || tertiary ? false : true;
  return (
    <button
      id={id}
      onMouseOver={() => setOnHover(true)}
      onMouseOut={() => setOnHover(false)}
      disabled={disabled}
      className={`app-button  ${bigButton ? "app-button__big" : ""} ${
        primary ? "app-button__white" : "app-button__orang"
      } ${defaultButton ? "app-button__orange" : ""} ${
        secondary ? "app-button__no-border " : ""
      } ${tertiary ? "app-button__white-no-border" : ""}${
        disabled ? "app-button__disabled" : ""
      } ${customStyle}`}
      style={style}
      onClick={onClick}
    >
      {icon && (
        <img
          src={onHover && iconHover ? iconHover : icon}
          className={`button-icon ${buttonIconCustomStyle}`}
          alt="button icon"
        />
      )}
      <Text
        textType={customTextSize ? customTextSize : bigButton ? "h6" : "h9"}
        Tag="span"
        isBold={isBold}
        fontSize={fontSize}
        mobileFontSize={mobileFontSize}
        className="button-text"
      >
        {text}
      </Text>
      {iconLeft && (
        <img
          src={iconLeft}
          className={`button-left-icon ${buttonIconCustomStyle}`}
          alt="button icon"
        />
      )}
    </button>
  );
};
Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  secondary: PropTypes.bool,
  primary: PropTypes.bool,
  tertiary: PropTypes.bool,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconLeft: PropTypes.string,
  iconHover: PropTypes.string,
  style: PropTypes.object,
  bigButton: PropTypes.bool,
  isBold: PropTypes.bool,
  id: PropTypes.string,
  customStyle: PropTypes.string,
  buttonIconCustomStyle: PropTypes.string,
  customTextSize: PropTypes.string,
  fontSize: PropTypes.string,
  mobileFontSize: PropTypes.string,
};
Button.defaultProps = {
  disabled: false,
  secondary: false,
  bigButton: false,
  isBold: true,
  customStyle: "",
  primary: false,
  tertiary: false,
  fontSize: "",
  mobileFontSize: "",
};

export default Button;
