import React from "react";
export const YesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={15} {...props}>
    <path
      fill={props.fill || "#92929D"}
      fillRule="evenodd"
      d="m14.54 7.462-1.427 4.69a1.813 1.813 0 0 1-1.732 1.288H7.12c-.439 0-.86-.175-1.17-.487l-.556-.559V7.927a2.522 2.522 0 0 0 2.232-1.796l1.12-3.77a.603.603 0 0 1 .88-.349c.196.114.31.33.298.557L9.75 5.611a.805.805 0 0 0 .802.854h3.256a.77.77 0 0 1 .733.997zm.934-1.465a2.077 2.077 0 0 0-1.666-.844h-2.724l.144-2.508A1.91 1.91 0 0 0 9.325.616a1.9 1.9 0 0 0-1.83 1.37l-1.12 3.77a1.229 1.229 0 0 1-.98.858V6.47a.768.768 0 0 0-.765-.77H.983a.767.767 0 0 0-.765.77v7.515c0 .425.342.769.765.769H4.63a.765.765 0 0 0 .739-.58c.505.374 1.113.58 1.753.58h4.259a3.1 3.1 0 0 0 2.981-2.217l1.427-4.69a2.1 2.1 0 0 0-.315-1.849z"
    />
  </svg>
);
