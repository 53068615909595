import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { twMerge } from '../../views/Pages/AssessmentFeature/lib/tw-merge';
import { EyeIcon } from './EyeIcon';
import { MinusIcon } from './MinusIcon';
import { PlusIcon } from './PlusIcon';
export function AccessiblityContainer({
    cls, disabledCls
}) {
    const { base: size, inverted } = useSelector(state => state.accessibility)
    const dispatch = useDispatch();
    const clss = cls ?? "text-black stroke-black fill-black"
    const eyeCls = cls ?? "text-black stroke-black fill-black"
    const disabledClss = disabledCls ?? "text-gray-400 stroke-gray-400 fill-gray-400"
    const increaseFontSize = () => {
        // set document base-size variable to +1 each time the button is clicked
        if (size >= 1) return;
        dispatch({ type: "SET_ACCESSIBILITY", payload: { base: size + 1, inverted } })
        document.body.style.setProperty("--base-size", parseInt(getComputedStyle(document.body).getPropertyValue("--base-size")) + 1 + "px");
    };
    const decreaseFontSize = () => {
        if (size <= -1) return;
        dispatch({ type: "SET_ACCESSIBILITY", payload: { base: size - 1, inverted } })
        document.body.style.setProperty("--base-size", parseInt(getComputedStyle(document.body).getPropertyValue("--base-size")) - 1 + "px");
    };
    const invertColors = (status) => {
        dispatch({ type: "SET_ACCESSIBILITY", payload: { base: size, inverted: !status } })
        // document.body.style.setProperty("filter", status ?
        //     ""
        //     : "invert(0.95) sepia(100%) saturate(0%) brightness(100%) contrast(100%)");

        const htmlElement = document.getElementsByTagName("html")[0];
        htmlElement.style.filter = status ? "" : "invert(0.95) sepia(100%) saturate(0%) brightness(100%) contrast(100%)";
    };
    return (
        <>
            <button disabled={size <= -1} onClick={decreaseFontSize}>
                <MinusIcon
                    className={twMerge(
                        "hover:opacity-80",
                        size <= -1 ? disabledClss : clss
                    )}
                    fill={"red"}
                />
                <span className={"sr-only"}>Minimize</span>
            </button>

            <button disabled={size >= 1} onClick={increaseFontSize}>
                <PlusIcon className={
                    twMerge(
                        "hover:opacity-80",
                        size >= 1 ? disabledClss : clss
                    )}
                />
                <span className={"sr-only"}>Maximize</span>
            </button>

            <button onClick={() => invertColors(inverted)}>
                <EyeIcon className={twMerge(eyeCls, "hover:opacity-80")} />
                <span className={"sr-only"}>Invert Colors</span>
            </button>

        </>
    )
};