export const STATUS_NOT_PAID = "Not-Paid";
export const STATUS_PAID = "Paid";
export const STATUS_OUT_DATE = "Out-Date";
export const BEFORE_ZATCA = "Before_Zatca";
export const PENDDING_ZATCA = "Pending_Zatca";
export const EXPORT_ZATCA = "Export_Zatca";

export const STATUSES = {
  [STATUS_NOT_PAID]: "بانتظار السداد",
  [STATUS_PAID]: "تم الدفع",
  [STATUS_OUT_DATE]: "منتهي",
};
