import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function paymentInitiate(
  state = initialState.paymentInitiate,
  action
) {
  switch (action.type) {
    case types.LOADING_SUBMIT_PAYMENT:
      return Object.assign({}, state, {
        isLoadingPayment: true,
      });
    case types.END_LOADING_SUBMIT_PAYMENT:
      return Object.assign({}, state, {
        isLoadingPayment: false,
        establishmentId: action.details.establishmentId,
        serviceId: action.details.serviceId,
        periodId: action.details.periodId,
        assessmentId: action.details.assessmentId,
        assessmentVersion: action.details.assessmentVersion,
      });

    default:
      return state;
  }
}
