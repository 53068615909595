import {
  IMS_INFO_START_LOADING,
  IMS_INFO_STOP_LOADING,
  IMS_INFO_SET_ERROR,
} from "../actions/actionTypes";
import request from "../common/request";

export const startLoading = () => ({
  type: IMS_INFO_START_LOADING,
});

export const stopLoading = () => ({
  type: IMS_INFO_STOP_LOADING,
});

export const setError = () => ({
  type: IMS_INFO_SET_ERROR,
});

export const fetchImsInfo = async () => {
  const res = await request(`/ims/info`, "GET", null, {}, false);
  const jsonRes = await res?.json();
  return jsonRes ?? {};
};
