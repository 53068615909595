import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Content from "../../containers/Content/Content";
import FeatureItem from "../reusableComponents/FeatureItem";
import SectionDescriptionWithIcon from "../reusableComponents/SectionDescriptionWithIcon";

import {
  whatIsCertification,
  certificationConditions,
  certificationBenefits,
  certificateTypes,
  certificationSteps,
} from "../home/static-data";
import "../home/homepage.css";
import "./specialTrackPage.css";
import TitleWithSubTitle from "../reusableComponents/TitleWithSubTitle";
import StepsCard from "../reusableComponents/StepsCard";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import AnimationRightToLeft from "../AnimationRightToLeft/AnimationRightToLeft";
import AnimationLeftToRight from "../AnimationLeftToRight/AnimationLeftToRight";
import AnimationBottomToTop from "../AnimationBottomToTop/AnimationBottomToTop";
import AnimationTopToBottom from "../AnimationTopToBottom/AnimationTopToBottom";
import AnimatedLeftArrow from "../AnimatedLeftArrow/AnimatedLeftArrow";
import AnimatedTextFade from "../AnimatedTextFade/AnimatedTextFade";
import AnimatedUpArrow from "../AnimatedUpArrow/AnimatedUpArrow";
import AnimatedDownArrow from "../AnimatedDownArrow/AnimatedDownArrow";
const { object } = PropTypes;

const SpecialTrackPage = (props) => {
  const myRef = useRef();
  const fistElement = useRef();
  useEffect(() => {
    let elem = document.getElementById("website-container-margins");
    elem.style.margin = 0;
    window.scrollTo({ top: 0, behavior: "smooth" });
    fistElement.current.focus();
  }, []);

  const handleUserKeyPress = (e) => {
    const active = document.activeElement;
    if (e.keyCode === 40 && active.nextSibling) {
      active.nextSibling.scrollIntoView({
        behavior: "smooth",
      });
      active.nextSibling.focus();
    }
    if (e.keyCode === 38 && active.previousSibling) {
      active.previousSibling.scrollIntoView({
        behavior: "smooth",
      });
      active.previousSibling.focus();
    }
  };

  useEffect(() => {
    const node = myRef.current;
    node.addEventListener("keydown", handleUserKeyPress);
    return () => {
      node.removeEventListener("keydown", handleUserKeyPress);
    };
  }, []);

  const renderStatsComponent = (statsArray) => {
    return (
      <div style={{ flex: 1, display: "flex" }}>
        <div
          className="steps-component-wrapper"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          key={0}
        >
          <AnimationRightToLeft>
            <StepsCard
              stepTitle={statsArray[0].step}
              description={statsArray[0].description}
              icon={statsArray[0].icon}
              number={`${1}`}
            />
          </AnimationRightToLeft>

          <div
            style={{
              width: "100%",
              height: "230px",
              display: "flex",
              flex: 1,
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                justifyContent: "flex-end",
                display: "inline-flex",
                height: "147px",
                width: "100%",
              }}
            >
              <AnimatedLeftArrow />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AnimatedTextFade delay={800}>
                <Text
                  Tag="div"
                  textType="h6"
                  className="special-track-steps-color"
                >
                  إرسال الطلب
                </Text>
              </AnimatedTextFade>
            </div>
          </div>
        </div>

        <AnimationBottomToTop>
          <div
            className="steps-component-wrapper"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingRight: "50px",
              paddingLeft: "50px",
            }}
            key={1}
          >
            <div
              style={{
                minHeight: "230px",
                flex: 1,
                width: "100%",
                marginBottom: "35px",
              }}
            >
              <br />
            </div>
            <div>
              <StepsCard
                stepTitle={statsArray[1].step}
                description={statsArray[1].description}
                icon={statsArray[1].icon}
                number={`${2}`}
              />
            </div>
          </div>
        </AnimationBottomToTop>

        <div
          className="steps-component-wrapper"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          key={2}
        >
          <AnimationTopToBottom>
            <StepsCard
              stepTitle={statsArray[2].step}
              description={statsArray[2].description}
              icon={statsArray[2].icon}
              number={`${3}`}
            />
          </AnimationTopToBottom>
          <div
            style={{
              width: "100%",
              height: "230px",
              display: "flex",
              flex: 1,
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                alignContent: "flex-end",
                display: "inline-flex",
                height: "113px",
                width: "100%",
              }}
            >
              <AnimatedUpArrow />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                paddingTop: "25px",
                paddingRight: "20px",
              }}
            >
              <AnimatedTextFade delay={1800}>
                <Text
                  Tag="div"
                  textType="h6"
                  className="special-track-steps-color"
                >
                  تحديد موعد الزيارة
                </Text>
              </AnimatedTextFade>
            </div>
          </div>
        </div>

        <div
          className="steps-component-wrapper"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          key={3}
        >
          <div
            style={{
              width: "100%",
              height: "230px",
              display: "flex",
              flex: 1,
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                paddingRight: "70px",
              }}
            >
              <AnimatedTextFade delay={2800}>
                <Text
                  Tag="div"
                  textType="h6"
                  className="special-track-steps-color"
                >
                  إرسال النتائج
                </Text>
              </AnimatedTextFade>
            </div>
            <div
              style={{
                height: "113px",
                width: "100%",
                justifyContent: "flex-start",
                display: "inline-flex",
              }}
            >
              <AnimatedDownArrow />
            </div>
          </div>
          <AnimationLeftToRight>
            <StepsCard
              stepTitle={statsArray[3].step}
              description={statsArray[3].description}
              icon={statsArray[3].icon}
              number={`${4}`}
            />
          </AnimationLeftToRight>
        </div>
      </div>
    );
  };
  const renderCertificateDescription = (certificate, position) => {
    return (
      <div
        className={`certificate-description-Wrapper certificate-description-Wrapper-${position}`}
      >
        <div>
          <Text Tag="div" textType="p2" isBold className="certificate-name">
            {certificate.name}
          </Text>
          <Text Tag="div" textType="p2" className="certificate-description">
            {certificate.score}
          </Text>
          <Text Tag="div" textType="p2" className="certificate-description">
            {certificate.duration}
          </Text>
        </div>
      </div>
    );
  };
  const renderCertificatesTypes = (data) => {
    return (
      <div className="flex-1 horizontal certificates-types-wrapper vertical">
        <AnimationRightToLeft>
          {renderCertificateDescription(data[0], "right")}
        </AnimationRightToLeft>
        <AnimationBottomToTop>
          <img src="img/Certifcate.svg" className="certificate-image" />
        </AnimationBottomToTop>
        <AnimationLeftToRight>
          {renderCertificateDescription(data[1], "left")}
        </AnimationLeftToRight>
      </div>
    );
  };

  return (
    <Content history={props.history}>
      <div className="animated fadeIn main-page">
        <div className="main-thread " id="main-thread" ref={myRef}>
          <section
            className="flex-1 "
            style={{ marginBottom: "200px" }}
            tabIndex="0"
            ref={fistElement}
          >
            <SectionDescriptionWithIcon
              data={whatIsCertification}
              imageSrc="img/specialTrackLaptop.svg"
              descriptionMaxwidth="text-max-width "
            />
          </section>

          <section tabIndex="1 margin-top-section">
            <div className="flex-1 section-inner-style  ">
              <div className="horizontal ">
                <div className="flex-1-certificate">
                  <AnimationRightToLeft>
                    <TitleWithSubTitle
                      title={certificationConditions.title}
                      subtitle={certificationConditions.subtitle}
                      customStyle="get-certification-section"
                    />
                  </AnimationRightToLeft>
                </div>

                <div className="vertical">
                  <AnimationLeftToRight>
                    {certificationConditions.description.map(
                      ({ iconTitle }, i) => (
                        <FeatureItem key={i} iconTitle={iconTitle} />
                      )
                    )}
                  </AnimationLeftToRight>
                </div>
              </div>
            </div>
            <div className="flex-1 section-inner-style">
              <div className="flex-1 vertical">
                <AnimationRightToLeft>
                  <TitleWithSubTitle
                    title={certificateTypes.title}
                    subtitle={certificateTypes.subtitle}
                    customStyle="get-certification-section"
                  />
                </AnimationRightToLeft>

                {renderCertificatesTypes(certificateTypes.description)}
              </div>
            </div>
          </section>
          <section
            className="flex-1 stats-section section-outer-style"
            tabIndex="2"
            style={{
              marginTop: "200px",
              paddingTop: "100px",
              paddingBottom: "100px",
              marginBottom: "200px",
            }}
          >
            <div className="horizontal flex-1 section-inner-style">
              <div className="flex-1-certificate">
                <AnimationRightToLeft>
                  <TitleWithSubTitle
                    title={certificationBenefits.title}
                    subtitle={certificationBenefits.subtitle}
                    customStyle="get-certification-section"
                  />
                </AnimationRightToLeft>
              </div>
              <div className=" vertical ">
                <div className="text-max-width">
                  <AnimationLeftToRight>
                    {certificationBenefits.description.map(
                      ({ iconTitle }, i) => (
                        <FeatureItem key={i} iconTitle={iconTitle} />
                      )
                    )}
                  </AnimationLeftToRight>
                </div>
              </div>
            </div>
          </section>
          <section
            className="flex-1  section-outer-style"
            tabIndex="3"
            style={{ marginBottom: "300px" }}
          >
            <div className="flex-1 section-inner-style">
              <div
                style={{
                  flexDirection: "column",
                }}
                className="flex-1 "
              >
                <AnimationRightToLeft>
                  <TitleWithSubTitle
                    title={certificationSteps.title}
                    subtitle={certificationSteps.subtitle}
                  />
                </AnimationRightToLeft>
                <div className="stepsWrapper">
                  {renderStatsComponent(certificationSteps.description)}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Content>
  );
};

SpecialTrackPage.propTypes = {
  history: object.isRequired,
};

export default SpecialTrackPage;
