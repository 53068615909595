import * as React from "react";
import Popover from "@mui/material/Popover";
import PropTypes from "prop-types";

const styles = {
  width: 190,
  height: 55,
  borderRadius: 7,
  border: "1px solid #DFDFDF",
  background: "#FFFFFF",
  boxShadow: "0px 5px 15px #44444F1A",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "MolArabic",
  color: "#44444F",
  cursor: "pointer",
  outline: "none",
  fontSize: 14,
};

export const ReSubmitPopover = ({ trigger, id, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
        aria-describedby={id}
        onClick={handlePopoverOpen}
        onMouseEnter={handlePopoverOpen}
      >
        {trigger}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 36,
          horizontal: -36,
        }}
      >
        <button onClick={onClick} style={styles}>
          {"مراجعة النموذج"}
        </button>
      </Popover>
    </div>
  );
};

ReSubmitPopover.propTypes = {
  trigger: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
