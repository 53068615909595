const EMAIL_FORMAT =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]{2,}$/;
const EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`@{|}~-]*$/;
const ARABIC_LETTERS = /[\u0621-\u064A\u06A4\u067E]/;
const WHITESPACE = /\s/;
const ENGLISH_LETTERS = /^[a-zA-Z\s]*$/;
const ENGLISH_NUMBERS = /^[0-9]*$/;
const ARABIC_NUMBERS = /[\u0660-\u0669]/;
const PHONE_NUMBER = /05[0-9]{8}/;
const TEXT_AREA_NUMBERS_AND_SPECIAL_CHARACTERS =
  /^[0-9.#%'*+/_\-`{|}()~،٪˜؛]*$/;
const ARABIC_DIACRITICS =
  /^[\u064B\u064C\u064D\u064E\u064F\u0650\u0651\u0652\u0653]*$/;
const QUESTION_SPECIAL_CHARACTERS = /^[؟،]*$/;

export const arabicLettersOnly = (text) => {
  let arabicText = "";
  text.split("").forEach((character) => {
    if (
      ARABIC_LETTERS.test(character) ||
      WHITESPACE.test(character) ||
      ARABIC_DIACRITICS.test(character)
    ) {
      arabicText += character;
    }
  });
  return arabicText;
};
export const lettersNumbersAndEmail = (text) => {
  // Check if text is null or undefined, and return an empty string if so
  if (!text) {
    return "";
  }

  let formattedText = "";
  text.split("").forEach((character) => {
    if (
      ARABIC_LETTERS.test(character) ||
      ARABIC_NUMBERS.test(character) ||
      WHITESPACE.test(character) ||
      ARABIC_DIACRITICS.test(character) ||
      EMAIL.test(character)
    ) {
      if (ARABIC_NUMBERS.test(character)) {
        formattedText += arabicToEnglishDigits(character);
      } else {
        formattedText += character;
      }
    }
  });

  return formattedText;
};

export const NumbersAndEmail = (text) => {
  let formattedText = "";
  text.split("").forEach((character) => {
    if (
      ARABIC_NUMBERS.test(character) ||
      WHITESPACE.test(character) ||
      EMAIL.test(character)
    ) {
      if (ARABIC_NUMBERS.test(character)) {
        formattedText += arabicToEnglishDigits(character);
      } else formattedText += character;
    }
  });
  return formattedText;
};
export const arabicLettersWithNumbers = (text) => {
  let filteredText = "";
  text.split("").forEach((character) => {
    if (
      ARABIC_LETTERS.test(character) ||
      ARABIC_DIACRITICS.test(character) ||
      WHITESPACE.test(character) ||
      ARABIC_NUMBERS.test(character) ||
      ENGLISH_NUMBERS.test(character)
    ) {
      filteredText += character;
    }
  });
  return filteredText;
};
export const arabicLettersWithEnglishNumbers = (text) => {
  let filteredText = "";
  text.split("").forEach((character) => {
    if (
      ARABIC_LETTERS.test(character) ||
      ARABIC_DIACRITICS.test(character) ||
      WHITESPACE.test(character) ||
      ENGLISH_NUMBERS.test(character) ||
      ARABIC_NUMBERS.test(character)
    ) {
      if (ARABIC_NUMBERS.test(character)) {
        filteredText += arabicToEnglishDigits(character);
      } else filteredText += character;
    }
  });
  return filteredText;
};
export const arabicLettersWithNumbersAndSpecialCharacters = (text) => {
  let filteredText = "";
  text.split("").forEach((character) => {
    if (
      TEXT_AREA_NUMBERS_AND_SPECIAL_CHARACTERS.test(character) ||
      ARABIC_DIACRITICS.test(character) ||
      ARABIC_NUMBERS.test(character) ||
      ARABIC_LETTERS.test(character) ||
      WHITESPACE.test(character)
    ) {
      filteredText += character;
    }
  });
  return filteredText;
};
export const englishLettersOnly = (text) => {
  let englishText = "";
  text.split("").forEach((character) => {
    if (ENGLISH_LETTERS.test(character) || WHITESPACE.test(character)) {
      englishText += character;
    }
  });
  return englishText;
};
export const arabicAndEnglishLettersOnly = (text) => {
  let arabicEnglishText = "";
  text.split("").forEach((character) => {
    if (
      ARABIC_LETTERS.test(character) ||
      ARABIC_DIACRITICS.test(character) ||
      WHITESPACE.test(character) ||
      ENGLISH_LETTERS.test(character)
    ) {
      arabicEnglishText += character;
    }
  });
  return arabicEnglishText;
};
export const lettersAndNumbers = (text) => {
  let lettersAndNumbersText = "";
  text.split("").forEach((character) => {
    if (
      ARABIC_LETTERS.test(character) ||
      ARABIC_DIACRITICS.test(character) ||
      WHITESPACE.test(character) ||
      ENGLISH_LETTERS.test(character) ||
      ARABIC_NUMBERS.test(character) ||
      ENGLISH_NUMBERS.test(character)
    ) {
      if (ARABIC_NUMBERS.test(character)) {
        lettersAndNumbersText += arabicToEnglishDigits(character);
      } else lettersAndNumbersText += character;
    }
  });
  return lettersAndNumbersText;
};
export const digitsOnly = (s) => {
  let englishNumber = "";

  s.split("").forEach((character) => {
    if (ARABIC_NUMBERS.test(character) || ENGLISH_NUMBERS.test(character)) {
      if (ARABIC_NUMBERS.test(character)) {
        englishNumber += arabicToEnglishDigits(character);
      } else englishNumber += character;
    }
  });

  return englishNumber;
};

export const emailOnly = (s) => {
  let email = "";

  s.split("").forEach((character) => {
    if (EMAIL.test(character)) {
      email += character;
    }
  });

  return email;
};
export const questionsOnly = (text) => {
  let filteredText = "";
  text.split("").forEach((character) => {
    if (
      ARABIC_LETTERS.test(character) ||
      ARABIC_DIACRITICS.test(character) ||
      WHITESPACE.test(character) ||
      ARABIC_NUMBERS.test(character) ||
      ENGLISH_NUMBERS.test(character) ||
      QUESTION_SPECIAL_CHARACTERS.test(character)
    ) {
      filteredText += character;
    }
  });
  return filteredText;
};
export const isLettersWithNumbersAndSpecialCharacters = (text) => {
  if (
    TEXT_AREA_NUMBERS_AND_SPECIAL_CHARACTERS.test(text) ||
    ARABIC_DIACRITICS.test(text) ||
    ARABIC_NUMBERS.test(text) ||
    ARABIC_LETTERS.test(text) ||
    ENGLISH_LETTERS.test(text) ||
    ENGLISH_NUMBERS.test(text) ||
    WHITESPACE.test(text)
  ) {
    return true;
  }

  return false;
};

export const isEnglishLettersOnly = (text) => {
  if (ENGLISH_LETTERS.test(text)) return true;

  return false;
};
export const isDigitsOnly = (text) => {
  if (ENGLISH_NUMBERS.test(text)) {
    return true;
  }

  return false;
};

export const isValidEmail = (email) => {
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return EMAIL_REGEX.test(email);
};

export const isEmailFormatOnly = (text) => {
  if (!EMAIL_FORMAT.test(text) || !isValidEmail(text)) {
    console.log("Invalid email format")
    return false;
  }
  console.log("Email format")
  return true;
};

export const isPhoneNumberFormat = (text) => {
  if (PHONE_NUMBER.test(text)) {
    return true;
  }
  return false;
};
export const arabicToEnglishDigits = (arabicDigit) => {
  let englishNumber = "";
  arabicDigit.split("").forEach((character) => {
    switch (character) {
      case "١":
        englishNumber += "1";
        return englishNumber;
      case "٢":
        englishNumber += "2";
        return englishNumber;
      case "٣":
        englishNumber += "3";
        return englishNumber;
      case "٤":
        englishNumber += "4";
        return englishNumber;
      case "٥":
        englishNumber += "5";
        return englishNumber;
      case "٦":
        englishNumber += "6";
        return englishNumber;
      case "٧":
        englishNumber += "7";
        return englishNumber;
      case "٨":
        englishNumber += "8";
        return englishNumber;
      case "٩":
        englishNumber += "9";
        return englishNumber;
      case "٠":
        englishNumber += "0";
        return englishNumber;
      default:
        break;
    }
  });

  return englishNumber;
};
export const englishToArabic = (englishDigits) => {
  let arabicNumber = "";
  englishDigits.split("").forEach((character) => {
    switch (character) {
      case "1":
        arabicNumber += "١";
        return arabicNumber;
      case "2":
        arabicNumber += "٢";
        return arabicNumber;
      case "3":
        arabicNumber += "٣";
        return arabicNumber;
      case "4":
        arabicNumber += "٤";
        return arabicNumber;
      case "5":
        arabicNumber += "٥";
        return arabicNumber;
      case "6":
        arabicNumber += "٦";
        return arabicNumber;
      case "7":
        arabicNumber += "٧";
        return arabicNumber;
      case "8":
        arabicNumber += "٨";
        return arabicNumber;
      case "9":
        arabicNumber += "٩";
        return arabicNumber;
      case "0":
        arabicNumber += "٠";
        return arabicNumber;
      default:
        break;
    }
  });

  return arabicNumber;
};
export const removeExtraSpace = (s) => s.trim().split(/ +/).join(" ");
