import React from "react";
import "./mobileBillsList.css";
import PropTypes from "prop-types";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import { STATUSES as ASSESSMENT_STATUSES } from "../../../common/constants/paymentStatuses";
import {
  STATUSES,
  STATUS_NOT_PAID,
  STATUS_PAID,
  STATUS_OUT_DATE,
  PENDDING_ZATCA,
} from "../../../common/constants/billStatuses";
import Button from "../../../components/Button/Button";
import MobileTableCards from "../../Components/MobileTableCards/MobileTableCards";

const MobileBillsList = (props) => {
  const { bills, invoiceClick, isPaid, sdadInvoiceClick, isSdadModalOpen } =
    props;

  const getStatusColor = (status) => {
    switch (status) {
      case STATUS_NOT_PAID:
        return "purple";
      case STATUS_PAID:
        return "green";
      case STATUS_OUT_DATE:
        return "gray";
    }
  };
  const invoiceButton = (bill, index) => {
    if (
      bill.payment_status === STATUS_OUT_DATE
        ? null
        : bill.zatca_status === PENDDING_ZATCA && isPaid(bill)
    )
      return (
        <Text Tag="div" textType="h10" isBold className="pending-status">
          جاري إصدار الفاتورة . .
        </Text>
      );
    else if (bill.payment_status === "Not-Paid")
      return (
        <Button
          onClick={() => sdadInvoiceClick(bill)}
          style={{
            minWidth: "100%",
          }}
          isOpen={isSdadModalOpen}
          text="بيانات السداد"
        />
      );
    else
      return (
        <Button
          isBold={false}
          onClick={async () => await invoiceClick(bill)}
          iconHover={isPaid(bill) ? "img/downloadIconHover.svg" : ""}
          icon={isPaid(bill) ? "img/downloadIcon.svg" : null}
          text={isPaid(bill) ? "تحميل الفاتورة" : "ادفع الآن"}
          primary={isPaid(bill)}
          id={`bill-${index}-button`}
          disabled={!isPaid(bill)}
          style={{
            minWidth: "100%",
          }}
        />
      );
  };
  return (
    <div className="m-bills-wrapper">
      {bills.map((bill, index) => (
        <MobileTableCards
          key={bill.payment_resp_bill_number}
          headerTitle={"فاتورة رقم"}
          headerValue={bill.payment_resp_bill_number}
          headerStatus={STATUSES[bill.payment_status]}
          buttonComponent={invoiceButton(bill, index)}
          statusColor={getStatusColor(bill.payment_status)}
          bodyData={[
            { title: "اسم الفترة", value: bill.period_name },
            {
              title: "نوع الخدمة",
              value: ASSESSMENT_STATUSES[bill.service_type],
            },
            {
              title: ["مبلغ الفاتورة", "(شامل الضريبة)"],
              value: ` ${bill.totalAmount} ريال`,
            },
          ]}
        />
      ))}
    </div>
  );
};
MobileBillsList.propTypes = {
  bills: PropTypes.array.isRequired,
  invoiceClick: PropTypes.func.isRequired,
  isPaid: PropTypes.func.isRequired,
  sdadInvoiceClick: PropTypes.func.isRequired,
  isSdadModalOpen: PropTypes.bool.isRequired,
};

export default MobileBillsList;
