import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function report (state = initialState.report, action) {
  switch (action.type) {
    case types.LOAD_ESTABLISHMENTSELFASSESSMENTREPORTRESULT_SUCCESS:
      return Object.assign({}, state, {
        establishmentSelfAssessmentReportResult: action.selfAssessmentResult
      });
    default:
      return state;
  }
}

