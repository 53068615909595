import React, { Fragment } from "react";
import "./mobileAssessmentsList.css";
import PropTypes from "prop-types";
import {
  RE_SUBMIT_STATUS,
  ASSESSMENT_TYPES,
  STATUS_SUBMITTED,
  INSPECTION_REQUEST_STATUS_NEW,
  INSPECTION_REQUEST_STATUS_INITIATED,
  INSPECTION_REQUEST_STATUS_ON_PROGRESS,
  INSPECTION_REQUEST_STATUS_COMPLETED,
} from "../../../common/constants/assessmentStatuses";

import Button from "../../../components/Button/Button";
import MobileTableCards from "../../Components/MobileTableCards/MobileTableCards";
import moment from "moment";

const MobileAssessmentsList = (props) => {
  const { assessments, statusColor, statusName, profileId, history } = props;

  const canGoToSelfAssessmentResult = (assessment) => {
    return (
      assessment.audit_status !== RE_SUBMIT_STATUS &&
      assessment.state === STATUS_SUBMITTED &&
      ![
        INSPECTION_REQUEST_STATUS_NEW,
        INSPECTION_REQUEST_STATUS_INITIATED,
        INSPECTION_REQUEST_STATUS_ON_PROGRESS,
        INSPECTION_REQUEST_STATUS_COMPLETED,
      ].includes(assessment?.inspection_assessment_status?.String)
    );
  };
  const redirectToSelfAssessmentResult = (period) => {
    console.log("assessment", period);
    const effectivePeriodId = period.id;
    const effectiveAssessmentId = period.assessment.id;
    history.push(
      `/establishmentAssessmentResult/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${period.assessment_version}`
    );
  };
  const resultButton = (assessment, index) => {
    if (canGoToSelfAssessmentResult(assessment))
      return (
        <Button
          onClick={async () => redirectToSelfAssessmentResult(assessment)}
          text={"استعراض"}
          primary
          id={`bill-${index}-button`}
          style={{
            minWidth: "100%",
          }}
        />
      );
    else return;
  };

  return (
    <div className="m-assessments-wrapper">
      {assessments.map((assessment, index) => (
        <MobileTableCards
          key={`${assessment.assessment.id}-${index}`}
          headerTitle={"نوع الفترة"}
          headerValue={ASSESSMENT_TYPES[assessment.assessment_type]}
          headerStatus={statusName(assessment)}
          buttonComponent={resultButton(assessment, index)}
          statusColor={statusColor(assessment)}
          bodyData={[
            { title: "اسم الفترة", value: assessment.name },
            {
              title: "النسخة",
              value: assessment.assessment_version,
            },
            {
              title: "تاريخ بداية التقييم",
              value: moment(assessment.start_date.String).format("YYYY/MM/DD"),
            },
            {
              title: "تاريخ نهاية التقييم",
              value: moment(assessment.end_date.String).format("YYYY/MM/DD"),
            },
          ]}
        />
      ))}
    </div>
  );
};
MobileAssessmentsList.propTypes = {
  assessments: PropTypes.array.isRequired,
  statusColor: PropTypes.func.isRequired,
  statusName: PropTypes.func.isRequired,
  profileId: PropTypes.string.isRequired,
};

export default MobileAssessmentsList;
