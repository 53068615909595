export const STATUS_SUBMITTED = "Submitted";
export const STATUS_CHECKOUT = "Check-out";
export const STATUS_READY = "Ready";
export const STATUS_IN_PROGRESS = "In-Progress";
export const STATUS_COMPLETE = "Done";
export const STATUS_REGISTER = "Register";

export const STATUSES = {
  [STATUS_SUBMITTED]: "قيد التنفيذ",
  [STATUS_CHECKOUT]: "قيد الدفع",
  [STATUS_READY]: "تم الدفع",
  [STATUS_IN_PROGRESS]: "جاري التدريب",
  [STATUS_COMPLETE]: "تم التدريب",
};
