import {
  UNIFIED_CLEAR_SELECTED_ESTABLISHMENT,
  UNIFIED_DESELECT_ESTABLISHMENT,
  UNIFIED_FETCH_ESTABLISHMENT_SUCCESS,
  UNIFIED_GET_GRACE_PERIOD_STATUS,
  UNIFIED_RESET,
  UNIFIED_SAVE_SELECTED_ESTABLISHMENTS_SUCCESS,
  UNIFIED_SELECT_ESTABLISHMENT,
  UNIFIED_SET_ERROR,
  UNIFIED_SET_GRACE_PERIOD_STATUS,
  UNIFIED_START_LOADING,
  UNIFIED_STOP_LOADING,
} from "../actions/actionTypes";
import request from "../common/request";
import { loadEstablishmentAciveAssessment } from "./establishmentAssessmentActions";

export const startLoading = () => ({
  type: UNIFIED_START_LOADING,
});

export const stopLoading = () => ({
  type: UNIFIED_STOP_LOADING,
});

export const setError = () => ({
  type: UNIFIED_SET_ERROR,
});

export const selectEstablishment = (id) => ({
  type: UNIFIED_SELECT_ESTABLISHMENT,
  id,
});

export const deselectEstablishment = (id) => ({
  type: UNIFIED_DESELECT_ESTABLISHMENT,
  id,
});

export const clearSelectedEstablishments = () => ({
  type: UNIFIED_CLEAR_SELECTED_ESTABLISHMENT,
});

export const fetchUnifiedEstablishmentsSuccess = (establishments, total) => ({
  type: UNIFIED_FETCH_ESTABLISHMENT_SUCCESS,
  establishments,
  total,
});

export const saveSelectedEstablishmentsSuccess = (service) => ({
  type: UNIFIED_SAVE_SELECTED_ESTABLISHMENTS_SUCCESS,
  service,
});

export const reset = () => ({
  type: UNIFIED_RESET,
});

export const setGracePeriodStatus = (status) => ({
  type: UNIFIED_SET_GRACE_PERIOD_STATUS,
  status,
});

export const gettingGracePeriodStatus = () => ({
  type: UNIFIED_GET_GRACE_PERIOD_STATUS,
});
export const fetchUnifiedEstablishments =
  (
    establishmentId,
    periodId,
    assessmentId,
    periodAssessmentId,
    assessmentVersion
  ) =>
  (dispatch) => {
    dispatch(startLoading());

    return request(
      `/v1/establishments/${establishmentId}/unifiedAssessmentEstablishments/${periodId}/${assessmentId}/${assessmentVersion}/${periodAssessmentId}?limit=10&start=0`
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(
          fetchUnifiedEstablishmentsSuccess(
            data.establishments,
            data.total_results
          )
        );
        dispatch(stopLoading());
      })
      .catch((error) => {
        dispatch(setError());

        throw error;
      });
  };

export const saveSelectedEstablishments =
  (
    establishmentId,
    periodId,
    assessmentId,
    assessmentVersion,
    selectedEstablishments,
    period_assessment_id
  ) =>
  (dispatch) => {
    dispatch(startLoading());

    return request(
      `/v1/establishments/${establishmentId}/unifiedAssessmentEstablishments/service`,
      "POST",
      {
        establishment_ids: selectedEstablishments,
        period_id: periodId,
        assessment_id: assessmentId,
        period_assessment_id: period_assessment_id,
        assessment_version: parseInt(assessmentVersion),
      }
    )
      .then((response) => response.json())
      .then((service) => {
        dispatch(stopLoading());
        dispatch(saveSelectedEstablishmentsSuccess(service));
      })
      .catch((error) => {
        dispatch(setError());

        throw error;
      });
  };

export const payForService = (establishmentId, paymentBill) => (dispatch) => {
  dispatch(startLoading());

  return request(
    `/v1/establishments/${establishmentId}/unifiedAssessmentEstablishments/initiate-payment`,
    "POST",
    { payment_bill: paymentBill }
  )
    .then((res) => {
      if (res.status == 400) {
        throw res;
      } else return res.json();
    })
    .then((res) => {
      dispatch(stopLoading());
      dispatch(
        loadEstablishmentAciveAssessment(establishmentId, {
          get: "current-BasicTrack",
        })
      );
      return res;
    })
    .catch((error) => {
      dispatch(setError());
      dispatch(stopLoading());
      throw error;
    });
};

export const fetchGracePeriodStatus =
  (establishmentId, periodId, assessmentId, assessmentVersion) =>
  (dispatch) => {
    dispatch(startLoading());
    dispatch(gettingGracePeriodStatus());
    return request(
      `/v1/establishments/${establishmentId}/grace_period_status/${periodId}/${assessmentId}/${assessmentVersion}`
    )
      .then((response) => response.json())
      .then((data) => {
        const status = data.status === "Enable";

        dispatch(setGracePeriodStatus(status));
        dispatch(stopLoading());
        return status;
      })
      .catch((error) => {
        dispatch(setError());

        throw error;
      });
  };
