export const RE_ASSESSMENT = "Re-Assessment";
export const TRAINING = "Training";
export const FASTTRACK = "Fast-track";
export const FAST_TRACK = "Fast-Track";
export const DETAILEDREPORT = "Detailed-Report";
export const UNIFIED_ASSESSMENT = "Unified-Assessment";
export const ADDITIONAL_ASSESSMENT = "Additional-Assessment";
export const GRACE_PERIOD = "Grace-Period";
export const STATIC_FAST_TRACK_FOR_BRANCHES = "Static_Fast_Track_For_Branches";
export const FAST_TRACK_BRANCHES = "Fast-Track-Branches";
export const DETAILED_REPORT_WITH_GRACE_PERIOD =
  "Detailed-Report-with-Grace-Period";

export const SADAD = "Sadad";

export const STATUSES = {
  [RE_ASSESSMENT]: "إعادة التقييم",
  [TRAINING]: "التدريب",
  [FASTTRACK]: "المسار المميز",
  [DETAILEDREPORT]: "التقرير المفصل",
  [UNIFIED_ASSESSMENT]: "التقييم الموحد",
  [ADDITIONAL_ASSESSMENT]: "التقييم الذاتي",
  [GRACE_PERIOD]: "شهادة المهلة التصحيحية",
  [FAST_TRACK_BRANCHES]: "المسار المميز للفروع",
};
export const DESCRIPTION = {
  [RE_ASSESSMENT]:
    "من خلال خدمة إعادة التقييم تستطيع المنشأة عمل التقييم الذاتي لمعرفة نسبة امتثالها للأنظمة واللوائح بعد قيامها بتصحيح المخالفات ورفع نسبة الامثال",
  [TRAINING]: "",
  [FASTTRACK]:
    "هي شهادة تحصل عليها المنشأة بعد زيارة مدقق ميداني معتمد والذي يقوم بالتدقيق على اجابات وأدلة المنشأة المقدمة عن طريق منصة التقييم الذاتي والتأكد من إلتزامها وتقديمها للمعلومات الصحيحة والالتزام بجميع الأنظمة",
  [DETAILEDREPORT]:
    "يوضح التقرير المفصل  الوضع الحالي للمنشأة، بما في ذلك نسبة الامتثال ومجموع الغرامات المتوقعة في حال زيارة المفتش، مع الخطوات الإرشادية الواجب عملها لرفع نسبة الامتثال وتفادي الغرامات المالية. كما يتيح لك الحصول على مهلة تصحيحية من الزيارات التفتيشية التابعة لوزارة الموارد البشرية والتنمية الاجتماعية لمدة شهرين.",
  [UNIFIED_ASSESSMENT]: "التقييم الموحد",
  [ADDITIONAL_ASSESSMENT]: `تتيح لك هذه الخدمة إكمال التقييم الذاتي الإلزامي وعرض مجموعة من المؤشرات والبيانات تشمل :
  1. نسبة امتثال المنشأة الخاصة بكم
  2. عدد أيام الإغلاق الكلي لمنشأتكم عند وجود مخالفات
  3. مجموع الغرامات المتوقع إيقاعها على المنشأة إذا لم يتم عمل التصحيح اللازم لها قبل الزيارة التفتيشية`,

  [GRACE_PERIOD]:
    "يتيح لك الحصول على مهلة تصحيحية من الزيارات التفتيشية التابعة لوزارة الموارد البشرية والتنمية الاجتماعية لمدة شهرين.",
  [STATIC_FAST_TRACK_FOR_BRANCHES]:
    "شهادة تمكن فروع المنشآت من قياس نسبة امتثالها لأنظمة وزارة الموارد البشرية والتنمية الاجتماعية وتحقق للمنشأة تفادي المخالفات من الزيارات التفتيشية.",

  [FAST_TRACK_BRANCHES]:
    "شهادة تمكن فروع المنشآت من قياس نسبة امتثالها لأنظمة وزارة الموارد البشرية والتنمية الاجتماعية وتحقق للمنشأة تفادي المخالفات من الزيارات التفتيشية.",
};
