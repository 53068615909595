import React from "react";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import moment from "moment";

export const getMessageText = (
  isStartFreeAssessment,
  isReturnedAssessment,
  isPayForAssessment,
  // isBlocked,
  endDate,
  startDate,
  isBasicTrackNotComplete,
  returnedCaliberEndAt,
  isReassessment,
  isCompletedAndCertified,
  isCompletedAndNotCertified,
  isNewInspection
) => {
  const expectedEndDate = moment(returnedCaliberEndAt).format("YYYY/MM/DD");
  const start = moment(startDate).format("YYYY/MM/DD");
  const end = moment(endDate).format("YYYY/MM/DD");
  if (isNewInspection) {
    return (
      <Text Tag="div" textType="p4">
        تم طلب خدمة التفتيش بنجاح، سيتم التنسيق معكم لجدولة موعد الزيارة
        التفتيشية قريبًا.
      </Text>
    );
  } else if (isCompletedAndCertified) {
    return (
      <Text Tag="div" textType="p4">
        عميلنا العزيز، نفيدكم بأنه قد تم إصدار شهادة الامتثال بنجاح.
      </Text>
    );
  } else if (isCompletedAndNotCertified) {
    return (
      <Text Tag="div" textType="p4">
        عميلنا العزيز، يؤسفنا إبلاغكم بعدم تجاوز النسبة المحددة للحصول شهادة
        الامتثال، يرجى الاطلاع على التقرير المفصل، كما يمكنكم إعادة المحاولة مرة
        أخرى وذلك من خلال طلب خدمة إعادة التقييم
      </Text>
    );
  } else if (isReassessment) {
    return (
      <Text Tag="div" textType="p4">
        تم طلب خدمة إعادة التقييم بنجاح، يرجى إكمال النموذج و ذلك من خلال الضغط
        على “بدء إعادة التقييم”
      </Text>
    );
  }
  // else if (isBlocked)
  //   return (
  //     <Text Tag="div" textType="p4">
  //       تم إيقاف المنشأة لعدم التقييم، لرفع الإيقاف يرجى إكمال التقييم الذاتي من
  //       خلال الضغط على
  //       <Text Tag="span" textType="p4" isBold>
  //         {" "}
  //         بدء التقييم
  //       </Text>
  //     </Text>
  //   );
  else if (isStartFreeAssessment) {
    return (
      <Text Tag="div" textType="p4">
        أهلًا عميلنا، بدأت الفترة التقديمية الجديدة من تاريخ {start} إلى تاريخ{" "}
        {end} سارع بالتقييم قبل انتهاء الفترة .
      </Text>
    );
  } else if (isPayForAssessment)
    return (
      <Text Tag="div" textType="p4">
        للإستفادة من هذه الخدمة يرجى إكمال التقييم الذاتي.
      </Text>
    );
  else if (isReturnedAssessment) {
    return (
      <Text Tag="div" textType="p4">
        تم إرجاع طلبك للتعديل، يرجى مراجعة النموذج وتعديل الملاحظات التي تم
        ذكرها من قبل فريق التدقيق وذلك من خلال الضغط على
        <Text Tag="span" textType="p4" isBold>
          {" "}
          مراجعة النموذج
        </Text>
        ، علما أن صلاحية التعديل تنتهي في {expectedEndDate}.
      </Text>
    );
  } else if (isBasicTrackNotComplete) {
    return (
      <Text Tag="div" textType="p4">
        للإستفادة من هذه الخدمة يرجى إكمال التقييم الذاتي.
      </Text>
    );
  }
};
