import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function payment(state = initialState.payment, action) {
  switch (action.type) {
    case types.LOAD_PAYMENTSERVICES_SUCCESS:
      return Object.assign({}, state, {
        reopen_assessment_services: action.reopen_assessment_services,
        isLoadingServices: false,
      });
      case types.LOAD_PAYMENTSERVICESBYTYPE_SUCCESS:
        return Object.assign({}, state, {
          isLoadingServices: false,
          paymentServices: action.paymentServices, 
          isAlreadyPaid: action.isAlreadyPaid,
        } )
    case types.LOADING_PAYMENT_SERVICES:
      return Object.assign({}, state, {
        isLoadingServices: true,
      });
    case types.LOAD_PAYMENT_SERVICES_FAIL:
      return Object.assign({}, state, {
        isLoadingServices: false,
      });
    case types.LOAD_PAYMENTBILLS_SUCCESS:
      return Object.assign({}, state, {
        bills: action.bills,
      });

    case types.LOAD_TRAININGPAYMENTSERVICES_SUCCESS:
      return Object.assign({}, state, {
        training_services: action.training_services,
      });
    case types.LOAD_ALL_ESTABLISHMENT_BILLS_SUCCESS:
      return Object.assign({}, state, {
        establishmentAllBills: action.bills.invoice_detail,
        billsCount: action.bills.count,
      });
    default:
      return state;
  }
}
