import React, { useEffect, useRef } from "react";
import Content from "../../containers/Content/Content";

import TitleWithSubTitle from "../reusableComponents/TitleWithSubTitle";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import "./aboutPage.css";
import AnimationLeftToRight from "../AnimationLeftToRight/AnimationLeftToRight";
import AnimationRightToLeft from "../AnimationRightToLeft/AnimationRightToLeft";
import AnimationBottomToTop from "../AnimationBottomToTop/AnimationBottomToTop";

const AboutPage = (props) => {
  const myRef = useRef();
  const fistElement = useRef();

  useEffect(() => {
    let elem = document.getElementById("website-container-margins");
    elem.style.margin = 0;
    window.scrollTo({ top: 0, behavior: "smooth" });
    fistElement.current.focus();
  }, []);
  const handleUserKeyPress = (e) => {
    const active = document.activeElement;
    if (e.keyCode === 40 && active.nextSibling) {
      active.nextSibling.scrollIntoView({
        behavior: "smooth",
      });
      active.nextSibling.focus();
    }
    if (e.keyCode === 38 && active.previousSibling) {
      active.previousSibling.scrollIntoView({
        behavior: "smooth",
      });
      active.previousSibling.focus();
    }
  };

  useEffect(() => {
    const node = myRef.current;
    node.addEventListener("keydown", handleUserKeyPress);

    return () => {
      node.removeEventListener("keydown", handleUserKeyPress);
    };
  }, []);
  const renderAssessmentSteps = (title, icon) => {
    return (
      <div className="assessment-step-component">
        <div className="assessment-step-image-wrapper">
          <img
            src={icon}
            className="flex-1 assessment-step-image-wrapper"
            style={{
              flex: 5,
              height: "120px",
            }}
          />
        </div>
        <div className="stats-data assessment-step-text-wrapper">
          <Text Tag="span" textType="p2" className="stats-description">
            {title}
          </Text>
        </div>
      </div>
    );
  };

  let pdf_url = process.env.REACT_APP_API_URI + "/public/user-manual.pdf";
  return (
    <Content history={props.history}>
      <div className="animated fadeIn main-page">
        <div
          ref={myRef}
          className="main-thread "
          id="main-thread"
          style={{ marginBottom: "131px" }}
        >
          <section className="flex-1" tabIndex="0" ref={fistElement}>
            <div className="flex-1 vertical section-inner-style">
              <TitleWithSubTitle
                title="فكرة عن"
                subtitle="منصة التقييم الذاتي"
                customStyle="get-certification-section"
                withAnimation
              />
              <AnimationLeftToRight>
                <Text
                  Tag="div"
                  textType="p2"
                  className="thread-text"
                  style={{ width: "90%" }}
                >
                  خلق بيئات عمل تحقّق أعلى درجات الامتثال لقرارات وأنظمة وزارة
                  الموارد البشرية والتنمية الاجتماعية، وذلك من خلال حلقةٍ
                  متكاملة تبدأ بتقييم المنشأة لنفسها ذاتيًّا لمعرفة نسبة
                  امتثالها للقوانين والأنظمة، ومن ثمّ تصحيح المنشأة لأوضاعها من
                  خلال فهم مواضع الالتزام والعمل على تصحيح المخالفات - إن وُجدت
                  - قبل زيارة المفتّش.
                </Text>
              </AnimationLeftToRight>
            </div>
          </section>
          <section
            className="flex-1 stats-section section-outer-style "
            tabIndex="1"
          >
            <div className="vertical flex-1 section-inner-style">
              <AnimationRightToLeft>
                <TitleWithSubTitle
                  title="خطوات"
                  subtitle="التقييم الذاتي"
                  customStyle="get-certification-section"
                />
              </AnimationRightToLeft>
              <div className="stats-wrapper stepsWrapper">
                <AnimationRightToLeft>
                  {renderAssessmentSteps(
                    "سجل دخولك",
                    "img/certificationStep1.svg"
                  )}
                </AnimationRightToLeft>
                <AnimationBottomToTop>
                  {renderAssessmentSteps(
                    "قيم منشأتك",
                    "img/certificationStep4.svg"
                  )}
                </AnimationBottomToTop>
                <AnimationLeftToRight>
                  {renderAssessmentSteps(
                    "أرسل تقييمك",
                    "img/certificationStep3.svg"
                  )}
                </AnimationLeftToRight>
              </div>
            </div>
          </section>
          <section className="flex-1 section-outer-style" tabIndex="2">
            <div className="flex-1 vertical section-inner-style">
              <AnimationRightToLeft>
                <TitleWithSubTitle
                  title="كيف تتم"
                  subtitle="مرحلة التقييم الذاتي"
                  customStyle="get-certification-section"
                />
              </AnimationRightToLeft>
              <AnimationLeftToRight>
                <Text
                  Tag="div"
                  textType="p2"
                  className="thread-text"
                  style={{ width: "90%" }}
                >
                  مرحلة التقييم الذاتي هي أولى مراحل المنصة والتي تُجري المنشآت
                  خلالها تقييمًا ذاتيًّا عن طريق الدخول إلى الموقع الإلكتروني
                  لـ"منصة التقييم الذاتي". والهدف من هذه المرحلة مساعدة المنشآت
                  في التعرف على مدى التزامها بقرارات وأنظمة وزارة الموارد
                  البشرية والتنمية الاجتماعية ورفع نسبة امتثالها. عند البدء
                  بالتقييم الذاتي يجب على المنشأة الإجابة بمصداقيّة حيث لا يوجد
                  خيار صحيح أو خاطئ، والهدف في هذه المرحلة هو مجرّد تقييم
                  المنشأة لذاتها، مع ملاحظة أنّه تم تبسيط نظام العمل وتلخيصه في
                  معايير قُسّمت على النحو التالي :
                </Text>
              </AnimationLeftToRight>
              <AnimationBottomToTop>
                <Text
                  Tag="div"
                  textType="p2"
                  className="thread-text"
                  style={{ width: "90%" }}
                >
                  - المعايير المتعلّقة بالمنشآت وعددها (4) معايير.
                  <br />- المعايير المتعلّقة بالعاملين وعددها (2) معايير.
                  <br />- المعايير المتعلّقة بتوظيف بعض الفئات الخاصّة وعددها
                  (2) معايير.
                </Text>
              </AnimationBottomToTop>
            </div>
          </section>
        </div>
      </div>
    </Content>
  );
};

export default AboutPage;
