import * as React from 'react';

export const HomeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.999"
    height="20"
    viewBox="0 0 19.999 20"
    {...props}
  >
    <g id="Home_icon" transform="translate(-54.273 -68)">
      <path
        id="Path"
        d="M2397.588,8533h-3.332a.558.558,0,0,1-.557-.557v-6.666a.555.555,0,0,0-.555-.555h-3.334a.555.555,0,0,0-.555.555v6.666a.558.558,0,0,1-.557.557h-3.332a2.781,2.781,0,0,1-2.778-2.777v-8.628l-.2.167a.559.559,0,0,1-.354.128.556.556,0,0,1-.353-.984l9.444-7.778a.556.556,0,0,1,.354-.127.55.55,0,0,1,.353.127l9.446,7.778a.556.556,0,0,1-.353.984.559.559,0,0,1-.354-.128l-.2-.167v8.628A2.781,2.781,0,0,1,2397.588,8533Zm-7.778-8.889h3.334a1.669,1.669,0,0,1,1.666,1.668v6.109h2.777a1.668,1.668,0,0,0,1.666-1.666v-9.444a.639.639,0,0,1,.009-.092l-7.787-6.411-7.784,6.411a.62.62,0,0,1,.007.092v9.444a1.669,1.669,0,0,0,1.668,1.666h2.778v-6.109A1.669,1.669,0,0,1,2389.811,8524.111Z"
        transform="translate(-2327.205 -8445)"
        fill="#14415a"
      />
    </g>
  </svg>
);
