import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as auth from "../../actions/authActions";
import PropTypes from "prop-types";

import "./spinner.css";

class Spinner extends React.Component {
  render() {
    const { children, auth, ajaxCall, actions, isLogin } = this.props;
    return (
      <div>
        {children}
        <div
          id="loadingSpinner"
          className={ajaxCall.isLoading ? "show" : ""}
        ></div>
      </div>
    );
  }
}

Spinner.propTypes = {
  actions: PropTypes.object.isRequired,
  ajaxCall: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  isLogin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    ajaxCall: state.ajaxStatus,
    auth: state.auth,
    isLogin: state.auth.token !== null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(auth, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Spinner);
