import React, { Suspense } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isCaliberValid } from "./ManageEstablishmentAssessmentPage";
import { Col, Row } from "reactstrap";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import {
  ANSWER_INVALID,
  ANSWER_YES,
} from "../../common/constants/caliberAnswers";
import Loader from "../Loader/Loader";
const EstablishmentCaliberCard = React.lazy(() =>
  import("./EstablishmentCaliberCard.js")
);
const { object, bool, array, func } = PropTypes;

class EstablishmentCaliberCategoryCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedDescriptionOrderNumber: 1,
      caliberAnswers: [],
    };
    this.calibersAnswers = this.calibersAnswers.bind(this);
    this.onOpenDescription = this.onOpenDescription.bind(this);
    this.renderEstablishmentCaliberCard =
      this.renderEstablishmentCaliberCard.bind(this);
  }

  calibersAnswers(caliberAnswer) {
    const { caliberAnswers } = this.state;
    const { onChangeAnswer } = this.props;
    let caliberAnswerIndex = null;

    caliberAnswers.map((item, i) => {
      if (item.caliberIndex === caliberAnswer.caliberIndex) {
        caliberAnswerIndex = i;
      }
    });
    let newArray;
    if (caliberAnswerIndex !== null) {
      newArray = [...caliberAnswers];
      newArray[caliberAnswerIndex] = caliberAnswer;
    } else {
      newArray = [...caliberAnswers, caliberAnswer];
    }
    this.setState({
      caliberAnswers: newArray,
    });

    onChangeAnswer(newArray);
  }

  onOpenDescription(
    orderNum,
    caliberCategoryOrderNumber,
    caliberAnswer,
    isOnCaliberClick,
    isAttachmentUpload,
    currentAttachmentId,
    totalCaliberCategoriesOrder,
    caliber
  ) {
    const { onOpenCaliber, attachments } = this.props;
    const caliberAttachments = attachments.filter(
      (att) => att.caliberId === caliber.id
    );
    let caliberAttachmentsValid;

    if (isAttachmentUpload) {
      caliberAttachmentsValid =
        caliberAttachments.filter(
          (att) =>
            att.id !== currentAttachmentId &&
            att.mandatory &&
            !att.submission_attachment_id.Valid
        ).length === 0;
    } else {
      caliberAttachmentsValid =
        caliberAttachments.filter(
          (att) => att.mandatory && !att.submission_attachment_id.Valid
        ).length === 0;
    }
    // caliber.answer is the answer saved in redux, caliberAnswer is the direct value recieved from the component
    if (
      (!isAttachmentUpload && caliberAttachmentsValid) ||
      (!isAttachmentUpload && caliberAnswer && caliberAnswer !== ANSWER_YES) ||
      (isAttachmentUpload &&
        caliberAttachmentsValid &&
        caliberAnswer &&
        caliberAnswer !== ANSWER_INVALID) ||
      (isAttachmentUpload &&
        caliberAttachmentsValid &&
        caliber.answer &&
        caliber.answer !== ANSWER_INVALID) ||
      isOnCaliberClick
    ) {
      this.setState({ openedDescriptionOrderNumber: orderNum });
      if (orderNum > totalCaliberCategoriesOrder)
        onOpenCaliber(caliberCategoryOrderNumber + 1);
      else onOpenCaliber(caliberCategoryOrderNumber);
    }
  }
  renderEstablishmentCaliberCard(caliber, i, totalCaliberCategoriesOrder) {
    const { orderNumber, editCaliberAttachments, isOpen } = this.props;
    const { openedDescriptionOrderNumber } = this.state;
    const isCardOpen = i + 1 === openedDescriptionOrderNumber && isOpen;

    return (
      <Suspense fallback={<Loader />} key={caliber.id}>
        <EstablishmentCaliberCard
          caliber={caliber}
          editCaliberAttachments={editCaliberAttachments}
          updateCollapsedStatus={this.updateCollapsedStatus}
          caliberCategoryOrderNumber={orderNumber}
          orderNumber={i + 1}
          isReturned={this.props.isReturned}
          isOpen={isCardOpen}
          auditStatus={this.props.auditStatus}
          onOpenDescription={(
            orderNum,
            caliberCategoryOrderNumber,
            answer = null,
            isOnCaliberClick = false,
            isAttachmentUpload = false,
            currentAttachmentId = null
          ) => {
            this.onOpenDescription(
              orderNum,
              caliberCategoryOrderNumber,
              answer,
              isOnCaliberClick,
              isAttachmentUpload,
              currentAttachmentId,
              totalCaliberCategoriesOrder,
              caliber
            );
          }}
          onChangeAnswer={(val) => this.calibersAnswers(val)}
        />
      </Suspense>
    );
  }
  render() {
    const { calibers, caliberCategory, attachments } = this.props;

    const currentCategoryCalibers = calibers.filter(
      (c) => c.caliberCategoryId === caliberCategory.id
    );
    const totalCaliberCategoriesOrder = currentCategoryCalibers.length;
    const caliberCards = currentCategoryCalibers.map((caliber, i) =>
      this.renderEstablishmentCaliberCard(
        caliber,
        i,
        totalCaliberCategoriesOrder
      )
    );

    const completedCalibers = currentCategoryCalibers.filter((caliber) => {
      const caliberAttachments = attachments.filter(
        (att) => att.caliberId === caliber.id
      );

      return isCaliberValid(caliber, caliberAttachments);
    });
    const completionPercentage = parseInt(
      (completedCalibers.length / currentCategoryCalibers.length) * 100
    );

    return (
      <Row className={`establishment-assessment-card `}>
        <Col className="establishment-assessment-card-col">
          <Row>
            <span className="establishment-assessment-card-title ">
              <Text
                textType="h7"
                isBold
                className="green-title font-18 font-bold "
                style={{ marginBottom: "15px" }}
              >
                نوع المعيار: {caliberCategory.name}
              </Text>
            </span>
          </Row>

          {caliberCategory.description !== "" && (
            <span>
              <Row>
                <span className=" establishment-assessment-card-title">
                  <Text textType="p4" isBold className="green-title font-16">
                    شرح المعيار:
                  </Text>
                </span>
              </Row>
              <Row>
                <Text
                  textType="p4"
                  className="establishment-profile-text font-16"
                >
                  {caliberCategory.description}
                </Text>
              </Row>
            </span>
          )}
          <br />
          <Row>
            <Col>{caliberCards}</Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

EstablishmentCaliberCategoryCard.propTypes = {
  caliberCategory: object.isRequired,
  editCaliberAttachments: func.isRequired,
  calibers: array.isRequired,
  showOnlyInvalid: bool,
  updateCollapsedStatus: func,
  onChangeAnswer: func,
};

const mapStateToProps = (state, ownProps) => ({
  calibers: state.SAform.calibers,
  attachments: state.SAform.attachments,
  ...ownProps,
});

export default connect(mapStateToProps)(EstablishmentCaliberCategoryCard);
