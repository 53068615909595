export default [
  // المعايير العامة للعاملين 
  {
      id: 1,
      label: "الإجازات",
      parentId: 1,
      description: `يفصل في هذا المعيار الاجازات المستحقة للعاملين وضوابطها وشروطها واحكامها`,
    },
  {
        id: 2,
        label: "الأجور",
        parentId: 1,
        description: `يوضح المعيار الفترات والمواعيد التي يتم دفع أجور العاملين فيها، وينبغي على أصحاب العمل دفع أجور العاملين في حسابات العمال عن طريق البنوك المعتمدة ورفع ملف حماية الأجور بشكل شهري`,
    },
    {
      id: 3,
      label: "علاقات العمل",
      parentId: 1,
      description: `يوضح المعيار التزام بتوثيق عقود عامليها عبر منصة قوى (www.qiwa.sa) بدايةً من تسجيل العقد وتحديث بيانات المهن والأجور والمؤهلات إلى إنهاء أو إنتهاء العلاقة التعاقدية`,
    },
    // معايير عامة للمنشآت
    {
      id: 4,
      label: "أحكام عامة",
      parentId: 2,
      description:
        "يتضمن هذا المعيار الاحكام العامة التي ينبغي للمنشأة الالتزام بها مثل استعمال اللغة العربية وإعداد لائحة تنظيم العمل، الواجبات اللازم اتباعها من قبل صاحب العمل والعاملين وقواعد التأديب التي تضمن حقوق الطرفين.",
    },
    {
    id: 5,
    label: `قواعد الاستقدام وتقديم الخدمات العمالية للغير`,
    parentId: 2,
    description: `الواجبات والاشتراطات والضوابط لممارسة نشاط توظيف السعوديين ونشاط الاستقدام وتقديم الخدمات العمالية للغير، مثل وجوب الحصول على الترخيص المناسب لنوع النشاط والالتزام بنماذج العقود الموحدة التي تضعها الوزارة `,
   },
   {
     id: 6,
     label: "الوقاية من مخاطر العمل",
     parentId: 2,
     description: `وتتضمن الاحكام العامة للحفاظ على سلامة العاملين وتوفير بيئة عمل آمنة لهم، مثل النظافة العامة لمقر العمل واتخاذ الاحتياطات اللازمة للوقاية من الحرائق والزام العاملين باتخاذ التدابير الوقائية لحمايتهم من اخطار مهنتهم`,
   },
   {
     id: 7,
     label: "الخدمات الصحية والاجتماعية",
     parentId: 2,
     description: `يجب على صاحب العمل توفير الرعاية الصحية للعامل وأسرته وذلك بحسب نظام التأمين التعاوني الصحي ، وتوفير الخدمات الصحية والاجتماعية مثل توفير المساكن والمخيمات والوجبات الغذائية للعمال الذين يؤدون عملا في أماكن بعيدة عن العمران`,
   },
   // معايير خاصة للعاملين 
   {
    id: 8,
    label: "توظيف غير السعوديين",
    parentId: 3,
    description: `أحكام توظيف غير السعوديين، مثل اشتراط حصول العامل على رخصة عمل، وعدم ترك العامل يعمل في غير المهنة المدونة في رخصة عمله أو تشغيل غير السعوديين في مهن مقصورة على السعوديين`,
   },
   {
    id: 9,
    label: "أحكام عامة",
    parentId: 3,
    description:
     "أحكام عامة في توظيف العاملين السعوديين والغير سعوديين",
  },
];
