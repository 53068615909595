import React from "react";

import PropTypes from "prop-types";
import "./checkbox.scss";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }
  handleOnChange(e) {
    const { data, onChange } = this.props;

    onChange(e.target.checked, data);
  }

  render() {
    const { checked, disabled, id } = this.props;

    return (
      <div className="checkbox-group">
        {checked && (
          <div className="check-mark-icon-wrapper">
            <img
              src={"img/checkMark.svg"}
              style={{ width: "20px", height: "17px" }}
            />
          </div>
        )}
        <div style={{ zIndex: 0 }}>
          <input
            className={"p-0 m-0"}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={this.handleOnChange}
            id={id}
          />
        </div>
      </div>
    );
  }
}
Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  data: PropTypes.any,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};
Checkbox.defaultProps = {
  data: null,
  disabled: false,
};

export default Checkbox;
