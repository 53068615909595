import initialState from "./initialState";
import {
  CREATE_TRAINING_FAILURE,
  CREATE_TRAINING_SUCCESS,
  CREATE_TRAINING,
  DELETE_TRAINING_FAILURE,
  DELETE_TRAINING_SUCCESS,
  DELETE_TRAINING,
  FETCH_TRAINING_FAILURE,
  FETCH_TRAINING_SUCCESS,
  FETCH_TRAINING,
  FETCH_TRAININGS_FAILURE,
  FETCH_TRAININGS_SUCCESS,
  FETCH_TRAININGS,
  UPDATE_TRAINING_FAILURE,
  UPDATE_TRAINING_SUCCESS,
  UPDATE_TRAINING,
  LOAD_TRAININGS_SUCCESS,
  SEARCH_TRAININGS_SUCCESS,
} from "../actions/actionTypes";

export default function trainings(state = initialState.trainings, action) {
  switch (action.type) {
    // case types.LOAD_ESTABLISHMENTTRAININGS_SUCCESS:
    //   return Object.assign({}, state, {
    //     trainings: action.establishmentTrainings
    //   });

    case CREATE_TRAINING:
      return {
        ...state,
        trainingCreating: true,
        trainingCreatingError: null,
        trainingData: {
          ...action.payload.training,
          ...action.payload.trainingData,
        },
      };

    case CREATE_TRAINING_FAILURE:
      return {
        ...state,
        trainingCreating: false,
        trainingCreatingError: action.payload.error,
      };

    case CREATE_TRAINING_SUCCESS:
      return {
        ...state,
        training: action.payload.training,
        trainingCreating: false,
      };

    case DELETE_TRAINING:
      return {
        ...state,
        trainingDeleting: true,
        trainingDeletingError: null,
      };

    case DELETE_TRAINING_FAILURE:
      return {
        ...state,
        trainingDeleting: false,
        trainingDeletingError: action.payload.error,
      };

    case DELETE_TRAINING_SUCCESS:
      return {
        ...state,
        trainingDeleting: false,
      };

    case FETCH_TRAINING:
      return {
        ...state,
        training: null,
        trainingFetching: true,
        trainingFetchingError: null,
      };

    case FETCH_TRAINING_FAILURE:
      return {
        ...state,
        trainingFetching: false,
        trainingFetchingError: action.payload.error,
      };

    case FETCH_TRAINING_SUCCESS:
      return {
        ...state,
        training: action.payload.training,
        trainingFetching: false,
      };

    case FETCH_TRAININGS:
      return {
        ...state,
        trainingsListFetching: true,
        trainingsListFetchingError: null,
      };

    case FETCH_TRAININGS_FAILURE:
      return {
        ...state,
        trainingsListFetching: false,
        trainingsListFetchingError: action.payload.error,
      };

    case FETCH_TRAININGS_SUCCESS:
      return {
        ...state,
        trainingsList: action.payload.trainings.slice(),
        trainingsListFetching: false,
        trainingsTotal: action.payload.total,
      };

    case LOAD_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings: action.trainings,
      };

    case SEARCH_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings: action.trainings,
      };

    // case types.CREATE_TRAINING_SUCCESS:
    //   return {
    //     ...state,
    //     trainings: [
    //       ...state.trainings,
    //       Object.assign({}, action.training)
    //     ],
    //   };

    // case types.UPDATE_TRAINING_SUCCESS:
    //   return {
    //     ...state,
    //     trainings: [
    //       ...state.trainings.filter(training => training.id !== action.training.id),
    //       Object.assign({}, action.training)
    //     ],
    //   };

    case UPDATE_TRAINING:
      return {
        ...state,
        trainingUpdating: true,
        trainingUpdatingError: null,
      };

    case UPDATE_TRAINING_FAILURE:
      return {
        ...state,
        trainingUpdating: false,
        trainingUpdatingError: action.payload.error,
      };

    case UPDATE_TRAINING_SUCCESS:
      return {
        ...state,
        training: action.payload.training,
        trainingUpdating: false,
      };

    default:
      return state;
  }
}
