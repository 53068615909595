export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";
export const END_AJAX_CALL = "END_AJAX_CALL";
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";

export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOAD_USERINFO_SUCCESS = "LOAD_USERINFO_SUCCESS";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";


export const LOAD_ESTABLISHMENTPROFILE_SUCCESS =
  "LOAD_ESTABLISHMENTPROFILE_SUCCESS";
export const LOAD_ESTABLISHMENTS_SUCCESS = "LOAD_ESTABLISHMENTS_SUCCESS";
export const LOAD_ESTABLISHMENTACTIVEASSESSMENTS_SUCCESS =
  "LOAD_ESTABLISHMENTACTIVEASSESSMENTS_SUCCESS";
export const LOAD_ESTABLISHMENTACTIVEASSESSMENT_SUCCESS =
  "LOAD_ESTABLISHMENTACTIVEASSESSMENT_SUCCESS";

export const LOAD_ESTABLISHMENTACTIVEASSESSMENTS_TOTAL_SUCCESS =
  "LOAD_ESTABLISHMENTACTIVEASSESSMENTS_TOTAL_SUCCESS";
export const RESET_ESTABLISHMENTACTIVEASSESSMENTS =
  "RESET_ESTABLISHMENTACTIVEASSESSMENTS";
export const LOAD_ESTABLISHMENTASSESSMENTS_SUCCESS =
  "LOAD_ESTABLISHMENTASSESSMENTS_SUCCESS";
export const GET_ESTABLISHMENTSELFASSESSMENTRESULT_SUCCESS =
  "GET_ESTABLISHMENTSELFASSESSMENTRESULT_SUCCESS";
export const GET_MAJORVIOLATIONRESULT_SUCCESS =
  "GET_MAJORVIOLATIONRESULT_SUCCESS";
export const UPDATE_ESTABLISHMENT_PROFILE = "GET_MAJORVIOLATIONRESULT_SUCCESS";
export const SAVE_ESTABLISHMENT_PROFILE_SUCCESS =
  "SAVE_ESTABLISHMENT_PROFILE_SUCCESS";
export const SAVE_ESTABLISHMENT_PROFILE_FAIL =
  "SAVE_ESTABLISHMENT_PROFILE_FAIL";

export const LOAD_ESTABLISHMENTSELFASSESSMENTREPORTRESULT_SUCCESS =
  "LOAD_ESTABLISHMENTSELFASSESSMENTREPORTRESULT_SUCCESS";

export const LOAD_ESTABLISHMENTCATEGORIES_SUCCESS =
  "LOAD_ESTABLISHMENTCATEGORIES_SUCCESS";
export const LOAD_MAINESTABLISHMENTCATEGORIES_SUCCESS =
  "LOAD_MAINESTABLISHMENTCATEGORIES_SUCCESS";
export const LOAD_SELECTESTABLISHMENTCATEGORIES_SUCCESS =
  "LOAD_SELECTESTABLISHMENTCATEGORIES_SUCCESS";
export const CREATE_ESTABLISHMENTCATEGORY_SUCCESS =
  "CREATE_ESTABLISHMENTCATEGORY_SUCCESS";
export const UPDATE_ESTABLISHMENTCATEGORY_SUCCESS =
  "UPDATE_ESTABLISHMENTCATEGORY_SUCCESS";

export const LOAD_CALIBERCATEGORIES_SUCCESS = "LOAD_CALIBERCATEGORIES_SUCCESS";
export const LOAD_CALIBERSPECIALIZATIONS_SUCCESS =
  "LOAD_CALIBERSPECIALIZATIONS_SUCCESS";
export const CREATE_CALIBERCATEGORY_SUCCESS = "CREATE_CALIBERCATEGORY_SUCCESS";
export const UPDATE_CALIBERCATEGORY_SUCCESS = "UPDATE_CALIBERCATEGORY_SUCCESS";

export const LOAD_CALIBERS_SUCCESS = "LOAD_CALIBERS_SUCCESS";
export const SEARCH_CALIBERS_SUCCESS = "SEARCH_CALIBERS_SUCCESS";
export const CREATE_CALIBER_SUCCESS = "CREATE_CALIBER_SUCCESS";
export const UPDATE_CALIBER_SUCCESS = "UPDATE_CALIBER_SUCCESS";

export const LOAD_ATTACHMENTCATEGORIES_SUCCESS =
  "LOAD_ATTACHMENTCATEGORIES_SUCCESS";
export const CREATE_ATTACHMENTCATEGORY_SUCCESS =
  "CREATE_ATTACHMENTCATEGORY_SUCCESS";
export const UPDATE_ATTACHMENTCATEGORY_SUCCESS =
  "UPDATE_ATTACHMENTCATEGORY_SUCCESS";

export const LOAD_QUESTIONCATEGORIES_SUCCESS =
  "LOAD_QUESTIONCATEGORIES_SUCCESS";
export const CREATE_QUESTIONCATEGORY_SUCCESS =
  "CREATE_QUESTIONCATEGORY_SUCCESS";
export const UPDATE_QUESTIONCATEGORY_SUCCESS =
  "UPDATE_QUESTIONCATEGORY_SUCCESS";

export const LOAD_VIOLATIONCATEGORIES_SUCCESS =
  "LOAD_VIOLATIONCATEGORIES_SUCCESS";
export const CREATE_VIOLATIONCATEGORY_SUCCESS =
  "CREATE_VIOLATIONCATEGORY_SUCCESS";
export const UPDATE_VIOLATIONCATEGORY_SUCCESS =
  "UPDATE_VIOLATIONCATEGORY_SUCCESS";

export const LOAD_CALIBERSUBJECTS_SUCCESS = "LOAD_CALIBERSUBJECTS_SUCCESS";
export const CREATE_CALIBERSUBJECT_SUCCESS = "CREATE_CALIBERSUBJECT_SUCCESS";
export const UPDATE_CALIBERSUBJECT_SUCCESS = "UPDATE_CALIBERSUBJECT_SUCCESS";

export const LOAD_ASSESSMENTS_SUCCESS = "LOAD_ASSESSMENTS_SUCCESS";
export const LOAD_SELECTEDASSESSMENTS_SUCCESS =
  "LOAD_SELECTEDASSESSMENTS_SUCCESS";
export const CREATE_ASSESSMENT_SUCCESS = "CREATE_ASSESSMENT_SUCCESS";
export const UPDATE_ASSESSMENT_SUCCESS = "UPDATE_ASSESSMENT_SUCCESS";

export const LOAD_ESTABLISHMENTSPECIALIZATIONS_SUCCESS =
  "ESTABLISHMENTSPECIALIZATION";
export const CREATE_ESTABLISHMENTSPECIALIZATION_SUCCESS =
  "CREATE_ESTABLISHMENTSPECIALIZATION_SUCCESS";
export const UPDATE_ESTABLISHMENTSPECIALIZATION_SUCCESS =
  "UPDATE_ESTABLISHMENTSPECIALIZATION_SUCCESS";

export const LOAD_PERIODSELFASSESSMENTS_SUCCESS =
  "LOAD_PERIODSELFASSESSMENTS_SUCCESS";
export const SEARCH_PERIODSELFASSESSMENTS_SUCCESS =
  "SEARCH_PERIODSELFASSESSMENTS_SUCCESS";
export const CREATE_PERIODSELFASSESSMENT_SUCCESS =
  "CREATE_PERIODSELFASSESSMENT_SUCCESS";
export const UPDATE_PERIODSELFASSESSMENT_SUCCESS =
  "UPDATE_PERIODSELFASSESSMENT_SUCCESS";

export const LOAD_PERIODSELFASSESSMENTSTATUSES_SUCCESS =
  "LOAD_PERIODSELFASSESSMENTSTATUSES_SUCCESS";

export const FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT =
  "FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT";
export const FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_FAILURE =
  "FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_FAILURE";
export const FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_SUCCESS =
  "FETCH_ESTABLISHMENT_SELF_ASSESSMENT_RESULT_SUCCESS";
export const CHANGE_RESULT_FILTER = "CHANGE_RESULT_FILTER";
export const FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT =
  "FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT";
export const FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_SUCCESS =
  "FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_SUCCESS";
export const FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_FAILURE =
  "FETCH_ESTABLISHMENT_SELF_ASSESSMENT_FAST_TRACK_RESULT_FAILURE";

// SAform
export const SA_FORM_UPDATE = "SA_FORM_UPDATE";
export const SA_FORM_SET_PERIOD = "SA_FORM_SET_PERIOD";
export const SA_FORM_RESET_PERIOD = "SA_FORM_RESET_PERIOD";
export const SA_FORM_UPDATE_LEGAL_AGREEMENT = "SA_FORM_UPDATE_LEGAL_AGREEMENT";

export const CONFIRM_USER_INFO_SUCCESS = "CONFIRM_USER_INFO_SUCCESS";

export const FETCH_DURATION_SUCCESS = "FETCH_DURATION_SUCCESS";
export const FETCH_DURATION_FAIL = "FETCH_DURATION_FAIL";
export const SA_FORM_PERIOD_LOADING = "SA_FORM_PERIOD_LOADING";
export const SA_FORM_PERIOD_STOP_LOADING = "SA_FORM_PERIOD_STOP_LOADING";

export const FETCH_DOWNLOAD_FILE = "FETCH_DOWNLOAD_FILE";

export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  "MARK_NOTIFICATION_AS_READ_SUCCESS";

export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const LOAD_NOTIFICATIONS_SUCCESS = "LOAD_NOTIFICATIONS_SUCCESS";
export const LOAD_NOTIFICATIONS_FAILURE = "LOAD_NOTIFICATIONS_FAILURE";
export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";

export const LOAD_PAYMENTSERVICES_SUCCESS = "LOAD_PAYMENTSERVICES_SUCCESS";
export const LOAD_PAYMENTSERVICESBYTYPE_SUCCESS = "LOAD_PAYMENTSERVICESBYTYPE_SUCCESS";
export const LOADING_PAYMENT_SERVICES = "LOADING_PAYMENT_SERVICES";
export const LOAD_PAYMENT_SERVICES_FAIL = "LOAD_PAYMENT_SERVICES_FAIL";

export const LOAD_TRAININGPAYMENTSERVICES_SUCCESS =
  "LOAD_TRAININGPAYMENTSERVICES_SUCCESS";
export const LOAD_PAYMENTBILLS_SUCCESS = "LOAD_PAYMENTBILLS_SUCCESS";
export const LOAD_ALL_ESTABLISHMENT_BILLS_SUCCESS =
  "LOAD_ALL_ESTABLISHMENT_BILLS_SUCCESS";

export const LOAD_ESTABLISHMENTTRAININGS_SUCCESS =
  "LOAD_ESTABLISHMENTTRAININGS_SUCCESS";

export const CREATE_TRAINING = "app/Trainings/CREATE_TRAINING";
export const CREATE_TRAINING_FAILURE = "app/Trainings/CREATE_TRAINING_FAILURE";
export const CREATE_TRAINING_SUCCESS = "app/Trainings/CREATE_TRAINING_SUCCESS";
export const DELETE_TRAINING = "app/Trainings/DELETE_TRAINING";
export const DELETE_TRAINING_FAILURE = "app/Trainings/DELETE_TRAINING_FAILURE";
export const DELETE_TRAINING_SUCCESS = "app/Trainings/DELETE_TRAINING_SUCCESS";
export const FETCH_TRAINING = "app/Trainings/FETCH_TRAINING";
export const FETCH_TRAINING_FAILURE = "app/Trainings/FETCH_TRAINING_FAILURE";
export const FETCH_TRAINING_SUCCESS = "app/Trainings/FETCH_TRAINING_SUCCESS";
export const FETCH_TRAININGS = "app/Trainings/FETCH_TRAININGS";
export const FETCH_TRAININGS_FAILURE = "app/Trainings/FETCH_TRAININGS_FAILURE";
export const FETCH_TRAININGS_SUCCESS = "app/Trainings/FETCH_TRAININGS_SUCCESS";

export const LOAD_TRAININGS_SUCCESS = "LOAD_TRAININGS_SUCCESS";
export const SEARCH_TRAININGS_SUCCESS = "SEARCH_TRAININGS_SUCCESS";
export const UPDATE_TRAINING = "app/Trainings/UPDATE_TRAINING";
export const UPDATE_TRAINING_FAILURE = "app/Trainings/UPDATE_TRAINING_FAILURE";
export const UPDATE_TRAINING_SUCCESS = "app/Trainings/UPDATE_TRAINING_SUCCESS";

export const FETCH_TRAINING_SUBJECTS_FAILURE =
  "app/Trainings/FETCH_TRAINING_SUBJECTS_FAILURE";
export const FETCH_TRAINING_SUBJECTS_SUCCESS =
  "app/Trainings/FETCH_TRAINING_SUBJECTS_SUCCESS";
export const FETCH_TRAINING_SUBJECTS = "app/Trainings/FETCH_TRAINING_SUBJECTS";
export const FETCH_TRAINING_SUBJECT_FAILURE =
  "app/Trainings/FETCH_TRAINING_SUBJECT_FAILURE";
export const FETCH_TRAINING_SUBJECT_SUCCESS =
  "app/Trainings/FETCH_TRAINING_SUBJECT_SUCCESS";
export const FETCH_TRAINING_SUBJECT = "app/Trainings/FETCH_TRAINING_SUBJECT";

export const UNIFIED_START_LOADING = "UNIFIED_START_LOADING";
export const UNIFIED_STOP_LOADING = "UNIFIED_STOP_LOADING";
export const UNIFIED_FETCH_ESTABLISHMENT_SUCCESS =
  "UNIFIED_FETCH_ESTABLISHMENT_SUCCESS";
export const UNIFIED_SAVE_SELECTED_ESTABLISHMENTS_SUCCESS =
  "UNIFIED_SAVE_SELECTED_ESTABLISHMENTS_SUCCESS";
export const UNIFIED_SET_ERROR = "UNIFIED_SET_ERROR";
export const UNIFIED_SELECT_ESTABLISHMENT = "UNIFIED_SELECT_ESTABLISHMENT";
export const UNIFIED_DESELECT_ESTABLISHMENT = "UNIFIED_DESELECT_ESTABLISHMENT";
export const UNIFIED_CLEAR_SELECTED_ESTABLISHMENT =
  "UNIFIED_CLEAR_SELECTED_ESTABLISHMENT";
export const UNIFIED_RESET = "UNIFIED_RESET";
export const UNIFIED_SET_GRACE_PERIOD_STATUS =
  "UNIFIED_SET_GRACE_PERIOD_STATUS";
export const UNIFIED_GET_GRACE_PERIOD_STATUS =
  "UNIFIED_GET_GRACE_PERIOD_STATUS";

export const CERTIFICATE_SHOW_CERTIFICATE = "CERTIFICATE_SHOW_CERTIFICATE";
export const CERTIFICATE_HIDE_CERTIFICATE = "CERTIFICATE_HIDE_CERTIFICATE";
export const CERTIFICATE_START_LOADING = "CERTIFICATE_START_LOADING";
export const CERTIFICATE_STOP_LOADING = "CERTIFICATE_STOP_LOADING";
export const CERTIFICATE_SET_ERROR = "CERTIFICATE_SET_ERROR";
export const UNIFIED_FETCH_FAST_TRACK_RESULT_SUCCESS =
  "UNIFIED_FETCH_FAST_TRACK_RESULT_SUCCESS";

export const FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION =
  "FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION";
export const FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_SUCCESS =
  "FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_SUCCESS";
export const FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_FAILURE =
  "FETCH_ESTABLISHMENT_SELF_ASSESSMENT_DURATION_FAILURE";
export const UPDATE_LOCATION_HISTORY = "UPDATE_LOCATION_HISTORY";

export const BILL_INFO_START_LOADING = "BILL_INFO_START_LOADING";
export const BILL_INFO_STOP_LOADING = "BILL_INFO_STOP_LOADING";
export const BILL_INFO_SET_ERROR = "BILL_INFO_SET_ERROR";

export const HIDE_BLOCKED_POP_UP = "HIDE_BLOCKED_POP_UP";

export const FAST_TRACK_BRANCHES_START_LOADING =
  "FAST_TRACK_BRANCHES_START_LOADING";
export const FAST_TRACK_BRANCHES_STOP_LOADING =
  "FAST_TRACK_BRANCHES_STOP_LOADING";
export const FAST_TRACK_BRANCHES_SET_ERROR = "FAST_TRACK_BRANCHES_SET_ERROR";
export const FAST_TRACK_BRANCHES_ESTABLISHMENT_SUCCESS =
  "FAST_TRACK_BRANCHES_ESTABLISHMENT_SUCCESS";
export const LOADING_SUBMIT_PAYMENT = "LOADING_SUBMIT_PAYMENT";
export const END_LOADING_SUBMIT_PAYMENT = "END_LOADING_SUBMIT_PAYMENT";

export const IMS_INFO_START_LOADING = "IMS_INFO_START_LOADING";
export const IMS_INFO_STOP_LOADING = "IMS_INFO_STOP_LOADING";
export const IMS_INFO_SET_ERROR = "IMS_INFO_SET_ERROR";

export const SAVE_SELECTED_DURATION = "SAVE_SELECTED_DURATION";
export const RESET_SELECTED_DURATION = "RESET_SELECTED_DURATION";

// ** ACCESSIBILITY Actions **
export const SET_ACCESSIBILITY = "SET_ACCESSIBILITY";
export const SAVE_SELECTED_TRACK_TYPE = "SAVE_SELECTED_TRACK_TYPE";
