import React from "react";
import PropTypes from "prop-types";

export const IconPreviousAssessment = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="22.5" viewBox="0 0 23.5 22.5">
  <g id="Group_165511" data-name="Group 165511" transform="translate(-19803.25 -13472.834)">
    <path id="Shape" d="M3.095,19a.528.528,0,1,0,0-1.056H2.657a1.589,1.589,0,0,1-1.594-1.583V2.639A1.589,1.589,0,0,1,2.657,1.056H14.345a1.589,1.589,0,0,1,1.594,1.584v.278a.531.531,0,0,0,1.063,0V2.639A2.648,2.648,0,0,0,14.345,0H2.657A2.648,2.648,0,0,0,0,2.639V16.361A2.648,2.648,0,0,0,2.657,19Z" transform="translate(19803.5 13473.084)" fill="#14415a" stroke="#14415a" stroke-width="0.5"/>
    <path id="Shape_-_Outline" data-name="Shape - Outline" d="M3.095,19.25H2.657A2.9,2.9,0,0,1-.25,16.361V2.639A2.9,2.9,0,0,1,2.657-.25H14.345a2.9,2.9,0,0,1,2.907,2.889v.278a.781.781,0,0,1-1.563,0V2.639a1.34,1.34,0,0,0-1.344-1.334H2.657A1.34,1.34,0,0,0,1.313,2.639V16.361a1.34,1.34,0,0,0,1.344,1.333h.438a.778.778,0,1,1,0,1.556ZM2.657.25A2.4,2.4,0,0,0,.25,2.639V16.361A2.4,2.4,0,0,0,2.657,18.75h.438a.278.278,0,1,0,0-.556H2.657A1.841,1.841,0,0,1,.813,16.361V2.639A1.841,1.841,0,0,1,2.657.806H14.345a1.841,1.841,0,0,1,1.844,1.834v.278a.281.281,0,0,0,.563,0V2.639A2.4,2.4,0,0,0,14.345.25Z" transform="translate(19803.5 13473.084)" fill="#14415a"/>
    <path id="Shape-2" data-name="Shape" d="M10.095,19a.528.528,0,1,0,0-1.056H2.657a1.589,1.589,0,0,1-1.594-1.583V2.639A1.589,1.589,0,0,1,2.657,1.056H14.345a1.589,1.589,0,0,1,1.594,1.584v8.278a.531.531,0,0,0,1.063,0V2.639A2.648,2.648,0,0,0,14.345,0H2.657A2.648,2.648,0,0,0,0,2.639V16.361A2.648,2.648,0,0,0,2.657,19Zm2.125-6.334a.528.528,0,1,0,0-1.056H6.907a.528.528,0,1,0,0,1.056Zm0-3.167a.528.528,0,1,0,0-1.056H4.782a.528.528,0,1,0,0,1.056ZM10.095,6.333a.528.528,0,1,0,0-1.056H4.782a.528.528,0,1,0,0,1.056Z" transform="translate(19806.5 13476.084)" fill="#14415a" stroke="#14415a" stroke-width="0.5"/>
    <path id="Shape_-_Outline-2" data-name="Shape - Outline" d="M10.095,19.25H2.657A2.9,2.9,0,0,1-.25,16.361V2.639A2.9,2.9,0,0,1,2.657-.25H14.345a2.9,2.9,0,0,1,2.907,2.889v8.278a.781.781,0,0,1-1.563,0V2.639a1.34,1.34,0,0,0-1.344-1.334H2.657A1.34,1.34,0,0,0,1.313,2.639V16.361a1.34,1.34,0,0,0,1.344,1.333h7.438a.778.778,0,1,1,0,1.556ZM2.657.25A2.4,2.4,0,0,0,.25,2.639V16.361A2.4,2.4,0,0,0,2.657,18.75h7.438a.278.278,0,1,0,0-.556H2.657A1.841,1.841,0,0,1,.813,16.361V2.639A1.841,1.841,0,0,1,2.657.806H14.345a1.841,1.841,0,0,1,1.844,1.834v8.278a.281.281,0,0,0,.563,0V2.639A2.4,2.4,0,0,0,14.345.25Zm11.5,18.977A.776.776,0,0,1,13.6,19l-2.125-2.111a.775.775,0,0,1,0-1.1.785.785,0,0,1,1.1,0l1.573,1.564,4.761-4.73a.781.781,0,0,1,1.333.552.769.769,0,0,1-.23.55L14.708,19A.778.778,0,0,1,14.155,19.227Zm0-1.171C19.721,13.315,14.1,18.108,14.155,18.055ZM12.22,12.916H6.907a.778.778,0,1,1,0-1.556H12.22a.778.778,0,1,1,0,1.556ZM6.907,11.861a.278.278,0,1,0,0,.556H12.22a.278.278,0,1,0,0-.556ZM12.22,9.75H4.782a.778.778,0,1,1,0-1.556H12.22a.778.778,0,1,1,0,1.556ZM4.782,8.694a.278.278,0,1,0,0,.556H12.22a.278.278,0,1,0,0-.556Zm5.313-2.111H4.782a.778.778,0,1,1,0-1.556h5.313a.778.778,0,1,1,0,1.556ZM4.782,5.528a.278.278,0,1,0,0,.556h5.313a.278.278,0,1,0,0-.556Z" transform="translate(19806.5 13476.084)" fill="#14415a"/>
  </g>
</svg>
);

export default IconPreviousAssessment;