import EstablishmentAssessmentApi from "../api/mockEstablishmentAssessmentsApi";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallError, endAjaxCall } from "./ajaxStatusActions";
import request from "../common/request";
import {
  ASSESSMENT_TYPE_BASIC,
  ASSESSMENT_TYPE_FAST_TRACK,
  ASSESSMENT_TYPE_RE_ASSESSMENT,
  STATUS_ACTIVE,
  STATUS_ENABLED,
} from "../common/constants/assessmentStatuses";
import { loadEstablishments } from "../actions/establishmentProfileActions";

export function loadEstablishmentActiveAssessmentsSuccess(
  establishmentAssessments
) {
  return {
    type: types.LOAD_ESTABLISHMENTACTIVEASSESSMENTS_SUCCESS,
    establishmentAssessments,
  };
}
export function loadEstablishmentActiveAssessmentSuccess(
  establishmentActiveAssessment
) {
  return {
    type: types.LOAD_ESTABLISHMENTACTIVEASSESSMENT_SUCCESS,
    establishmentActiveAssessment,
  };
}

export function loadEstablishmentActiveAssessmentsTotalSuccess(total) {
  return {
    type: types.LOAD_ESTABLISHMENTACTIVEASSESSMENTS_TOTAL_SUCCESS,
    total,
  };
}

export function loadEstablishmentAssessmentsSuccess(establishmentAssessments) {
  return {
    type: types.LOAD_ESTABLISHMENTASSESSMENTS_SUCCESS,
    establishmentAssessments,
  };
}

export function resetEstablishmentActiveAssessments() {
  return { type: types.RESET_ESTABLISHMENTACTIVEASSESSMENTS };
}

export function loadEstablishmentAciveAssessment(
  establishmentId,
  params = {},
  assessmentType = ASSESSMENT_TYPE_BASIC,
  ASSESSMENT_TYPE_FAST_TRACK
) {
  return function (dispatch) {
    const queryString = new URLSearchParams(params).toString();

    dispatch(beginAjaxCall());
    // return EstablishmentAssessmentApi.getPeriodSelfAssessment()
    //   .then(establishmentAssessment => ({ periods: [establishmentAssessment] }))
    return request(
      `/v1/establishments/${establishmentId}/durations?${queryString}`
    )
      .then((res) => res.json())
      .then((establishmentAssessments) => {
        if (params.get === "previous") {
          dispatch(
            loadEstablishmentActiveAssessmentsTotalSuccess(
              establishmentAssessments.total_results
            )
          );
          dispatch(
            loadEstablishmentActiveAssessmentsSuccess(
              establishmentAssessments.periods
                ? establishmentAssessments.periods
                : []
            )
          );
        } else {
          let activeAssessment;
          if (establishmentAssessments?.periods?.length <= 1) {
            activeAssessment = establishmentAssessments.periods
              ? establishmentAssessments.periods[0]
              : null;
          } else if (establishmentAssessments?.periods?.length > 1) {
            activeAssessment =
              establishmentAssessments.periods[
                establishmentAssessments.periods.length - 1
              ];
          }
          activeAssessment &&
            dispatch(
              loadEstablishmentActiveAssessmentSuccess(activeAssessment)
            );
          dispatch(endAjaxCall());
          return {
            haveActivPeriod: establishmentAssessments.have_active_period,
            period: activeAssessment,
            periods: establishmentAssessments.periods,
          };
        }
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function loadEstablishmentPreviousAssessments(
  establishmentId,
  params = {}
) {
  return function (dispatch) {
    const queryString = new URLSearchParams(params).toString();
    dispatch(beginAjaxCall());
    return request(
      `/v1/establishments/${establishmentId}/previous_assessments?${queryString}`
    )
      .then((res) => res.json())
      .then((establishmentAssessments) => {
        dispatch(
          loadEstablishmentActiveAssessmentsTotalSuccess(
            establishmentAssessments.total_results
          )
        );
        dispatch(
          loadEstablishmentActiveAssessmentsSuccess(
            establishmentAssessments.periods
              ? establishmentAssessments.periods
              : []
          )
        );
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function loadEstablishmentCurrentSpecialAssessments(establishmentId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request(
      "/v1/establishments/" +
        establishmentId +
        "/current_special_track_assessment"
    )
      .then((res) => res.json())
      .then((currentSpecialTrack) => {
        dispatch(
          loadEstablishmentAssessmentsSuccess(
            currentSpecialTrack ? currentSpecialTrack : null
          )
        );
        dispatch(endAjaxCall());
        return {
          spacialTrack: currentSpecialTrack,
        };
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function loadEstablishmentCurrentBasicAssessments(establishmentId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request(
      "/v1/establishments/" +
        establishmentId +
        "/current_basic_track_assessment"
    )
      .then((res) => res.json())
      .then((currentBasicTrack) => {
        dispatch(
          loadEstablishmentAssessmentsSuccess(
            currentBasicTrack ? currentBasicTrack : null
          )
        );
        dispatch(endAjaxCall());
        return {
          basicTrack: currentBasicTrack,
        };
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function loadEstablishmentAssessment(establishmentId) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request("/v1/establishments/" + establishmentId + "/durations")
      .then((res) => res.json())
      .then((establishmentAssessments) => {
        dispatch(
          loadEstablishmentAssessmentsSuccess(
            establishmentAssessments.periods
              ? establishmentAssessments.periods
              : []
          )
        );

        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function savePeriodAssessment(periodAssessment) {
  return submit(periodAssessment);
}

export function update(periodAssessment) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request("/v1/establishments/assessments", "PUT", {
      assessment: periodAssessment.assessment,
    })
      .then(() => {
        dispatch(loadEstablishmentAciveAssessment());
        dispatch(loadEstablishmentAssessment());
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function submit(periodAssessment) {
  return function (dispatch, getState) {
    const { establishment, SAform } = getState();
    const establishmentId = establishment.establishmentProfile.id;
    const isReturned = periodAssessment.isReturned;
    dispatch(beginAjaxCall());
    periodAssessment.assessment.id = periodAssessment.SAform.assessmentId;
    periodAssessment.assessment.period_id = periodAssessment.SAform.periodId;
    periodAssessment.assessment.assessment_version =
      periodAssessment.SAform.assessmentVersion;
    periodAssessment.assessment.period_assessment_id =
      periodAssessment.SAform.PeriodAssessmentId;
    return request(
      `/v1/establishments/${establishmentId}/${
        isReturned ? "returned_assessment" : "assessments"
      }`,
      "POST",
      { assessment: periodAssessment.assessment }
    )
      .then(() => {
        dispatch(loadEstablishmentAciveAssessment(establishmentId));
        dispatch(loadEstablishmentAssessment(establishmentId));
        dispatch(loadEstablishments());
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function savePeriodAssessmentCaliberAnswer(periodAssessment) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return (
      EstablishmentAssessmentApi.savePeriodSelfAssessmentAnswer({
        submission: periodAssessment.submission,
      })
        // return request('/v1/establishments/assessments', 'PATCH', { submission: periodAssessment.submission })
        .then(() => {
          dispatch(endAjaxCall());
        })
        .catch((error) => {
          dispatch(ajaxCallError(error));
          dispatch(endAjaxCall());
          throw error;
        })
    );
  };
}

export function savePeriodAssessmentCaliberAttachment(periodAssessment) {
  return function (dispatch) {
    dispatch(beginAjaxCall());

    const data = new FormData();
    data.append("file", periodAssessment.attachment.answer.file);
    data.append("name", periodAssessment.attachment.answer.name);
    data.append(
      "period_assessment",
      periodAssessment.attachment.period_assessment
    );
    data.append(
      "assessment_caliber",
      periodAssessment.attachment.assessment_caliber
    );
    data.append(
      "caliber_attachment",
      periodAssessment.attachment.caliber_attachment
    );

    return (
      EstablishmentAssessmentApi.savePeriodSelfAssessmentAttachment(
        periodAssessment
      )
        // return request('/v1/establishments/assessments', 'POST', data, { upload: true })
        .then(() => {
          dispatch(endAjaxCall());
        })
        .catch((error) => {
          dispatch(ajaxCallError(error));
          dispatch(endAjaxCall());
          throw error;
        })
    );
  };
}

export function deletePeriodAssessmentCaliberAttachment(periodAssessment) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return (
      EstablishmentAssessmentApi.savePeriodSelfAssessmentAttachment(
        periodAssessment
      )
        // return request(`/v1/establishments/assessments/${periodAssessment.attachment.period_assessment}/attachments/${periodAssessment.attachment.caliber_attachment}`, 'DELETE')
        .then(() => {
          dispatch(endAjaxCall());
        })
        .catch((error) => {
          dispatch(ajaxCallError(error));
          dispatch(endAjaxCall());
          throw error;
        })
    );
  };
}
