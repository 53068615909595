import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function attachmentCategories(state = initialState.attachmentCategories, action) {
    switch (action.type) {
        case types.LOAD_ATTACHMENTCATEGORIES_SUCCESS:
            return action.attachmentCategories;

        case types.CREATE_ATTACHMENTCATEGORY_SUCCESS:
            return [
                ...state,
                Object.assign({}, action.attachmentCategory)
            ];

        case types.UPDATE_ATTACHMENTCATEGORY_SUCCESS:
            return [
                ...state.filter(attachmentCategory => attachmentCategory.id !== action.attachmentCategory.id),
                Object.assign({}, action.attachmentCategory)
            ];

        default:
            return state;
    }
}

