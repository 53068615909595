import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { downloadURI } from "../../../../actions/SAformActions";
import request from "../../../../common/request";

import AttachmentIcon from "./assets/attachment-icon.svg";
import DocumentIcon from "./assets/document-icon.svg";

import "./caliber-attachment.scss";
import { Text } from "../../../../../../shared-components/my-scope/atoms/text/index";

export const CaliberAttachment = ({ attachments, caliberIndex }) => {
  const establishmentId = useSelector(
    (state) => state.establishment.establishmentProfile.id
  );

  const download = useCallback(
    (attachment, filename) => {
      if (attachment.url_attachment) {
        downloadURI(attachment.url_attachment, filename);
      } else {
        request(
          `/v1/establishments/${establishmentId}/assessments/attachment/${attachment.sa_id}`
        )
          .then((response) => response.json())
          .then((data) => downloadURI(data.attachment_url, filename));
      }
    },
    [establishmentId]
  );
  return (
    <div className="caliber-attachment">
      {(attachments?.filter((attach) => attach?.file_name?.length > 0))?.length > 0 && (
        <header className="caliber-attachment-header">
          <img className="caliber-attachment-header__icon" src={AttachmentIcon} />
          <Text isBold textType="p5" className="caliber-attachment-header__title">
            المرفقات
          </Text>
        </header>
      )}
      <main className="caliber-attachment-main">
        {attachments?.map((attachment, index) =>
          attachment.file_name != "" ? (
            <div className="caliber-attachment-document" key={attachment.id}>
              <img
                className="caliber-attachment-document__file"
                onClick={() => download(attachment, attachment.file_name)}
                src={DocumentIcon}
                alt="Attachment Icon"
                id={`caliber-${caliberIndex}-attachment-${index}-download`}
              />
              <Text
                textType="p7"
                className="caliber-attachment-document__file-name"
              >
                {attachment.file_name}
              </Text>
            </div>
          ) : (
            ""
          )
        )}
      </main>
    </div>
  );
};

CaliberAttachment.propTypes = {
  attachments: PropTypes.array.isRequired,
  caliberIndex: PropTypes.number,
};

export default CaliberAttachment;
