import initialState from './initialState';
import {
  FETCH_TRAINING_SUBJECTS_FAILURE,
  FETCH_TRAINING_SUBJECTS_SUCCESS,
  FETCH_TRAINING_SUBJECTS,
  FETCH_TRAINING_SUBJECT_FAILURE,
  FETCH_TRAINING_SUBJECT_SUCCESS,
  FETCH_TRAINING_SUBJECT,
} from '../actions/actionTypes';

export default function trainingSubjects(state = initialState.trainingSubjects, action) {
  switch (action.type) {
    case FETCH_TRAINING_SUBJECTS:
      return {
        ...state,
        trainingSubjectsListFetching: true,
        trainingSubjectsListFetchingError: null,
      };

    case FETCH_TRAINING_SUBJECTS_FAILURE:
      return {
        ...state,
        trainingSubjectsListFetching: false,
        trainingSubjectsListFetchingError: action.payload.error,
      };

    case FETCH_TRAINING_SUBJECTS_SUCCESS:
      return {
        ...state,
        trainingSubjectsList: action.payload.training_subjects.slice(),
        trainingSubjectsListFetching: false,
        trainingSubjectsTotal: action.payload.total,
      };

      case FETCH_TRAINING_SUBJECT:
      return {
        ...state,
        trainingSubject: null,
        trainingSubjectFetching: true,
        trainingSubjectFetchingError: null,
      };

    case FETCH_TRAINING_SUBJECT_FAILURE:
      return {
        ...state,
        trainingSubjectFetching: false,
        trainingSubjectFetchingError: action.payload.error,
      };

    case FETCH_TRAINING_SUBJECT_SUCCESS:
      return {
        ...state,
        trainingSubject: action.payload.training_subject,
        trainingSubjectFetching: false,
      };


    default:
      return state;
  }
}

