import React from "react";
import { StepContainer } from "./self-components/components/step-container";

export default function SelfFormChildern({ history }) {
  return (
    <div className="flex flex-col w-full  h-full assessment-form-child-height">
      <StepContainer history={history} />
    </div>
  );
}
