const RIYADH_LOCATION_LAT = 24.7162642;
const RIYADH_LOCATION_LNG = 46.6865202;

export const defaultMapLocation = {
  lat: RIYADH_LOCATION_LAT,
  lng: RIYADH_LOCATION_LNG,
};

export const DEFAULT_NITAQAT_COLOR = "80ac87";

const MANDATORY_FIELD_ERROR_MSG = "الرجاء ادخال جميع الحقول المطلوبة";
const TAX_MUST_CONSIST_OF_15_NUMBERS_MSG =
  "عذرا ،الرقم الضريبي المدخل غير صحيح يتكون الرقم الضريبي من 15 رقم";

export function handleLocationChange(location) {
  const { address, position } = location;
  this.props.actions.updateEstablishmentProfile({
    key: "google_address",
    value: address,
  });
  this.props.actions.updateEstablishmentProfile({
    key: "lat",
    value: { Float64: position.lat, Valid: true },
  });
  this.props.actions.updateEstablishmentProfile({
    key: "lng",
    value: { Float64: position.lng, Valid: true },
  });
}

export function validateProfileEditing() {
  const { establishmentProfile } = this.props;
  let formIsValid = true;
  let errors = {};

  if (
    !establishmentProfile.tax_number ||
    !establishmentProfile.tax_number.trim().length
  ) {
    // errors.tax_number = MANDATORY_FIELD_ERROR_MSG;
    formIsValid = true;
  } else if (establishmentProfile.tax_number.length !== 15) {
    errors.tax_number = TAX_MUST_CONSIST_OF_15_NUMBERS_MSG;
    formIsValid = false;
  }

  // if (!establishmentProfile.lat.Valid || !establishmentProfile.lng.Valid) {
  //   errors.google_address = MANDATORY_FIELD_ERROR_MSG;
  //   formIsValid = false;
  // }

  this.setState({
    profileEditingValidationErrors: { ...errors },
  });

  return formIsValid;
}

export function saveProfile() {
  const { establishmentProfile } = this.props;

  return this.props.actions.saveEstablishmentProfile(establishmentProfile.id, {
    establishment_details: {
      tax_number: establishmentProfile.tax_number,
      lat: establishmentProfile.lat.Float64,
      lng: establishmentProfile.lng.Float64,
      google_address: establishmentProfile.google_address,
      english_name: establishmentProfile.english_name,
      payment_requester_name: establishmentProfile.payment_requester_name,
      IBAN_number: establishmentProfile.IBAN_number,
      bank_id: establishmentProfile.bank_id,
    },
  });
}

export function onProfileFieldChange(e) {
  const key = e.target.name;
  const value = e.target.value;

  // Fields restrictions:
  switch (key) {
    case "tax_number": {
      if (!/^\d*$/g.test(value)) {
        return false;
      }
    }
  }

  this.props.actions.updateEstablishmentProfile({ key, value });
}

export const emptyEstablishmentProfile = {
  establishment_id: "",
  establishment_name: "",
  labor_office_id: "",
  sequence_id: "",
  establishment_status_id: "",
  establishment_status: "",
  main_economic_activity_id: "",
  sub_economic_activity_id: "",
  economic_activity_id: "",
  economic_activity_name: "",
  color_id: "",
  nitaqat_color: "",
  size_id: "",
  size_name: "",
  color_code: "",
  saudis: "",
  foreigners: "",
  total_laborer_count: "",
  tax_number: "",
  lat: {
    Float64: null,
    Valid: false,
  },
  lng: {
    Float64: null,
    Valid: false,
  },
};
