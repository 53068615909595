import * as types from "./actionTypes";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "./ajaxStatusActions";
import request, { parseError } from "../common/request";
import qs from "query-string";

export const fetchInterActiveGuide = async () => {
  const res = await request("/interactive-guide/files", "GET", null, {}, false);
  const jsonRes = await res?.json();
  return jsonRes ?? {};
};

export const downloadAttachment = async (attachmentId) => {
  const res = await request(
    `/interactive-guide/download/${attachmentId}`,
    "GET",
    null,
    {},
    false
  );
  const jsonRes = await res?.blob();
  return jsonRes ?? {};
};
