import React from "react";
import Wrapper from "./self-components/components/wrapper";
import SelfFormChildern from "./child";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import "../../../../public/tailwind-output.css";

export default function DashboardIndex(props) {
  // console.log("isReturned", props.location.state?.isReturned);
  const { assessment_version, id, isBasicAssessment, periodAssessmentId } =
    props?.match?.params;

  // console.log("assessmentId, periodId, establishmentProfile", {
  //   assessment_version,
  //   id,
  //   isBasicAssessment,
  //   periodAssessmentId,
  // });
  return (
    <PageWrapper withBreadcrumbs>
      <Wrapper params={props?.match?.params}>
        <SelfFormChildern history={props.history} />
      </Wrapper>
    </PageWrapper>
  );
}
