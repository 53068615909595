import React from "react";
import PropTypes from "prop-types";
import "./caliberItemContent.css";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";

class CaliberItemContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  toggleCollapsed(id) {
    this.setState({ collapsed: !this.state.collapsed });

    let elem = document.getElementById(id);
    if (elem.style.display === "flex") {
      elem.style.display = "none";
    } else {
      elem.style.display = "flex";
    }
  }

  render() {
    const {
      id,
      caliberItemTitle,
      caliberItemDescription,
      caliberItemSecondaryDescription,
    } = this.props;
    const { collapsed } = this.state;

    return (
      <div className="caliber-item-wrapper ">
        <div className="caliber-panel-card ">
          <div
            className="caliber-panel-heading flex-1"
            onClick={() => {
              this.toggleCollapsed(id);
            }}
          >
            <Text
              Tag="div"
              textType="h8"
              isBold
              className="caliber-panel-title "
            >
              {caliberItemTitle}
            </Text>

            <i
              className={`fa icon-arrow-${
                collapsed ? "down" : "up"
              } collapse-btn arrow-wrapper `}
            />
          </div>
        </div>
        <div id={id} className="caliber-panel-body-display">
          <div className="caliber-panel-body">
            <Text
              Tag="div"
              textType="p4"
              isBold
              className="caliber-item-title-1"
            >
              شرح المادة
              <Text Tag="div" textType="p4" className="caliber-item-text">
                {caliberItemDescription}
              </Text>
            </Text>
            <Text
              Tag="div"
              textType="p4"
              isBold
              className="caliber-item-title-1 "
            >
              إجراءات التحقق من الامتثال
              <Text Tag="div" textType="p4" className="caliber-item-text">
                {caliberItemSecondaryDescription}
              </Text>
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

CaliberItemContent.propTypes = {
  caliberItemTitle: PropTypes.string,
  caliberItemDescription: PropTypes.string,
  caliberItemSecondaryDescription: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string,
};

export default CaliberItemContent;
