import initialState from "./initialState";
import {
  IMS_INFO_START_LOADING,
  IMS_INFO_STOP_LOADING,
  IMS_INFO_SET_ERROR,
} from "../actions/actionTypes";

export default function imsInfo(state = initialState.imsInfo, action) {
  switch (action.type) {
    case IMS_INFO_START_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
      });
    case IMS_INFO_STOP_LOADING:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case IMS_INFO_SET_ERROR:
      return Object.assign({}, state, {
        error: true,
        isLoading: false,
      });

    default:
      return state;
  }
}
