import React from "react";
export const CardsGroupTitle = ({ title }) => {
  return (
    <h1
      className={
        "text-[1.25rem] text-deep_sea_blue font-mol font-bold leading-relaxed"
      }
    >
      {title}
    </h1>
  );
};
