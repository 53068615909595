import initialState from "./initialState";
import {
  SAVE_SELECTED_DURATION,
  RESET_SELECTED_DURATION,
} from "../actions/actionTypes";

export default function duration(state = initialState.duration, action) {
  switch (action.type) {
    case SAVE_SELECTED_DURATION:
      return Object.assign({}, state, {
        selectedDuration: action.duration,
      });
    case RESET_SELECTED_DURATION:
      return Object.assign({}, state, {
        selectedDuration: null,
      });
    default:
      return state;
  }
}
