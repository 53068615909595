import * as React from 'react';

export const DislikeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.662"
    height="14.478"
    viewBox="0 0 15.662 14.478"
    {...props}
  >
    <g id="Dislike_icon" data-name="20 Dislike" transform="translate(-0.218 -0.141)">
      <path
        id="Fill_515"
        data-name="Fill 515"
        d="M9.107,14.478a1.9,1.9,0,0,1-1.829-1.4L6.158,9.214a1.241,1.241,0,0,0-.981-.879v.149a.777.777,0,0,1-.765.787H.765A.777.777,0,0,1,0,8.484V.788A.777.777,0,0,1,.765,0H4.412a.767.767,0,0,1,.739.595A2.878,2.878,0,0,1,6.9,0h4.259a3.113,3.113,0,0,1,2.982,2.271l1.427,4.8a2.2,2.2,0,0,1-.316,1.894,2.06,2.06,0,0,1-1.666.864H10.867l.144,2.569a1.971,1.971,0,0,1-.943,1.809A1.868,1.868,0,0,1,9.107,14.478ZM6.9,1.345a1.644,1.644,0,0,0-1.171.5l-.556.572V6.99A2.552,2.552,0,0,1,7.409,8.83l1.121,3.861a.6.6,0,0,0,.578.443.584.584,0,0,0,.3-.085.62.62,0,0,0,.3-.571L9.532,9.363a.842.842,0,0,1,.226-.624.786.786,0,0,1,.576-.25H13.59a.753.753,0,0,0,.608-.309.8.8,0,0,0,.125-.712L12.9,2.664a1.809,1.809,0,0,0-1.733-1.319Z"
        transform="translate(0.218 0.141)"
      // fill="#92929d"
      />
    </g>
  </svg>
);
