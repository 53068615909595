import {
    SET_ACCESSIBILITY
} from "../actions/actionTypes";

const ACCESSIBILITY_INITIAL_STATE = {
    base: 1,
    inverted: false,
}
export default function AccessibilityReducer(state = ACCESSIBILITY_INITIAL_STATE, action) {
    switch (action.type) {
        case SET_ACCESSIBILITY:
            // payload should be { base: number; inverted: boolean }
            return action.payload;
        default:
            return state;
    }
}

export const setWindowSize = (size) => ({
    type: SET_SIZE_WINDOW,
    payload: size,
});