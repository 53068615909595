import nth from "lodash/nth";
import last from "lodash/last";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function attachmentCategories(
  state = initialState.history,
  action
) {
  switch (action.type) {
    case types.UPDATE_LOCATION_HISTORY: {
      const current = last(state.locations);

      if (current === action.location) {
        return Object.assign({}, state);
      }

      const lastLocation = nth(state.locations, -2);
      const isDuplicate = lastLocation === action.location;

      return {
        locations: isDuplicate
          ? state.locations.slice(0, -1)
          : state.locations.concat(action.location),
      };
    }

    default:
      return state;
  }
}
