import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_SUCCESS,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  LOAD_NOTIFICATIONS_SUCCESS,
  LOAD_NOTIFICATIONS_FAILURE,
  LOAD_NOTIFICATIONS,
} from "../actions/actionTypes";
import initialState from "./initialState";

export default function (state = initialState.notifications, action) {
  switch (action.type) {
    case LOAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        list: action.payload.notifications,
        listFetching: false,
        listFetchingError: null,
        listTotal: action.payload.total,
      };
    case LOAD_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationsListFetching: false,
        notificationsListFetchingError: action.payload.error,
      };
    case LOAD_NOTIFICATIONS:
      return {
        ...state,
        listFetching: true,
        listFetchingError: null,
      };
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        notificationsListFetching: true,
        notificationsListFetchingError: null,
      };

    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationsListFetching: false,
        notificationsListFetchingError: action.payload.error,
      };

    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsList: action.payload.notifications.slice(),
        notificationsListFetching: false,
        notificationsTotal: action.payload.total,
      };

    default:
      return state;
  }
}
